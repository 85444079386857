import React from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useApiClient,
  Link,
  ReviewCard,
  InfiniteList,
  decodeHTMLEntities,
  replaceNewLine,
  getFormattedDateShortMonthDDYYYY,
} from '@marageti/z4-lib';
import { getAvatarSrcAndAlt, getRatingNumber } from '../../utils/helpers';

const TravelerReviews = ({ travelerName }: { travelerName: string }) => {
  const apiClient = useApiClient();
  const { travelerId } = useParams();

  const getSearchQuery = (state: any) => {
    const { selectedChips } = state;

    const filterBy = [{ field: 'travelerId', value: [travelerId] }];

    if (selectedChips?.length) {
      filterBy.push({
        field: 'rating',
        value: selectedChips,
      });
    }

    const fullText = undefined;

    return {
      filterBy,
      fullText,
      sortBy: [{ field: 'createdAt', order: 'desc' }],
    };
  };

  return (
    <>
      <Typography variant="h2" mb={3}>
        Reviews by&nbsp;
        {travelerName}
      </Typography>
      <InfiniteList
        searchFilterSort={{
          disableSearch: true,
          disableCountriesFilter: true,
          chips: {
            label: undefined,
            options: [
              { key: 0, label: '5 Stars', value: '100' },
              { key: 1, label: '4 Stars', value: '80' },
              { key: 2, label: '3 Stars', value: '60' },
              { key: 3, label: '2 Stars', value: '40' },
              { key: 4, label: '1 Star', value: '20' },
            ],
            defaultValue: [],
          },
        }}
        getSearchQuery={getSearchQuery}
        searchFunction={apiClient.reviewsClient.postReviewsSearch}
        renderItem={(review: any) => (
          <Link key={review.id} to={`/reviews/${review.id}`} state={{ review }}>
            <ReviewCard
              title={replaceNewLine(decodeHTMLEntities(review.title))}
              subtitle={`${review.travelerName} • ${getFormattedDateShortMonthDDYYYY(review.createdAt) || ''}`}
              avatar={getAvatarSrcAndAlt(review.travelerName, review.travelerAvatar)}
              rating={getRatingNumber(review.rating)}
            />
          </Link>
        )}
        options={{
          hideCount: true,
        }}
      />
    </>
  );
};

export default TravelerReviews;

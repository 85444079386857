import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from '@marageti/z4-lib';
import { AgentCount } from '@marageti/z4-sdk/lib/travel';
import { LoadingButton } from '@mui/lab';

type DeclineFormProps = {
  reason: string | undefined;
  comment: string | undefined;
  onSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DeclineForm = ({
  comment,
  reason,
  onSelectChange,
  onCommentChange,
}: DeclineFormProps) => (
  <FormControl fullWidth>
    <TextField
      select
      value={reason}
      label={reason ? 'Reason' : 'Select A Reason'}
      onChange={onSelectChange}
    >
      <MenuItem value="unavailable">Unavailable / On Vacation</MenuItem>
      <MenuItem value="other">Other</MenuItem>
    </TextField>
    {reason === 'unavailable' && (
      <Typography mt={1} variant="caption" color="primary.light">
        Make sure to update your&nbsp;
        <Link underline="always" color="#0D1718" to="/availability">availability</Link>
        &nbsp;settings. We use this to avoid giving you matches when you are unavailable.
      </Typography>
    )}
    <TextField
      label="Comments"
      multiline
      rows={6}
      value={comment}
      onChange={onCommentChange}
    />
  </FormControl>
);

type AgentOptionsProps = {
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  agents: any[] | undefined;
};

const AgentOptions = ({
  value,
  onChange,
  agents,
}:AgentOptionsProps) => (
  <>
    <Typography variant="captionBold" color="primary">Assign to...</Typography>
    <FormControl>
      <RadioGroup
        aria-labelledby="accept-radio-group-label"
        name="accept-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {agents && agents.map((agent) => (
          <FormControlLabel key={agent.id} value={agent.id} control={<Radio />} label={`${agent.fullName} (${agent.count})`} />
        ))}
      </RadioGroup>
    </FormControl>
  </>
);

type AcceptFormProps = {
  agents: AgentCount[] | undefined;
  isSubmitting: boolean;
  onRejectTrip: (arg:{ reason:string, comment: string }) => void;
  onAssignTrip: (agentId: string) => void;
  cardSx?: SxProps;
};

const AcceptForm = ({
  cardSx = {},
  agents,
  isSubmitting,
  onRejectTrip,
  onAssignTrip,
}:AcceptFormProps) => {
  const [actionValue, setActionValue] = useState('accept');
  const [selectedAgentId, setSelectedAgentId] = useState<string | undefined>('');
  const [rejectReason, setRejectReason] = useState<string | undefined>('');
  const [rejectComment, setRejectComment] = useState<string | undefined>('');

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionValue((event.target as HTMLInputElement).value);
  };

  const handleAgentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAgentId((event.target as HTMLInputElement).value);
  };

  const handleReasonChange = (event: SelectChangeEvent) => {
    setRejectReason(event.target.value as string);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectComment(event.target.value);
  };

  const handleConfirmReject = () => {
    onRejectTrip({ reason: rejectReason as string, comment: rejectComment as string });
  };

  const handleAssign = () => {
    onAssignTrip(selectedAgentId as string);
  };

  return (
    <Card variant="outlined" sx={{ backgroundColor: 'inherit', ...cardSx }}>
      <CardHeader titleTypographyProps={{ variant: 'h4' }} id="accept-radio-group-label" title="Match Acceptance" />
      <CardContent sx={{ pt: 3 }}>
        <Stack spacing={2}>
          <FormControl>
            <RadioGroup
              aria-labelledby="accept-radio-group-label"
              name="accept-radio-buttons-group"
              value={actionValue}
              onChange={handleActionChange}
            >
              <FormControlLabel value="accept" control={<Radio />} label="Accept" />
              <FormControlLabel value="decline" control={<Radio />} label="Decline" />
            </RadioGroup>
          </FormControl>
          {actionValue === 'decline' && (
            <Typography variant="caption" color="primary.light">
              This is a qualified trip from Zicasso.
              If you have any questions or concerns about your ability to fulfill this trip, please&nbsp;
              <Link underline="always" color="#0D1718" to="./messages">message us</Link>
              &nbsp;before you decline this trip. If your decline rate is too high, you may see a reduction in trip capacity.
            </Typography>
          )}
          <Divider flexItem />
          {actionValue === 'accept' && (
            <AgentOptions
              agents={agents}
              value={selectedAgentId}
              onChange={handleAgentChange}
            />
          )}
          {actionValue === 'decline' && (
            <DeclineForm
              reason={rejectReason}
              comment={rejectComment}
              onCommentChange={handleCommentChange}
              onSelectChange={handleReasonChange}
            />
          )}
        </Stack>
      </CardContent>
      <CardActions sx={{ pt: 3 }}>
        <Stack spacing={2} width={{ mobile: '100%', desktop: 'fit-content' }} direction={{ mobile: 'column', desktop: 'row' }}>
          {actionValue === 'accept' && (
            <LoadingButton loading={isSubmitting} variant="contained" disabled={!selectedAgentId} onClick={handleAssign}>
              Accept & Assign
            </LoadingButton>
          )}
          {actionValue === 'decline' && (
            <LoadingButton loading={isSubmitting} variant="contained" color="error" disabled={!rejectComment || !rejectReason} onClick={handleConfirmReject}>
              Decline Trip
            </LoadingButton>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
};

export default AcceptForm;

import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container, IconButton, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ContextMenuComplete, OutlinedIcons, SecondaryAppBar } from '@marageti/z4-lib';
import { useAuthStore } from '../../store';
import UserProfile from '../../components/user-profile';
import SecurityForm from '../../components/security-form';

type ContextMenuCompleteProps = Parameters<typeof ContextMenuComplete>[0];

const DefaultView = ({
  agentId,
  contextMenu,
  onClickChangePassword,
}:{
  agentId: string;
  contextMenu: ContextMenuCompleteProps;
  onClickChangePassword: () => void;
}) => (
  <Container
    maxWidth="md"
    sx={{ pb: 8, my: 8 }}
  >
    <Typography variant="h2" sx={{ mb: 3 }}>My Profile</Typography>
    <Stack spacing={6}>
      <UserProfile
        agentId={agentId}
        contextMenuProps={contextMenu}
      />
      <Card>
        <CardHeader title="Security" sx={{ mb: 2 }} />
        <CardContent>
          <Stack
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Typography variant="caption" fontWeight="bold" color="textPrimary">
              Password
            </Typography>
            <Typography color="text.secondary">
              ************
            </Typography>
          </Stack>
          <Button variant="outlined" onClick={onClickChangePassword}>
            Change Password
          </Button>
        </CardContent>
      </Card>
    </Stack>
  </Container>
);

const ChangePasswordView = ({
  onNavigateBack,
}:{
  onNavigateBack: () => void;
}) => (
  <>
    <SecondaryAppBar
      startAdornment={(
        <IconButton onClick={onNavigateBack}>
          <OutlinedIcons.OutlinedArrowLeftIcon color="primary" />
        </IconButton>
      )}
    />
    <Container maxWidth="md">
      <Box
        sx={{
          pt: {
            desktop: 6,
            mobile: 3,
          },
          pb: 12,
        }}
      >
        <SecurityForm
          onSuccessfulPasswordChange={onNavigateBack}
        />
      </Box>
    </Container>
  </>
);

const MyProfile = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const {
    loggedInAgent,
  } = useAuthStore();

  const handleEditClick = () => {
    navigate(`/users/${loggedInAgent?.id}/edit`);
  };

  const editContextMenu = {
    id: 'user-profile-context-menu',
    items: [{
      label: 'Edit',
      action: handleEditClick,
    }],
  };

  if (showPassword) {
    return (
      <ChangePasswordView
        onNavigateBack={() => setShowPassword(false)}
      />
    );
  }

  return (
    <DefaultView
      agentId={loggedInAgent!.id}
      contextMenu={editContextMenu}
      onClickChangePassword={() => setShowPassword(true)}
    />
  );
};
export default MyProfile;

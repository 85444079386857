import React from 'react';
import {
  Container, Box, Alert, AlertTitle, Typography, Link,
} from '@mui/material';
import {
  Review,
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  mapReviewToReviewProps,
} from '@marageti/z4-lib';
import { Review as ReviewType } from '@marageti/z4-sdk/lib/travel';
import { isBefore } from 'date-fns';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { ViewProps } from './types';
import ZSpinner from '../../../components/z-lib/z-spinner';

const TripNotHappenedAlert = () => (
  <Alert severity="info">
    <AlertTitle>This trip hasn&apos;t happened yet.</AlertTitle>
  </Alert>
);

const EmptyReviewsAlert = () => (
  <Alert severity="info">
    <AlertTitle>This trip hasn&apos;t been reviewed yet.</AlertTitle>
  </Alert>
);

const ErrorAlert = ({ error } : { error: ApiError }) => (
  <ErrorBox>
    <ErrorTitle>
      There was an error getting review details
    </ErrorTitle>
    <ErrorMessage>
      {`Code: ${error.code}: ${error.message}`}
    </ErrorMessage>
  </ErrorBox>
);

const DetailView = ({
  tripMatch, reviews, loading, error,
}: ViewProps) => {
  const isDepartureDatePassed = isBefore(new Date(), new Date(tripMatch?.departureDate || ''));

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{
          pt: { desktop: 11, mobile: 2 },
          pb: 5,
        }}
        >
          <Box display="flex" justifyContent="center"><ZSpinner /></Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{
        pt: { desktop: 11, mobile: 2 },
        pb: 5,
      }}
      >
        {error && <ErrorAlert error={error} />}
        {tripMatch && isDepartureDatePassed && <TripNotHappenedAlert />}
        {reviews && reviews.length === 0 && !isDepartureDatePassed && <EmptyReviewsAlert />}
        {reviews && reviews.length > 0 && reviews.map((review: ReviewType) => (
          <div key={review.id}>
            <Review
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...mapReviewToReviewProps(review)}
            />
            {!review?.agentReply.reply && (
              <Box sx={{ mt: 5 }}>
                <Alert severity="info">
                  <AlertTitle>Reply to this Review</AlertTitle>
                  <Typography>
                    To reply to a review, please email the
                    {' '}
                    <Link href="mailto:review@zicasso.com" underline="always" color="inherit">
                      Zicasso Care Team
                    </Link>
                    . We plan to implement the ability to reply directly in the future.
                  </Typography>
                </Alert>
              </Box>
            )}
          </div>
        ))}

      </Box>
    </Container>
  );
};

export default DetailView;

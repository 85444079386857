import React, { useMemo } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Paper,
  Link,
} from '@mui/material';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { format } from 'date-fns';
import { SalesRecordingStatus, SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';

type Props = {
  sales: SalesRecording[],
  totalSales: string | number | undefined,
  totalCommission: string | number | undefined,
};

const typeLabel = {
  [SalesRecordingType.Adjustment]: 'Sales Adjustment',
  [SalesRecordingType.General]: 'Standard Sale',
  [SalesRecordingType.PartnerRepeat]: 'Repeat Sale',
  [SalesRecordingType.TravelerReferral]: 'Referral Sale',
  [SalesRecordingType.CommissionAfterTravel]: 'Commission After Travel',
  [SalesRecordingType.PlanningFee]: 'Planning Fee',
  [SalesRecordingType.RefundCredit]: 'Refund Credit',
};

// Update repeat/referral to URL used in 1943
const typeUrl = {
  [SalesRecordingType.Adjustment]: 'adjustment',
  [SalesRecordingType.General]: 'recording',
  [SalesRecordingType.PartnerRepeat]: 'referral',
  [SalesRecordingType.TravelerReferral]: 'referral',
};

const formatDate = (timestamp: string) => format(new Date(timestamp), 'MMM d, yyyy');

const MonthlySalesView = ({
  sales,
  totalCommission,
  totalSales,
}: Props) => {
  const navigate = useNavigate();

  const rows = useMemo(() => (sales.map((sale) => ({
    id: sale.id,
    status: sale.status,
    recorded: formatDate(sale.createdAt),
    type: sale.salesRecordingType,
    amount: new Intl.NumberFormat('en-US', { style: 'currency', currency: sale.price.currencyCode }).format(sale.price.amount),
    commission: new Intl.NumberFormat('en-US', { style: 'currency', currency: sale.commission.currencyCode }).format(sale.commission.amount),
  }))), [sales]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Recorded</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Commission</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} onClick={() => navigate(`${row.id}`)} sx={{ cursor: 'pointer' }}>
              <TableCell>{row.recorded}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', width: { desktop: '380px' } }}>{typeLabel[row.type]}</TableCell>
              <TableCell align="right" sx={{ color: row.amount.startsWith('-') ? 'error.main' : undefined }}>{row.amount}</TableCell>
              <TableCell align="right" sx={{ color: row.commission.startsWith('-') ? 'error.main' : undefined }}>{row.commission}</TableCell>
              {(row.status === SalesRecordingStatus.SoldStatus
                && (row.type !== SalesRecordingType.PlanningFee && row.type !== SalesRecordingType.CommissionAfterTravel))
                && (
                  <TableCell
                    sx={{ cursor: 'default' }}
                    onClick={(e) => { e.stopPropagation(); }}
                  >
                    {row.type in typeUrl
                      ? (
                        <Link
                          component={RouterLink}
                          to={`${typeUrl[row.type as keyof typeof typeUrl]}/${row.id}`}
                          variant="captionBold"
                          underline="none"
                        >
                          Edit
                        </Link>
                      )
                      : null}
                  </TableCell>
                )}

              {row.status !== SalesRecordingStatus.SoldStatus && (
                <TableCell sx={{ fontStyle: 'italic' }}>
                  {row.status === SalesRecordingStatus.InvoicedStatus ? 'Invoiced' : 'Approved'}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell>Totals</TableCell>
            <TableCell align="right">{totalSales}</TableCell>
            <TableCell align="right">{totalCommission}</TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MonthlySalesView;

import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  TripMatch, SalesRecording, SalesRecordingStatus, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SalesRecordingView from './sales-recording-view';

const SalesRecordingContainer = () => {
  const {
    tripMatchId,
    salesRecordingId,
  } = useParams<{
    tripMatchId: string,
    salesRecordingId?: string
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const {
    isLoading: tripMatchIsLoading,
    data: tripMatch,
    error: tripMatchError,
    mutate: mutateTripMatch,
  } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);

  const {
    isLoading: salesRecordingIsLoading,
    data: salesRecording,
    error: salesRecordingError,
    mutate: mutateSalesRecording,
  } = useFetch<SalesRecording>(apiClient.salesRecordingsClient.getSalesRecording, [salesRecordingId, { params: { trip_match: tripMatchId } }], !!salesRecordingId);
  const {
    isLoading: salesRecordingsIsLoading,
    data: salesRecordings,
    error: salesRecordingsError,
  } = useFetch<SalesRecording[]>(apiClient.tripMatchesClient.getTripMatchSalesRecordings, [tripMatchId], !!tripMatchId);
  const salesRecordingToEdit = salesRecording;

  const getFormDisabledReason = (): string | false => {
    const isCreatingSalesRecording = !salesRecordingToEdit;
    const hasSalesRecordings = Boolean(salesRecordings?.length);

    if (salesRecordingId && !salesRecordingToEdit) {
      return 'No Sales Recording found.';
    }
    if (salesRecordingToEdit && salesRecordingToEdit.salesRecordingType !== SalesRecordingType.General) {
      return 'No Sales Recording found.';
    }
    if (tripMatch && ![
      TripMatchStatus.MatchActive,
      TripMatchStatus.MatchSold,
    ].includes(tripMatch.status)) {
      return 'Cannot create sales recording for this trip match.';
    }
    if (isCreatingSalesRecording && hasSalesRecordings) {
      return 'Only one standard sale can be created per trip match. To make additional changes, please edit the existing standard sale or create an sales adjustment.';
    }
    if (salesRecordingToEdit && (salesRecordingToEdit.status === SalesRecordingStatus.InvoicedStatus || salesRecordingToEdit?.status === SalesRecordingStatus.VerifiedStatus)) {
      return 'Standard sales that have been invoiced or verified can\'t be edited. To make changes, you\'ll need to create a sales adjustment.';
    }

    return false;
  };

  const isLoading = tripMatchIsLoading || salesRecordingIsLoading || salesRecordingsIsLoading;
  const isError = tripMatchError || salesRecordingError || salesRecordingsError;
  const formDisabledReason = getFormDisabledReason();

  const onSuccess = (createdUpdatedSalesRecordingId: string) => {
    mutateTripMatch();
    if (salesRecordingToEdit) {
      mutateSalesRecording();
    }
    // Fallback to sales recording page if the user cannot go back to the previous page
    if (location.key === 'default' && tripMatch) {
      navigate(`/trip-matches/${tripMatch.id}/sales/${createdUpdatedSalesRecordingId}`);
    } else {
      navigate(-1);
    }
  };

  const onCancel = () => {
    // Fallback to the home page if the user cannot go back to the previous page
    if (location.key === 'default' && tripMatch) {
      navigate('../');
    } else {
      navigate(-1);
    }
  };

  return (
    <SalesRecordingView
      isLoading={isLoading}
      isError={isError}
      tripMatch={tripMatch}
      salesRecordingToEdit={salesRecordingToEdit}
      formDisabledReason={formDisabledReason}
      onSuccess={onSuccess}
      onCancel={onCancel}
    />
  );
};

export default SalesRecordingContainer;

import {
  Dispatch,
  Reducer,
  useReducer,
} from 'react';
import {
  SET_IS_AUTHENTICATED,
  SET_GOOGLE_USER_TOKEN,
  RESET_AUTHENTICATED_AGENT,
  SET_AUTHENTICATED_AGENT,
} from './actions';
import { AuthStateProps } from './types';
import { Actions } from '../../types';

const initialState: AuthStateProps = {
  isAuthenticated: null,
  googleToken: null,
  loggedInAgent: null,
};

const reducer: Reducer<AuthStateProps, Actions> = (state, action): AuthStateProps => {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case SET_GOOGLE_USER_TOKEN:
      return {
        ...state,
        googleToken: action.googleToken,
      };
    case RESET_AUTHENTICATED_AGENT:
      return {
        ...state,
        googleToken: null,
        loggedInAgent: null,
      };
    case SET_AUTHENTICATED_AGENT:
      return {
        ...state,
        loggedInAgent: action.payload,
      };
    default:
      return state;
  }
};

const useAuthReducer = () => {
  const [auth, dispatchAuth] = useReducer(reducer, initialState);

  return [auth, dispatchAuth] as [AuthStateProps, Dispatch<Actions>];
};

export default useAuthReducer;

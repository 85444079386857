import { LocalStorageService, useApiClient, useFetch } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';

const LocalStorageClient = new LocalStorageService();

const useUser = () => {
  const apiClient = useApiClient();
  const email = LocalStorageClient.getFromLocalStorage('email');

  const user = useFetch<Agent>(apiClient.peopleClient.getPerson, [email], !!email);
  return user;
};

export default useUser;

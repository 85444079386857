import React from 'react';
import {
  AvatarCard,
} from '@marageti/z4-lib';
import { useConfirm } from 'material-ui-confirm';
import { Agent } from '@marageti/z4-sdk/lib/people';
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getInitials } from '../../utils/formatting';

export type ViewProps = {
  agents: Agent[] | undefined;
  currentAssignedToId: string | undefined;
  isSubmitting: boolean;
  onAgentClick: (agentId: string) => void;
  onCancel: () => void;
  open: boolean;
};

const AssignToView = ({
  agents,
  currentAssignedToId,
  isSubmitting,
  onAgentClick,
  onCancel,
  open,
}: ViewProps) => {
  const confirm = useConfirm();

  const confirmAssignTo = (agent: Agent) => {
    confirm({
      title: `${currentAssignedToId ? 'Reassign' : 'Assign'} to ${agent.fullName}`,
      description: `Are you sure you want to ${currentAssignedToId ? 'reassign' : 'assign'} this trip to ${agent.fullName}? Once confirmed, new message notifications will be sent to this agent.`,
      confirmationButtonProps: {
        color: 'primary',
      },
    })
      .then(() => onAgentClick(agent.id))
      .catch(() => { });
    onCancel();
  };

  return (
    <Dialog
      open={open}
    >
      <DialogTitle
        sx={{
          pb: 3,
          pt: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
        variant="h3"
      >
        {currentAssignedToId ? 'Reassign' : 'Assign to...'}
      </DialogTitle>
      <Box component="form" name="re-assign-agent-form">
        <DialogContent sx={{ padding: '0 32px' }}>
          <Stack spacing={2}>
            {agents?.map((agent) => (currentAssignedToId === agent.id ? null : (
              <ButtonBase key={agent.id} onClick={() => confirmAssignTo(agent)} sx={{ justifyContent: 'left' }} disabled={isSubmitting}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AvatarCard size={32} alt={agent.fullName} src={agent.avatar}>
                    {getInitials(agent)}
                  </AvatarCard>
                  <Typography variant="bodyBold" sx={{ ml: '12px', textAlign: 'left' }}>{agent.fullName}</Typography>
                </Box>
              </ButtonBase>
            )))}
          </Stack>
        </DialogContent>
        <DialogActions disableSpacing sx={{ p: 4, pt: 4 }}>
          <LoadingButton loading={isSubmitting} onClick={onCancel} sx={{ width: '100%' }} variant="outlined" color="primary">
            Cancel
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AssignToView;

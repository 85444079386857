/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Controller, FieldValues, SubmitHandler,
} from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  DialogTitle,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useForm,
  getSubmitButtonProps,
  BackButton,
  useApiClient,
  getFieldProps,
  useFetch,
} from '@marageti/z4-lib';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import {
  AvailabilityLimitType, Partner, PartnerAvailabilityRequest,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { addYears } from 'date-fns';
import { useAuthStore } from '../../../store';

const AddEvent = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    clearErrors,
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [submissionError, setSubmissionError] = useState<ApiError>();
  const {
    loggedInAgent,
  } = useAuthStore();

  const {
    data: partner,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const today = dayjs().toDate();
  const tomorrow = dayjs().add(1, 'day').toDate();
  const maxDate = addYears(new Date(), 10);
  const [minEndDate, setMinEndDate] = useState(tomorrow);

  const [selectedType, setSelectedType] = useState<AvailabilityLimitType | null>(null);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value as AvailabilityLimitType;
    setSelectedType(newType);
    if (newType === AvailabilityLimitType.LIMIT_LEADS) {
      setValue('weeklyCapacity', 0);
    }
    if (newType === AvailabilityLimitType.LIMIT_TRAVEL) {
      setValue('weeklyCapacity', null);
      if (errors.weeklyCapacity) clearErrors('weeklyCapacity');
    }
  };

  const handleStartDateChange = (
    newDate: Date | null,
    onChange: (date: Date | null) => void,
  ) => {
    onChange(newDate);
    if (newDate) {
      const newMinEndDate = dayjs(newDate).add(1, 'day').toDate();
      setMinEndDate(newMinEndDate);
      const currentEndDate = getValues('endDate');
      if (dayjs(currentEndDate).isBefore(newMinEndDate)) {
        setValue('endDate', newMinEndDate);
      }
    }
  };

  const navigateBack = () => {
    navigate('/availability');
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    if (!loggedInAgent || !loggedInAgent.partnerId) {
      return;
    }
    const request: PartnerAvailabilityRequest = {
      partnerId: loggedInAgent?.partnerId!,
      limitType: data.type,
      startDate: data.startDate,
      endDate: data.endDate,
      leadsPerWeek: data.type === AvailabilityLimitType.LIMIT_TRAVEL ? null : data.weeklyCapacity,
      comments: data.comments,
    };

    await apiClient.partnersClient.postPartnerAvailability(loggedInAgent?.partnerId, request)
      .then(() => {
        enqueueSnackbar('Event added.', { variant: 'success' });
        navigateBack();
      })
      .catch((e: ApiError) => {
        if (e.code === 401) navigate('/logout');
        enqueueSnackbar('Event add failed.', { variant: 'default' });
        setSubmissionError(e);
      });
  };

  const validateLeadCapacity = (value: any) => {
    const numValue = Number(value);
    if (Number.isNaN(numValue)) return 'Please enter a valid number';
    if (numValue < 0) return 'Please enter a number greater than or equal to 0';
    if (partner?.capacity && numValue >= partner.capacity) return `Must be less than the current Max Capacity of ${partner.capacity}`;
    return true;
  };

  const getHelperText = () => {
    if (errors.weeklyCapacity) {
      return errors.weeklyCapacity.message;
    } if (selectedType === AvailabilityLimitType.LIMIT_TRAVEL) {
      return 'Limit Leads Type required to set Lead Capacity';
    }
    return `Must be less than the current Max Capacity of ${partner?.capacity}`;
  };

  return (
    <Container maxWidth="md" sx={{ pt: { mobile: 3, desktop: 6 }, mb: 20 }}>
      <BackButton aria-label="back to availability" onClick={() => navigateBack()} edge="start" sx={{ mb: 2 }} />
      <DialogTitle sx={{ p: 0, mb: 3 }}>Update Capacity</DialogTitle>
      <form name="edit-availability-form" onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={3}>
          <Box>
            <Stack spacing={2}>
              <TextField
                required
                defaultValue={AvailabilityLimitType.LIMIT_LEADS}
                label="Type"
                select
                {...register('type')}
                {...getFieldProps('type', errors)}
                onChange={handleTypeChange}
              >
                <MenuItem key={AvailabilityLimitType.LIMIT_LEADS} value={AvailabilityLimitType.LIMIT_LEADS}>Lead Capacity Restriction</MenuItem>
                <MenuItem key={AvailabilityLimitType.LIMIT_TRAVEL} value={AvailabilityLimitType.LIMIT_TRAVEL}>Travel Date Restriction</MenuItem>
              </TextField>
              <List sx={{ mt: '0 !important', listStyleType: 'disc', pl: 3 }}>
                <ListItem sx={{
                  p: 0, display: 'list-item', '&::marker': { color: 'primary.light' }, lineHeight: '1rem',
                }}
                >
                  <Typography variant="caption">
                    Use&nbsp;
                    <Typography variant="captionBold">Lead Capacity Restriction</Typography>
                    &nbsp;to restrict your lead flow on a specific period of time (e.g.: too busy, out of office, etc.)
                  </Typography>
                </ListItem>
                <ListItem sx={{
                  p: 0, display: 'list-item', '&::marker': { color: 'primary.light' }, lineHeight: '1rem',
                }}
                >
                  <Typography variant="caption">
                    Use&nbsp;
                    <Typography variant="captionBold">Travel Dates Restriction</Typography>
                    &nbsp;for travel dates you are unable to accommodate (e.g.: cherry blossom season is sold out)
                  </Typography>
                </ListItem>
              </List>
              {selectedType !== AvailabilityLimitType.LIMIT_TRAVEL && (
                <TextField
                  type="number"
                  defaultValue={0}
                  label="Weekly Capacity"
                  inputProps={{ min: 0, max: partner?.capacity && partner.capacity - 1 }}
                  {...register('weeklyCapacity', { valueAsNumber: true, validate: validateLeadCapacity })}
                  {...getFieldProps('weeklyCapacity', errors)}
                  helperText={getHelperText() as string}
                />
              )}
              <Controller
                name="startDate"
                control={control}
                defaultValue={today}
                rules={{
                  validate: (value) => {
                    const selectedDate = dayjs(value);
                    return !selectedDate.isBefore(today, 'day') || 'Start Date must be today or later';
                  },
                }}
                render={({
                  field: {
                    onChange, onBlur, value, ref, name,
                  },
                }) => (
                  <DatePicker
                    label="Start Date"
                    onChange={(newDate) => handleStartDateChange(newDate, onChange)}
                    minDate={today}
                    maxDate={maxDate}
                    value={value}
                    views={['year', 'month', 'day']}
                    slotProps={{
                      textField: {
                        name,
                        onBlur,
                        error: !!errors.startDate,
                        sx: {
                          width: '100%',
                        },
                        helperText: errors.startDate?.message as string,
                        className: 'custom-datePicker',
                      },
                    }}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                defaultValue={minEndDate}
                rules={{
                  validate: (value) => {
                    const selectedDate = dayjs(value);
                    return !selectedDate.isBefore(tomorrow, 'day') || 'End Date must be later than today';
                  },
                }}
                render={({
                  field: {
                    onChange, onBlur, value, ref, name,
                  },
                }) => (
                  <DatePicker
                    label="End Date"
                    onChange={onChange}
                    minDate={minEndDate}
                    maxDate={maxDate}
                    value={value}
                    views={['year', 'month', 'day']}
                    slotProps={{
                      textField: {
                        name,
                        onBlur,
                        error: !!errors.endDate,
                        sx: { width: '100%' },
                        helperText: errors.endDate?.message as string,
                        className: 'custom-datePicker',
                      },
                    }}
                    inputRef={ref}
                  />
                )}
              />
              <TextField
                label="Comments"
                minRows={6}
                maxRows={12}
                multiline
                required
                sx={{
                  '.MuiOutlinedInput-root': {
                    padding: '10px 12px',
                    '> textarea': {
                      padding: '0 !important',
                    },
                  },
                }}
                {...register('comments', {
                  validate: (value: string) => value?.trim() !== '' || 'Comments are required',
                })}
                {...getFieldProps('comments', errors)}
                error={Boolean(errors.comments)}
                helperText={errors.comments ? errors.comments.message as string : ''}
              />
            </Stack>
          </Box>
          <Container
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: {
                mobile: 'column-reverse',
                desktop: 'row',
              },
              px: '0 !important',
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                width: { desktop: 'fit-content' },
              }}
              {...getSubmitButtonProps(isSubmitting, isValid)}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => navigateBack()}
              sx={{
                width: { desktop: 'fit-content' },
              }}
            >
              Cancel
            </Button>
          </Container>
          {submissionError && (
            <Alert severity="error">
              <AlertTitle>
                There was an error saving your event
              </AlertTitle>
              {submissionError.message}
              {submissionError.code && `Code: ${submissionError.code} `}
              {submissionError.id && `ID: ${submissionError.id}`}
            </Alert>
          )}
        </Stack>
      </form>
    </Container>
  );
};

export default AddEvent;

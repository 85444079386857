import React, { useState } from 'react';
import {
  useParams, useNavigate, useLocation,
} from 'react-router-dom';
import {
  Message, MessageType,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import LogView from './log-view';
import useChat from '../../../../hooks/use-chat';

const LogContainer = () => {
  const { conversationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<Error | null>(null);

  const {
    sender,
    sendMessage,
  } = useChat({
    conversationId,
  });

  const reset = () => {
    setError(null);
  };

  const handleNavigateBack = () => {
    if (location.key === 'default') {
      navigate('..');
    } else navigate(-1);
  };

  const handleSubmit = ({
    message,
    action,
  }: {
    message: string;
    action: {
      title: string;
      value: string;
    };
  }) => {
    reset();

    const actionString = action.value === 'call' ? 'Phone call log' : 'Text Message log';
    const senderName = sender.data?.fullName;
    const title = [actionString, senderName].filter(Boolean).join(' by ');

    const messageToSend: Partial<Message> = {
      conversationId,
      text: message,
      title,
      messageType: MessageType.PhoneCall,
    };

    sendMessage(messageToSend)
      .then(() => {
        enqueueSnackbar(`${actionString} created.`, {
          variant: 'success',
        });
        handleNavigateBack();
      })
      .catch((e: Error) => {
        setError(e);
      });
  };

  return (
    <LogView
      onSubmit={handleSubmit}
      handleNavigateBack={handleNavigateBack}
      error={error}
    />
  );
};

export default LogContainer;

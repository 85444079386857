import React from 'react';
import {
  Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider, LoaderFunctionArgs,
} from 'react-router-dom';
import { useApiClient } from '@marageti/z4-lib';
import Login from '../pages/login';
import ResetSendLink from '../pages/reset/send-link';
import ResetSetPassword from '../pages/reset/set-password';
import Home from '../pages/home';
import Logout from '../pages/logout';
import NotFound from '../pages/404';
import Dummy from '../pages/dummy';
import ProtectedLayout from './wrappers/layout-wrapper';
import ProtectedRoutes from './wrappers/protected-wrapper';
import ProtectedStore from './wrappers/store-wrapper';
import { useAuthStore } from '../store';
import UserProfile from '../pages/user';
import UserProfileEdit from '../pages/user/edit-user';
import useSettingsRoutes from './settings';
import useTripMatchesRoutes from './trip-matches';
import Activate from '../pages/activate';
import Availability from '../pages/availability';
import GettingStarted from '../pages/getting-started';
import RedirectNavigator from './wrappers/redirect-wrapper';
import EditEvent from '../pages/availability/edit';
import AddEvent from '../pages/availability/add';
import useSalesRoutes from './sales';
import Reviews from '../pages/reviews';
import Review from '../pages/review';
import useHotjar from '../hooks/use-hotjar';
import MyProfile from '../pages/my-profile';
import useFullPageRoutes from './full-page';
import ErrorBoundaryRouter from '../components/error-boundary/ErrorBoundaryRouter';
import D7TripRedirectWrapper from './wrappers/d7-trip-redirect-wrapper';

const Router = () => {
  const { isAuthenticated } = useAuthStore();
  const apiClient = useApiClient();
  const fullPageRoutes = useFullPageRoutes();

  const tokenExistsLoader = async ({ request }: LoaderFunctionArgs): Promise<boolean> => {
    const { searchParams } = new URL(request.url);
    const email = searchParams.get('email') || '';
    const token = searchParams.get('token') || '';
    return apiClient.authClient.resetTokenExists(email, token).catch(() => false);
  };

  const salesRoutes = useSalesRoutes();
  const settingRoutes = useSettingsRoutes();
  const tripMatchesRoutes = useTripMatchesRoutes();

  useHotjar();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RedirectNavigator />} ErrorBoundary={ErrorBoundaryRouter}>
        <Route path="/login" element={isAuthenticated ? <Navigate to={`/${window.location.search}`} /> : <Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="/reset" element={<ResetSendLink />} />
        <Route path="/reset/password" element={<ResetSetPassword />} loader={tokenExistsLoader} />
        <Route path="/activate" element={<Activate />} loader={tokenExistsLoader} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<D7TripRedirectWrapper />}>
            <Route element={<ProtectedStore />}>
              <Route element={<ProtectedLayout />}>
                <Route index element={<Home />} />
                <Route path="my-profile" element={<MyProfile />} />
                <Route path="dummy" element={<Dummy />} />
                {salesRoutes}
                {settingRoutes}
                <Route path="users/:userId" element={<UserProfile />} />
                <Route path="users/:userId/edit" element={<UserProfileEdit />} />
                {tripMatchesRoutes}
                <Route path="availability" element={<Availability />} />
                <Route path="availability/:eventId/edit" element={<EditEvent />} />
                <Route path="availability/add" element={<AddEvent />} />
                <Route path="reviews">
                  <Route index element={<Reviews />} />
                  <Route path=":reviewId" element={<Review />} />
                </Route>
              </Route>
              {fullPageRoutes}
              <Route element={<ProtectedLayout />}>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default Router;

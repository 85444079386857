import React from 'react';
import { useFetch, useApiClient, useFetchImmutable } from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { useAuthStore } from '../../../store';
import CountriesView from './countries-view';

const CountriesContainer = () => {
  const {
    loggedInAgent,
  } = useAuthStore();
  const apiClient = useApiClient();
  const partner = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);
  const countryCodes = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);

  return (
    <CountriesView
      partner={partner}
      countryCodes={countryCodes}
    />
  );
};

export default CountriesContainer;

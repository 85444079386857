import React, { createContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { CountryCodeMap, TimeZoneMap } from '@marageti/z4-sdk/lib/standards';
import {
  ProviderProps,
  ContextProps,
} from './types';
import {
  setCountryCodes,
  setStandardsError,
  setTimeZones,
} from './actions';
import useStandardsReducer from './use-standards-reducer';

export const StandardsStore = createContext({} as ContextProps);

export const StandardsProvider = ({ children }: ProviderProps) => {
  const [standards, standardsDispatch] = useStandardsReducer();
  const navigate = useNavigate();
  const apiClient = useApiClient();

  // Init Country Codes
  useEffect(() => {
    Promise.all([
      apiClient.standardsClient.getCountryCodes(),
      apiClient.standardsClient.getTimeZones(),
    ])
      .then(([countryCodesMap, timeZonesMap]) => {
        standardsDispatch(setCountryCodes(countryCodesMap as CountryCodeMap));
        standardsDispatch(setTimeZones(timeZonesMap as TimeZoneMap));
      })
      // Display error message?
      .catch((error: ApiError) => {
        if (error.code === 401) navigate('/logout');
        setStandardsError(true);
      });
  }, []);

  const value: ContextProps = useMemo(() => ({
    ...standards,
    standardsDispatch,
  }), [standards, standardsDispatch]);

  return (
    <StandardsStore.Provider value={value}>
      {children}
    </StandardsStore.Provider>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Fab,
  useForm, getSubmitButtonProps, BackNavigationAppBar, RichTextEditor,
} from '@marageti/z4-lib';
import {
  Container, Button, Alert, AlertTitle, Stack, Typography,
} from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';

const schema = z.object({
  message: z.string().min(1, 'Required'),
});

type NoteViewProps = {
  isInternal: boolean;
  onSubmit: (data: any) => void;
  handleNavigateBack: () => void
  error: Error | null;
};

const NoteView = ({
  isInternal,
  onSubmit,
  handleNavigateBack,
  error,
}: NoteViewProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control,
  } = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <>
      <BackNavigationAppBar
        primary={isInternal ? 'Add Internal Note' : 'Add Note'}
        defaultPath=".."
      />
      <Container
        maxWidth="md"
        sx={{
          marginBottom: '145px',
          display: 'flex',
          flexDirection: 'column',
          height: { mobile: 'calc(100vh - 77px - 145px)', desktop: '440px' },
          maxHeight: { mobile: 'calc(100vh - 77px - 145px)', desktop: '440px' },
          width: { desktop: '700px' },
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            height: '100%',
            pb: 5,
          }}
        >
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <RichTextEditor
                editorName="Add-Note-Editor"
                error={!!errors.message}
                label="Message"
                onChange={field.onChange}
                sx={{
                  height: '380px',
                  maxHeight: '380px',
                }}
                useLinks
                useLists
              />
            )}
          />
          {isInternal && (
            <Typography variant="caption" color="text.secondary">
              Internal notes will not be seen by the traveler.
            </Typography>
          )}
          <Fab>
            <Container sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              flexDirection: {
                mobile: 'column',
                desktop: 'row',
              },
            }}
            >
              <Button
                variant="outlined"
                onClick={handleNavigateBack}
                sx={{
                  width: { desktop: 'fit-content' },
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  width: { desktop: 'fit-content' },
                }}
                {...getSubmitButtonProps(isSubmitting, isValid)}
              >
                Submit
              </LoadingButton>
            </Container>
          </Fab>
        </Stack>

        {error && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting note</AlertTitle>
            {error?.message}
          </Alert>
        )}
      </Container>
    </>
  );
};

export default NoteView;

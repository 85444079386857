import React from 'react';
import { OutlinedIcons } from '@marageti/z4-lib';
import { LinkCardProps } from '../../components/link-card';
import SettingsView from './settings-view';

const {
  OutlinedUserGroupIcon,
  OutlinedIdentificationIcon,
  OutlinedLibraryIcon,
  OutlinedGlobeAltIcon,
  OutlinedTemplateIcon,
} = OutlinedIcons;

const SETTINGS_LINKS: LinkCardProps[] = [
  {
    title: 'Users',
    subTitle: 'View and edit users',
    href: 'users',
    icon: <OutlinedUserGroupIcon />,
  },
  {
    title: 'Company Profile',
    subTitle: 'Edit your Zicasso Profile',
    href: 'company-profile',
    icon: <OutlinedIdentificationIcon />,
  },
  {
    title: 'Policies & Terms',
    subTitle: 'Cancelation and payment',
    href: 'policies-and-terms',
    icon: <OutlinedLibraryIcon />,
  },
  {
    title: 'Countries',
    subTitle: 'Approved destinations',
    href: 'countries',
    icon: <OutlinedGlobeAltIcon />,
  },
  {
    title: 'Templates',
    subTitle: 'Message Templates',
    href: 'templates',
    icon: <OutlinedTemplateIcon />,
  },
];

const SettingsContainer = () => (
  <SettingsView
    links={SETTINGS_LINKS}
  />
);

export default SettingsContainer;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Typography,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldValues, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Template } from '@marageti/z4-sdk/lib/templates/Template';
import {
  getFieldProps,
  getSubmitButtonProps,
  useApiClient,
  BackButton,
  useForm,
  useFetch,
  RichTextEditor,
} from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';

const schema = z.object({
  body: z.string().nonempty('Please enter a body'),
  title: z.string().nonempty('Please enter a title'),
});

const EditTemplate = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const { templateId } = useParams();

  const { data: template, isLoading, mutate } = useFetch<Template>(apiClient.templatesClient.getTemplate, [templateId], true);

  const [submissionError, setSubmissionError] = useState<ApiError>();

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const navigateBack = () => {
    navigate('/settings/templates');
  };

  const onSubmit: SubmitHandler<FieldValues> = (t) => {
    if (template && templateId) {
      const request: Template = {
        ...template,
        body: t.body,
        title: t.title,
      };
      apiClient.templatesClient.putTemplate(templateId, request)
        .then(() => {
          mutate();
          navigateBack();
        })
        .catch(setSubmissionError);
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        py: { mobile: 3, desktop: 6 },
        mb: { desktop: 20 },
        height: { mobile: 'calc(100vh - 77px)', desktop: '750px' },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BackButton onClick={navigateBack} edge="start" sx={{ mb: 2, width: 'fit-content' }} />
      <Typography variant="h2" sx={{ p: 0, mb: 3 }}>Edit Template</Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={3}
        sx={{
          height: { mobile: 'calc(100vh - 170px)', desktop: '100%' },
          width: '100%',
          flex: 1,
        }}
      >
        {!isLoading ? (
          <>
            <TextField
              defaultValue={template?.title}
              label="Title"
              {...register('title')}
              {...getFieldProps('title', errors)}
            />
            <Controller
              control={control}
              name="body"
              rules={{ maxLength: 6000 }}
              render={({ field }) => (
                <RichTextEditor
                  defaultValue={template?.body}
                  editorName="Body"
                  error={!!errors.body}
                  helperText={errors.body?.message}
                  label="Body"
                  onChange={field.onChange}
                  useLinks
                  useLists
                  sx={{
                    minHeight: { desktop: '500px' },
                    maxHeight: '600px',
                    flex: 1,
                  }}
                />
              )}
            />
          </>
        ) : <Skeleton variant="rounded" height={375} />}
        <Container
          sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: {
              mobile: 'column-reverse',
              desktop: 'row',
            },
            px: '0 !important',
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              width: { desktop: 'fit-content' },
            }}
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={navigateBack}
            sx={{
              width: { desktop: 'fit-content' },
            }}
          >
            Cancel
          </Button>
        </Container>
        {submissionError && (
        <Alert severity="error">
          <AlertTitle>
            There was an error saving your template
          </AlertTitle>
          {submissionError.message}
          {submissionError.code && `Code: ${submissionError.code} `}
          {submissionError.id && `ID: ${submissionError.id}`}
        </Alert>
        )}
      </Stack>
    </Container>
  );
};

export default EditTemplate;

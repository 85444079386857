import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedLayout from '../wrappers/layout-wrapper';
import Log from '../../pages/trip-match/messages/log';
import Note from '../../pages/trip-match/messages/note';
import Conversation from '../../pages/conversation';

const useFullPageRoutes = () => (
  <Route element={<ProtectedLayout hideNav />}>
    <Route path="trip-matches/:tripMatchId/messages/:conversationId">
      <Route index element={<Conversation />} />
      <Route path="log" element={<Log />} />
      <Route path="note" element={<Note />} />
    </Route>
  </Route>
);

export default useFullPageRoutes;

import React, { useState } from 'react';
import {
  TripMatch, SalesRecording, Partner,
} from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  DocumentType, DocumentRequest, MediaType, OwnerType,
} from '@marageti/z4-sdk/lib/documents/Document';
import { useSnackbar } from 'notistack';
import SalesRecordingFormView from './sales-recording-form-view';
import putS3Document from '../../utils/s3-documents';

const createDocumentRequest = (document: File) => ({
  mediaType: document.type as MediaType,
  documentType: DocumentType.DocumentItinerary,
  sourceFileName: document.name,
  title: document.name.replace(/\./g, ' '),
} as DocumentRequest);

type SalesRecordingFormContainerProps = {
  tripMatch: TripMatch
  salesRecording?: SalesRecording | undefined
  onSuccess: (createdUpdatedSalesRecordingId: string) => void
  onCancel: () => void
};

const SalesRecordingFormContainer = ({
  tripMatch,
  salesRecording = undefined,
  onSuccess,
  onCancel,
}: SalesRecordingFormContainerProps) => {
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const partner = useFetch<Partner>(apiClient.partnersClient.getPartner, [tripMatch.partnerId], !!tripMatch.partnerId);
  // African Safari (country code ZZ-AFR) is not allowed in sales recordings
  const allowedCountries = partner?.data?.countries.filter((c) => c.country !== 'ZZ-AFR');
  const tripMatchFilteredDest = {
    ...tripMatch,
    countries: tripMatch.countries.filter((c) => c !== 'ZZ-AFR'),
  };
  const addSalesRecording = async (formValues: any) => {
    const file = formValues.document ? formValues.document[0] : undefined;
    const documentRequest = file ? createDocumentRequest(file) : undefined;

    // 1. Create Sales Recording
    // 2. Upload Document to S3 using preSignedURL
    const salesRecordingRequest = {
      tripRequestId: tripMatch.tripRequestId,
      tripMatchId: tripMatch.id,
      travelers: tripMatch.travelers,
      countries: formValues.countries,
      salesRecordingType: SalesRecordingType.General,
      price: formValues.price,
      commission: formValues.commission,
      priceComments: formValues.priceComments,
      document: documentRequest,
    };

    try {
      const response = await apiClient.tripMatchesClient.postTripMatchSalesRecording(tripMatch.id, salesRecordingRequest);
      if (documentRequest) {
        await putS3Document(response.preSignedURL.url, file);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSalesRecording = async (id: string, previousSalesRecording: SalesRecording, formValues: any) => {
    const file = formValues.document ? formValues.document[0] : undefined;
    const documentRequest = file ? createDocumentRequest(file) : undefined;

    // 1. Update Sales Recording
    // 2. Replace Document in S3 using preSignedURL
    const salesRecordingUpdate = {
      ...previousSalesRecording,
      priceComments: formValues.priceComments,
      price: { ...previousSalesRecording.price, ...formValues.price },
      commission: { ...previousSalesRecording.commission, ...formValues.commission },
    };

    const putSalesRecordingResponse = await apiClient.salesRecordingsClient.putSalesRecording(id, salesRecordingUpdate);

    if (documentRequest) {
      const putSalesRecordingDocumentResponse = await apiClient.salesRecordingsClient
        .putSalesRecordingDocument(previousSalesRecording.id, {
          ...documentRequest,
          owners: {
            [OwnerType.OwnerMatch]: tripMatch.id,
          },
        });

      const putS3DocumentResponse = await putS3Document(putSalesRecordingDocumentResponse.preSignedURL.url, file);

      return ([putSalesRecordingResponse, putS3DocumentResponse]);
    } if (previousSalesRecording.document) {
      const deleteSalesRecordingDocumentResponse = await apiClient.salesRecordingsClient.deleteSalesRecordingDocument(
        previousSalesRecording.id,
      );

      return ([putSalesRecordingResponse, deleteSalesRecordingDocumentResponse]);
    }

    return ([putSalesRecordingResponse]);
  };

  const handleSubmit = async (formValues: any) => {
    setSubmissionError(false);

    const updateTripMatch = (id: string, previousTripMatch: TripMatch, values: any) => {
      const tripMatchUpdate = {
        countries: values.countries,
        departureDate: values.departureDate,
        totalNights: values.totalNights,
        numberOfAdults: values.numberOfAdults,
        numberOfChildren: values.numberOfChildren,
      };

      return apiClient.tripMatchesClient.putTripMatch(id, {
        ...previousTripMatch,
        ...tripMatchUpdate,
      });
    };

    try {
      await updateTripMatch(tripMatch.id, tripMatch, formValues);
      // Create or Update Sales Recording
      if (!salesRecording) {
        const response = await addSalesRecording(formValues);
        enqueueSnackbar('Standard Sale added.', { variant: 'success' });
        onSuccess(response.salesRecording.id);
      } else {
        await updateSalesRecording(salesRecording.id, salesRecording, formValues);
        enqueueSnackbar('Standard Sale edited.', { variant: 'success' });
        onSuccess(salesRecording.id);
      }
    } catch (error) {
      setSubmissionError(true);
    }
  };

  return (
    <SalesRecordingFormView
      tripMatch={tripMatchFilteredDest}
      salesRecording={salesRecording}
      partnerCountries={allowedCountries}
      handleSubmit={handleSubmit}
      submissionError={submissionError}
      onCancel={onCancel}
    />
  );
};

export default SalesRecordingFormContainer;

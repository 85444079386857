import React from 'react';
import {
  Box,
  Container,
  Grid,
  Skeleton,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import {
  BackNavigationAppBar,
  OutlinedIcons,
} from '@marageti/z4-lib';
import { ParticipantInfo, TripMatch } from '@marageti/z4-sdk/lib/travel';
import TripSidebar from '../../components/trip-sidebar';
import ZicassoChat from '../../components/zicasso-chat';
import EditParticipantsContainer from './edit-participants';
import ConversationParticipantNames from './conversation-participant-names';

const {
  OutlinedUserAddIcon,
} = OutlinedIcons;

type Props = {
  conversationTitle: string | undefined,
  tripMatchId: string | undefined,
  tripRequestId: string | undefined,
  partnerId: string | undefined,
  assignedTo: string | undefined,
  travelerId: string | undefined,
  participants: ParticipantInfo[],
  tripMatch?: TripMatch,
  isEditParticipantsOpen: boolean,
  onOpenEditParticipants: () => void,
  onCloseEditParticipants: () => void,
  onConfirmEditParticipants: () => void,
};

const ConversationView = ({
  conversationTitle,
  tripMatchId,
  tripRequestId,
  partnerId,
  assignedTo,
  travelerId,
  participants,
  tripMatch,
  isEditParticipantsOpen,
  onOpenEditParticipants,
  onCloseEditParticipants,
  onConfirmEditParticipants,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const isParticipantsLoading = !participants;

  const renderEditParticipantsButton = () => (
    <IconButton onClick={onOpenEditParticipants}>
      <OutlinedUserAddIcon color="primary" sx={{ fontSize: 20 }} />
    </IconButton>
  );

  if (isMobile) {
    return (
      <Container
        sx={{
          position: 'fixed',
          inset: 0,
          backgroundColor: 'primary.contrastText',
          zIndex: 1200,
          padding: 0,
        }}
      >
        <BackNavigationAppBar
          defaultPath={`/trip-matches/${tripMatchId}/messages`}
          options={{
            replace: true,
          }}
          primary={conversationTitle || <Skeleton width={75} />}
          secondary={(
            <ConversationParticipantNames
              participants={participants}
              isLoading={isParticipantsLoading}
              enableTooltip
              tripMatch={tripMatch}
            />
          )}
          endAdornment={renderEditParticipantsButton()}
        />

        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flex: 1,
            p: 0,
            minHeight: 'calc(100vh - 72px)',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: 'calc(100dvh - 72px)',
            px: 3,
          }}
          >
            <ZicassoChat />
          </Box>
        </Container>
        <EditParticipantsContainer
          open={isEditParticipantsOpen}
          onClose={onCloseEditParticipants}
          onSuccess={onConfirmEditParticipants}
          partnerId={partnerId}
          assignedTo={assignedTo}
          travelerId={travelerId}
          participants={participants}
        />
      </Container>
    );
  }

  return (
    <>
      <BackNavigationAppBar
        defaultPath={`/trip-matches/${tripMatchId}/messages`}
        options={{
          replace: true,
        }}
        primary={conversationTitle || <Skeleton width={75} />}
        secondary={(
          <ConversationParticipantNames
            participants={participants}
            isLoading={isParticipantsLoading}
            enableTooltip
            tripMatch={tripMatch}
          />
        )}
        endAdornment={renderEditParticipantsButton()}
      />
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flex: 1,
          pt: 3.75,
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            desktop={4}
            sx={{
              display: 'block',
            }}
          >
            {/* Sticky Sidebar */}
            <Box
              sx={{
                position: 'sticky',
                pr: 3,
                height: 'calc(100vh - 118px)',
                overflow: 'auto',
              }}
            >
              <TripSidebar
                tripMatchId={tripMatchId}
                tripRequestId={tripRequestId}
              />
            </Box>
          </Grid>
          <Grid
            item
            desktop={8}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              height: 'calc(100vh - 108px)',
            }}
            >
              <ZicassoChat />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <EditParticipantsContainer
        open={isEditParticipantsOpen}
        onClose={onCloseEditParticipants}
        onSuccess={onConfirmEditParticipants}
        partnerId={partnerId}
        assignedTo={assignedTo}
        travelerId={travelerId}
        participants={participants}
      />
    </>
  );
};

export default ConversationView;

import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  TripMatch, TripMatchStatus, ParticipantInfo,
} from '@marageti/z4-sdk/lib/travel';
import {
  useApiClient,
  useFetch,
} from '@marageti/z4-lib';
import ConversationView from './conversation-view';
import useZicassoChat from '../../hooks/use-zicasso-chat';

const DetailContainer = () => {
  const { tripMatchId, conversationId } = useParams();
  const apiClient = useApiClient();
  const [isEditParticipantsOpen, setIsEditParticipantsOpen] = useState(false);

  const {
    messages,
    updateMessages,
  } = useZicassoChat({ conversationId });

  const { data: tripMatch, mutate: mutateTripMatch } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const {
    data: participants,
    mutate: mutateParticipants,
  } = useFetch<ParticipantInfo[]>(apiClient.conversationsClient.getParticipants, [conversationId, { params: { inactive: false } }], !!conversationId);

  // Checks to see if we need to update the NextSteps Card in the Trip Sidebar - when an agent sends a first message.
  // Needs to check that the message is a text message and not type document.
  useEffect(() => {
    if (tripMatch?.status === TripMatchStatus.MatchAccepted) {
      if (messages && messages?.length > 0 && messages.some((message) => message.messageType === 'TEXT')) {
        mutateTripMatch();
      }
    }
  }, [messages, tripMatch]);

  const handleOpenEditParticipants = () => setIsEditParticipantsOpen(true);
  const handleCloseEditParticipants = () => {
    setIsEditParticipantsOpen(false);
  };
  const onConfirmEditParticipants = () => {
    setIsEditParticipantsOpen(false);
    mutateTripMatch();
    mutateParticipants();
    updateMessages();
  };

  const travelerName = tripMatch?.travelers[0].fullName;
  const conversationTitle = travelerName ? `${travelerName}'s Trip` : '';
  const participantsArray = useMemo(() => (participants ? Object.values(participants) : []), [participants]);

  return (
    <ConversationView
      conversationTitle={conversationTitle}
      tripMatchId={tripMatchId}
      tripRequestId={tripMatch?.tripRequestId}
      partnerId={tripMatch?.partnerId}
      assignedTo={tripMatch?.assignedTo}
      travelerId={tripMatch?.travelers[0].id}
      participants={participantsArray}
      tripMatch={tripMatch}
      isEditParticipantsOpen={isEditParticipantsOpen}
      onOpenEditParticipants={handleOpenEditParticipants}
      onCloseEditParticipants={handleCloseEditParticipants}
      onConfirmEditParticipants={onConfirmEditParticipants}
    />
  );
};

export default DetailContainer;

import { useState, useEffect } from 'react';
import { z3ApiEndpoint, z3Endpoint } from '../config';

const useFetchItineraryPath = (itineraryId: string | undefined) => {
  const [path, setPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (itineraryId) {
      const fetchItineraryPath = async () => {
        try {
          const response = await fetch(`${z3ApiEndpoint[process.env.REACT_APP_STAGE || 'dev']}/v2/paths?entity=${itineraryId}`);
          const data = await response.json();
          const { canonicalPath, published } = data.find((p: { [key: string]: any }) => p.redirect === false);
          if (published) {
            setPath(`${z3Endpoint[process.env.REACT_APP_STAGE || 'dev']}/${canonicalPath}`);
          }
        } catch (err) {
          console.error(err);
        }
      };

      fetchItineraryPath();
    }
  }, [itineraryId]);

  return path;
};

export default useFetchItineraryPath;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, CardHeader } from '@mui/material';
import { ContextMenuComplete } from '@marageti/z4-lib';

type TemplateCardProps = {
  id: string;
  onClickEdit: () => void;
  onClickRemove: () => void;
  title: string;
};

const TemplateCard = ({
  id,
  onClickEdit,
  onClickRemove,
  title,
}: TemplateCardProps) => (
  <Card>
    <CardHeader
      title={title}
      titleTypographyProps={{
        variant: 'bodyBold',
      }}
      action={(
        <ContextMenuComplete
          id={`${id}-card-action`}
          items={[
            {
              label: 'Edit',
              action: onClickEdit,
            },
            {
              label: 'Remove',
              action: onClickRemove,
            },
          ]}
        />
      )}
    />
  </Card>
);

export default TemplateCard;

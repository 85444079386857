import React from 'react';
import {
  Card,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ContextMenu,
  ContextMenuButton,
  ContextMenuItem,
  ProfileCardContent,
  ProfileCardTitle,
  RichTextDisplay,
} from '@marageti/z4-lib';
import { ProfileDetailCardProps } from '../types';
import stylesT from '../styles';

const LabelAndValueElementWithWhitespace = ({
  title,
  value,
}: any) => (value !== undefined ? (
  <Stack>
    <ProfileCardTitle sx={{ mb: 1 }}>{title}</ProfileCardTitle>
    <RichTextDisplay color="text.secondary">
      {value}
    </RichTextDisplay>
  </Stack>
) : (
  <Skeleton
    variant="rounded"
    animation="wave"
    sx={{
      margin: '0 auto',
      height: '44px',
      width: '100%',
    }}
  />
));

const ProfileDetailCard = ({
  contextMenuAnchor,
  contextMenuOpen,
  onCloseContextMenu,
  onOpenContextMenu,
  onClickEdit,
  partner,
  partnerDetail,
  isAdmin,
}: ProfileDetailCardProps) => {
  const theme = useTheme();
  const styles = stylesT(theme);
  const menuId = `profile-detail-context-menu-${partnerDetail.attributeName}`;
  const buttonId = `profile-detail-context-button-${partnerDetail.attributeName}`;
  return (
    <Card sx={styles.profileCard}>
      <ProfileCardContent>
        <Stack spacing="24px" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <LabelAndValueElementWithWhitespace
              subtitleSx={{ whiteSpace: 'pre-wrap' }}
              title={partnerDetail.displayName}
              value={partner?.[partnerDetail.attributeName as keyof typeof partner] as string || '-'}
              key={partnerDetail.attributeName}
            />
            { isAdmin && (
              <>
                <ContextMenuButton
                  id={buttonId}
                  open={contextMenuOpen}
                  onClick={onOpenContextMenu}
                  menuId={menuId}
                />
                <ContextMenu
                  anchorEl={contextMenuAnchor}
                  buttonId={buttonId}
                  id={menuId}
                  onClose={onCloseContextMenu}
                  open={contextMenuOpen}
                >
                  <ContextMenuItem onClick={onClickEdit}>
                    Edit
                  </ContextMenuItem>
                </ContextMenu>
              </>
            )}
          </Stack>
        </Stack>
      </ProfileCardContent>
    </Card>
  );
};

export default ProfileDetailCard;

import { useEffect, useRef, useState } from 'react';
import {
  useApiClient,
  useFetch,
} from '@marageti/z4-lib';
import {
  Message, GetParticipantsResponse, MessageType, Conversation,
} from '@marageti/z4-sdk/lib/travel';
import useSWRInfinite from 'swr/infinite';
import { useConversationsStore, useInboxStore, usePusherStore } from '../store';

const useChatData = ({
  conversationId,
}: {
  conversationId?: string,
}) => {
  const limit = 30;
  const apiClient = useApiClient();
  const {
    refreshConversationList,
  } = useConversationsStore();
  const {
    selectedInboxConversationId,
  } = useInboxStore();
  const conversation = useFetch<Conversation>(apiClient.conversationsClient.getConversation, [conversationId], !!conversationId);
  const participants = useFetch<GetParticipantsResponse>(apiClient.conversationsClient.getParticipants, [conversationId ?? undefined], conversationId !== undefined);

  const getKey = (pageIndex: number, previousPageData: Message[] | null) => {
    if (previousPageData && !previousPageData.length) return null;
    return [conversationId, { params: { offset: previousPageData ? previousPageData[0]?.id : undefined, limit } }];
  };

  const fetcher = async ([id, params]: [any, any]) => {
    if (typeof id !== 'string') {
      return [];
    }
    return apiClient.conversationsClient.getMessages(id, params as any);
  };

  const paginatedMessages = useSWRInfinite(getKey, fetcher, {
    onSuccess: () => {
      if (selectedInboxConversationId === conversationId) {
        refreshConversationList();
      }
    },
  });

  const loadMoreMessages = async () => {
    paginatedMessages.setSize((size) => size + 1);
  };

  const sortedMessages = paginatedMessages.data ? [...paginatedMessages.data].reverse().flat() : [];

  // If the first page is empty, there are no more messages
  let hasMoreMessages = true;
  if (paginatedMessages.data && paginatedMessages.data[0] && paginatedMessages.data[0].length === 0) {
    hasMoreMessages = false;
  } else if (paginatedMessages.data && paginatedMessages.data.length > 0) {
    const lastPage = paginatedMessages.data[paginatedMessages.data.length - 1];
    // If the last page is less than the limit, there are no more messages.
    // Otherwise, there are more messages to fetch.
    if (lastPage.length < limit) {
      hasMoreMessages = false;
    } else {
      hasMoreMessages = true;
    }
  }

  const messages = {
    data: sortedMessages,
    isLoading: paginatedMessages.isLoading,
    isValidating: paginatedMessages.isValidating,
    error: paginatedMessages.error,
    mutate: paginatedMessages.mutate,
  };

  return {
    conversation,
    participants,
    messages,
    loadMoreMessages,
    hasMoreMessages,
  };
};

type UseZicassoChatProps = {
  conversationId?: string,
  viewerId?: string,
};

const useZicassoChat = ({
  conversationId,
  viewerId,
}: UseZicassoChatProps) => {
  const [lastSeenMessageId, setLastSeenMessageId] = useState<string | undefined>(undefined);
  const initializedLastSeenMessageId = useRef<boolean>(false);

  const apiClient = useApiClient();
  const {
    conversation,
    participants,
    messages,
    loadMoreMessages,
    hasMoreMessages,
  } = useChatData({ conversationId });

  // Only set lastSeenMessageId once when conversation is loaded
  useEffect(() => {
    if (initializedLastSeenMessageId.current || !conversation?.data) return;

    const viewerMessageState = conversation.data.messageState.find((m) => m.personId === viewerId);
    setLastSeenMessageId(viewerMessageState?.lastMessageRead);
    initializedLastSeenMessageId.current = true;
  }, [conversation]);

  const sendMessage = async (message: Partial<Message>) => {
    if (!conversationId || !message.text) {
      throw new Error('Missing conversationId or message text');
    }

    const messageToSend = {
      conversationId,
      messageType: MessageType.Text,
      ...message,
    };

    try {
      const postMessage = await apiClient.conversationsClient.postMessage(conversationId, messageToSend);
      // Update last seen message id
      setLastSeenMessageId(postMessage.id);
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  const updateMessages = async () => {
    await messages.mutate();
  };

  // PUSHER
  const channelName = conversationId ? `presence-${conversationId}` : '';

  const {
    bindToEvent,
    ready,
    subscribeToChannel,
    subscribedChannels,
    unbindFromEvent,
    unsubscribeFromChannel,
  } = usePusherStore();

  // Subscribe to Pusher Channel
  useEffect(() => {
    if (ready && channelName) {
      subscribeToChannel(channelName);
    }

    return () => {
      if (ready) {
        unsubscribeFromChannel(channelName);
      }
    };
  }, [ready, channelName]);

  // Listen to new messages and update messages and conversation
  useEffect(() => {
    if (subscribedChannels[channelName]) {
      bindToEvent(channelName, MessageType.Text, () => {
        updateMessages();
      });

      bindToEvent(channelName, 'MESSAGE_READ', () => {
        conversation.mutate();
      });

      bindToEvent(channelName, 'PERSON_ADDED', () => {
        participants.mutate();
      });
    }

    return () => {
      if (subscribedChannels[channelName]) {
        unbindFromEvent(channelName, MessageType.Text);
        unbindFromEvent(channelName, 'MESSAGE_READ');
        unbindFromEvent(channelName, 'PERSON_ADDED');
      }
    };
  }, [subscribedChannels]);

  return {
    conversation: conversation.data,
    isError: !!participants.error || !!messages.error,
    isLoading: participants.isLoading || messages.isLoading,
    isValidating: messages.isValidating,
    messages: messages.data,
    participants: participants.data,
    lastSeenMessageId,
    updateMessages,
    sendMessage,
    loadMoreMessages,
    hasMoreMessages,
  };
};

export default useZicassoChat;

import React from 'react';
import {
  ConversationSummary, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import CardLink from './CardLink';
import {
  getConversationIcon,
  getConversationTypeTitle,
} from './helpers';
import ConversationParticipantNames from '../conversation-participant-names';

export type ConversationLinkProps = {
  to?: string
  badgeColor?: 'info'
  conversationSummary: ConversationSummary
  getConversationTypeTitle?: (type: ConversationType) => string | undefined
};

const ConversationLink = ({
  to = undefined,
  badgeColor,
  conversationSummary,
  getConversationTypeTitle: customGetConversationTypeTitle = undefined,
}: ConversationLinkProps) => {
  const { conversationType, hasNewMessages } = conversationSummary;
  const title = customGetConversationTypeTitle
    ? customGetConversationTypeTitle(conversationType)
    : getConversationTypeTitle({ conversationType });

  return (
    <CardLink
      to={to}
      primary={title}
      secondary={(
        <ConversationParticipantNames
          conversationId={conversationSummary.conversationId}
          conversationType={conversationType}
          typographyProps={{
            variant: 'caption',
          }}
        />
      )}
      icon={getConversationIcon({
        conversationType,
      })}
      showBadge={badgeColor && hasNewMessages}
      badgeColor={badgeColor}
    />
  );
};

export default ConversationLink;

import { Agent, TeamMember, Traveler } from '@marageti/z4-sdk/lib/people';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { PartnerOffice, Partner } from '@marageti/z4-sdk/lib/travel';
import parsePhoneNumber from 'libphonenumber-js';

export const getFormattedPartnerCountries = (partner: Partner, countryCodes: CountryCodeMap) => {
  let str = '';
  if (partner?.primaryCountries?.length > 0) {
    str += countryCodes[partner.primaryCountries[0].country];
  }
  if (partner?.secondaryCountries?.length > 0) {
    str += ' · ';
    str += countryCodes[partner.secondaryCountries[0].country];
  }
  return str;
};

export const getFormattedMonthAndYear = (dateString: string): string => {
  const dateObj = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { month: 'long' };
  const month = dateObj.toLocaleString('en-US', options);
  const year = dateObj.getFullYear();
  return `${month} ${year}`;
};

// This function compares the full names of two objects, and it used for sorting
// a list of names into alphabetical order
// The objects are expected to have a 'person' property with a 'fullName' string.
// The function returns -1 if the full name of the first object is alphabetically
// less than the full name of the second object, 1 if the full name of the first
// object is alphabetically greater than the full name of the second object,
// and 0 if the full names are equal.

// ***This function was refactored with ChatGPT.***
export const compareNames = (
  a: { person: { fullName: string } },
  b: { person: { fullName: string } },
) => (a.person.fullName.localeCompare(b.person.fullName));

export const getFormattedOnboardingDate = (dateString?: Date) : string => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(date);

  return formattedDate;
};

export const getYear = (dateString: string): string => {
  const year = new Date(dateString).getFullYear();
  return `${year}`;
};

export const getTimeZoneOffsetString = (offset: number) => {
  const hours = Math.trunc(offset / 60);
  const minutes = (Math.abs(offset % 60)).toString().padEnd(2, '0');
  return `GMT ${hours}:${minutes}`;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const formatPhoneNumber = (prefix: string = '', phoneNumber: string = '') => {
  const pN = parsePhoneNumber(`${prefix}${phoneNumber}`);
  return pN?.formatInternational() || '-';
};

export const getPhoneNumber = (person: Agent | TeamMember | Traveler | undefined): string | undefined => {
  if (!person) return undefined;
  if (!person.phone || !person.phone.prefix || !person.phone.number || person.phone.prefix === '') {
    return '-';
  }
  return formatPhoneNumber(person.phone.prefix, person.phone.number);
};
export const getPartnerUSPhoneNumber = (partner: Partner | undefined): string | undefined => {
  if (!partner) return undefined;
  if (!partner.usPhone
    || !partner.usPhone.prefix
    || !partner.usPhone.number
    || partner.usPhone.prefix === '') {
    return '-';
  }
  return formatPhoneNumber(partner.usPhone.prefix, partner.usPhone.number);
};
export const getPartnerPrimaryPhoneNumber = (partner: Partner | undefined): string | undefined => {
  if (!partner) return undefined;
  if (!partner.primaryPhone
    || !partner.primaryPhone.prefix
    || !partner.primaryPhone.number
    || partner.primaryPhone.prefix === '') {
    return '-';
  }
  return formatPhoneNumber(partner.primaryPhone.prefix, partner.primaryPhone.number);
};
export const getPartnerOfficePhoneNumber = (office: PartnerOffice | undefined): string | undefined => {
  if (!office) return undefined;
  if (!office.phone
    || !office.phone.prefix
    || !office.phone.number
    || office.phone.prefix === '') {
    return '-';
  }
  return formatPhoneNumber(office.phone.prefix, office.phone.number);
};
export const getPartnerOfficeTime = (partnerOffice: PartnerOffice | undefined): string | undefined => {
  if (!partnerOffice) return undefined;
  if (!partnerOffice.timeZone || !partnerOffice.timeZone.name || partnerOffice.timeZone.name === '') {
    return '-';
  }
  const t = new Date().toLocaleString('en-US', {
    timeZone: partnerOffice.timeZone.name,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return `${t} (${partnerOffice.timeZone.name})`;
};

export const getTimeZone = (person: Agent | TeamMember | Traveler | undefined): string | undefined => {
  if (!person) return undefined;
  if (!person.timeZone || !person.timeZone.name || !person.timeZone.rawOffsetInMinutes || person.timeZone.name === '') {
    return '-';
  }
  return `${person.timeZone.name} (${getTimeZoneOffsetString(person.timeZone.rawOffsetInMinutes)})`;
};

export const getHistory = (person: Agent | TeamMember | Traveler | undefined): string | undefined | any => {
  if (!person) return undefined;
  if (!person.createdAt) return '-';
  return getFormattedMonthAndYear(person.createdAt);
};

export const getCountryOfResidence = (traveler: Traveler | undefined): string | undefined => {
  if (!traveler) return undefined;
  if (!traveler.countryOfResidence) return '-';
  return traveler.countryOfResidence;
};

export const getInitials = (person: Agent | TeamMember | Traveler | undefined): string | undefined => {
  if (!person) return undefined;
  if (!person.givenName || !person.familyName) return '';
  return `${person.givenName.charAt(0)}${person.familyName.charAt(0)}`;
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  ContextMenuComplete,
  formatClassNameAsDateRange, getDateRangeString, ProfileCardSubtitle, ProfileCardTitle,
} from '@marageti/z4-lib';
import { AvailabilityLimitType, PartnerAvailability } from '@marageti/z4-sdk/lib/travel';
import {
  Chip, Typography, Card, Stack,
} from '@mui/material';
import dayjs from 'dayjs';

type EventCardProps = {
  availabilityItem: PartnerAvailability;
  capacity: number;
  admin: boolean;
  onClickEdit: () => void;
  onClickRemove: () => void;
};

const EventCardView = ({
  availabilityItem,
  capacity,
  admin,
  onClickEdit,
  onClickRemove,
}: EventCardProps) => {
  const showFooter = availabilityItem.leadsPerWeek > capacity;
  const today = dayjs().startOf('day').toDate();
  const endDate = dayjs(availabilityItem?.endDate);
  return (
    <Card
      className={formatClassNameAsDateRange(availabilityItem?.startDate, availabilityItem?.endDate)}
      sx={{
        mb: 2,
        padding: '24px',
      }}
    >
      <ProfileCardTitle>
        <Stack direction="row" justifyContent="space-between">
          {getDateRangeString(
            availabilityItem?.startDate,
            availabilityItem?.endDate,
          )}
          {/* Allow editing and deleting if user is admin and the events end date has not already passed */}
          {admin && (endDate.isAfter(today, 'day') || endDate.isSame(today, 'day')) && (
          <ContextMenuComplete
            id={`${availabilityItem.id}-card-action`}
            items={[
              {
                label: 'Edit',
                action: onClickEdit,
              },
              {
                label: 'Remove',
                action: onClickRemove,
              },
            ]}
          />
          )}
        </Stack>
      </ProfileCardTitle>
      <ProfileCardSubtitle>{availabilityItem.comments}</ProfileCardSubtitle>
      {availabilityItem.limitType === AvailabilityLimitType.LIMIT_TRAVEL && (
        <Chip
          sx={{
            backgroundColor: '#F9F9FB',
            borderRadius: '100px',
            border: '1px solid #C2C5C5',
            marginTop: 1.5,
            mr: 1,
          }}
          label="Travel Date Restriction"
        />
      )}
      {availabilityItem.limitType === AvailabilityLimitType.LIMIT_LEADS && (
        <Chip
          sx={{
            backgroundColor: '#F9F9FB',
            borderRadius: '100px',
            border: '1px solid #C2C5C5',
            marginTop: 1.5,
          }}
          label={`Lead Capacity Restriction (${availabilityItem.leadsPerWeek})${showFooter ? '*' : ''}`}
        />
      )}
      {showFooter && (
        <Typography sx={{ mt: 1.5 }} variant="subtitle1">
          *Maximum capacity has been lowered and will be used in place of this value.
        </Typography>
      )}
    </Card>
  );
};
export default EventCardView;

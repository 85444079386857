import React from 'react';
import {
  IconButtonProps,
  IconButton,
} from '@mui/material';
import { OutlinedIcons } from '@marageti/z4-lib';
import { useSnackbar } from 'notistack';

const { OutlinedDocumentDuplicateIcon } = OutlinedIcons;

type CopyProps = {
  textToCopy: string,
} & IconButtonProps;

// Added to support event handling that the CopyButton from the lib does not support
const CopyButton = ({
  textToCopy,
  ...iconButtonProps
}: CopyProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const onClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(textToCopy);
      enqueueSnackbar('Copied!', { variant: 'success' });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to copy text to clipboard', e);
    }
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton onClick={onClick} sx={{ fontSize: '1.1rem' }} {...iconButtonProps}>
      <OutlinedDocumentDuplicateIcon color="primary" fontSize="inherit" />
    </IconButton>
  );
};

export default CopyButton;

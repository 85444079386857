import {
  Alert, AlertTitle, Box, Container,
} from '@mui/material';
import React from 'react';
import { BackButton } from '@marageti/z4-lib';
import { SalesRecording, TripMatch } from '@marageti/z4-sdk/lib/travel';
import SalesAdjustmentForm from '../../../../components/sales-adjustment-form';

const SalesRecordingView = ({
  formDisabledReason,
  isError,
  isLoading,
  onCancel,
  onSuccess,
  salesAdjustmentToEdit,
  salesRecordings,
  tripMatch,
}: {
  formDisabledReason: string | false
  isError: boolean
  isLoading: boolean
  onCancel: () => void
  onSuccess: (createdUpdatedSalesRecordingId: string) => void
  salesAdjustmentToEdit: SalesRecording | undefined
  salesRecordings: SalesRecording[]
  tripMatch: TripMatch | undefined
}) => (
  <Box
    py={{
      mobile: 3,
      desktop: 6,
    }}
  >
    <Container maxWidth="md">
      <BackButton
        onClick={onCancel}
        edge="start"
        sx={{
          mb: 2,
        }}
      />
      {isLoading && null}

      {!isLoading && isError && (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        There was an error getting the data. Please try again later.
      </Alert>
      )}

      {!isLoading && !isError && formDisabledReason && (
      <Alert severity="error">
        <AlertTitle>Cannot Display Sales Adjustment Form</AlertTitle>
        {formDisabledReason}
      </Alert>
      )}

      {!isLoading && !isError && !formDisabledReason && tripMatch && (
      <SalesAdjustmentForm
        onCancel={onCancel}
        onSuccess={onSuccess}
        salesAdjustment={salesAdjustmentToEdit}
        tripMatchSalesRecordings={salesRecordings}
        tripMatch={tripMatch}
      />
      )}
    </Container>
  </Box>
);

export default SalesRecordingView;

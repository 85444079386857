import * as React from 'react';
import { Outlet } from 'react-router-dom';
import StoreProvider from '../../store';

const ProtectedRoutes = () => (
  <StoreProvider>
    <Outlet />
  </StoreProvider>
);

export default ProtectedRoutes;

import React from 'react';
import { AlertObj, AlertType } from '../alerts/utils';
import { Inactive, Postponed, SendFirstMessage } from '../alerts';

const Alerts = ({ alert }: { alert: AlertObj }) => {
  switch (alert.type) {
    case AlertType.FirstMessageReminder:
      if (!alert.url || !alert.createdAt) return null;
      return <SendFirstMessage url={alert.url} createdAt={alert.createdAt} showButton={false} />;
    case AlertType.Postponed:
      return alert.handleReactivation ? <Postponed handleReactivation={alert.handleReactivation} /> : null;
    case AlertType.Inactive:
      return alert.handleReactivation ? <Inactive handleReactivation={alert.handleReactivation} /> : null;
    default:
      return null;
  }
};

export default Alerts;

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  ErrorBox,
  ErrorMessage,
  ErrorTitle,
} from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';

type ConfirmDeleteProps = {
  title: string;
  helperText?: string;
  onCancel: () => void;
  onRemove: () => void;
  open: boolean;
  submissionError?: ApiError;
};

const ConfirmDelete = ({
  title,
  helperText = undefined,
  onCancel,
  onRemove,
  open,
  submissionError = undefined,
}: ConfirmDeleteProps) => (
  <Dialog open={open}>
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {helperText}
      </DialogContentText>
    </DialogContent>
    {submissionError && (
      <ErrorBox>
        <ErrorTitle>
          There was an error on our end.
        </ErrorTitle>
        <ErrorMessage>
          {`Code: ${submissionError.code}: ${submissionError.message}`}
        </ErrorMessage>
      </ErrorBox>
    )}
    <DialogActions>
      <Button
        variant="contained"
        onClick={onRemove}
        color="error"
        sx={{
          width: { mobile: '100%', desktop: 'fit-content' },
        }}
      >
        Remove
      </Button>
      <Button
        variant="outlined"
        onClick={onCancel}
        sx={{
          width: { mobile: '100%', desktop: 'fit-content' },
        }}
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDelete;

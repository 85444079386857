import React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  useApiClient,
  useFetch,
  BackButton,
  useFetchImmutable,
} from '@marageti/z4-lib';
import { Partner, PartnerOffice } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import EditView from './edit-office-view';
import { useAuthStore } from '../../../../store';

const EditContainer = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const { enqueueSnackbar } = useSnackbar();

  const partnerId = loggedInAgent?.partnerId;
  const [searchParams] = useSearchParams();
  const officeIndex = searchParams.get('index');
  const swrRes = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);
  const {
    data: countryCodes,
  } = useFetchImmutable(apiClient.standardsClient.getCountryCodes, [], true);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);

  const goBack = () => navigate('/settings/company-profile');

  const insertAtIndex = (officeArr: PartnerOffice[], office: any, i:number):PartnerOffice[] => {
    if (i < 0 || i > officeArr.length) {
      return [...officeArr, office];
    }
    const newArray = [...officeArr];
    newArray.splice(i, 1, office);
    return newArray;
  };

  const handleSubmit = async (data: any) => {
    if (swrRes.data && partnerId) {
      const newOffice = {
        ...data,
        country: data.country.value,
      };
      const ar = swrRes.data.offices ? [...swrRes.data.offices] : [];
      const index = officeIndex === 'add' ? ar.length : parseInt(officeIndex || '0', 10);
      const offices = insertAtIndex(ar, newOffice, index);
      const updatePartner = {
        ...swrRes.data,
        offices,
      };

      try {
        await apiClient.partnersClient.putPartner(partnerId, updatePartner);
        enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
        swrRes.mutate();
        goBack();
      } catch (error) {
        setSubmissionError(true);
      }
    }
  };

  if (officeIndex === null) {
    navigate('/settings/company-profile');
  }

  const getOffice = (partner:Partner | undefined, index:string | null) => {
    if (!partner?.offices && index !== 'add') return undefined;
    if (index === null) return undefined;
    if (index === 'add') return {} as PartnerOffice;
    return partner!.offices![parseInt(index, 10)];
  };

  return (
    <Container
      maxWidth="sm"
    >
      <Box
        sx={{
          py: {
            desktop: 6,
            mobile: 3,
          },
        }}
      >
        <BackButton onClick={goBack} edge="start" sx={{ mb: 2 }} />
        <EditView
          titleFrag={officeIndex === 'add' ? 'Add' : 'Edit'}
          partnerOffice={getOffice(swrRes.data, officeIndex)}
          isLoading={swrRes.isLoading}
          partnerError={swrRes.error}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={goBack}
          countryCodes={countryCodes}
        />
      </Box>
    </Container>
  );
};

export default EditContainer;

import React, { createContext, useMemo } from 'react';
import { ProviderProps } from '../../types';
import { ValueProps } from './types';
import {
  setSelectedInboxConversationId,
  setShowUnreadOnly,
} from './actions';
import useInboxReducer from './use-inbox-reducer';

export const InboxStore = createContext({} as ValueProps);

export const InboxProvider = ({ children }: ProviderProps) => {
  const [inboxState, inboxDispatch] = useInboxReducer();
  const value: ValueProps = useMemo(
    () => ({
      ...inboxState,
      setSelectedInboxConversationId: (selectedInboxConversationId: string | undefined) => inboxDispatch(setSelectedInboxConversationId(selectedInboxConversationId)),
      setShowUnreadOnly: (showUnread: boolean) => inboxDispatch(setShowUnreadOnly(showUnread)),
    }),
    [inboxState, inboxDispatch],
  );

  return (
    <InboxStore.Provider value={value}>
      {children}
    </InboxStore.Provider>
  );
};

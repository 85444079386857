import {
  Dispatch,
  Reducer,
  useReducer,
} from 'react';
import { Actions } from '../../types';
import {
  SET_SELECTED_INBOX_CONVERSATION_ID,
  SET_SHOW_UNREAD_ONLY,
} from './actions';
import { InboxStateProps } from './types';

const initialState: InboxStateProps = {
  showUnreadOnly: false,
  selectedInboxConversationId: undefined,
};

const reducer: Reducer<InboxStateProps, Actions> = (state, action): InboxStateProps => {
  switch (action.type) {
    case SET_SELECTED_INBOX_CONVERSATION_ID:
      return {
        ...state,
        selectedInboxConversationId: action.payload,
      };
    case SET_SHOW_UNREAD_ONLY:
      return {
        ...state,
        showUnreadOnly: action.payload,
      };
    default:
      return state;
  }
};

const useInboxReducer = () => {
  const [inbox, dispatchInbox] = useReducer(reducer, initialState);
  return [inbox, dispatchInbox] as [InboxStateProps, Dispatch<Actions>];
};

export default useInboxReducer;

import React from 'react';
import {
  Box,
  Divider,
  Typography,
  Stack,
} from '@mui/material';
import {
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
} from '@marageti/z4-lib';
import PasswordLogin from '../../components/auth/email-password-form';
import SendLink from '../../components/auth/email-reset-link-form';
import { ReactComponent as ZicassoSVG } from '../../assets/svg/zicasso.svg';
import ZSpinner from '../../components/z-lib/z-spinner';
import GoogleLoginView from '../../components/auth/google-sso-button';
import { ViewProps } from './types';
import './activate.scss';

const View = ({
  email,
  loading,
  loginError = undefined,
  newLinkSent,
  onGoogleSSOSubmit,
  onPasswordSubmit,
  onResendLinkSuccess,
  tokenExpired,
}: ViewProps): React.ReactElement => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack
      spacing={3}
      sx={{
        width: { mobile: '342px', desktop: '333px' },
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stack flexDirection="column" spacing="10px" alignItems="center">
        <ZicassoSVG aria-label="Zicasso" />
        {!tokenExpired && (
          <Typography color="text.secondary" style={{ marginTop: '10px', textAlign: 'center' }}>
            Create a password to activate your account or sign in with Google.
          </Typography>
        )}
        {(tokenExpired && !newLinkSent) && (
          <Typography color="error" style={{ marginTop: '10px', textAlign: 'center', maxWidth: '285px' }}>
            Your link has expired. Please enter your email and we will send you a new link.
          </Typography>
        )}
        {newLinkSent && (
          <Typography color="text.secondary" sx={{ marginTop: '10px', textAlign: 'center', maxWidth: '333px' }}>
            Thanks! We&apos;ll send you an email with instructions on how to reset your password.
          </Typography>
        )}
      </Stack>
      {loading && (
      <ZSpinner />
      )}
      {!tokenExpired && (
        <PasswordLogin
          defaultValues={{ username: email, password: '' }}
          onSubmit={onPasswordSubmit}
          emailReadOnly
          confirmPassword
          submitText="Activate Account"
        />
      )}
      {(tokenExpired && !newLinkSent) && (
        <SendLink email={email} onSuccess={onResendLinkSuccess} type="activate" />
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Divider sx={{ flex: 1 }} />
        <Typography variant="body2" color="" margin="0 12px">or</Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
      >
        <GoogleLoginView onSuccess={onGoogleSSOSubmit} />
      </Box>
      {loginError && (
        <ErrorBox>
          <ErrorTitle>
            There was an on our end.
          </ErrorTitle>
          {loginError.code ? (
            <ErrorMessage>
              {loginError.code && `Code: ${loginError.code} `}
              {loginError.message || 'Please contact support'}
              <br />
              {loginError.id && `ID: ${loginError.id}`}
            </ErrorMessage>
          ) : (
            <ErrorMessage>
              Please contact support.
            </ErrorMessage>
          )}
        </ErrorBox>
      )}
    </Stack>
  </Box>
);

export default View;

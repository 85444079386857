import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { SWRResponse } from 'swr';
import { Agent } from '@marageti/z4-sdk/lib/people';

type UserProfileErrorProps = {
  error: SWRResponse<Agent, any, any>['error']
};
const UserProfileError = ({ error } : UserProfileErrorProps) => (
  <Alert severity="error">
    <AlertTitle>Error</AlertTitle>
    {error.message}
  </Alert>
);

export default UserProfileError;

import React from 'react';
import {
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import Alerts from './alerts';
import TotalAndTable from './totals-and-table';
import { AlertObj } from '../../../components/alerts/utils';

type Props = {
  alert: AlertObj | undefined,
  isLoading: boolean,
  sales: SalesRecording[] | undefined,
  showConfirmDeposit: boolean,
};

const SalesView = ({
  alert,
  isLoading,
  sales,
  showConfirmDeposit,
}: Props) => (
  <Container maxWidth="xl">
    <Box
      sx={{
        pt: { desktop: 4.5, mobile: 6.5 },
        pb: 20,
        maxWidth: { desktop: 'desktop' },
        margin: { desktop: '0 auto 56px' },
      }}
    >
      <Stack spacing={5}>
        <Typography variant="h2" sx={{ mb: { mobile: 3, desktop: 'none' } }}>Sales</Typography>
        {alert && <Alerts alert={alert} />}
        {!showConfirmDeposit && (
          <TotalAndTable isLoading={isLoading} sales={sales} />
        )}
      </Stack>
    </Box>
  </Container>
);

export default SalesView;

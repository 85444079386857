import React, { useState } from 'react';
import {
  useParams, useNavigate, useLocation,
} from 'react-router-dom';
import {
  Message, MessageType,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import NoteView from './note-view';
import useChat from '../../../../hooks/use-chat';

const NoteContainer = () => {
  const { conversationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<Error | null>(null);
  const isInternal = location.state?.isInternal;

  const {
    sender,
    sendMessage,
  } = useChat({
    conversationId,
  });

  const reset = () => {
    setError(null);
  };

  const handleNavigateBack = () => {
    if (location.key === 'default') {
      navigate('..');
    } else navigate(-1);
  };

  const handleSubmit = ({
    message,
  }: {
    message: string;
  }) => {
    reset();

    const senderName = sender.data?.fullName;
    const title = `Note from ${senderName}`;

    const messageToSend: Partial<Message> = {
      conversationId,
      text: message,
      title,
      messageType: MessageType.PartnerNote,
    };

    sendMessage(messageToSend)
      .then(() => {
        enqueueSnackbar('Note created.', {
          variant: 'success',
        });
        handleNavigateBack();
      })
      .catch((e: Error) => {
        setError(e);
      });
  };

  return (
    <NoteView
      isInternal={isInternal}
      onSubmit={handleSubmit}
      handleNavigateBack={handleNavigateBack}
      error={error}
    />
  );
};

export default NoteContainer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import {
  ChipOption,
  ChipSelectList,
  DebouncedSearchInput,
} from '@marageti/z4-lib';
import SalesTable from './sales-table';
import AssignedToSelect from '../../../components/assignee-input';

interface SearchFilterSortState {
  searchValue: string | undefined;
  selectedCountries: string[] | undefined;
  selectValues: { [key: string]: Option } | undefined;
  switchValues: { [key: string]: boolean } | undefined;
  selectedChips: string[] | undefined;
}

export type Option = {
  title: string;
  value: string;
};

export type AgentOption = Option & {
  status?: string;
};

type Props = {
  chipTypeOptions: ChipOption[];
  chipStatusOptions: ChipOption[];
  handleSearchInputChange: (value: string) => void;
  isLoading: boolean;
  onChangeAgent: (value: string) => void;
  onChangeEndDay: (value: Date | null) => void;
  onChangeOrderBy: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSortBy: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStartDay: (value: Date | null) => void;
  onChipsChange: (value: string[], type: 'status' | 'type') => void;
  orderBy: string;
  orderByOptions: Option[];
  refreshKey: number;
  sales: SalesRecording[] | undefined;
  searchTerm: string;
  selectedAgent: string;
  selectedTypeChips: string[];
  selectedStatusChips: string[];
  selectedEndDay: Date | null;
  selectedStartDay: Date | null;
  sortBy: string;
  sortByOptions: Option[];
};

export interface SelectConfig {
  label: string;
  value: string;
  options: Option[] | ((state: SearchFilterSortState) => Option[]);
  defaultValue: Option
}

const today = new Date();

const AllSalesView = ({
  chipTypeOptions,
  chipStatusOptions,
  handleSearchInputChange,
  isLoading,
  onChangeAgent,
  onChangeEndDay,
  onChangeOrderBy,
  onChangeSortBy,
  onChangeStartDay,
  onChipsChange,
  orderBy,
  orderByOptions,
  refreshKey,
  sales,
  searchTerm,
  selectedAgent,
  selectedTypeChips,
  selectedStatusChips,
  selectedEndDay,
  selectedStartDay,
  sortBy,
  sortByOptions,
}: Props) => {
  const navigate = useNavigate();
  const minDate = new Date('2008-01-01T00:00:00');
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          pt: { desktop: 4.5, mobile: 6.5 },
          pb: 20,
          maxWidth: { desktop: 'desktop' },
          margin: { desktop: '0 auto 56px' },
        }}
      >
        <Stack spacing={3} useFlexGap>
          <Stack direction={{ desktop: 'row' }} justifyContent="space-between" alignItems={{ desktop: 'center' }}>
            <Typography variant="h2" sx={{ mb: { mobile: 3, desktop: 'none' } }}>All Sales</Typography>
            <Button variant="contained" onClick={() => navigate('/sales/repeat-referral')}>Record Repeat / Referral</Button>
          </Stack>
          <Grid container columnSpacing={2}>
            <Grid item mobile={12} desktop={3}>
              <DebouncedSearchInput
                label="Search by ID or Traveler Name"
                name="fullText"
                onChange={handleSearchInputChange}
                defaultValue={searchTerm}
                key={`refreshKey-${refreshKey}`}
              />
            </Grid>
            <Grid
              item
              mobile={12}
              desktop={3}
            >
              <AssignedToSelect
                assignedTo={selectedAgent as string}
                onChange={onChangeAgent}
                showCount
                label="Agent"
              />
            </Grid>
            <Grid
              item
              mobile={12}
              desktop={6}
            >
              <Stack direction="row" alignItems="center">
                <DatePicker
                  label="Start Date"
                  maxDate={selectedEndDay || today}
                  onChange={onChangeStartDay}
                  value={selectedStartDay}
                  views={['year', 'month', 'day']}
                  format="MM/dd/yy"
                  minDate={minDate}
                  slotProps={{
                    textField: {
                      sx: {
                        maxWidth: { desktop: '304px' },
                        width: '100%',
                      },
                    },
                  }}
                />
                <Typography mx={1}>to</Typography>
                <DatePicker
                  label="End Date"
                  maxDate={today}
                  onChange={onChangeEndDay}
                  value={selectedEndDay}
                  views={['year', 'month', 'day']}
                  format="MM/dd/yy"
                  minDate={selectedStartDay || minDate}
                  slotProps={{
                    textField: {
                      sx: {
                        maxWidth: { desktop: '304px' },
                        width: '100%',
                      },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              mobile={6}
              desktop={3}
            >
              <TextField
                fullWidth
                select
                disabled={!!searchTerm.length}
                key="sory-by-select"
                label="Sort By"
                name="sortBy"
                onChange={onChangeSortBy}
                value={sortBy}
              >
                {sortByOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              mobile={6}
              desktop={3}
            >
              <TextField
                fullWidth
                select
                key="order-by-select"
                label="Order By"
                name="orderBy"
                onChange={onChangeOrderBy}
                value={orderBy}
              >
                {orderByOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack
            direction={{
              mobile: 'column', desktop: 'row',
            }}
            spacing={{
              mobile: 2, desktop: 4,
            }}
          >
            <ChipSelectList
              label="Sales Type"
              chipOptions={chipTypeOptions}
              selectedChips={selectedTypeChips}
              onChange={(chips) => onChipsChange(chips, 'type')}
            />
            <ChipSelectList
              label="Status"
              chipOptions={chipStatusOptions}
              selectedChips={selectedStatusChips}
              onChange={(chips) => onChipsChange(chips, 'status')}
            />
          </Stack>
          {!isLoading && sales?.length === 0 ? (
            <Card>
              <CardContent>
                <Typography variant="body" color="primary.light" textAlign="center">
                  There are no sales recordings for the selected month.
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <SalesTable
              sales={sales}
            />
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default AllSalesView;

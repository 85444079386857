import React, { useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  AgentCount, TripMatch, TripMatchStatus, TripRequest,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { RoleName, UserStatus } from '@marageti/z4-sdk/lib/auth';
import AcceptTripView from './accept-lead-view';
import { useAuthStore } from '../../../store';

const shouldRedirectTM = (tm: TripMatch) => {
  if (tm.status === TripMatchStatus.MatchAccepted) {
    return true;
  }
  if (tm.status === TripMatchStatus.MatchActive) {
    return true;
  }
  if (tm.status === TripMatchStatus.MatchPostponed) {
    return true;
  }
  if (tm.status === TripMatchStatus.MatchInactive) {
    return true;
  }
  return false;
};

const shouldBLockTM = (tm: TripMatch) => {
  if (tm.status === TripMatchStatus.MatchPartnerDeclined) {
    return true;
  }
  if (tm.status === TripMatchStatus.MatchZicassoCanceled) {
    return true;
  }
  if (tm.status === TripMatchStatus.MatchTravelerCanceled) {
    return true;
  }
  return false;
};

const AcceptTripContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { tripMatchId } = useParams();
  const { loggedInAgent } = useAuthStore();

  const {
    data: agentList,
    mutate: mutateSearch,
  } = useFetch<AgentCount[]>(apiClient.partnersClient.getPartnerAgentCounts, [loggedInAgent?.partnerId], true);
  const { data: agents } = useFetch<Agent[]>(apiClient.partnersClient.getPartnerAgents, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  // Assignable agents are agents that are enabled and not agent support
  const assignableAgents = agentList?.filter((agent) => {
    const matchingAgent = agents?.find((a) => a.id === agent.id);

    return matchingAgent?.userInfo.some(
      (userInfo) => userInfo.status === UserStatus.ENABLED
        && !userInfo.roles?.includes(RoleName.AGENT_SUPPORT),
    );
  });

  const {
    data: tripMatchData,
    error: tripMatchError,
    isLoading: isLoadingTripMatch,
  } = useFetch<TripMatch>(
    apiClient.tripMatchesClient.getTripMatch,
    [tripMatchId],
    true,
  );
  const { tripRequestId } = tripMatchData || {};
  const {
    data: tripRequestData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: tripRequestError,
    isLoading: isLoadingTripRequest,
  } = useFetch<TripRequest>(apiClient.tripRequestsClient.getTripRequest, [tripRequestId], !!tripRequestId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onRejectTrip = (arg:{ reason:string, comment: string }) => {
    setIsSubmitting(true);
    apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId as string, {
      id: tripMatchId as string,
      status: TripMatchStatus.MatchPartnerDeclined,
      statusInfo: {
        reason: `${arg.reason}: ${arg.comment}`,
      },
    }).then(() => {
      mutateSearch();
      enqueueSnackbar('Trip Declined', { variant: 'success' });
      navigate('/trip-matches', { state: { refreshKey: tripMatchId }, replace: true });
    }).catch((e) => (console.error(e)))
      .finally(() => setIsSubmitting(false));
  };

  const onAssignTrip = (agentId: string) => {
    setIsSubmitting(true);
    apiClient.tripMatchesClient.putTripMatchAssignment(tripMatchId as string, agentId).then((res) => {
      const { assignedToName } = res;
      mutateSearch();
      enqueueSnackbar(`Trip assigned to ${assignedToName}`, { variant: 'success' });
      navigate('/trip-matches', { replace: true });
    }).catch((e) => (console.error(e)))
      .finally(() => setIsSubmitting(false));
  };

  if (tripMatchError?.code === 401 || tripMatchError?.code === 404) {
    return (
      <Navigate to="/404" replace />
    );
  }

  if (tripMatchData && shouldBLockTM(tripMatchData)) {
    return (
      <Navigate to="/404" replace />
    );
  }

  if (tripMatchData && shouldRedirectTM(tripMatchData)) {
    return (
      <Navigate to={`/trip-matches/${tripMatchData.id}`} replace />
    );
  }

  return (
    <AcceptTripView
      isSubmitting={isSubmitting}
      loading={isLoadingTripMatch || isLoadingTripRequest}
      tripMatch={tripMatchData}
      tripRequest={tripRequestData}
      agents={assignableAgents?.sort((a, b) => a.fullName.localeCompare(b.fullName)) || []}
      onRejectTrip={onRejectTrip}
      onAssignTrip={onAssignTrip}
    />
  );
};

export default AcceptTripContainer;

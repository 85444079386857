import React from 'react';
import {
  TripMatch, ConversationSummaries, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { ConversationLinkProps } from './ConversationLink';
import { getConversationSummaryByConversationId } from './helpers';
import ConversationLinksCard, { ConversationLinksCardProps } from './ConversationLinksCard';
import { ConversationLinkMap } from './types';

type TripMatchConversationLinksProps = {
  tripMatchId: string;
  renderConversationLink: ConversationLinksCardProps['renderConversationLink'];
  getConversationTypeTitle: ConversationLinkProps['getConversationTypeTitle'];
};

const TripMatchConversationLinks = ({
  tripMatchId,
  renderConversationLink,
  getConversationTypeTitle,
}: TripMatchConversationLinksProps) => {
  const apiClient = useApiClient();

  // Fetch all required data upfront
  const {
    data: tripMatch,
    error: tripMatchError,
    isLoading: isTripMatchLoading,
  } = useFetch<TripMatch>(
    apiClient.tripMatchesClient.getTripMatch,
    [tripMatchId],
    !!tripMatchId,
  );

  const {
    data: allConversations,
    error: allConversationsError,
    isLoading: isAllConversationsLoading,
  } = useFetch<ConversationSummaries>(
    apiClient.tripRequestsClient.getConversationSummaries,
    [tripMatch?.tripRequestId],
    !!tripMatch?.tripRequestId,
  );

  // Handle loading state
  const isLoading = isTripMatchLoading
    || isAllConversationsLoading;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  const hasError = tripMatchError
    || allConversationsError
    || !allConversations
    || !tripMatch;

  if (hasError) {
    return <div>Error loading conversation data</div>;
  }

  // Get default conversations
  const defaultTravelerToPartner = getConversationSummaryByConversationId(
    tripMatch.travelerToPartnerId,
    allConversations,
  );
  const defaultZicassoToPartner = getConversationSummaryByConversationId(
    tripMatch.zicassoToPartnerId,
    allConversations,
  );

  if (!defaultTravelerToPartner || !defaultZicassoToPartner) {
    return <div>Error loading conversation data</div>;
  }

  const summaries: ConversationLinkMap = {};

  // Build conversation summaries
  summaries[ConversationType.TravelerToPartner] = {
    defaultContext: defaultTravelerToPartner,
  };
  summaries[ConversationType.ZicassoToPartner] = {
    defaultContext: defaultZicassoToPartner,
  };

  return (
    <ConversationLinksCard
      key={tripMatch.id}
      conversationSummaries={summaries}
      tripMatchId={tripMatchId}
      renderConversationLink={renderConversationLink}
      getConversationTypeTitle={getConversationTypeTitle}
    />
  );
};

export default TripMatchConversationLinks;

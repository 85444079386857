import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useFetch, useApiClient, useFetchImmutable } from '@marageti/z4-lib';
import { Partner, PartnerOffice } from '@marageti/z4-sdk/lib/travel';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { useAuthStore } from '../../../store';
import View from './profile-view';

const Container = () => {
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    loggedInAgent,
  } = useAuthStore();
  const loggedInAgentIsAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN)) || false;
  const {
    data: countryCodes,
  } = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const {
    data: partner,
    mutate,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);
  const [contextMenusOpen, setContextMenusOpen] = useState<{ [key: string]: boolean }>({});
  const [contextMenuAnchors, setContextMenuAnchors] = useState<{ [key: string]: HTMLElement | null }>({});
  const [indexToDelete, setIndexToDelete] = useState< undefined | number>(undefined);

  const handleOpenContextMenu = (attributeName: string, e: React.MouseEvent<HTMLButtonElement>) => {
    const updatedContextMenuAnchors = {
      ...contextMenuAnchors,
      [attributeName]: e.currentTarget || null,
    };
    const updatedContextMenusOpen = {
      ...contextMenusOpen,
      [attributeName]: true,
    };
    setContextMenusOpen(updatedContextMenusOpen);
    setContextMenuAnchors(updatedContextMenuAnchors);
  };

  const handleCloseContextMenu = (attributeName: string) => {
    const updatedContextMenuAnchors = {
      ...contextMenuAnchors,
      [attributeName]: null,
    };
    const updatedContextMenusOpen = {
      ...contextMenusOpen,
      [attributeName]: false,
    };
    setContextMenusOpen(updatedContextMenusOpen);
    setContextMenuAnchors(updatedContextMenuAnchors);
  };

  const removeOfficeAtindx = (array:PartnerOffice[], index:number) => {
    if (index < 0 || index >= array.length) {
      return array;
    }

    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  };

  const onConfirmDelete = async () => {
    const ar = partner?.offices ? [...partner.offices] : [];
    const offices = removeOfficeAtindx(ar, indexToDelete!);
    const updatePartner = {
      ...partner,
      offices,
    } as Partner;

    try {
      await apiClient.partnersClient.putPartner(partner!.id, updatePartner);
      enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
    } catch {
      enqueueSnackbar('Profile updated failed.', { variant: 'default' });
    } finally {
      setIndexToDelete(undefined);
      // In Production mutate doesn't seem to be functioning as expected
      mutate();
      // Trigger a page refresh to render updated partner data
      navigate('/settings/company-profile');
    }
  };

  const onRemoveClick = (index:number) => (setIndexToDelete(index));
  const onCancelDelete = () => (setIndexToDelete(undefined));

  return (
    <View
      isAdmin={loggedInAgentIsAdmin}
      contextMenuAnchors={contextMenuAnchors}
      contextMenusOpen={contextMenusOpen}
      onCloseContextMenu={handleCloseContextMenu}
      onOpenContextMenu={handleOpenContextMenu}
      onRemoveOffice={onRemoveClick}
      partner={partner}
      indexToDelete={indexToDelete}
      onCancelDelete={onCancelDelete}
      onConfirmDelete={onConfirmDelete}
      countryCodes={countryCodes}
    />
  );
};

export default Container;

import React from 'react';
import { Stack } from '@mui/material';
import {
  Card,
  ListItemCardContent,
  ListItemCardTitle,
  ListItemCardSubtitle,
  Link,
} from '@marageti/z4-lib';

export type LinkCardProps = {
  title: string;
  subTitle: string;
  href: string;
  icon?: React.ReactNode;
};

const LinkCard = ({
  href,
  title,
  subTitle,
  icon = undefined,
}: LinkCardProps) => (
  <Link
    to={href}
    sx={{
      maxWidth: {
        desktop: '774px',
      },
      width: {
        desktop: '100%',
      },
    }}
  >
    <Card
      sx={{
        '&:hover': {
          bgcolor: 'grey.A100',
        },
      }}
    >
      <ListItemCardContent>
        <Stack>
          <ListItemCardTitle>{title}</ListItemCardTitle>
          <ListItemCardSubtitle>{subTitle}</ListItemCardSubtitle>
        </Stack>
        {icon && (icon)}
      </ListItemCardContent>
    </Card>
  </Link>
);

export default LinkCard;

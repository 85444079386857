import * as z from 'zod';
import { SalesStage } from '@marageti/z4-sdk/lib/travel/TripMatch';

export const DestinationsSchema = z.object({
  countries: z.array(z.string()),
  createdAt: z.string(),
  id: z.string(),
  name: z.string(),
  served: z.boolean(),
  updateId: z.string(),
  updatedAt: z.string(),
});

export const TripEditSchema = z.object({
  countries: z.array(z.string()).min(1, 'Select at least one country'),
  departureDate: z.date(),
  totalNights: z.number().min(5, 'Enter the total number of nights'),
  budget: z.number().min(1, 'Enter a total budget'),
  numberOfAdults: z.number().min(1, 'Enter the number of adults'),
  numberOfChildren: z.number(),
  salesStage: z.union([z.nativeEnum(SalesStage), z.literal('Undefined')], { required_error: 'SalesStage is required' }),
});

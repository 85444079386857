import React from 'react';
import {
  Stack, Skeleton, Box,
} from '@mui/material';
import DetailsCard from './DetailsCard';

const UserProfileSkeleton = () => (
  <Stack
    direction="column"
    spacing={3}
  >
    <Box
      display="flex"
      justifyContent="center"
    >
      <Skeleton
        variant="circular"
        width={138}
        height={138}
      />
    </Box>

    <DetailsCard
      items={[
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
        {
          title: <Skeleton width={50} />,
          content: <Skeleton width={125} />,
        },
      ]}
    />
  </Stack>
);

export default UserProfileSkeleton;

import React from 'react';
import {
  MenuItem,
  Grid,
  TextField,
} from '@mui/material';
import {
  ChipOption,
  ChipSelectList,
  DebouncedSearchInput,
  MultiCountrySelector,
  Option,
} from '@marageti/z4-lib';
import AssignedToSelect from '../../../components/assignee-input';

type SoldTripsFiltersProps = {
  searchTerm: string;
  refreshKey: number;
  assignedTo: string;
  chipOptions: ChipOption[];
  countries: string[],
  onChange: (n: string, v: string | string[]) => void;
  selectedChips: string[];
  sortBy: string;
  sortByOptions: Option[];
  countryOptions: { country:string }[];
};

const SoldTripsFilters = ({
  searchTerm,
  refreshKey,
  assignedTo,
  chipOptions,
  countries,
  countryOptions,
  onChange,
  selectedChips,
  sortBy,
  sortByOptions,
}: SoldTripsFiltersProps) => (
  <Grid container spacing={{ mobile: 0, desktop: 2 }} mb={4}>
    <Grid item mobile={12} desktop={3}>
      <DebouncedSearchInput
        label="Search"
        onChange={(v: string) => onChange('fullText', v)}
        defaultValue={searchTerm}
        key={`refreshKey-${refreshKey}`}
      />
    </Grid>
    <Grid item mobile={12} desktop={3}>
      <MultiCountrySelector
        key="destination-select"
        label="Countries"
        name="countries"
        onChange={(_, value) => onChange('countries', value)}
        value={countries}
        countries={countryOptions}
      />
    </Grid>
    <Grid item mobile={12} desktop={3}>
      <AssignedToSelect assignedTo={assignedTo} key="assignedTo-select" onChange={(v) => onChange('assignedTo', v)} />
    </Grid>
    <Grid item mobile={12} desktop={3}>
      <TextField
        key="sort-by-select"
        label="Sort By"
        name="sortBy"
        onChange={(e) => onChange('sortBy', e.target.value)}
        select
        sx={{
          width: '100%',
          '& .MuiSelect-icon > svg > path': { color: 'primary.main' },
        }}
        value={sortBy}
      >
        {sortByOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
    <Grid item mobile={12} desktop={12}>
      <ChipSelectList
        chipOptions={chipOptions}
        label="Refine Sold"
        onChange={(v: string[]) => onChange('selectedChips', v)}
        selectedChips={selectedChips}
      />
    </Grid>
  </Grid>
);

export default SoldTripsFilters;

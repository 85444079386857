import React from 'react';
import { useParams, Outlet, Navigate } from 'react-router-dom';
import {
  useApiClient,
  useFetchImmutable,
  OtherChips,
  TravelerTagChips,
  TripMatchChips,
  useFetch,
  BackButton,
} from '@marageti/z4-lib';
import {
  Skeleton,
  Stack,
} from '@mui/material';
import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import { TripMatch } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { ReferralType } from '@marageti/z4-sdk/lib/travel/TripRequest';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import { formatCountryCodes } from '../../utils/helpers';
import { TabItem } from '../../components/sub-navigation/types';
import SubNavigation from '../../components/sub-navigation';
import { useAuthStore } from '../../store';

const NAV_LINKS: TabItem[] = [
  {
    label: 'acceptance',
    to: '',
  },
  {
    label: 'messages',
    to: 'messages',
  },
];

const { ChipVIP } = TravelerTagChips;
const { ChipRepeat, ChipPRL } = OtherChips;
const { getTripMatchStatusChip } = TripMatchChips;

const tagIcons = {
  VIP: <ChipVIP />,
  RPT: <ChipRepeat />,
  PRL: <ChipPRL />,
};

const AcceptLeadSubNav = () => {
  const { loggedInAgent } = useAuthStore();
  const loggedInAgentIsAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN));
  const { tripMatchId } = useParams();
  const apiClient = useApiClient();
  const { data: countryCodes } = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const { data: tripMatch } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);

  const traveler = tripMatch?.travelers[0];
  const partnerName = tripMatch ? tripMatch.partnerName : <Skeleton width={150} />;
  const travelerName = traveler ? traveler.fullName : <Skeleton width={250} />;
  const countries = tripMatch && countryCodes ? formatCountryCodes(tripMatch.countries, countryCodes) : <Skeleton width={100} />;

  if (!loggedInAgentIsAdmin) {
    return <Navigate to="/trip-matches" replace />;
  }
  return (
    <>
      <SubNavigation
        profile={{
          primary: travelerName,
          secondary: countries,
          tertiary: partnerName,
          decoration: (
            <Stack direction="row" spacing={0.75}>
              {tripMatch ? getTripMatchStatusChip(tripMatch.status, 'small') : null}
              {traveler?.tags?.includes(TravelerTags.VIP) ? tagIcons.VIP : null}
              {tripMatch?.repeatTraveler ? tagIcons.RPT : null}
              {tripMatch?.referralType === (ReferralType.OneTimePartnerReferral || ReferralType.LifetimePartnerReferral) ? tagIcons.PRL : null}
            </Stack>
          ),
        }}
        tabs={NAV_LINKS}
        startAdornment={(
          <BackButton
            aria-label="back to trip matches"
            defaultPath="/trip-matches"
            edge="start"
          />
        )}
      />
      <Outlet />
    </>
  );
};

export default AcceptLeadSubNav;

import {
  Typography,
  Box,
  Container,
  Stack,
  Card,
  CardHeader,
  Skeleton,
  Alert,
  AlertTitle,
} from '@mui/material';
import React from 'react';
import { SWRResponse } from 'swr';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';

type CountryCardProps = {
  name: React.ReactNode;
};

const CountryCard = ({ name }: CountryCardProps) => (
  <Card variant="dense">
    <CardHeader
      title={name}
      titleTypographyProps={{
        variant: 'bodyBold',
      }}
    />
  </Card>
);

type SectionProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  countryNames: React.ReactNode[];
};

const Section = ({
  title,
  content,
  countryNames,
}: SectionProps) => (
  <div>
    <Box mb={3}>
      <Typography
        variant="h3"
        sx={{
          mb: 1,
        }}
      >
        {title}

      </Typography>
      <Typography variant="body1">{content}</Typography>
    </Box>
    <Stack spacing={3}>
      {countryNames.map((name: React.ReactNode, index: number) => (
        <CountryCard
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${name}`}
          name={name}
        />
      ))}
    </Stack>
  </div>
);

type CountriesViewProps = {
  partner: SWRResponse<Partner, any, any>;
  countryCodes: SWRResponse<CountryCodeMap, any, any>;
};

const CountriesView = ({ partner, countryCodes }: CountriesViewProps) => {
  const getCountryName = React.useCallback(
    (countryCode: string) => {
      if (!countryCodes.data) {
        return null;
      }

      return countryCodes.data[countryCode];
    },
    [countryCodes.data],
  );

  const countries = React.useMemo(
    () => {
      if (!partner.data || !countryCodes.data) {
        return {
          primaryCountries: [],
          secondaryCountries: [],
        };
      }

      const primaryCountries = [] as string[];
      const secondaryCountries = [] as string[];

      partner.data.countries.forEach((country) => {
        const countryName = getCountryName(country.country);
        if (countryName) {
          if (country.primary) {
            primaryCountries.push(countryName);
          } else {
            secondaryCountries.push(countryName);
          }
        }
      });

      return {
        primaryCountries,
        secondaryCountries,
      };
    },
    [partner.data, countryCodes.data],
  );

  const isLoading = countryCodes.isLoading || partner.isLoading;
  const isError = countryCodes.error || partner.error;
  const skeletonCountries = Array.from({ length: 3 }).map(() => <Skeleton width={125} />);

  return (
    <Container
      maxWidth="md"
      sx={{
        py: {
          desktop: 6,
          mobile: 3,
        },
      }}
    >
      <Typography variant="h2" sx={{ mb: 3 }}>
        Countries
      </Typography>
      {isError ? (
        <Alert severity="error">
          <AlertTitle>Countries Error</AlertTitle>
          There was an error loading the countries. Please try again later.
        </Alert>
      ) : (
        <Stack spacing={5}>
          <Section
            title="Primary Countries"
            content={(
              <>
                <Typography component="span" variant="bodyBold"> Primary Destinations</Typography>
                &nbsp;include only those countries where you have in-depth knowledge and visit frequently. Zicasso will match you with leads specifically for that destination.
              </>
            )}
            countryNames={isLoading
              ? skeletonCountries
              : countries?.primaryCountries}
          />
          <Section
            title="Secondary Countries"
            content={(
              <>
                <Typography component="span" variant="bodyBold"> Secondary Destinations</Typography>
                &nbsp;include those countries where you have a working knowledge, including the highlights.
                Zicasso will match you with leads for any Secondary Destination that also include a Primary Destination you service.
              </>
            )}
            countryNames={isLoading
              ? skeletonCountries
              : countries?.secondaryCountries}
          />
        </Stack>
      )}
    </Container>
  );
};

export default CountriesView;

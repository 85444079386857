/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { Template, TemplateType } from '@marageti/z4-sdk/lib/templates/Template';
import TemplateCard from './template-card';

type TemplatesViewProps = {
  onClickAdd: (typeOptions: TemplateType) => void,
  onClickEdit: (t: Template) => void,
  onClickRemove: (id: string, type: TemplateType) => void,
  templates: {
    [TemplateType.PersonalTemplate]: Template[],
    [TemplateType.PartnerTemplate]: Template[],
  },
};

const TemplatesView = ({
  onClickAdd,
  onClickEdit,
  onClickRemove,
  templates,
}: TemplatesViewProps) => (
  <Container maxWidth="md">
    <Box
      sx={{
        py: { desktop: 6, mobile: 3 },
      }}
    >
      <Typography variant="h2" mb={3}>
        Templates
      </Typography>
      <Stack spacing={9}>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h3" mb={0.5}>
              My Templates
            </Typography>
            <Typography color="primary.light">
              These are your personal message templates
            </Typography>
          </Box>
          {templates[TemplateType.PersonalTemplate]?.length > 0 && (
            <Stack spacing={1.5}>
              {templates[TemplateType.PersonalTemplate].map((template) => (
                <TemplateCard
                  key={template.id}
                  onClickEdit={() => onClickEdit(template)}
                  onClickRemove={() => onClickRemove(template.id, template.templateType)}
                  {...template}
                />
              ))}
            </Stack>
          )}
          <Button
            onClick={() => onClickAdd(TemplateType.PersonalTemplate)}
            sx={{ maxWidth: { desktop: 'fit-content' } }}
            variant="outlined"
          >
            Add New Template
          </Button>
        </Stack>

        <Stack spacing={3}>
          <Box>
            <Typography variant="h3" mb={0.5}>
              Team Templates
            </Typography>
            <Typography color="primary.light">
              These are your team&apos;s shared message templates
            </Typography>
          </Box>
          {templates[TemplateType.PartnerTemplate]?.length > 0 && (
            <Stack spacing={1.5}>
              {templates[TemplateType.PartnerTemplate].map((template) => (
                <TemplateCard
                  key={template.id}
                  onClickEdit={() => onClickEdit(template)}
                  onClickRemove={() => onClickRemove(template.id, template.templateType)}
                  {...template}
                />
              ))}
            </Stack>
          )}
          <Button
            onClick={() => onClickAdd(TemplateType.PartnerTemplate)}
            sx={{ maxWidth: { desktop: 'fit-content' } }}
            variant="outlined"
          >
            Add New Team Template
          </Button>
        </Stack>
      </Stack>
    </Box>
  </Container>
);

export default TemplatesView;

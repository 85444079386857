import React from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  TripMatch, TripMatchStatus, ConversationSummaries, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import MessagesView from './messages-view';

const MessagesContainer = () => {
  const { tripMatchId } = useParams();
  const apiClient = useApiClient();
  const tripMatch = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const conversationSummaries = useFetch<ConversationSummaries>(apiClient.tripRequestsClient.getConversationSummaries, [tripMatch.data?.tripRequestId], !!tripMatch.data?.tripRequestId);

  const travelerConversation = conversationSummaries?.data?.find((c) => c.conversationType === ConversationType.TravelerToPartner);
  const zicassoConversation = conversationSummaries?.data?.find((c) => c.conversationType === ConversationType.ZicassoToPartner);

  const disabledTravelerLink = tripMatch.data?.status === TripMatchStatus.MatchPending;

  return (
    <MessagesView
      disabledTravelerLink={disabledTravelerLink}
      error={tripMatch.error || conversationSummaries.error}
      loading={tripMatch.isLoading || conversationSummaries.isLoading}
      travelerConversation={travelerConversation}
      tripMatch={tripMatch.data}
      tripMatchId={tripMatchId!}
      zicassoConversation={zicassoConversation}
    />
  );
};

export default MessagesContainer;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  AutocompleteSelect,
  useForm,
  getSubmitButtonProps,
  RichTextEditor,
} from '@marageti/z4-lib';
import {
  Button,
  Alert,
  AlertTitle,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';

const ACTIONS = [{
  title: 'Log Phone Call',
  value: 'call',
}, {
  title: 'Log Text',
  value: 'text',
}];

const schema = z.object({
  action: z.object({
    title: z.string(),
    value: z.string(),
  }),
  message: z.string().min(1, 'Required'),
});

type LogViewProps = {
  closeDialog: () => void,
  error: Error | null;
  onSubmit: (data: any) => void;
  open: boolean,
};

const LogView = ({
  closeDialog,
  error,
  onSubmit,
  open,
}: LogViewProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      message: '',
      action: ACTIONS[0],
    },
  });

  const handleSelectChange = (_: any, selectedOption: {
    title: string;
    value: string;
  }) => {
    setValue('action', selectedOption);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={isMobile}
    >
      <DialogTitle>
        Log Call / Text
      </DialogTitle>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: '100%' }}
      >
        <DialogContent>
          <AutocompleteSelect
            label="Action"
            name="action"
            value={watch('action')}
            onChange={handleSelectChange}
            options={ACTIONS}
          />
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <RichTextEditor
                editorName="Log-Editor"
                error={!!errors.message}
                label="Message"
                onChange={field.onChange}
                sx={{
                  height: '380px',
                  maxHeight: '380px',
                }}
                useLinks
                useLists
              />
            )}
          />
          <Typography variant="caption" color="text.secondary">
            Call and Text Logs are internal only, not visible to the other party.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              width: { desktop: 'fit-content' },
            }}
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Submit
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={closeDialog}
            sx={{
              width: { desktop: 'fit-content' },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Stack>
      {error && (
      <Alert severity="error">
        <AlertTitle>There was an error submitting log</AlertTitle>
        {error?.message}
      </Alert>
      )}
    </Dialog>
  );
};

export default LogView;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { BearerToken } from '@marageti/z4-sdk/lib/auth';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Agent } from '@marageti/z4-sdk/lib/people';
import {
  hasAuthorizedRole,
  LocalStorageService,
  useQueryParams,
  useApiClient,
} from '@marageti/z4-lib';
import { FieldValues } from 'react-hook-form';
import { GoogleLoginRequest, Token } from '@marageti/z4-sdk/lib/auth/Token';
import LoginView from './activate-view';
import { useAuthStore } from '../../store';
import { authorizedRoles, getMatchingUser } from '../../utils/auth';

const LocalStorage = new LocalStorageService();

const ActivateContainer = () => {
  const {
    isAuthenticated,
    removeAuthenticatedAgent,
    setIsAuthenticated,
    setAuthenticatedAgent,
  } = useAuthStore();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const tokenExists = useLoaderData();
  const { email, token, redirect } = useQueryParams();

  const [loginError, setLoginError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(!tokenExists);
  const [newLinkSent, setNewLinkSent] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirect || '/getting-started');
    }
  }, [isAuthenticated]);

  const onActivationSuccess = (bearerToken: BearerToken): void => {
    const { accessToken } = bearerToken;
    apiClient.instance.setAuthToken(accessToken);

    apiClient.authClient.getToken(accessToken)
      .then((t: Token) => {
        LocalStorage.addToLocalStorage('accessToken', accessToken);
        LocalStorage.addToLocalStorage('email', t.email);
        return apiClient.peopleClient.getAgent(email);
      })
      .then((data: Agent) => {
        const matchingUser = getMatchingUser(data.email, data.userInfo);
        if (!matchingUser || !hasAuthorizedRole(matchingUser.roles, authorizedRoles)) {
          throw new Error('Not Authorized');
        }
        setAuthenticatedAgent(data);
        setIsAuthenticated(true);
      })
      .catch((e: ApiError) => {
        removeAuthenticatedAgent();
        setIsAuthenticated(false);
        setLoginError(e);
      })
      .finally(() => setLoading(false));
  };

  // Username and password activation and login
  const onPasswordSubmit = (data: FieldValues) => {
    setLoginError(undefined);
    setLoading(true);
    apiClient.authClient
      .putUserResetToken(email, token, { password: data.password }, { withCredentials: true })
      .then(onActivationSuccess)
      .catch((e: ApiError) => {
        setLoginError(e);
        setLoading(false);
        setTokenExpired(true);
      });
  };

  // For Google SSO logging in will activate the user
  const onGoogleSSOSubmit = (loginRequest: GoogleLoginRequest): void => {
    setLoginError(undefined);
    setLoading(true);
    apiClient.authClient.login(loginRequest)
      .then(onActivationSuccess)
      .catch((e: ApiError) => {
        setLoginError(e);
        setLoading(false);
      });
  };

  const onResendLinkSuccess = () => {
    setNewLinkSent(true);
  };

  return (
    <LoginView
      email={email}
      loading={loading}
      loginError={loginError}
      newLinkSent={newLinkSent}
      onGoogleSSOSubmit={onGoogleSSOSubmit}
      onPasswordSubmit={onPasswordSubmit}
      onResendLinkSuccess={onResendLinkSuccess}
      tokenExpired={tokenExpired}
    />
  );
};

export default ActivateContainer;

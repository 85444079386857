import React from 'react';
import {
  Container,
  Stack,
  Typography,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Doc } from '@marageti/z4-sdk/lib/documents';
import { DocumentType } from '@marageti/z4-sdk/lib/documents/Document';
import ConfirmDelete from '../../../components/confirm-delete';
import DocumentList from './document-list';
import AddDocumentsDialog from '../../../components/zicasso-dialogs/add-documents-dialog';
import AddItineraryDialog from '../../../components/zicasso-dialogs/add-itinerary';

type DocsViewProps = {
  closeDialog: () => void;
  docs: Doc[];
  docError: ApiError | undefined;
  isLoading: boolean;
  itineraries: Doc[];
  docToDelete: Doc | null;
  handleDelete: (doc: Doc) => void;
  onCancelDelete: () => void;
  onClickDocument: (document: Doc) => void;
  onConfirmDelete: () => void;
  openDialog: string;
  refreshDocuments: () => void;
  setOpenDialog: (value: string) => void;
  submissionError: ApiError | undefined;
  tripMatchId: string;
  partnerId: string;
};

const DocsView = ({
  closeDialog,
  docs,
  docError,
  isLoading,
  itineraries,
  docToDelete,
  handleDelete,
  onCancelDelete,
  onClickDocument,
  onConfirmDelete,
  openDialog,
  refreshDocuments,
  setOpenDialog,
  submissionError,
  tripMatchId,
  partnerId,
}: DocsViewProps) => (
  <Container
    maxWidth="md"
    sx={{
      paddingTop: {
        desktop: 5,
        mobile: 4,
      },
      paddingBottom: {
        desktop: 5,
        mobile: 4.5,
      },
    }}
  >
    <Stack spacing={4}>
      {docError && (
      <Alert severity="error">
        <AlertTitle>There was an issue downloading the document</AlertTitle>
        {docError.message}
        {docError.code && `Code: ${docError.code} `}
        {docError.id && `ID: ${docError.id}`}
      </Alert>
      )}
      {/* General Documents */}
      <Stack
        direction={{ xs: 'column', md: 'row' }} // 'xs' denotes mobile and 'md' and above denotes desktop
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        sx={{ mb: 4 }}
      >
        <Typography variant="h2">
          Documents
        </Typography>
        <Button
          data-e2e="ztest-trip-match-docs-upload"
          variant="outlined"
          onClick={() => setOpenDialog('addDocuments')}
          sx={{
            minWidth: { xs: '100%', md: 'fit-content' },
            mt: { xs: 2, md: 0 }, // Add margin-top for mobile only
          }}
        >
          Upload Document
        </Button>
      </Stack>
      <DocumentList
        documents={docs}
        isLoading={isLoading}
        partnerId={partnerId}
        onClickDocument={onClickDocument}
        onClickDelete={handleDelete}
        emptyList={(
          <Alert severity="info">
            <AlertTitle>
              Upload invoices, travel references, and other links/files provided to the traveler
            </AlertTitle>
          </Alert>
      )}
      />

      {/* Itinerary Documents */}
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        sx={{ mb: 4 }}
      >
        <Typography variant="h2">
          Itineraries
        </Typography>
        <Button
          data-e2e="ztest-trip-match-docs-upload"
          variant="outlined"
          onClick={() => setOpenDialog('addItinerary')}
          sx={{
            minWidth: { xs: '100%', md: 'fit-content' },
            mt: { xs: 2, md: 0 },
          }}
        >
          Upload Itinerary
        </Button>
      </Stack>
      <DocumentList
        documents={itineraries}
        isLoading={isLoading}
        partnerId={partnerId}
        onClickDocument={onClickDocument}
        onClickDelete={handleDelete}
        emptyList={(
          <Alert severity="info">
            <AlertTitle>
              Upload the Itineraries and related links/files proposed to the traveler
            </AlertTitle>
          </Alert>
      )}
      />
    </Stack>
    {docToDelete && (
      <ConfirmDelete
        onCancel={onCancelDelete}
        onRemove={onConfirmDelete}
        open={!!docToDelete}
        submissionError={submissionError}
        title={`Remove ${docToDelete?.documentType === DocumentType.DocumentGeneral ? 'Document' : 'Itinerary'}?`}
        helperText={`The ${docToDelete?.documentType === DocumentType.DocumentGeneral ? 'document' : 'itinerary'} will be permanently deleted and the traveler will no longer have access.`}
      />
    )}
    <AddDocumentsDialog
      closeDialog={closeDialog}
      onDocumentUploaded={refreshDocuments}
      open={openDialog === 'addDocuments'}
      tripMatchId={tripMatchId}
    />
    <AddItineraryDialog
      closeDialog={closeDialog}
      onDocumentUploaded={refreshDocuments}
      open={openDialog === 'addItinerary'}
      tripMatchId={tripMatchId}
    />
  </Container>
);

export default DocsView;

/* eslint-disable import/prefer-default-export */
import { TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';

// We can edit trip details if:
// - the return date is in the future, the trip is not canceled, or
// - the return date is in the past, the trip is not sold and not canceled
const getCanEditTripDetails = (tripMatch: TripMatch | undefined) => {
  const now = new Date();
  const returnDate = tripMatch ? new Date(tripMatch.returnDate) : new Date();
  const isReturnDateFuture = returnDate.getTime() > now.getTime();
  const isReturnDatePast = returnDate.getTime() <= now.getTime();

  const canceledStatuses = [
    TripMatchStatus.MatchTravelerCanceled,
    TripMatchStatus.MatchZicassoCanceled,
    TripMatchStatus.MatchPartnerCanceled,
  ];

  // Partners can only edit the trip match after it is active
  const nonEditableStatusesFutureReturn = [
    TripMatchStatus.MatchPending,
    TripMatchStatus.MatchAccepted,
    ...canceledStatuses,
  ];

  const nonEditableStatusesPastReturn = [
    ...canceledStatuses,
  ];

  const canEditTripDetails = (
    tripMatch
      && (
        (isReturnDateFuture && !nonEditableStatusesFutureReturn.includes(tripMatch.status))
        || (isReturnDatePast && !nonEditableStatusesPastReturn.includes(tripMatch.status))
      )
  );

  return canEditTripDetails;
};

export { getCanEditTripDetails };

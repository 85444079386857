import React from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { LinkExternal, LinkNav } from '@marageti/z4-lib';
import { Link } from 'react-router-dom';
import { MainNavListProps, NavItem, UserMenuProps } from './types';
import config from '../../config';

const navItemsMainMobile: NavItem[] = [
  {
    label: 'zicasso.com',
    to: config.Z3_URL,
  },
  {
    label: 'trips',
    to: '/trip-matches',
  },
  {
    label: 'reviews',
    to: '/reviews',
  },
  {
    label: 'availability',
    to: 'availability',
  },
  {
    label: 'sales',
    to: '/sales',
  },
  {
    label: 'settings',
    to: 'settings',
  },
];

const navItemsMainDesktop: NavItem[] = [
  {
    label: 'trips',
    to: '/trip-matches',
  },
  {
    label: 'reviews',
    to: '/reviews',
  },
  {
    label: 'availability',
    to: 'availability',
  },
  {
    label: 'sales',
    to: '/sales',
  },
  {
    label: 'settings',
    to: 'settings',
  },
];

const navItemsUser: NavItem[] = [
  {
    e2e: 'user-menu-account',
    label: 'My Profile',
    to: '/my-profile',
  },
  {
    e2e: 'zicademy',
    label: 'Zicademy',
    to: 'https://zicademy.zicasso.com/_hcms/mem/login?redirect_url=/',
    external: true,
  },
  {
    e2e: 'user-menu-logout',
    label: 'Log Out',
    to: 'logout',
  },
];

const MainNavListDesktop = ({
  sx,
}: MainNavListProps) => (
  <Stack
    direction="row"
    spacing={8}
    component="ul"
    sx={{
      margin: 0,
      padding: 0,
      listStyle: 'none',
      ...sx,
    }}
  >
    {navItemsMainDesktop.map((item: NavItem) => (
      <Box
        key={`${item.to}${item.label}`}
        component="li"
        display="flex"
        alignItems="center"
      >
        <LinkNav
          activeStyle={{ opacity: 0.5 }}
          color="primary"
          sx={{
            '&:hover': { color: 'secondary.main' },
          }}
          to={item.to}
        >
          {item.label || item.to}
        </LinkNav>
      </Box>
    ))}
  </Stack>
);

const MainNavListMobile = () => (
  <Stack
    direction="column"
    spacing={8}
    component="ul"
    sx={{
      px: 0,
      py: 1,
      margin: 0,
      listStyle: 'none',
    }}
  >
    {navItemsMainMobile.map((item: NavItem) => (
      <Box
        key={`${item.to}${item.label}`}
        component="li"
      >
        <LinkNav
          activeStyle={{ opacity: 0.5 }}
          color="primary"
          end
          sx={{
            '&:hover': { color: { mobile: 'inherit', desktop: 'secondary.main' } },
          }}
          to={item.to}
        >
          {item.label || item.to}
        </LinkNav>
      </Box>
    ))}
  </Stack>
);

const UserNavListMobile = () => (
  <Stack
    direction="column"
    spacing={8}
    component="ul"
    sx={{
      paddingX: 0,
      paddingY: 8,
      margin: 0,
      listStyle: 'none',
    }}
  >
    {navItemsUser.map((item: NavItem) => (
      <Box
        key={`${item.to}${item.label}`}
        component="li"
        data-e2e={`ztest-${item.e2e}`}
      >
        { item.external ? (
          <LinkExternal
            ariaLabel="external-link"
            key={`${item.to}${item.label}`}
            color="primary"
            href={item.to}
            sx={{
              fontWeight: 500,
            }}
            variant="navigation"
          >
            {item.label || item.to}
          </LinkExternal>
        ) : (
          <LinkNav
            key={`${item.to}${item.label}`}
            activeStyle={{ opacity: 0.5 }}
            color="primary"
            end
            to={item.to}
            sx={{
              fontWeight: 500,
            }}
          >
            {item.label || item.to}
          </LinkNav>
        )}
      </Box>
    ))}
  </Stack>
);

const UserMenuDesktop = ({
  anchorEl,
  open,
  handleClose,
}: UserMenuProps) => (
  <Menu
    anchorEl={anchorEl}
    id="user-menu"
    open={open}
    onClick={handleClose}
    onClose={handleClose}
    PaperProps={{
      sx: {
        display: { mobile: 'none', desktop: 'block' },
      },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    {navItemsUser.map((item: NavItem) => (
      <MenuItem
        key={`${item.to}${item.label}`}
        component={item.external ? LinkExternal : Link}
        data-e2e={`ztest-${item.e2e}`}
        to={item.to}
        href={item.to}
      >
        {item.label || item.to}
      </MenuItem>
    ))}
  </Menu>
);

export {
  MainNavListDesktop, MainNavListMobile, UserNavListMobile, UserMenuDesktop,
};

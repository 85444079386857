import React, { useEffect, useRef, useState } from 'react';
import {
  ChipOption,
  InfiniteScroll,
  useApiClient,
  useFetch,
} from '@marageti/z4-lib';
import { TripMatch, Partner, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import {
  Container,
  Box,
  Skeleton,
  Typography,
  Stack,
} from '@mui/material';
import { useAuthStore } from '../../../store';
import SoldCard from '../../../components/trips-card/sold-card';
import SoldTripsFilters from './sold-filters';
import useTripMatchSearch from '../../../hooks/use-trip-match-search';
import useURLSearchQuery from '../../../hooks/use-url-for-search-query';

type Option = {
  title: string;
  value: string;
};

const sortByOptions: Option[] = [
  { title: 'New to Old', value: 'createdAt' },
  { title: 'Total Trip Price', value: 'totalTripPrice' },
  { title: 'Departure Date (Upcoming)', value: 'departureDateUpcoming' },
  { title: 'Departure Date (Past)', value: 'departureDatePast' },
  { title: 'Departure Date (All)', value: 'departureDateAll' },
];

const sortByValues: { [key: string]: { field: string; order: string } } = {
  createdAt: { field: 'createdAt', order: 'desc' },
  totalTripPrice: { field: 'totalTripPrice.amountUSD', order: 'desc' },
  departureDateUpcoming: { field: 'departureDate', order: 'asc' },
  departureDatePast: { field: 'departureDate', order: 'desc' },
  departureDateAll: { field: 'departureDate', order: 'asc' },
};

const chipOptions: ChipOption[] = [
  { key: 0, label: 'Pre Travel', value: 'preTravel' },
  { key: 1, label: 'In Country', value: 'inCountry' },
  { key: 2, label: 'Completed Trip', value: 'completedTrip' },
];

interface DefaultSearch extends Record<string, string | string[]> {
  fullText: string;
  countries: string[];
  assignedTo: string;
  sort: string;
  refineSold: string[];
}

const defaultSearch: DefaultSearch = {
  fullText: '',
  countries: [],
  assignedTo: 'all',
  sort: 'createdAt',
  refineSold: [],
};

const SoldTripsContainer = () => {
  const { loggedInAgent } = useAuthStore();
  const [queryValues, updateURLQuery] = useURLSearchQuery<DefaultSearch>(defaultSearch);
  const {
    fullText: searchTerm,
    countries: selectedCountries,
    assignedTo,
    sort: sortBy,
    refineSold: selectedChips,
  } = queryValues;

  /**
   * Values needed to insure the search input is refreshed when the search term changes via history navigation
   */
  const [refreshKey, setRefreshKey] = useState(0);
  const searchRef = useRef(searchTerm || '');
  useEffect(() => {
    if (searchTerm !== searchRef.current) {
      setRefreshKey((prev) => prev + 1);
    }
  }, [searchTerm, setRefreshKey]);

  const partner = useFetch<Partner>(
    useApiClient().partnersClient.getPartner,
    [loggedInAgent?.partnerId],
    !!loggedInAgent?.partnerId,
  );
  const pDestinations = partner?.data?.countries || [];
  const partnerCountryCodes = pDestinations.map((destination) => ({ country: destination.country }));

  const {
    tripMatches,
    error,
    hasMore: hasMoreItems,
    isLoading,
    loadMore,
    totalHits,
  } = useTripMatchSearch({
    fullText: searchTerm,
    countries: selectedCountries,
    refineSold: selectedChips,
    assignedTo,
    sort: sortByValues[sortBy as string].field,
    order: sortByValues[sortBy as string].order,
    status: [TripMatchStatus.MatchSold],
  });

  const onChangeFilter = (name: string, v: string | string[]) => {
    switch (name) {
      case 'assignedTo':
        updateURLQuery({ assignedTo: v as string });
        break;
      case 'sortBy':
        updateURLQuery({ sort: v as string });
        break;
      case 'countries':
        updateURLQuery({ countries: v as string[] });
        break;
      case 'fullText':
        updateURLQuery({ fullText: v as string });
        searchRef.current = v as string;
        break;
      case 'selectedChips':
        if (Array.isArray(v)) {
          // Only one chip can be selected at a time
          const filtered = v.filter((chip) => !selectedChips!.includes(chip));
          updateURLQuery({ refineSold: filtered });
        }
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Box sx={{
        mt: {
          desktop: 6,
          mobile: 3,
        },
      }}
      >
        <SoldTripsFilters
          searchTerm={searchTerm || ''}
          refreshKey={refreshKey}
          assignedTo={assignedTo || 'all'}
          chipOptions={chipOptions}
          countries={selectedCountries || []}
          countryOptions={partnerCountryCodes}
          onChange={onChangeFilter}
          selectedChips={selectedChips || []}
          sortBy={sortBy || 'createdAt'}
          sortByOptions={sortByOptions}
        />
        {isLoading ? (
          <Skeleton variant="rounded" sx={{ mb: 2, height: '24px', width: '100px' }} />
        ) : (
          <Typography variant="bodyBold" mb={2}>{`${totalHits} Results`}</Typography>
        )}
        <Stack spacing={1}>
          <InfiniteScroll
            error={error}
            hasMoreItems={hasMoreItems}
            isLoading={isLoading}
            loadMoreItems={loadMore}
          >
            {tripMatches?.flatMap((item: TripMatch) => (
              <SoldCard
                key={item.id}
                tripMatch={item}
              />
            ))}
          </InfiniteScroll>
        </Stack>
      </Box>
    </Container>
  );
};

export default SoldTripsContainer;

import React, { useMemo, useState } from 'react';
import {
  createNewUTCDateFromString,
  sortListOfObjectsByDateField,
  isDateInRange,
  useFetch,
  useApiClient,
} from '@marageti/z4-lib';
import { Partner, PartnerAvailability } from '@marageti/z4-sdk/lib/travel';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import { enqueueSnackbar } from 'notistack';
import { FilterOp, SearchRequest, SearchResult } from '@marageti/z4-sdk/lib/entity';
import AvailabilityView from './availability-view';
import { filterByDateMonthAndYear, getFirstDayOfCurrentMonth } from '../../utils/partners';
import { useAuthStore } from '../../store';

const AvailabilityContainer = () => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [firstDayOfSelectedMonth, setFirstDayOfSelectedMonth] = useState(getFirstDayOfCurrentMonth());

  const loggedInAgentIsAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN));

  const {
    data: partner,
    error: partnerError,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const formattedDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return `${year}-${String(month + 1).padStart(2, '0')}-01`;
  };

  const searchRequest: SearchRequest = {
    filterBy: [{
      field: 'endDate',
      op: FilterOp.FilterOpGTE,
      value: [firstDayOfSelectedMonth],
    },
    ],
    sortBy: [{ field: 'createdAt', order: 'asc' }],
    pagination: { limit: 10000, page: 1 },
  };

  const {
    data: partnerAvailabilities,
    error: availabilitiesError,
    isLoading: availabilitiesLoading,
    mutate: mutatePartnerAvailabilities,
  } = useFetch<SearchResult<PartnerAvailability>>(
    apiClient.partnersClient.postPartnerAvailabilitySearch,
    [searchRequest],
    partner?.id !== undefined,
  );

  const fetchHistoricAvailabilityData = () => {
    const firstDayOfMonth = new Date(Date.parse(firstDayOfSelectedMonth));
    const firstDayOfPreviousMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 1);
    setFirstDayOfSelectedMonth(formattedDate(firstDayOfPreviousMonth));
  };
  const [eventToDelete, setEventToDelete] = useState< undefined | string>(undefined);

  const partnerAvailabilityData = useMemo(() => {
    if (partnerAvailabilities && partnerAvailabilities.hits.length > 0) {
      return sortListOfObjectsByDateField(partnerAvailabilities.hits || [], 'startDate');
    }
    return undefined;
  }, [partnerAvailabilities]);

  // Used to show the current cards events for the selected month, includes events that start before the month and end after the month.
  const availabilityListByMonth = useMemo(() => partnerAvailabilityData && filterByDateMonthAndYear(partnerAvailabilityData, activeDate), [partnerAvailabilityData, activeDate]);

  /**
 * Checks if a lead limit is in effect for the current date (today).
 * If one or more lead limits are found to be overlapping, the function returns the lowest available leads per week value.
 * If no valid lead limit is found, the function returns null.
 *
 * Note: Availabilities with a `limitType` of "LIMIT_TRAVEL" are ignored.
 *
 * @param partnerAvailabilityData - The list of PartnerAvailability objects to search for a lead limit.
 * @returns The lowest leads per week value, or null if no valid lead limit is in effect.
 */
  const leadsPerWeekInRange = useMemo(() => {
    const today = new Date();
    const currentDate = createNewUTCDateFromString(today.toString());

    const availabilitiesInRange = partnerAvailabilityData?.filter((a) => {
      if (a.limitType === 'LIMIT_TRAVEL') {
        return false;
      }
      const startDate = createNewUTCDateFromString(a.startDate);
      const endDate = createNewUTCDateFromString(a.endDate);
      return isDateInRange(currentDate, startDate, endDate);
    }) || [];

    const availabilitiesWithLeads = availabilitiesInRange.filter((a) => a.leadsPerWeek != null);
    if (availabilitiesWithLeads.length === 0) return null;

    const lowestAvailability = availabilitiesWithLeads.reduce((lowest, a) => (lowest.leadsPerWeek <= a.leadsPerWeek ? lowest : a));
    return lowestAvailability.leadsPerWeek;
  }, [partnerAvailabilityData]);

  const error = partnerError || availabilitiesError;

  const handleActiveDateChange = (date: Date) => {
    setActiveDate(date);
  };

  const onConfirmDelete = async () => {
    try {
      await apiClient.partnersClient.deletePartnerAvailability(partner!.id, eventToDelete!);
      enqueueSnackbar('Event removed successfully.', { variant: 'success' });
    } catch {
      enqueueSnackbar('Event removal failed.', { variant: 'default' });
    } finally {
      setEventToDelete(undefined);
      mutatePartnerAvailabilities();
    }
  };

  const onRemoveClick = (id:string) => (setEventToDelete(id));
  const onCancelDelete = () => (setEventToDelete(undefined));

  return (
    <AvailabilityView
      activeDate={activeDate}
      capacity={partner?.capacity || 0}
      error={error}
      eventToDelete={eventToDelete}
      fetchHistoricAvailabilityData={fetchHistoricAvailabilityData}
      handleActiveDateChange={handleActiveDateChange}
      isAdmin={loggedInAgentIsAdmin!}
      leadLimit={leadsPerWeekInRange !== null ? leadsPerWeekInRange : partner?.capacity || 0}
      loading={availabilitiesLoading}
      onCancelDelete={onCancelDelete}
      onConfirmDelete={onConfirmDelete}
      onRemoveEvent={onRemoveClick}
      partnerAvailability={partnerAvailabilityData}
      partnerAvailabilityList={availabilityListByMonth}
    />
  );
};

export default AvailabilityContainer;

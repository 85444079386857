/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Template, TemplateType } from '@marageti/z4-sdk/lib/templates/Template';
import { useApiClient, useFetch } from '@marageti/z4-lib';

const simplifyString = (string: string) => string.toLowerCase().replace(/[^a-z0-9]/g, '');

type Props = {
  closeDialog: () => void,
  onSubmit: (text: string) => void,
  open: boolean,
};

const ChooseTemplateDialog = ({
  closeDialog,
  onSubmit,
  open,
}: Props) => {
  const apiClient = useApiClient();
  const { data: templates } = useFetch<Template[]>(apiClient.templatesClient.getTemplates, [], true);
  const [value, setValue] = useState(null as Template & { group: string } | null);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));

  const onChange = (e: React.SyntheticEvent<Element, Event>, selectedTemplate: Template & { group: string } | null) => {
    setValue(selectedTemplate);
  };

  const handleSubmit = () => {
    onSubmit(value?.body || '');
    setValue(null);
    closeDialog();
  };

  const sortedGroupedTemplates = useMemo(() => {
    if (!templates) return [];

    const templateGroups = [
      { type: TemplateType.PersonalTemplate, label: '--- Personal ---' },
      { type: TemplateType.PartnerTemplate, label: '--- Team Templates ---' },
    ];

    return templateGroups.flatMap(({ type, label }) => templates
      .filter((template) => template.templateType === type)
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((template) => ({
        ...template,
        group: label,
      })));
  }, [templates]);

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth={isMobile}>
      <DialogTitle>Choose Template</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={sortedGroupedTemplates}
          groupBy={(option) => option.group}
          getOptionLabel={(option) => option.title || ''}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Template"
              fullWidth
              sx={{ minWidth: { desktop: '300px' } }}
            />
          )}
          renderOption={({ key, ...props }, option, _, ownerState) => (
            <MenuItem key={key} {...props}>
              <Typography variant="body1">{ownerState.getOptionLabel(option)}</Typography>
            </MenuItem>
          )}
          filterOptions={(options, params) => {
            const searchText = simplifyString(params.inputValue);
            return options.filter((option) => simplifyString(option.title).includes(searchText));
          }}
        />
      </DialogContent>
      <DialogActions sx={{ pt: 3 }}>
        <Button variant="contained" onClick={handleSubmit}>Use</Button>
        <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseTemplateDialog;

import React,
{
  createContext,
  useEffect,
  useMemo,
} from 'react';
import { ProviderProps } from '../../types';
import { PusherCallback, ValueProps } from './types';
import usePusherReducer from './use-pusher-reducer';
import usePusherClient from '../../../hooks/use-pusher-client';
import {
  addPusherChannel,
  removePusherChannel,
  setPusherError,
  setPusherReady,
} from './actions';

export const PusherStore = createContext({} as ValueProps);

export const PusherProvider = ({ children }: ProviderProps) => {
  const [pusherState, pusherDispatch] = usePusherReducer();
  const pusherClient = usePusherClient();

  useEffect(() => {
    if (pusherClient) {
      pusherClient.bind('pusher:signin_success', () => {
        pusherDispatch(setPusherReady(true));
      });
      pusherClient.bind('pusher:error', (data: any) => {
        pusherDispatch(setPusherError(data));
      });
      pusherClient.signin();
    }

    return () => {
      pusherDispatch(setPusherReady(false));
    };
  }, [pusherClient]);

  const subscribeToChannel = (channelName: string): void => {
    if (pusherClient) {
      const sub = pusherClient
        .subscribe(channelName)
        .bind('pusher:subscription_error', (data: any) => {
          pusherDispatch(setPusherError(data));
        })
        .bind('pusher:subscription_succeeded', () => {
          pusherDispatch(addPusherChannel(channelName, sub));
        });
    }
  };

  const unsubscribeFromChannel = (channelName: string): void => {
    if (pusherClient) {
      pusherClient.unsubscribe(channelName);
      pusherDispatch(removePusherChannel(channelName));
    }
  };

  const bindToEvent = (channelName: string, eventName: string, callback: PusherCallback): void => {
    pusherState.subscribedChannels[channelName].bind(eventName, callback);
  };

  const unbindFromEvent = (channelName: string, eventName: string): void => {
    pusherState.subscribedChannels[channelName].unbind(eventName);
  };

  const value: ValueProps = useMemo(() => ({
    ...pusherState,
    pusherDispatch,
    subscribeToChannel: (channelName: string) => subscribeToChannel(channelName),
    unsubscribeFromChannel: (channelName: string) => unsubscribeFromChannel(channelName),
    bindToEvent: (channelName: string, eventName: string, callback: PusherCallback) => bindToEvent(channelName, eventName, callback),
    unbindFromEvent: (channelName: string, eventName: string) => unbindFromEvent(channelName, eventName),
  }), [pusherState, pusherDispatch]);

  return (
    <PusherStore.Provider value={value}>
      {children}
    </PusherStore.Provider>
  );
};

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EditUserView from './edit-user-view';

const EditUserContainer = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <EditUserView
      agentId={userId}
      onNavigateBack={handleNavigateBack}
    />
  );
};

export default EditUserContainer;

import React from 'react';
import {
  Box,
  Typography,
  Stack,
} from '@mui/material';
import {
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  Link,
} from '@marageti/z4-lib';
import ResetForm from '../../../components/auth/email-password-form';
import SendResetLinkForm from '../../../components/auth/email-reset-link-form';
import { ReactComponent as ZicassoSVG } from '../../../assets/svg/zicasso.svg';
import ZSpinner from '../../../components/z-lib/z-spinner';
import { ViewProps } from './types';
import '../reset.scss';

const SetPasswordView = ({
  email,
  loading,
  loginError,
  newLinkSent,
  onResendLinkSuccess,
  onSubmit,
  tokenExpired,
}: ViewProps): React.ReactElement => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack
      spacing={3}
      sx={{
        width: { mobile: '342px', desktop: '333px' },
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stack flexDirection="column" spacing="10px" alignItems="center">
        <ZicassoSVG aria-label="Zicasso" />
        {!tokenExpired && (
          <Typography color="text.secondary" sx={{ marginTop: '10px', textAlign: 'center' }}>
            Enter a new password for your account.
          </Typography>
        )}
        {(tokenExpired && !newLinkSent) && (
          <Typography color="error.dark" sx={{ marginTop: '10px', textAlign: 'center', maxWidth: '285px' }}>
            Your link has expired. Please enter your email and we will send you a new link.
          </Typography>
        )}
        {newLinkSent && (
          <Typography color="text.secondary" sx={{ marginTop: '10px', textAlign: 'center', maxWidth: '333px' }}>
            Thanks! We&apos;ll send you an email with instructions on how to reset your password.
          </Typography>
        )}
      </Stack>
      {loading && (
        <ZSpinner />
      )}
      {!tokenExpired && (
        <ResetForm
          confirmPassword
          defaultValues={{ username: email, password: '' }}
          emailReadOnly
          onSubmit={onSubmit}
          submitText="Reset Password"
        />
      )}
      {(tokenExpired && !newLinkSent) && (
        <SendResetLinkForm onSuccess={onResendLinkSuccess} type="reset" />
      )}
      <Link
        color="primary"
        sx={{ '&:hover': { color: 'secondary.main' } }}
        to="/login"
        underline="always"
        variant="subtitle1"
      >
        Go back to Sign In
      </Link>
      {loginError && (
        <ErrorBox>
          <ErrorTitle>
            There was an on our end.
          </ErrorTitle>
          {loginError.code ? (
            <ErrorMessage>
              {loginError.code && `Code: ${loginError.code} `}
              {loginError.message || 'Please contact support'}
              <br />
              {loginError.id && `ID: ${loginError.id}`}
            </ErrorMessage>
          ) : (
            <ErrorMessage>
              Please contact support.
            </ErrorMessage>
          )}
        </ErrorBox>
      )}
    </Stack>
  </Box>
);

export default SetPasswordView;

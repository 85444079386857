/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RegisterOptions, SubmitHandler } from 'react-hook-form';
import {
  useForm,
  FormElement,
  Inputs,
  useApiClient,
  useFetch,
  getSubmitButtonProps,
} from '@marageti/z4-lib';
import { LoadingButton } from '@mui/lab';
import { LoginRequest } from '@marageti/z4-sdk/lib/auth/Token';
import { Stack, TextField } from '@mui/material';
import { PasswordConfig } from '@marageti/z4-sdk/lib/auth';

type ActivateRequest = LoginRequest & { confirmPassword?: string };

export interface PasswordLoginViewProps {
  confirmPassword?: boolean;
  defaultValues?: ActivateRequest;
  disablePasswordValidation?: boolean;
  emailReadOnly?: boolean;
  mode?: 'onSubmit' | 'onChange' | 'onBlur' | 'all' | 'onTouched';
  onSubmit: SubmitHandler<ActivateRequest>;
  options?: RegisterOptions;
  submitText?: string;
}

const PasswordLogin = ({
  confirmPassword = false,
  defaultValues = undefined,
  disablePasswordValidation = false,
  emailReadOnly = false,
  mode = undefined,
  onSubmit,
  options = undefined,
  submitText = 'Sign In',
}: PasswordLoginViewProps) => {
  const apiClient = useApiClient();
  const { data: passwordConfig } = useFetch<PasswordConfig>(apiClient.authClient.getPasswordConfig, [], true);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    getValues,
  } = useForm<ActivateRequest>({ defaultValues, mode: mode || 'onChange' });

  const validatePassword = (value: string | undefined) => {
    if (passwordConfig && value && !disablePasswordValidation) {
      const reg = new RegExp(passwordConfig.filter);
      return reg.test(value) || passwordConfig.description;
    }
    return true;
  };

  return (
    <FormElement
      name="login-form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing="24px" alignItems="center">
        <Stack spacing="12px" width="100%" useFlexGap>
          {/* TODO: Bring back email validation */}
          {/* <Inputs.Email
            error={!!errors?.username}
            helperText={errors?.username ? 'Please enter a valid email address' : undefined}
            id="username"
            label="Email"
            readOnly={emailReadOnly}
            {...register('username', { required: true })}
          /> */}
          <TextField
            {...register('username', { required: true })}
            autoComplete="username"
            error={!!errors?.username}
            helperText={errors?.username ? 'Please enter a valid email address' : undefined}
            id="username"
            label="Email"
            InputProps={{
              readOnly: emailReadOnly,
            }}
          />
          <Inputs.Password
            autoComplete={confirmPassword ? 'new-password' : 'current-password'}
            error={!!errors?.password}
            helperText={errors?.password?.message as string}
            id="password"
            label="Password"
            {...register('password', {
              validate: validatePassword,
              required: { value: true, message: 'Please enter a password' },
              ...options,
            })}
          />
          {confirmPassword && (
            <Inputs.Password
              autoComplete="confirm-password"
              error={!!errors?.confirmPassword}
              helperText={errors?.confirmPassword?.message as string}
              id="confirmPassword"
              label="Confirm Password"
              {...register('confirmPassword', {
                required: { value: true, message: 'Please confirm your password' },
                validate: (value: string) => value === getValues('password') || 'Passwords do not match',
              })}
            />
          )}
        </Stack>
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ width: '100%' }}
          {...getSubmitButtonProps(isSubmitting, isValid)}
        >
          {submitText}
        </LoadingButton>
      </Stack>
    </FormElement>
  );
};

export default PasswordLogin;

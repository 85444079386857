import React from 'react';
import {
  Tabs,
  Tab,
} from '@mui/material';
import {
  Link, useMatches,
} from 'react-router-dom';
import { TabItem, SecondaryAppbarTabsProps } from './types';

const SecondaryAppbarTabs = ({
  tabs,
}: SecondaryAppbarTabsProps) => {
  const matches = useMatches();

  const activeTab = matches.find((match) => {
    const matchedTab = match.handle as { tab: string };
    return Boolean(matchedTab?.tab);
  }) as { handle: { tab: string } } | undefined;

  return (
    <Tabs
      value={activeTab?.handle.tab}
    >
      {tabs?.map((item: TabItem) => (
        <Tab
         // Using item.to instead of item.to + item.label
         // because the label might change, but the key should still refer to the same tab.
          key={item.to}
          data-e2e={item.e2e}
          value={`/${item.to}`}
          label={item.label?.toUpperCase() || item.to.toUpperCase()}
          disableRipple
          component={Link}
          to={item.to}
          replace={item.replace ?? true}
        />
      ))}
    </Tabs>
  );
};

export default SecondaryAppbarTabs;

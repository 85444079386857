import React from 'react';
import { Stack } from '@mui/material';
import { TripMatchStatus, ReferralType } from '@marageti/z4-sdk/lib/travel';
import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import {
  TripMatchChips,
  TravelerTagChips,
  OtherChips,
} from '@marageti/z4-lib';

const { getTripMatchStatusChip } = TripMatchChips;
const { getTravelerTagChip } = TravelerTagChips;
const { ChipPRL, ChipRepeat } = OtherChips;

const ChipList = ({
  chips = undefined,
}: {
  chips?: string[],
}) => {
  if (!chips || chips.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
    >
      {chips.map((chip: string) => {
        switch (chip) {
          case TripMatchStatus.MatchPending:
          case TripMatchStatus.MatchAccepted:
          case TripMatchStatus.MatchPartnerDeclined:
          case TripMatchStatus.MatchActive:
          case TripMatchStatus.MatchSold:
          case TripMatchStatus.MatchPostponed:
          case TripMatchStatus.MatchInactive:
          case TripMatchStatus.MatchTravelerCanceled:
          case TripMatchStatus.MatchPartnerCanceled:
          case TripMatchStatus.MatchZicassoCanceled:
          case TripMatchStatus.MatchZicassoMatchError:
          case TripMatchStatus.MatchSoldCanceled:
          case TripMatchStatus.MatchRefundedCanceled:
            return getTripMatchStatusChip(chip, 'small');
          case TravelerTags.VIP:
          case TravelerTags.BAD_APPLE:
          case TravelerTags.RED_FLAG:
          case TravelerTags.TRAVEL_AGENT:
          case TravelerTags.JUNK:
            return getTravelerTagChip(chip, 'small');
          case ReferralType.OneTimePartnerReferral:
          case ReferralType.LifetimePartnerReferral:
            return <ChipPRL key={chip} />;
          case 'REPEAT_TRAVELER':
            return <ChipRepeat key={chip} />;
          default:
            return null;
        }
      })}
    </Stack>
  );
};

export default ChipList;

import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSubNavigation from '../../../components/settings-sub-navigation';

const View = () => (
  <>
    <SettingsSubNavigation />
    <Outlet />
  </>
);

export default View;

import React from 'react';
import {
  Stack, Container, Box, Typography, Grid,
} from '@mui/material';
import {
  Link,
  RatingHistogram,
  ReviewCard,
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  mapReviewToReviewProps,
  ZSpinner,
} from '@marageti/z4-lib';
import { ViewProps } from './types';

const DetailView = ({
  histogramAggregation,
  loadingHistogram,
  errorHistogram,
  onHistogramSelect,
  sentryRef,
  hasNextPage,
  reviews,
  errorReviews,
  selectedRatingFilter,
  clearFilter,
}: ViewProps) => {
  const renderReviewFilter = () => {
    if (!selectedRatingFilter) {
      return (
        <Typography variant="bodyBold">All Reviews</Typography>
      );
    }
    return (
      <>
        <Typography variant="bodyBold">{`${selectedRatingFilter} Star Reviews`}</Typography>
        <Typography variant="caption" onClick={clearFilter} sx={{ cursor: 'pointer' }}>Show All Reviews</Typography>
      </>
    );
  };

  const renderHistogram = () => {
    if (loadingHistogram || !histogramAggregation) {
      return null;
    }

    if (errorHistogram) {
      return (
        <ErrorBox>
          <ErrorTitle>
            There was an error loading the histogram
          </ErrorTitle>
          <ErrorMessage>
            {`Code: ${errorHistogram.code}: ${errorHistogram.message}`}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    return (
      <Box sx={{
        width: '100%',
        maxWidth: {
          desktop: '100%',
          mobile: '400px',
        },
      }}
      >
        <RatingHistogram
          aggregation={histogramAggregation}
          onSelect={onHistogramSelect}
          sx={{
            maxWidth: '100%',
          }}
        />
      </Box>
    );
  };

  const renderReviews = () => {
    if (errorReviews) {
      return (
        <ErrorBox>
          <ErrorTitle>
            There was an error getting reviews
          </ErrorTitle>
          <ErrorMessage>
            {`Code: ${errorReviews.code}: ${errorReviews.message}`}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    if (reviews && reviews.length === 0) {
      return <Typography variant="body2" textAlign="center">There are no reviews to display</Typography>;
    }

    return (
      <Stack spacing={1.5}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
          sx={{
            justifyContent: {
              mobile: 'space-between',
              desktop: 'flex-start',
            },
          }}
        >
          {renderReviewFilter()}
        </Stack>
        {reviews && reviews.map((review) => (
          <Link key={review.id} to={`/reviews/${review.id}`} state={{ review }}>
            <ReviewCard
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...mapReviewToReviewProps(review, { internal: false })}
            />
          </Link>
        ))}
        <Box display="flex" ref={sentryRef}>
          {hasNextPage && <ZSpinner />}
        </Box>
      </Stack>
    );
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h2" sx={{ mt: { desktop: 6.75, mobile: 3 } }}>Reviews</Typography>
      <Box sx={{
        py: { desktop: 6.5, mobile: 4 },
      }}
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          spacing={5.5}
        >
          <Grid item mobile={12} desktop={3} display="flex" justifyContent="center" alignSelf="flex-start">{renderHistogram()}</Grid>
          <Grid item mobile={12} desktop={9}>{renderReviews()}</Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DetailView;

import React from 'react';
import { Link } from '@mui/material';
import { TripMatch, TripRequest } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import {
  CopyButton,
  DetailCard,
  DetailCardProps,
  formatCountryCodesToString,
  getBudgetPerPersonString,
  getFormattedDateShortMonthDDYYYY,
  getTotalBudgetString,
  useApiClient,
  useFetchImmutable,
} from '@marageti/z4-lib';
import { SalesStage } from '@marageti/z4-sdk/lib/travel/TripMatch';
import { ReactComponent as SalesStage0 } from '../../assets/svg/sales-00.svg';
import { ReactComponent as SalesStage1 } from '../../assets/svg/sales-01.svg';
import { ReactComponent as SalesStage2 } from '../../assets/svg/sales-02.svg';
import { ReactComponent as SalesStage3 } from '../../assets/svg/sales-03.svg';
import ChipList from '../chip-list/trip-match';
import useFetchItineraryPath from '../../hooks/use-fetch-itinerary-path';

type SalesStageIconMapping = {
  [key in SalesStage]: { icon: React.ComponentType; stageName: string };
};

interface SalesStageContentProps {
  salesStage: SalesStage | string;
}

const SalesStageContent: React.FC<SalesStageContentProps> = ({ salesStage }) => {
  const salesStageIcons: SalesStageIconMapping = {
    [SalesStage.NeedsFollowUp]: { icon: SalesStage1, stageName: 'Needs Follow Up' },
    [SalesStage.NeedsProposal]: { icon: SalesStage2, stageName: 'Needs Proposal' },
    [SalesStage.ProposalSent]: { icon: SalesStage3, stageName: 'Proposal Sent' },
  };

  const { icon: Icon, stageName } = salesStageIcons[salesStage as SalesStage] || { icon: SalesStage0, stageName: 'Undefined' };

  return (
    <span style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
      <Icon />
      <span>{stageName}</span>
    </span>
  );
};

type TripMatchCardProps = {
  chips?: string[] | undefined,
  title?: string,
  titleTypographyProps?: DetailCardProps['titleTypographyProps'];
  tripMatch: TripMatch | undefined,
  tripRequest: TripRequest | undefined,
  action?: DetailCardProps['action'];
};

const TripDetailCard = ({
  chips = undefined,
  title = undefined,
  titleTypographyProps = {},
  tripMatch,
  tripRequest,
  action = undefined,
}: TripMatchCardProps) => {
  const apiClient = useApiClient();
  const countryCodes = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const itineraryPath = useFetchItineraryPath(tripRequest?.basedOnItineraryId);

  if (!tripMatch) {
    return null;
  }

  const {
    countries,
    departureDate,
    totalNights,
    createdAt,
    budget,
    numberOfAdults,
    numberOfChildren,
    referenceId,
  } = tripMatch;

  let travelersString = `${numberOfAdults} Adults`;
  if (numberOfChildren > 0) {
    travelersString += `, ${numberOfChildren} Children`;
  }

  const basedOnItineraryContent = itineraryPath ? (
    <Link href={itineraryPath} target="_blank" rel="noopener noreferrer">Link to Itinerary</Link>
  ) : '-';

  if (!countryCodes.data) {
    return null;
  }

  return (
    <DetailCard
      chips={chips ? <ChipList chips={chips} /> : undefined}
      title={title}
      action={action}
      titleTypographyProps={titleTypographyProps}
      items={[
        { title: 'Description', content: tripRequest?.tripDescription },
        { title: 'Countries', content: countryCodes && formatCountryCodesToString(countries, countryCodes.data) },
        { title: 'When', content: `${getFormattedDateShortMonthDDYYYY(departureDate) || ''} (${totalNights} Nights)` },
        { title: 'Budget Per Person', content: `${getBudgetPerPersonString(budget, numberOfAdults, numberOfChildren)}` },
        { title: 'Total Budget', content: `${getTotalBudgetString(budget)}` },
        { title: 'Travelers', content: `${travelersString}` },
        { title: 'Sales Stage', content: <SalesStageContent salesStage={tripMatch.salesStage} /> },
        { title: 'Based on Itinerary', content: basedOnItineraryContent },
        { title: 'Matched', content: getFormattedDateShortMonthDDYYYY(createdAt) || '' },
        {
          title: 'Reference ID',
          content: referenceId && (
            <>
              {referenceId}
              <CopyButton textToCopy={referenceId} iconFontSize="small" size="small" />
            </>
          ),
        },
      ]}
    />
  );
};

export default TripDetailCard;

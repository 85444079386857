import React from 'react';
import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';

type CardLinkProps = {
  to?: string;
  primary: React.ReactNode;
  secondary: React.ReactNode;
  icon: React.ReactNode;
  showBadge?: boolean;
  badgeColor?: 'info';
};

const CardLink = ({
  to = undefined,
  primary,
  secondary,
  icon,
  showBadge = false,
  badgeColor = 'info',
}: CardLinkProps) => (
  <ListItem
    component={to ? Link : 'div'}
    to={to || undefined}
    disableGutters
    disablePadding
    sx={{
      textDecoration: 'none',
      color: 'inherit',
    }}
  >
    <ListItemAvatar>
      <Badge
        invisible={!showBadge}
        color={badgeColor}
        variant="dot"
        overlap="circular"
      >
        <div>
          <Avatar>
            {icon}
          </Avatar>
        </div>
      </Badge>
    </ListItemAvatar>

    <ListItemText
      primary={primary}
      primaryTypographyProps={{
        variant: 'bodyBold',
        sx: {
          textDecoration: 'none',
        },
      }}
      secondary={secondary}
    />
  </ListItem>
);

export default CardLink;

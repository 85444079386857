/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Typography, Skeleton, Tooltip } from '@mui/material';
import { ParticipantInfo, TripMatch, ConversationType } from '@marageti/z4-sdk/lib/travel';
import { PersonType } from '@marageti/z4-sdk/lib/people';
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../store';
import { getConversationTypeFromTripMatch } from '../../utils/helpers';

const ConversationParticipantNames = ({
  participants,
  isLoading,
  typographyProps,
  skeletonProps,
  enableTooltip = false,
  tripMatch,
}: {
  participants: ParticipantInfo[] | undefined;
  isLoading: boolean;
  typographyProps?: React.ComponentProps<typeof Typography>;
  skeletonProps?: React.ComponentProps<typeof Skeleton>;
  enableTooltip?: boolean;
  tripMatch?: TripMatch;
}) => {
  const { conversationId } = useParams();
  const { loggedInAgent } = useAuthStore();

  const names = useMemo(() => {
    if (!participants || !tripMatch) return '';

    const conversationType = getConversationTypeFromTripMatch(tripMatch, conversationId);
    if (!conversationType) return '';

    const isLoggedInUserParticipant = participants.some((p) => p.id === loggedInAgent?.id);
    const formatName = (p: ParticipantInfo) => `${p.givenName} ${p.familyName}`;

    let namesList = [];

    if (conversationType === ConversationType.TravelerToPartner) {
      namesList = participants
        .filter((p) => p.id !== loggedInAgent?.id)
        .map(formatName);
    } else if (conversationType === ConversationType.ZicassoToPartner) {
      namesList = ['Zicasso', ...participants
        .filter((p) => p.personType !== PersonType.TeamMember && p.id !== loggedInAgent?.id)
        .map(formatName)];
    } else {
      return 'Unknown participants';
    }

    if (isLoggedInUserParticipant) {
      namesList.push('You');
    }

    return namesList.join(', ');
  }, [participants, tripMatch, conversationId, loggedInAgent]);

  if (isLoading) {
    return (
      <Typography {...typographyProps}>
        <Skeleton width="60%" {...skeletonProps} />
      </Typography>
    );
  }

  if (!participants || participants.length === 0) {
    return null;
  }

  return (
    <Tooltip title={names} disableHoverListener={!enableTooltip}>
      <Typography variant="caption" {...typographyProps}>
        {names}
      </Typography>
    </Tooltip>
  );
};

export default ConversationParticipantNames;

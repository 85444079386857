/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Typography,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldValues, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { TemplateRequest, TemplateType } from '@marageti/z4-sdk/lib/templates/Template';
import {
  getFieldProps,
  getSubmitButtonProps,
  useApiClient,
  BackButton,
  useForm,
  extractParams,
  RichTextEditor,
} from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { useAuthStore } from '../../../../store';

const schema = z.object({
  body: z.string().nonempty('Please enter a body'),
  title: z.string().nonempty('Please enter a title'),
  type: z.enum([TemplateType.PartnerTemplate, TemplateType.PersonalTemplate]),
});

const AddTemplate = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { type } = extractParams(search);
  const { loggedInAgent } = useAuthStore();

  const [submissionError, setSubmissionError] = useState<ApiError>();

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const navigateBack = () => navigate('/settings/templates');

  const onSubmit: SubmitHandler<FieldValues> = (t) => {
    const request: TemplateRequest = {
      ownerId: type === TemplateType.PersonalTemplate ? loggedInAgent?.id! : loggedInAgent?.partnerId!,
      templateType: t.type,
      body: t.body,
      title: t.title,
    };
    apiClient.templatesClient.postTemplate(request)
      .then(() => {
        navigateBack();
      })
      .catch(setSubmissionError);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        py: { mobile: 3, desktop: 6 },
        mb: { desktop: 20 },
        height: { mobile: 'calc(100vh - 77px)', desktop: '750px' },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BackButton onClick={navigateBack} edge="start" sx={{ mb: 2, width: 'fit-content' }} />
      <Typography variant="h2" sx={{ p: 0, mb: 3 }}>Add New Template</Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={3}
        sx={{
          height: { mobile: 'calc(100vh - 237px)', desktop: '100%' },
          width: '100%',
          flex: 1,
        }}
      >
        <TextField
          defaultValue={type}
          label="Type"
          select
          {...register('type')}
          {...getFieldProps('type', errors)}
        >
          <MenuItem key={TemplateType.PersonalTemplate} value={TemplateType.PersonalTemplate}>Personal Template</MenuItem>
          <MenuItem key={TemplateType.PartnerTemplate} value={TemplateType.PartnerTemplate}>Team Template</MenuItem>
        </TextField>
        <TextField
          label="Title"
          {...register('title')}
          {...getFieldProps('title', errors)}
        />
        <Controller
          control={control}
          name="body"
          rules={{ maxLength: 6000 }}
          render={({ field }) => (
            <RichTextEditor
              editorName="body"
              error={!!errors.body}
              helperText={errors.body?.message}
              label="Body"
              onChange={field.onChange}
              useLinks
              useLists
              sx={{
                minHeight: { desktop: '500px' },
                maxHeight: '500px',
                flex: 1,
              }}
            />
          )}
        />
        <Container
          sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: {
              mobile: 'column-reverse',
              desktop: 'row',
            },
            px: '0 !important',
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              width: { desktop: 'fit-content' },
            }}
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Add Template
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={navigateBack}
            sx={{
              width: { desktop: 'fit-content' },
            }}
          >
            Cancel
          </Button>
        </Container>
        {submissionError && (
        <Alert severity="error">
          <AlertTitle>
            There was an error creating your template
          </AlertTitle>
          {submissionError.message}
          {submissionError.code && `Code: ${submissionError.code} `}
          {submissionError.id && `ID: ${submissionError.id}`}
        </Alert>
        )}
      </Stack>
    </Container>
  );
};

export default AddTemplate;

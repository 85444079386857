/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Box,
  Stack,
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Card,
  CardContent,
  Avatar,
  MenuItem,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Controller,
} from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
  MultiCountrySelector,
  OutlinedIcons,
  AvatarCard,
  Link,
} from '@marageti/z4-lib';
import { PartnerCountry } from '@marageti/z4-sdk/lib/travel';
import { Traveler } from '@marageti/z4-sdk/lib/people';
import { CurrencyAmount } from '@marageti/z4-sdk/lib/standards';
import { addYears } from 'date-fns';
import CurrencyInput from '../../currency-input';
import config from '../../../config';

const tooltipProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -12],
      },
    },
  ],
  sx: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: {
        xs: '275px',
        md: 'none',
      },
    },
  },
};

type FormDefaultValues = {
  countries: string[] | undefined,
  departureDate: Date | undefined,
  totalNights: number | undefined,
  numberOfAdults: number | undefined,
  numberOfChildren: number | undefined,
  price: Partial<CurrencyAmount> | undefined,
  commission: Partial<CurrencyAmount> | undefined,
  priceComments: string | undefined,
  document?: any[] | undefined,
};

const DEFAULT_VALUES = {
  countries: [],
  departureDate: undefined,
  totalNights: undefined,
  numberOfAdults: undefined,
  numberOfChildren: undefined,
  price: {
    amount: 0,
    currencyCode: 'USD',
  },
  commission: {
    amount: 0,
    currencyCode: 'USD',
  },
  priceComments: '',
} as FormDefaultValues;

const schema = z.object({
  countries: z.array(z.string()).min(1, { message: 'Required' }),
  departureDate: z.date(),
  totalNights: z.coerce.number().min(1, 'Enter the total number of nights'),
  numberOfAdults: z.coerce.number().min(1, 'Enter the number of adults'),
  numberOfChildren: z.coerce.number().min(0, 'Enter the number of children'),
  price: z.object({
    amount: z.number(),
    currencyCode: z.string(),
  }),
  commission: z.object({
    amount: z.number(),
    currencyCode: z.string(),
  }),
  priceComments: z.string().min(1, { message: 'Required' }),
  document: z
    .any()
    // Optional but if provided, must be a file with size <= 50 MB
    .refine((files) => files === undefined || files.length === 0 || files[0]?.size <= 50 * 1024 * 1024, 'Max file size is 50 MB')
    // Only PDF, TXT, DOC, DOCX
    .refine(
      (files) => files === undefined || files.length === 0 || [
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(files?.[0]?.type),
      'Only .pdf, .txt, .doc, .docx files are allowed',
    ),
});

type FormProps = {
  primaryTraveler: Traveler | undefined,
  countries: PartnerCountry[] | undefined,
  defaultValues: {
    countries: string[] | undefined,
    departureDate: Date | undefined,
    totalNights: number | undefined,
    numberOfAdults: number | undefined,
    numberOfChildren: number | undefined,
    price: Partial<CurrencyAmount> | undefined,
    commission: Partial<CurrencyAmount> | undefined,
    priceComments: string | undefined,
    document?: any[] | undefined,
  },
  totalsOfSalesRecordings: {
    price: {
      amount: number
    },
    commission: {
      amount: number
    }
  },
  onSubmit: (data: any) => void,
  submissionError: boolean
  onCancel?: () => void
};

const Form = ({
  primaryTraveler,
  countries,
  defaultValues,
  totalsOfSalesRecordings,
  onSubmit,
  submissionError,
  onCancel = undefined,
}: FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    control,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const currency = defaultValues.price?.currencyCode;
  const maxDate = addYears(new Date(), 10);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const selectedDocument = watch('document');
  const hasDocument = selectedDocument && selectedDocument.length > 0;

  useEffect(() => {
    trigger('document');
  }, [selectedDocument]);

  const handleRemoveDocument = () => {
    setValue('document', undefined);
  };

  return (
    <form {...getFormProps(handleSubmit(onSubmit))}>
      <Stack spacing={2} useFlexGap>
        <div>
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Confirm Trip Details
          </Typography>
          <Typography>
            Please review and confirm the sold trip details. These details will be shown to the traveler.
          </Typography>
        </div>

        {primaryTraveler && (
          <Card variant="dense">
            <CardContent>
              <List disablePadding>
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <AvatarCard size={32} src={primaryTraveler.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={primaryTraveler.fullName}
                    primaryTypographyProps={{
                      variant: 'bodyBold',
                    }}
                    secondary="Primary Traveler"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        )}

        <Grid
          container
          columnSpacing={2}
        >
          <Grid item mobile={12} desktop={12}>
            <Controller
              name="countries"
              control={control}
              render={({ field }) => (
                <MultiCountrySelector
                  {...field}
                  {...getFieldProps('countries', errors)}
                  label="Countries"
                  countries={countries}
                  required
                  onChange={(_: any, data: String[]) => {
                    field.onChange(data);
                    trigger('countries');
                  }}
                />
              )}
            />
          </Grid>

          <Grid item mobile={12} desktop={3}>
            <Controller
              name="departureDate"
              control={control}
              render={({
                field: {
                  onChange, onBlur, value, ref, name,
                },
              }) => (
                <DatePicker
                  label="Departure Date"
                  onChange={onChange}
                  value={value}
                  maxDate={maxDate}
                  slotProps={{
                    textField: {
                      name,
                      onBlur,
                      error: !!errors.departureDate,
                      fullWidth: true,
                      required: true,
                    },
                  }}
                  inputRef={ref}
                />
              )}
            />
          </Grid>

          <Grid item mobile={12} desktop={3}>
            <TextField
              type="number"
              fullWidth
              label="Nights"
              required
              {...register('totalNights')}
              {...getFieldProps('totalNights', errors)}
            />
          </Grid>

          <Grid item mobile={12} desktop={3}>
            <TextField
              type="number"
              fullWidth
              label="Adults"
              required
              {...register('numberOfAdults')}
              {...getFieldProps('numberOfAdults', errors)}
            />
          </Grid>

          <Grid item mobile={12} desktop={3}>
            <TextField
              type="number"
              fullWidth
              label="Children"
              {...register('numberOfChildren')}
              {...getFieldProps('numberOfChildren', errors)}
            />
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 2,
          }}
        />

        {/* Changes to Trip Price */}
        <div>

          <Stack spacing={1} mb={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="h3"
              >
                Change to Trip Price
              </Typography>
              {currency !== 'USD' && (
                <Tooltip
                  title="Please note that the traveler will see an approximate conversion to USD."
                  placement="top-start"
                  PopperProps={tooltipProps}
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              )}
            </Stack>
            <Typography>Please note that the total trip price entered will be shown to the traveler.</Typography>
          </Stack>

          <Grid container columnSpacing={2}>
            <Grid item mobile={4} desktop={2}>
              <TextField
                fullWidth
                label="Currency"
                InputProps={{
                  defaultValue: defaultValues?.price?.currencyCode,
                  readOnly: true,
                }}
                select
                {...register('price.currencyCode')}
              >
                <MenuItem key="USD" value="USD">USD</MenuItem>
                <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                <MenuItem key="GBP" value="GBP">GBP</MenuItem>
              </TextField>
            </Grid>

            <Grid item mobile={8} desktop={10}>
              <Controller
                control={control}
                name="price.amount"
                render={({ field }) => (
                  <CurrencyInput
                    label="Amount"
                    fullWidth
                    required
                    {...field}
                    {...getFieldProps('price.amount', errors)}
                    defaultValue={defaultValues?.price?.amount}
                    numericFormatProps={{
                      currencyCode: currency,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item mobile={12} desktop={12}>
              <Typography
                component="div"
                variant="caption"
                sx={{
                  my: 1,
                }}
              >
                Make sure you are only entering the&nbsp;
                <Typography variant="captionItalic">
                  difference&nbsp;
                </Typography>
                from the total sale of this trip, which is
                {' '}
                {currencyFormatter.format(totalsOfSalesRecordings.price.amount)}
                .
              </Typography>
              <Typography variant="caption">
                This is an adjustment to your previously recorded price. To indicate a subtraction from your original price,
                simply add a “-” before the amount. For example “-500”.
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Divider
          sx={{
            my: 2,
          }}
        />

        {/* Changes to Commission to Zicasso */}
        <div>
          <Stack spacing={1} mb={2}>
            <Typography
              variant="h3"
            >
              Changes to Commission to Zicasso
            </Typography>
            <Typography>
              These details will&nbsp;
              <Typography component="span" variant="bodyBold">not be shown</Typography>
              &nbsp;to the traveler.
            </Typography>
          </Stack>
          <Grid container columnSpacing={2}>
            <Grid item mobile={4} desktop={2}>
              <TextField
                fullWidth
                label="Currency"
                select
                InputProps={{
                  defaultValue: defaultValues?.commission?.currencyCode,
                  readOnly: true,
                }}
                {...register('commission.currencyCode')}
              >
                <MenuItem key="USD" value="USD">USD</MenuItem>
                <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                <MenuItem key="GBP" value="GBP">GBP</MenuItem>
              </TextField>
            </Grid>

            <Grid item mobile={8} desktop={10}>
              <Controller
                control={control}
                name="commission.amount"
                render={({ field }) => (
                  <CurrencyInput
                    label="Amount"
                    fullWidth
                    required
                    {...field}
                    {...getFieldProps('commission.amount', errors)}
                    defaultValue={defaultValues?.commission?.amount}
                    numericFormatProps={{
                      currencyCode: currency,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={12} desktop={12}>
              <Typography
                component="div"
                variant="caption"
                sx={{
                  my: 1,
                }}
              >
                Make sure you are only entering the&nbsp;
                <Typography variant="captionItalic">
                  difference&nbsp;
                </Typography>
                to your previously entered commission, which is
                {' '}
                {currencyFormatter.format(totalsOfSalesRecordings.commission.amount)}
                .
              </Typography>
              <Typography variant="caption">
                This is an adjustment to your previously recorded commission to Zicasso. To indicate a subtraction from your original commission,
                simply add a “-” before the amount. For example “-500”.
              </Typography>
            </Grid>
          </Grid>

          <Stack spacing={2} useFlexGap>
            <TextField
              label="Comments"
              multiline
              minRows={6}
              required
              {...register('priceComments')}
              {...getFieldProps('priceComments', errors)}
            />
            <Typography variant="caption">
              Please describe how you calculated commission. Please recall that 8% of the total trip cost to the traveler is commissionable at 8%. The only exception is non-bundled airfare.
            </Typography>
            <Typography variant="caption">
              Please see&nbsp;
              <Link sx={{ textDecoration: 'underline' }} to={`${config.Z3_URL}tc-terms-agreement`}>Travel Company Agreement</Link>
              &nbsp;(section 5) for specific details.
            </Typography>
          </Stack>
        </div>

        <Divider
          sx={{
            my: 2,
          }}
        />

        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Confirmed Itinerary / Quote (For
            {' '}
            Zicasso&apos;s Records)
          </Typography>
          <Typography>
            Please submit an itinerary as proof of the services provided in connection with this sales recording.
            Files must not exceed 50 MB and can be the following file formats: txt, pdf, doc, and docx.
            {' '}
            <Typography component="span" variant="bodyBold">
              This file is NOT shared with the traveler.
            </Typography>
          </Typography>

          <Button
            component="label"
            variant="outlined"
            size="small"
            sx={{
              my: 2,
              display: hasDocument ? 'none' : 'inline-flex',
            }}
          >
            Browse...
            <input
              type="file"
              accept=".pdf,.txt,.doc,.docx"
              hidden
              {...register('document')}
            />
          </Button>

          {hasDocument && (
            <>
              <List
                disablePadding
              >
                <ListItem
                  disableGutters
                  secondaryAction={(
                    <IconButton
                      edge="end"
                      aria-label="remove document"
                      onClick={handleRemoveDocument}
                    >
                      <OutlinedIcons.OutlinedTrashIcon color="primary" />
                    </IconButton>
                  )}
                >
                  <ListItemAvatar>
                    <Avatar variant="rounded">
                      <OutlinedIcons.OutlinedDocumentIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={selectedDocument[0].name}
                  />
                </ListItem>
              </List>
              {errors.document && (
                <Typography variant="caption" color="error">{errors.document.message}</Typography>
              )}
            </>
          )}
        </Box>

        {submissionError && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting the form.</AlertTitle>
            Please try again.
          </Alert>
        )}

        <Stack
          direction="row"
          spacing={3}
          sx={(theme) => ({
            [theme.breakpoints.down('desktop')]: {
              marginTop: 'auto',
              flexDirection: 'column-reverse',
              '& > :not(:first-of-type)': {
                margin: theme.spacing(0, 0, 2.5, 0),
              },
            },
          })}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Save
          </LoadingButton>

          {onCancel && (
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;

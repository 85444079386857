/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Agent } from '@marageti/z4-sdk/lib/people';
import {
  Alert,
  AlertTitle,
  Box, Container, Skeleton, Stack, Typography, useMediaQuery, useTheme,
  FormControlLabel, Checkbox,
} from '@mui/material';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import UserLinkCard from '../../../components/user-link-card';
import { compareNames } from '../../../utils/helpers';

type Props = {
  error?: ApiError;
  agents: Agent[];
  showDisabled: boolean;
  toggleShowDisabled: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
};

const UsersView = ({
  agents, error = undefined, showDisabled, toggleShowDisabled, loading,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Container maxWidth="md">
      <Box sx={{ py: { desktop: 6, mobile: 3 } }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
          >
            {!loading ? 'Users' : <Skeleton width={75} />}
          </Typography>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showDisabled}
                onChange={toggleShowDisabled}
              />
            )}
            label="Show Disabled"
          />
        </Box>
        <Stack spacing={1} sx={{ mb: { mobile: 4, desktop: 3 }, minHeight: '933px' }}>
          {loading && <Skeleton width="100%" variant="rounded" height={300} />}
          {(!loading && agents) && (
            agents?.sort(compareNames).map((agent: Agent) => (
              <UserLinkCard
                key={agent.id}
                individual={agent}
                to={`/users/${agent.id}`}
              />
            ))
          )}
          {(!loading && error) && (
          <Alert severity="error">
            <AlertTitle>There was an error getting users.</AlertTitle>
            {`Code: ${error.code}: ${error.message}`}
            <br />
            {error.id && `ID: ${error.id}`}
          </Alert>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default UsersView;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  SvgIcon,
  Tooltip,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  getFormattedDateShortMonthDDYYYY,
  OutlinedIcons,
  CustomIcons,
  getTotalBudgetString,
} from '@marageti/z4-lib';
import { TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { titleCase } from '../../../utils/helpers';
import { formatCountryCodesToString, getFirstNameAndLastInitial } from '../utils';
import { TripMatchTravelerChips, TripMatchStatusChip } from '../chips';
import { PhoneIndicator, ChatIndicator, SalesStageIndicator } from '../trip-indicators';

const {
  OutlinedUserGroupIcon,
  OutlinedLocationMarkerIcon,
  OutlinedCurrencyDollarIcon,
  OutlinedUserIcon,
  OutlinedInformationCircleIcon,
} = OutlinedIcons;

const {
  DepartureIcon,
} = CustomIcons;

const IconLabel = ({ icon, label }: { icon: any, label: any }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <SvgIcon color="disabled" component={icon} />
    <Typography variant="caption">
      {label}
    </Typography>
  </Stack>
);

type AllCardViewProps = {
  countryCodes: CountryCodeMap | undefined;
  tripMatch: TripMatch;
};

const showToolTip = (tripMatch: TripMatch) => [
  TripMatchStatus.MatchSold,
  TripMatchStatus.MatchSoldCanceled,
  TripMatchStatus.MatchRefundedCanceled,
].includes(tripMatch.status);

const AllCardView = ({
  countryCodes,
  tripMatch,
}: AllCardViewProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const fullName = titleCase(tripMatch?.travelers[0].fullName);
  const countryNames = countryCodes ? formatCountryCodesToString(tripMatch.countries, countryCodes) : '';
  const departureDate = getFormattedDateShortMonthDDYYYY(tripMatch.departureDate) || '';
  const totalNights = `${tripMatch.totalNights} Nights`;
  const numberOfTravelers = tripMatch.numberOfAdults + tripMatch.numberOfChildren;
  const totalTravelers = numberOfTravelers === 1
    ? '1 Traveler'
    : `${numberOfTravelers} Travelers`;
  const assignedToFirstNameLastInitial = getFirstNameAndLastInitial(tripMatch.assignedToName);
  const matchedDate = getFormattedDateShortMonthDDYYYY(tripMatch.createdAt) || '';

  return (
    <Card
      component={Link}
      sx={{
        '&:hover': {
          bgcolor: 'grey.A100',
        },
        textDecoration: 'none',
      }}
      to={`/trip-matches/${tripMatch?.id}`}
    >
      <CardContent>
        {isMobile ? (
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
              <Typography variant="bodyBold">
                {fullName}
              </Typography>
              <TripMatchTravelerChips tripMatch={tripMatch} />
            </Stack>

            <Stack direction="column" spacing={1}>
              <IconLabel icon={OutlinedUserGroupIcon} label={totalTravelers} />
              <IconLabel icon={OutlinedLocationMarkerIcon} label={countryNames} />
              <IconLabel
                icon={DepartureIcon}
                label={(
                  <>
                    {departureDate}
                    {' '}
                    ·
                    {' '}
                    {totalNights}
                  </>
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconLabel
                  icon={OutlinedCurrencyDollarIcon}
                  label={(
                    <>
                      {getTotalBudgetString(tripMatch.budget)}
                      {' '}
                      Total Budget
                    </>
                )}
                />

                {showToolTip(tripMatch) && (
                  <Tooltip
                    title={`Total Trip Price: ${getTotalBudgetString(tripMatch.totalTripPrice.amount, 2, 2, tripMatch.totalTripPrice.currencyCode)}`}
                    placement="top-start"
                  >
                    <OutlinedInformationCircleIcon
                      color="action"
                      sx={{ fontSize: 16, ml: 0.5 }}
                    />
                  </Tooltip>
                )}
              </Box>
              <IconLabel icon={OutlinedUserIcon} label={assignedToFirstNameLastInitial} />
            </Stack>

            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={6} display="flex">
                <TripMatchStatusChip tripMatch={tripMatch} />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={1.5}>
                  <PhoneIndicator tripMatch={tripMatch} />
                  <ChatIndicator tripMatch={tripMatch} />
                  <SalesStageIndicator tripMatch={tripMatch} />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Grid container direction="row" alignItems="center" columnSpacing={1}>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography variant="bodyBold">
                  {fullName}
                </Typography>
                <TripMatchTravelerChips tripMatch={tripMatch} />
              </Stack>
              <Typography variant="caption">
                {countryNames}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body">
                {departureDate}
              </Typography>
              <Typography variant="caption">
                {totalNights}
                {' '}
                ·
                {' '}
                {totalTravelers}
              </Typography>
            </Grid>

            <Grid item xs={1.5}>
              <Typography variant="body">
                {getTotalBudgetString(tripMatch.budget)}
              </Typography>
              <Box sx={{
                display: 'flex',
              }}
              >
                <Typography variant="caption">
                  Total Budget
                </Typography>
                {showToolTip(tripMatch) && (
                  <Tooltip
                    title={`Total Trip Price: ${getTotalBudgetString(tripMatch.totalTripPrice.amount, 2, 2, tripMatch.totalTripPrice.currencyCode)}`}
                    placement="top-start"
                    color="primary"
                  >
                    <span>
                      <OutlinedInformationCircleIcon
                        color="action"
                        sx={{
                          ml: 0.5,
                          fontSize: 16,
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body">
                {assignedToFirstNameLastInitial}
              </Typography>
              <Typography variant="caption">
                Matched:
                {' '}
                {matchedDate}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Stack direction="row" spacing={3}>
                <PhoneIndicator tripMatch={tripMatch} />
                <ChatIndicator tripMatch={tripMatch} />
                <SalesStageIndicator tripMatch={tripMatch} />
              </Stack>

            </Grid>
            <Grid item xs={1.5} display="flex" justifyContent="flex-end">
              <TripMatchStatusChip tripMatch={tripMatch} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AllCardView;

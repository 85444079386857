import React from 'react';
import { Typography } from '@mui/material';
import { ViewProps } from './types';
import SubNavigation from '../sub-navigation';

const SettingsSecondaryAppbarView = ({
  tabs,
}: ViewProps) => (
  <SubNavigation
    startAdornment={(
      <Typography variant="h3" color="primary">
        Settings
      </Typography>
    )}
    tabs={tabs}
    endAdornment={undefined}
  />
);

export default SettingsSecondaryAppbarView;

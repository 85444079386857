/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Controller, FieldValues, SubmitHandler,
} from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertTitle,
  TextField,
  Box,
  Button,
  Stack,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
} from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { PartnerCountry, TripMatch } from '@marageti/z4-sdk/lib/travel';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import {
  MultiCountrySelector,
  useForm,
  getFieldProps,
  getFormProps,
  getSubmitButtonProps,
} from '@marageti/z4-lib';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addYears } from 'date-fns';
import { SalesStage } from '@marageti/z4-sdk/lib/travel/TripMatch';
import {
  TripEditSchema,
} from './trip-match-edit-form-util';
import { ReactComponent as SalesStage0 } from '../../../assets/svg/sales-00.svg';
import { ReactComponent as SalesStage1 } from '../../../assets/svg/sales-01.svg';
import { ReactComponent as SalesStage2 } from '../../../assets/svg/sales-02.svg';
import { ReactComponent as SalesStage3 } from '../../../assets/svg/sales-03.svg';
import CurrencyInput from '../../../components/currency-input';

export interface CountryObject {
  country: string
}

type TripEditProps = {
  countries: PartnerCountry[];
  onCancel: () => void;
  onSubmit: SubmitHandler<FieldValues>;
  submissionError?: ApiError;
  tripMatch?: TripMatch;
};

const TripMatchEditForm = ({
  countries,
  onCancel,
  onSubmit,
  submissionError = undefined,
  tripMatch = undefined,
}: TripEditProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
    register,
    trigger,
  } = useForm({
    defaultValues: {
      ...tripMatch,
      departureDate: tripMatch?.departureDate ? new Date(tripMatch.departureDate) : undefined,
      budget: tripMatch?.budget ? tripMatch.budget : undefined,
      salesStage: tripMatch?.salesStage || 'Undefined',
    },
    resolver: zodResolver(TripEditSchema),
  });

  useEffect(() => {
    trigger();
  }, []);

  const maxDate = addYears(new Date(), 10);

  const salesStages = {
    Undefined: { icon: SalesStage0, stageName: 'Undefined' },
    [SalesStage.NeedsFollowUp]: { icon: SalesStage1, stageName: 'Needs Follow Up' },
    [SalesStage.NeedsProposal]: { icon: SalesStage2, stageName: 'Needs Proposal' },
    [SalesStage.ProposalSent]: { icon: SalesStage3, stageName: 'Proposal Sent' },
  };

  return (
    <form name="trip-match-form" {...getFormProps(handleSubmit(onSubmit))}>
      <Stack spacing={3}>
        <Controller
          name="countries"
          control={control}
          render={({ field }) => (
            <MultiCountrySelector
              {...field}
              countries={countries}
              label="Countries"
              onChange={(_: any, data: String[]) => field.onChange(data)}
              {...getFieldProps('countries', errors)}
            />
          )}
        />
        <Box>
          <Stack direction="row" spacing={2}>
            <Controller
              name="departureDate"
              control={control}
              render={({
                field: {
                  onChange, onBlur, value, ref, name,
                },
              }) => (
                <DatePicker
                  label="Departure Date"
                  onChange={onChange}
                  maxDate={maxDate}
                  value={value}
                  slotProps={{
                    textField: {
                      name, onBlur, error: !!errors.departureDate, sx: { width: '70%' },
                    },
                  }}
                  inputRef={ref}
                />
              )}
            />
            <TextField
              type="number"
              label="Nights"
              sx={{ flex: 1 }}
              inputProps={{ min: 5 }}
              {...register('totalNights', { valueAsNumber: true })}
              {...getFieldProps('totalNights', errors)}
            />
          </Stack>
          {errors.departureDate && <FormHelperText error>{errors.departureDate.message as string}</FormHelperText>}
          {errors.totalNights && <FormHelperText error>{errors.totalNights.message as string}</FormHelperText>}
        </Box>
        <Controller
          control={control}
          name="budget"
          render={({ field }) => (
            <CurrencyInput
              label="Total Budget"
              fullWidth
              required
              {...field}
              {...getFieldProps('budget', errors)}
              defaultValue={tripMatch?.budget}
              numericFormatProps={{
                allowNegative: false,
                decimalScale: 0,
              }}
            />
          )}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            type="number"
            label="Adults"
            sx={{ flex: 1 }}
            inputProps={{ min: 0 }}
            {...register('numberOfAdults', { valueAsNumber: true })}
            {...getFieldProps('numberOfAdults', errors)}
          />
          <TextField
            type="number"
            label="Children"
            sx={{ flex: 1 }}
            inputProps={{ min: 0 }}
            {...register('numberOfChildren', { valueAsNumber: true })}
            {...getFieldProps('numberOfChildren', errors)}
          />
        </Stack>
        <Divider sx={{ pt: '8px' }} />
        <Box>
          <Typography variant="h2" width="100%" textAlign="left" position="relative" pt="8px">
            Edit Sales Stage
          </Typography>
          <Typography variant="body" width="100%" textAlign="left" position="relative" pt="8px" sx={{ color: 'text.secondary' }}>
            Please note that the sales stage entered will
            {' '}
            <strong>not be shown</strong>
            {' '}
            to the traveler.
          </Typography>
        </Box>
        <Controller
          name="salesStage"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <InputLabel>Sales Stage</InputLabel>
              <Select
                {...field}
                label="Sales Stage"
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                displayEmpty
                renderValue={(selectedValue) => {
                  const Icon = salesStages[selectedValue as SalesStage]?.icon;
                  const stageName = salesStages[selectedValue as SalesStage]?.stageName || 'Select a stage';
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      {Icon && <Icon sx={{ fontSize: '1.25rem' }} />}
                      {stageName}
                    </Box>
                  );
                }}
              >
                {Object.entries(salesStages).map(([key, { icon: Icon, stageName }]) => (
                  <MenuItem key={key} value={key}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <Icon sx={{ fontSize: '1.25rem' }} />
                      {stageName}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              {!!error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />

        {submissionError && (
          <Alert severity="error">
            <AlertTitle>There was an error on our end.</AlertTitle>
            {`Code: ${submissionError.code}: ${submissionError.message}`}
            <br />
            {submissionError.id && `ID: ${submissionError.id}`}
          </Alert>
        )}
      </Stack>
      <Stack direction="row" spacing={2} mt="44px">
        <LoadingButton
          variant="contained"
          type="submit"
          {...getSubmitButtonProps(isSubmitting, isValid)}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onCancel}>Cancel</Button>
      </Stack>
    </form>
  );
};

export default TripMatchEditForm;

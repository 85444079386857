import React from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';
import UserProfileView from './user-profile-view';

type UserProfileContainerProps = {
  agentId: string | undefined
  contextMenuProps: Parameters<typeof UserProfileView>[0]['contextMenuProps']
};

const UserProfileContainer = ({
  agentId,
  contextMenuProps,
}: UserProfileContainerProps) => {
  const apiClient = useApiClient();
  const agent = useFetch<Agent>(apiClient.peopleClient.getAgent, [agentId], !!agentId);

  return (
    <UserProfileView
      agent={agent}
      contextMenuProps={contextMenuProps}
    />
  );
};

export default UserProfileContainer;

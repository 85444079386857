import { PartnerAvailability } from '@marageti/z4-sdk/lib/travel';
import { createNewUTCDateFromString } from '@marageti/z4-lib';

/**
 * Filter PartnerAvailability objects that overlap with the given date's month and year.
 * @param availabilities An array of PartnerAvailability objects to filter.
 * @param date The target date object.
 * @returns An array of PartnerAvailability objects that overlap with the given date's month and year.
 */
export const filterByDateMonthAndYear = (
  availabilities: PartnerAvailability[],
  date: Date,
): PartnerAvailability[] => {
  const targetMonth = date.getMonth();
  const targetYear = date.getFullYear();
  const targetDate = new Date(targetYear, targetMonth, 1);
  const targetMonthEnd = new Date(targetYear, targetMonth + 1, 0);

  return availabilities?.filter((availability: PartnerAvailability) => {
    const startDate: Date = createNewUTCDateFromString(availability.startDate);
    const endDate: Date = createNewUTCDateFromString(availability.endDate);
    return startDate <= targetMonthEnd && endDate >= targetDate;
  });
};

/**
 * @returns A string representing the first day of the current month in the format yyyy-mm-dd
 */
export const getFirstDayOfCurrentMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // add 1 to month index because it starts at 0
  return `${year}-${month}-01`;
};

/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import { validateEmail } from '../../utils/helpers';

interface EmailInputProps {
  value: string[];
  onChange: (event: React.SyntheticEvent<Element, Event> | null, value: string[]) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  name?: string;
  onBlur?: (event: React.FocusEvent) => void;
}

const MultiEmailInput = forwardRef(({
  value = [],
  onChange,
  error = false,
  helperText = '',
  label = 'Email',
  name = '',
  onBlur,
}: EmailInputProps, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [localError, setLocalError] = useState<string>('');
  const totalCharLimit = 100;

  const calculateTotalChars = (emails: string[]) => emails.join('').length;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
    if (inputValue.trim()) {
      const email = inputValue.trim();
      if (!value.includes(email)) {
        const newValue = [...value, email];
        const totalChars = calculateTotalChars(newValue);

        if (totalChars > totalCharLimit) {
          setLocalError(`Total character limit of ${totalCharLimit} exceeded.`);
        } else {
          const invalidEmails = newValue.filter((mail) => !validateEmail(mail.trim()));
          if (invalidEmails.length > 0) {
            setLocalError(`Invalid email(s): ${invalidEmails.join(', ')}`);
          } else {
            setLocalError('');
          }
          onChange(event, newValue);
        }
      }
      setInputValue('');
    }
  };

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    setInputValue(newInputValue);
    if (localError) {
      setLocalError('');
    }
  };

  const handleChange = (event: React.SyntheticEvent<Element, Event> | null, newValue: string[]) => {
    const totalChars = calculateTotalChars(newValue);

    if (totalChars > totalCharLimit) {
      setLocalError(`Total character limit of ${totalCharLimit} exceeded.`);
    } else {
      const invalidEmails = newValue.filter((email) => !validateEmail(email.trim()));
      if (invalidEmails.length > 0) {
        setLocalError(`Invalid email(s): ${invalidEmails.join(', ')}`);
      } else {
        setLocalError('');
      }
      onChange(event, newValue);
    }
  };

  const renderTags = (item: string[], getTagProps: any) => item.map((option: string, index: number) => {
    const { key, ...tagProps } = getTagProps({ index });
    return (
      <Chip
        key={key}
        sx={{
          backgroundColor: 'secondary.light',
        }}
        label={option}
        {...tagProps}
        onDelete={() => {
          const newValue = value.filter((email) => email !== option);
          handleChange(null, newValue);
        }}
      />
    );
  });

  return (
    <Autocomplete
      ref={ref}
      multiple
      freeSolo
      options={[]}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Type and press Enter"
          error={error || !!localError}
          helperText={localError || helperText}
          onBlur={handleBlur}
          name={name}
          InputLabelProps={{ required: true }}
        />
      )}
    />
  );
});

export default MultiEmailInput;

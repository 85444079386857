import React, { Route } from 'react-router-dom';
import Sales from '../../pages/sales';
import SalesSubNav from '../../pages/sales/sub-nav-layout';
import Invoices from '../../pages/sales/invoices';
import AllSales from '../../pages/sales/all';
import Dummy from '../../pages/dummy';
import RepeatReferralForm from '../../pages/sales/repeat-referral-form';
import RepeatReferralDetails from '../../pages/sales/repeat-referral-details';

const useSalesRoutes = () => (
  <>
    <Route path="sales" element={<Sales />} />
    <Route path="sales/*" element={<SalesSubNav />}>
      <Route path="invoices" element={<Invoices />} handle={{ tab: '/invoices' }} />
      <Route path="refer" element={<Dummy />} handle={{ tab: '/refer' }} />
      <Route path="all" element={<AllSales />} handle={{ tab: '/all' }} />
      <Route path="repeat-referral" element={<RepeatReferralForm />} handle={{ tab: '/monthly' }} />
      <Route path="repeat-referral/:repeatReferralId" element={<RepeatReferralDetails />} handle={{ tab: '/monthly' }} />
      <Route path="repeat-referral/:repeatReferralId/edit" element={<RepeatReferralForm />} handle={{ tab: '/monthly' }} />
    </Route>

  </>
);

export default useSalesRoutes;

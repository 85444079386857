import React from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  SalesRecording, TripMatch, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import { enqueueSnackbar } from 'notistack';
import SalesView from './sales-view';
import { createAlertObj } from '../../../components/alerts/utils';
import { useAuthStore } from '../../../store';

const SalesContainer = () => {
  const apiClient = useApiClient();
  const { tripMatchId } = useParams();
  const { loggedInAgent } = useAuthStore();

  const { data: tripMatch, isLoading: isLoadingTM, mutate } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const { data: salesRecordings, isLoading: isLoadingSR } = useFetch<SalesRecording[]>(
    apiClient.tripMatchesClient.getTripMatchSalesRecordings,
    [tripMatchId],
    !!tripMatchId,
    { revalidateOnMount: true },
  );

  // show Confirm Deposit if the trip match is active and there are no sales recordings
  const showConfirmDeposit = (tripMatch?.status === TripMatchStatus.MatchActive) && salesRecordings?.length === 0;

  const handleReactivation = async () => {
    if (tripMatchId && tripMatch) {
      try {
        await apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId, {
          id: tripMatchId,
          status: TripMatchStatus.MatchActive,
          statusInfo: {
            reason: 'Agent Reactivated Trip',
          },
        });
        mutate();
        enqueueSnackbar('Trip reactivated.', { variant: 'success' });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const alert = createAlertObj(tripMatch, salesRecordings, loggedInAgent, handleReactivation);

  return (
    <SalesView
      alert={alert}
      isLoading={isLoadingTM || isLoadingSR}
      sales={salesRecordings}
      showConfirmDeposit={showConfirmDeposit}
    />
  );
};

export default SalesContainer;

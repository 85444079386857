/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useForm,
  getSubmitButtonProps,
  RichTextEditor,
} from '@marageti/z4-lib';
import {
  Button,
  Alert,
  AlertTitle,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';

const schema = z.object({
  message: z.string().min(1, 'Required'),
});

type NoteViewProps = {
  closeDialog: () => void;
  error: Error | null;
  isInternal: boolean;
  onSubmit: (data: any) => void;
  open: boolean;
};

const NoteView = ({
  closeDialog,
  error,
  isInternal,
  onSubmit,
  open,
}: NoteViewProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control,
  } = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={isMobile}
    >
      <DialogTitle>
        {isInternal ? 'Add Internal Note' : 'Add Note'}
      </DialogTitle>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: '100%' }}
      >
        <DialogContent>
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <RichTextEditor
                editorName="Add-Note-Editor"
                error={!!errors.message}
                label="Message"
                onChange={field.onChange}
                sx={{
                  height: '380px',
                  maxHeight: '380px',
                }}
                useLinks
                useLists
              />
            )}
          />
          {isInternal && (
            <Typography variant="caption" color="text.secondary">
              Internal notes will not be seen by the traveler.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              width: { desktop: 'fit-content' },
            }}
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Submit
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={closeDialog}
            sx={{
              width: { desktop: 'fit-content' },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Stack>
      {error && (
      <Alert severity="error">
        <AlertTitle>There was an error submitting note</AlertTitle>
        {error?.message}
      </Alert>
      )}
    </Dialog>
  );
};

export default NoteView;

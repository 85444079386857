import { useApiClient } from '@marageti/z4-lib';
import { Message } from '@marageti/z4-sdk/lib/travel';
import useUser from './use-user';

type ChatProps = {
  conversationId: string | undefined
};

const useChat = ({ conversationId }: ChatProps) => {
  const user = useUser();
  const apiClient = useApiClient();

  const sendMessage = (message: Partial<Message>) => {
    if (conversationId) {
      return apiClient.conversationsClient
        .postMessage(conversationId, message);
    }

    const error = new Error('Missing conversationId');
    return Promise.reject(error);
  };

  return {
    sender: user,
    sendMessage,
  };
};

export default useChat;

import * as React from 'react';
import {
  Navigate, Outlet, useLocation, useSearchParams,
} from 'react-router-dom';
import { useAuthStore } from '../../store';

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuthStore();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const redirect = searchParams.get('redirect');

  if (isAuthenticated === null) return null;
  // If not authenticated and attempting to access a protected route, route user to login
  // with search query redirect set to pathname if not set already. Maintain other query params
  if (isAuthenticated === false) {
    if (!redirect) searchParams.set('redirect', pathname);
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: searchParams.toString(),
        }}
        replace
      />
    );
  }
  return <Outlet />;
};

export default ProtectedRoutes;

import { useMemo } from 'react';
import Pusher from 'pusher-js';
import { LocalStorageService } from '@marageti/z4-lib';
import { TApiEndpoints } from '@marageti/z4-sdk/lib/client';
import config from '../config';
import { env } from '../utils/standards';

const LocalStorageClient = new LocalStorageService();

const getPusherInstance = (accessToken: string) => new Pusher(config.PUSHER_APP_KEY, {
  cluster: config.PUSHER_APP_CLUSTER,
  channelAuthorization: {
    endpoint: `${TApiEndpoints[env]}/v3/push_notifier/channel_auth`,
    transport: 'ajax',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },
  userAuthentication: {
    endpoint: `${TApiEndpoints[env]}/v3/push_notifier/user_auth`,
    transport: 'ajax',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },
});

const usePusherClient = () => {
  const accessToken = LocalStorageClient.getFromLocalStorage('accessToken');
  const pusherInstance = useMemo(() => (accessToken ? getPusherInstance(accessToken) : undefined), [accessToken]);
  Pusher.logToConsole = false;

  return pusherInstance;
};

export default usePusherClient;

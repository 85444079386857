import React from 'react';
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import { TripMatchNotesCard } from '@marageti/z4-lib';
import { AgentCount, TripMatch, TripRequest } from '@marageti/z4-sdk/lib/travel';
import TripDetailCard from '../../../components/trip-detail-card';
import AcceptForm from '../../../components/accept-form';
import TravelerCard from '../../../components/traveler-card';

type ViewProps = {
  tripMatch: TripMatch | undefined;
  tripRequest: TripRequest | undefined;
  agents: AgentCount[] | undefined;
  onRejectTrip: (arg: { reason: string, comment: string }) => void;
  onAssignTrip: (agentId: string) => void;
  loading: boolean;
  isSubmitting: boolean;
};

const AcceptTripView = ({
  isSubmitting,
  loading,
  agents,
  tripMatch,
  tripRequest,
  onRejectTrip,
  onAssignTrip,
}: ViewProps) => (
  <Container maxWidth="lg">
    <Box sx={{
      pt: {
        mobile: 3, desktop: 6.5,
      },
      pb: 3.5,
    }}
    >
      <Grid
        container
        spacing={1.5}
        sx={{
          flexDirection: {
            mobile: 'column-reverse',
            desktop: 'row',
          },
        }}
      >
        <Grid item xs={12} md={7}>
          {!loading ? (
            <Stack spacing={2.5}>
              {tripMatch?.travelers && tripMatch.travelers.length > 0 && (
                <TravelerCard
                  hidePhone
                  travelers={tripMatch.travelers.slice(0, 2)}
                  baseRoute={`/trip-matches/${tripMatch.id}`}
                />
              )}
              <TripDetailCard
                title="Match Details"
                titleTypographyProps={{ variant: 'bodyBold' }}
                tripMatch={tripMatch}
                tripRequest={tripRequest}
              />
              <TripMatchNotesCard
                title="Notes from Zicasso"
                notes={{ ...tripRequest?.matchingNotes }}
                detailCardProps={{ titleTypographyProps: { variant: 'bodyBold' } }}
              />
            </Stack>
          ) : (
            <Stack spacing={2.5}>
              <Skeleton variant="rounded" height={225} />
              <Skeleton variant="rounded" height={670} />
              <Skeleton variant="rounded" height={110} />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {!loading ? (
            <AcceptForm
              agents={agents}
              isSubmitting={isSubmitting}
              onRejectTrip={onRejectTrip}
              onAssignTrip={onAssignTrip}
            />
          ) : (<Skeleton variant="rounded" height={415} />)}
        </Grid>
      </Grid>
    </Box>
  </Container>
);

export default AcceptTripView;

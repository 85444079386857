import React from 'react';
import {
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';
import { PartnerOffice } from '@marageti/z4-sdk/lib/travel';
import EditOfficeForm from '../../../../components/office-edit-form';

type UserProfileEditViewProps = {
  titleFrag: string;
  partnerOffice: PartnerOffice | undefined
  isLoading: boolean
  partnerError: any
  onSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
  countryCodes: any | undefined
};

const EditView = ({
  titleFrag,
  partnerError,
  isLoading,
  partnerOffice,
  onSubmit,
  submissionError,
  onCancel = undefined,
  countryCodes,
}: UserProfileEditViewProps) => {
  if (isLoading || !partnerOffice || !countryCodes) {
    return (
      <Skeleton variant="rounded" height={500} />
    );
  }

  if (partnerError) {
    return (
      <Alert severity="error">
        <AlertTitle>User Error</AlertTitle>
        There was an error getting user.
      </Alert>
    );
  }

  return (
    <EditOfficeForm
      countryCodes={countryCodes}
      titleFragment={titleFrag}
      office={partnerOffice}
      submissionError={submissionError}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default EditView;

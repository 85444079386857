import React from 'react';
import {
  Container, Box,
} from '@mui/material';
import {
  BackButton,
} from '@marageti/z4-lib';
import UserProfile from '../../components/user-profile';

type UserViewProps = {
  agentId: string | undefined
  onNavigateBack: () => void
  userInfoContextMenuProps: Parameters<typeof UserProfile>[0]['contextMenuProps']
};

const UserView = ({ agentId, onNavigateBack, userInfoContextMenuProps }: UserViewProps) => (
  <Container
    maxWidth="sm"

  >
    <Box
      sx={{
        py: {
          desktop: 6,
          mobile: 3,
        },
      }}
    >
      <BackButton onClick={onNavigateBack} edge="start" sx={{ mb: 2 }} />
      <UserProfile
        agentId={agentId}
        contextMenuProps={userInfoContextMenuProps}
      />
    </Box>
  </Container>
);

export default UserView;

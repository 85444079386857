import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Typography,
  Stack,
  Skeleton,
  Button,
} from '@mui/material';
import {
  DetailCard, CopyButton, ContextMenuComplete,
} from '@marageti/z4-lib';
import { SalesRecording, TripMatch } from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingStatus, SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { format } from 'date-fns';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { DocStatus } from '@marageti/z4-sdk/lib/documents';
import { formatCountryCodes, getCurrencyDetailsContent } from '../../../utils/helpers';
import { formatInvoiceMonth } from '../../../utils/sales';

const formatDate = (timestamp: string, formatType: string) => format(new Date(timestamp), formatType);

type ViewProps = {
  countryCodes: CountryCodeMap | undefined;
  docError: ApiError | undefined;
  isLoading: boolean;
  onClickDownload: () => void;
  onClickEdit: () => void;
  onClickRemove: () => void;
  salesRecording: SalesRecording | undefined;
  saleUnderReview: boolean;
  tripMatch: TripMatch | undefined;
};

const SalesRecordingView = ({
  countryCodes,
  docError,
  isLoading,
  onClickDownload,
  onClickEdit,
  onClickRemove,
  salesRecording,
  saleUnderReview,
  tripMatch,
}: ViewProps) => {
  const getPageTitle = (salesRecordingType: SalesRecordingType) => {
    const types = {
      [SalesRecordingType.General]: 'Standard Sale',
      [SalesRecordingType.Adjustment]: 'Sales Adjustment',
      [SalesRecordingType.PartnerRepeat]: 'Repeat Sale',
      [SalesRecordingType.TravelerReferral]: 'Referral Sale',
      [SalesRecordingType.CommissionAfterTravel]: 'Commission After Travel',
      [SalesRecordingType.PlanningFee]: 'Planning Fee',
      [SalesRecordingType.RefundCredit]: 'Refund Credit',
    };
    return types[salesRecordingType] || '';
  };

  const getSalesRecordingPriceTitle = (salesRecordingType: SalesRecordingType) => {
    const types = {
      [SalesRecordingType.General]: 'Total Trip Price',
      [SalesRecordingType.Adjustment]: 'Changes to Total Trip Price',
      [SalesRecordingType.PartnerRepeat]: 'Total Trip Price',
      [SalesRecordingType.TravelerReferral]: 'Total Trip Price',
      [SalesRecordingType.CommissionAfterTravel]: 'Total Trip Price',
      [SalesRecordingType.PlanningFee]: 'Amount',
      [SalesRecordingType.RefundCredit]: 'Total Trip Price',
    };
    return types[salesRecordingType] || '';
  };

  const getContextMenuItems = (salesRecordingType: SalesRecordingType) => {
    if (salesRecordingType === SalesRecordingType.CommissionAfterTravel || salesRecordingType === SalesRecordingType.PlanningFee) {
      return [
        { label: 'Remove', action: onClickRemove },
      ];
    }
    return [
      { label: 'Edit', action: onClickEdit },
      { label: 'Remove', action: onClickRemove },
    ];
  };

  return (
    <Container
      maxWidth="md"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          py: { desktop: 7, mobile: 3 },
        }}
      >
        <Stack spacing={3}>
          {isLoading && (
          <>
            <Skeleton height={32} width={200} />
            <Skeleton height={252} width={774} />
            <Skeleton height={480} width={774} />
          </>
          )}
          {(!isLoading && tripMatch && salesRecording && countryCodes) && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h2">{getPageTitle(salesRecording.salesRecordingType)}</Typography>
              {salesRecording.status === SalesRecordingStatus.SoldStatus && (
              <ContextMenuComplete
                id="sales-recording-content"
                items={getContextMenuItems(salesRecording.salesRecordingType)}
              />
              )}
            </Stack>
            {salesRecording.status === SalesRecordingStatus.InvoicedStatus && (
              <Alert severity="error">
                <AlertTitle>Previously Invoiced</AlertTitle>
                This recording has already been invoiced, and can no longer be edited. If you need to make changes, you can submit a sales adjustment for the associated trip.
              </Alert>
            )}
            {salesRecording.status === SalesRecordingStatus.VerifiedStatus && (
            <Alert severity="success">
              <AlertTitle>Recording Approved</AlertTitle>
              This recording has already been approved by Zicasso and is locked for invoicing. If changes are needed, you can submit a sales adjustment once the status changes to Invoiced.
            </Alert>
            )}
            {docError && (
              <Alert severity="error">
                <AlertTitle>There was an issue downloading the sales recording</AlertTitle>
                {docError.message}
                {docError.code && `Code: ${docError.code} `}
                {docError.id && `ID: ${docError.id}`}
              </Alert>
            )}
            { saleUnderReview && (
              <Alert severity="warning">
                <AlertTitle>Sales Under Review</AlertTitle>
                Our team is currently reviewing your sales and preparing for invoice.
                Please do not make any changes to this sales record unless contacted by our team.
                Once your sales are approved, this record will be locked for invoicing.
              </Alert>
            )}
            <DetailCard
              title="Trip Info"
              items={[
                { title: 'Countries', content: formatCountryCodes(tripMatch.countries, countryCodes) },
                { title: 'When', content: `${formatDate(tripMatch.departureDate, 'MMM d, yyyy')} (${tripMatch.totalNights} Nights)` },
                { title: 'Travelers', content: `${tripMatch.numberOfAdults} Adults, ${tripMatch.numberOfChildren} Children` },
              ]}
            />
            <DetailCard
              title={salesRecording.salesRecordingType === SalesRecordingType.Adjustment ? 'Adjustments' : 'Sales Details'}
              items={[
                {
                  title: 'Date Recorded',
                  content: formatDate(salesRecording.createdAt, 'MMM dd, yyyy'),
                },
                {
                  title: getSalesRecordingPriceTitle(salesRecording.salesRecordingType),
                  content: getCurrencyDetailsContent(salesRecording.price),
                },
                {
                  title: salesRecording.salesRecordingType === SalesRecordingType.Adjustment ? 'Changes to Commission to Zicasso' : 'Commission to Zicasso',
                  content: getCurrencyDetailsContent(salesRecording.commission),
                },
                { title: 'Comments', content: salesRecording.priceComments },
                {
                  title: 'Invoice Month',
                  content: formatInvoiceMonth(salesRecording.invoiceDate),
                },
                {
                  title: 'Sales ID',
                  content: (
                    <>
                      {salesRecording.id}
                      <CopyButton textToCopy={salesRecording.id} iconFontSize="small" size="small" />
                    </>
                  ),
                },
                ((salesRecording.document) ? (
                  <Button
                    disabled={salesRecording.document.status !== DocStatus.AvailableStatus}
                    onClick={onClickDownload}
                    variant="contained"
                    sx={{ width: 'fit-content' }}
                  >
                    Download Attachment
                  </Button>
                ) : null),
              ]}
            />
          </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default SalesRecordingView;

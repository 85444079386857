import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const Dummy = () => {
  const [params] = useSearchParams();
  const text = params.get('text');

  return (
    <Container maxWidth="xl">
      <Box
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'calc(100vh - 508px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">Development Page</Typography>
        { text && (
        <Typography variant="body1">
          {text}
        </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Dummy;

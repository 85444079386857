import * as React from 'react';
import {
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import { useApiClient } from '@marageti/z4-lib';
import { useAuthStore } from '../../store';

const DestinationNavigator = () => {
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuthStore();
  const [searchParams] = useSearchParams();

  const ciphertext = searchParams.get('ciphertext');
  const redirect = searchParams.get('redirect');
  const sessionCookieExists = document.cookie.includes('user_session');

  const checkCiphertext = async (text: string) => {
    try {
      const { redirectUrl } = await apiClient.authClient.ssoRedirect({ ciphertext: text });
      if (redirectUrl) window.location.href = redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  // Redirect if authenticated and ciphertext is available
  if (isAuthenticated && sessionCookieExists && ciphertext) {
    checkCiphertext(ciphertext);
    return null;
  }

  // Route authenticated users to the redirect query param maintaining other query params
  if (isAuthenticated && redirect) {
    searchParams.delete('ciphertext');
    searchParams.delete('redirect');
    return <Navigate to={{ pathname: redirect, search: searchParams.toString() }} replace />;
  }
  return <Outlet />;
};

export default DestinationNavigator;

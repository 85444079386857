import React from 'react';
import {
  Box,
  // Divider,
  Typography,
  Stack,
  Divider,
} from '@mui/material';
import {
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  Link,
} from '@marageti/z4-lib';
import GoogleLoginView from '../../components/auth/google-sso-button';
import PasswordLogin from '../../components/auth/email-password-form';
import TestingBanner from '../../components/banners/testing-banner';
import { ReactComponent as ZicassoSVG } from '../../assets/svg/zicasso.svg';
import ZSpinner from '../../components/z-lib/z-spinner';
import { ViewProps } from './types';
import './login.scss';

const View = ({
  loading,
  loginError = undefined,
  onSubmit,
}: ViewProps): React.ReactElement => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack
      spacing={3}
      sx={{
        width: { mobile: '342px', desktop: '333px' },
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TestingBanner />
      <Stack flexDirection="column" spacing="10px" alignItems="center">
        <ZicassoSVG aria-label="Zicasso" />
        <Typography color="text.secondary" style={{ marginTop: '10px' }}>
          Please sign in to continue.
        </Typography>
      </Stack>
      {loading && (
      <ZSpinner />
      )}
      <GoogleLoginView onSuccess={onSubmit} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Divider sx={{ flex: 1 }} />
        <Typography variant="body2" color="" margin="0 12px">or</Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <PasswordLogin onSubmit={onSubmit} disablePasswordValidation mode="onSubmit" />
      <Link
        color="primary"
        sx={{ '&:hover': { color: 'secondary.main' } }}
        to="/reset"
        underline="always"
        variant="subtitle1"
      >
        Forgot Password?
      </Link>
      {loginError && (
        <ErrorBox>
          <ErrorTitle>
            There was an error logging in.
          </ErrorTitle>
          {loginError.code ? (
            <ErrorMessage>
              {loginError.code && `Code: ${loginError.code} `}
              {loginError.message || 'Please contact support'}
              <br />
              {loginError.id && `ID: ${loginError.id}`}
            </ErrorMessage>
          ) : (
            <ErrorMessage>
              Please contact support.
            </ErrorMessage>
          )}
        </ErrorBox>
      )}
    </Stack>
  </Box>
);

export default View;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  AutocompleteSelect, Fab,
  useForm, getSubmitButtonProps, BackNavigationAppBar, RichTextEditor,
} from '@marageti/z4-lib';
import {
  Container, Button, Alert, AlertTitle, Stack, Typography,
} from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';

const ACTIONS = [{
  title: 'Log Phone Call',
  value: 'call',
}, {
  title: 'Log Text',
  value: 'text',
}];

const schema = z.object({
  action: z.object({
    title: z.string(),
    value: z.string(),
  }),
  message: z.string().min(1, 'Required'),
});

type LogViewProps = {
  onSubmit: (data: any) => void;
  handleNavigateBack: () => void
  error: Error | null;
};

const LogView = ({
  onSubmit,
  handleNavigateBack,
  error,
}: LogViewProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      message: '',
      action: ACTIONS[0],
    },
  });

  const handleSelectChange = (_: any, selectedOption: {
    title: string;
    value: string;
  }) => {
    setValue('action', selectedOption);
  };

  return (
    <>
      <BackNavigationAppBar
        primary="Log Call / Text"
        defaultPath=".."
      />
      <Container
        maxWidth="md"
        sx={{
          marginBottom: '145px',
          display: 'flex',
          flexDirection: 'column',
          height: { mobile: 'calc(100vh - 77px - 145px)', desktop: '440px' },
          maxHeight: { mobile: 'calc(100vh - 77px - 145px)', desktop: '440px' },
          width: { desktop: '700px' },
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            height: '100%',
            pb: 5,
          }}
        >
          <AutocompleteSelect
            label="Action"
            name="action"
            value={watch('action')}
            onChange={handleSelectChange}
            options={ACTIONS}
          />
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <RichTextEditor
                editorName="Log-Editor"
                error={!!errors.message}
                label="Message"
                onChange={field.onChange}
                sx={{
                  height: '380px',
                  maxHeight: '380px',
                }}
                useLinks
                useLists
              />
            )}
          />
          <Typography variant="caption" color="text.secondary">
            Call and Text Logs are internal only, not visible to the other party.
          </Typography>
          <Fab>
            <Container sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              flexDirection: {
                mobile: 'column',
                desktop: 'row',
              },
            }}
            >
              <Button
                variant="outlined"
                onClick={handleNavigateBack}
                sx={{
                  width: { desktop: 'fit-content' },
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  width: { desktop: 'fit-content' },
                }}
                {...getSubmitButtonProps(isSubmitting, isValid)}
              >
                Submit
              </LoadingButton>
            </Container>
          </Fab>

          {error && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting log</AlertTitle>
            {error?.message}
          </Alert>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default LogView;

/* eslint-disable import/prefer-default-export, quote-props */

import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { TripMatchStatus, TripMatch } from '@marageti/z4-sdk/lib/travel/TripMatch';

export const monthMap: { [key: string]: string } = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

/**
 * Format the invoice date to MMMM YYYY'
 * @param invoiceDate the inovice date in the format of 'YYYY-MM'
 */
export const formatInvoiceMonth = (invoiceDate: string) => {
  if (!invoiceDate) return '-';
  const [year, month] = invoiceDate.split('-');
  return `${monthMap[month]} ${year}`;
};

export const salesRecordingTypeLabel = {
  [SalesRecordingType.Adjustment]: 'Sales Adjustment',
  [SalesRecordingType.General]: 'Standard Sale',
  [SalesRecordingType.PartnerRepeat]: 'Repeat Sale',
  [SalesRecordingType.TravelerReferral]: 'Referral Sale',
  [SalesRecordingType.CommissionAfterTravel]: 'Commission After Travel',
  [SalesRecordingType.PlanningFee]: 'Planning Fee',
  [SalesRecordingType.RefundCredit]: 'Refund Credit',
};

export const shouldShowSalesCard = (tripMatch: TripMatch) => {
  const statuses = [
    TripMatchStatus.MatchActive,
    TripMatchStatus.MatchSold,
    TripMatchStatus.MatchRefundedCanceled,
    TripMatchStatus.MatchSoldCanceled,
  ];
  return statuses.includes(tripMatch.status);
};

import React from 'react';
import {
  Container,
} from '@mui/material';
import { TimeZone } from '@marageti/z4-sdk/lib/standards';
import UserProfileEditForm from '../../components/user-profile-edit-form';

type GettingStartedViewProps = {
  agentId: string | undefined
  suggestedTimezoneOption: TimeZone | undefined
  onSuccess: () => void
};

const GettingStartedView = ({ agentId, suggestedTimezoneOption, onSuccess }: GettingStartedViewProps) => (
  <Container
    maxWidth="sm"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: {
        desktop: 20,
        mobile: 10,
      },
    }}
  >
    <UserProfileEditForm
      title="Complete Your Profile"
      subtitle="Add details about yourself."
      submitText="Continue"
      agentId={agentId}
      suggestedTimezoneOption={suggestedTimezoneOption}
      onSuccess={onSuccess}
    />
  </Container>
);

export default GettingStartedView;

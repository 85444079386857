import React from 'react';
import {
  AddAdjustment, Inactive, ConfirmDeposit, Postponed, SendFirstMessage,
} from '../../../components/alerts';
import { AlertObj, AlertType } from '../../../components/alerts/utils';

const Alerts = ({
  alert,
}: { alert: AlertObj }) => {
  switch (alert.type) {
    case AlertType.FirstMessageReminder:
      if (!alert.url || !alert.createdAt) return null;
      return <SendFirstMessage url={alert.url} createdAt={alert.createdAt} />;
    case AlertType.DepositConfirmation:
      return alert.url ? <ConfirmDeposit url={alert.url} /> : null;
    case AlertType.SalesAdjustment:
      return alert.url ? <AddAdjustment url={alert.url} /> : null;
    case AlertType.Postponed:
      return alert.handleReactivation ? <Postponed handleReactivation={alert.handleReactivation} /> : null;
    case AlertType.Inactive:
      return alert.handleReactivation ? <Inactive handleReactivation={alert.handleReactivation} /> : null;
    default:
      return null;
  }
};

export default Alerts;

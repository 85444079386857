import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import { TripMatch } from '@marageti/z4-sdk/lib/travel';

// eslint-disable-next-line import/prefer-default-export
export const getLeadsChips = (tripMatch: TripMatch) => {
  const primaryTraveler = tripMatch.travelers[0];
  const chips = [];

  // Tags
  if (primaryTraveler.tags && primaryTraveler.tags.includes(TravelerTags.VIP)) {
    chips.push(TravelerTags.VIP);
  }

  //  Repeat Traveler
  if (tripMatch.repeatTraveler) {
    chips.push('REPEAT_TRAVELER');
  }

  // Referral Type
  if (tripMatch.referralType) {
    chips.push(tripMatch.referralType);
  }

  // Status
  if (tripMatch.status) {
    chips.push(tripMatch.status);
  }

  return chips;
};

export const getChipsFromTripMatch = (tripMatch: TripMatch) => {
  const primaryTraveler = tripMatch.travelers[0];
  const chips = [];

  // Status
  if (tripMatch.status) {
    chips.push(tripMatch.status);
  }

  // Tags
  if (primaryTraveler.tags && primaryTraveler.tags.includes(TravelerTags.VIP)) {
    chips.push(TravelerTags.VIP);
  }

  // Referral Type
  if (tripMatch.referralType) {
    chips.push(tripMatch.referralType);
  }

  //  Repeat Traveler
  if (tripMatch.repeatTraveler) {
    chips.push('REPEAT_TRAVELER');
  }

  return chips;
};

import { Partner } from '@marageti/z4-sdk/lib/travel';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PolicyAndTermsCardView, { ProfileDetails } from './detail-card-view';

type Props = {
  partner: Partner,
  partnerDetail: ProfileDetails,
  agentAdmin: boolean;
};

const PolicyAndTermsCardContainer = ({
  partner,
  partnerDetail,
  agentAdmin,
}: Props) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(partnerDetail.routeName);
  };

  const editContextMenu = (agentAdmin) ? {
    id: `${partnerDetail.routeName}-context-menu`,
    items: [{
      label: 'Edit',
      action: handleEditClick,
    }],
  } : undefined;

  return (
    <PolicyAndTermsCardView
      partner={partner}
      partnerDetail={partnerDetail}
      agentAdmin={agentAdmin}
      contextMenuProps={editContextMenu}
    />
  );
};

export default PolicyAndTermsCardContainer;

import React from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  SalesRecording, TripMatch, TripMatchStatus, TripRequest,
} from '@marageti/z4-sdk/lib/travel';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import TripSidebarView from './trip-sidebar-view';
import { createAlertObj } from '../alerts/utils';
import { getCanEditTripDetails } from '../trip-detail-card/helpers';
import { useAuthStore } from '../../store';

type TripSidebarContainerProps = {
  tripMatchId: string | undefined;
  tripRequestId: string | undefined;
};

const TripSidebarContainer = ({ tripMatchId, tripRequestId }: TripSidebarContainerProps) => {
  const apiClient = useApiClient();
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const { loggedInAgent } = useAuthStore();
  const { data: tripMatch, error: tmError, mutate: mutateTM } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const tripRequest = useFetch<TripRequest>(apiClient.tripRequestsClient.getTripRequest, [tripRequestId], !!tripRequestId);

  const { data: salesRecordings } = useFetch<SalesRecording[]>(apiClient.tripMatchesClient.getTripMatchSalesRecordings, [tripMatchId], !!tripMatchId);
  const isTravelerConversation = tripMatch?.travelerToPartnerId === conversationId;
  const travelers = (tripMatch?.travelers || tripRequest.data?.travelers) ?? [];

  const handleReactivation = async () => {
    if (tripMatchId && tripMatch) {
      try {
        await apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId, {
          id: tripMatchId,
          status: TripMatchStatus.MatchActive,
          statusInfo: {
            reason: 'Agent Reactivated Trip',
          },
        });
        mutateTM();
        enqueueSnackbar('Trip reactivated.', { variant: 'success' });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClickEdit = () => {
    if (tripMatchId) {
      navigate(`/trip-matches/${tripMatchId}/edit`);
    }
  };

  const alert = createAlertObj(tripMatch, salesRecordings, loggedInAgent, handleReactivation);

  return (
    <TripSidebarView
      alert={alert}
      error={tmError || tripRequest.error}
      isTravelerConversation={isTravelerConversation}
      travelers={travelers}
      tripMatch={tripMatch}
      tripRequest={tripRequest.data}
      salesRecordings={salesRecordings}
      canEditTripDetails={getCanEditTripDetails(tripMatch)}
      onClickEdit={handleClickEdit}
    />
  );
};

export default TripSidebarContainer;

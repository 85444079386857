import { Environment } from '@marageti/z4-sdk/lib/client';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';

// eslint-disable-next-line import/prefer-default-export
export const getCountryCode = (country: string, countryCodes: CountryCodeMap) => {
  const [codeAndName] = Object.entries(countryCodes).filter(([,value]) => (value === country));
  return codeAndName;
};

const environments: { [key: string]: Environment } = {
  dev: Environment.local,
  staging: Environment.staging,
  qa: Environment.qa,
  prod: Environment.prod,
  local: Environment.local,
};
export const env = process.env.REACT_APP_STAGE ? environments[process.env.REACT_APP_STAGE] : Environment.local;

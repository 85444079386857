/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Stack,
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Divider,
  MenuItem,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
  AutocompleteSelect,
  LinkExternal,
} from '@marageti/z4-lib';
import { Controller } from 'react-hook-form';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { CurrencyAmount } from '@marageti/z4-sdk/lib/standards';
import CurrencyInput from '../../currency-input';
import config from '../../../config';

const SALES_RECORDING_TYPE_OPTIONS = [
  {
    title: 'Repeat',
    value: SalesRecordingType.PartnerRepeat,
  }, {
    title: 'Referral',
    value: SalesRecordingType.TravelerReferral,
  },
];

type FormDefaultValues = {
  salesRecordingType: SalesRecordingType | null,
  agent: string | undefined,
  givenName: string | undefined,
  familyName: string | undefined,
  price: Partial<CurrencyAmount> | undefined,
  commission: Partial<CurrencyAmount> | undefined,
  priceComments: string | undefined,
};

const DEFAULT_VALUES = {
  salesRecordingType: null,
  agent: undefined,
  givenName: undefined,
  familyName: undefined,
  price: {
    amount: 0,
    currencyCode: 'USD',
  },
  commission: {
    amount: 0,
    currencyCode: 'USD',
  },
  priceComments: '',
} as FormDefaultValues;

const schema = z.object({
  salesRecordingType: z.string().min(1, { message: 'Required' }),
  agent: z.string(),
  givenName: z.string().min(1, { message: 'Required' }),
  familyName: z.string().min(1, { message: 'Required' }),
  price: z.object({
    amount: z.number().min(0, 'Total Trip Price must be a positive value'),
    currencyCode: z.string(),
  }),
  commission: z.object({
    amount: z.number().min(0, 'Total Trip Commission must be a positive value'),
    currencyCode: z.string(),
  }),
  priceComments: z.string().min(1, { message: 'Comments are required' }),
});

type FormProps = {
  defaultValues: {
    salesRecordingType?: SalesRecordingType | undefined,
    agent?: string | undefined,
    givenName?: string | undefined,
    familyName?: string | undefined,
    price: Partial<CurrencyAmount> | undefined,
    commission: Partial<CurrencyAmount> | undefined,
    priceComments: string | undefined,
  },
  agents: {
    title: string,
    value: string,
  }[]
  | undefined,
  onSubmit: (data: any) => void,
  submissionError: boolean,
  onCancel: () => void,
  readOnlyCurrency: boolean,
};

const Form = ({
  defaultValues,
  agents = [],
  onSubmit,
  submissionError,
  onCancel,
  readOnlyCurrency,
}: FormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const currency = watch('price.currencyCode');

  useEffect(() => {
    setValue('commission.currencyCode', currency);
  }, [currency]);

  return (
    <form {...getFormProps(handleSubmit(onSubmit))}>
      <Stack spacing={2} useFlexGap>
        <div>
          <Typography component="div" color="text.secondary">
            This section is for you to report the following offline sales:
            <ul>
              <li>
                Repeat: Zicasso client who contacted you directly (i.e. repeat clients&apos; trips not matched via Zicasso).
              </li>
              <li>
                Referrals: Clients who were referred by a Zicasso client, or referrals of their referrals (in multiple degrees).
              </li>
            </ul>
            <em>This recording will be added to the current month&apos;s sales and applied to the following month&apos;s invoice.</em>
          </Typography>

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="salesRecordingType"
                render={({ field }) => (
                  <AutocompleteSelect
                    label="Repeat or Referral"
                    options={SALES_RECORDING_TYPE_OPTIONS}
                    value={SALES_RECORDING_TYPE_OPTIONS.find((option) => option.value === field.value) || null as any}
                    onChange={(_, selectedOption) => {
                      field.onChange(selectedOption.value);
                    }}
                    {...getFieldProps('salesRecordingType', errors)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="agent"
                render={({ field }) => (
                  <AutocompleteSelect
                    label="Agent"
                    options={agents as any}
                    value={(agents && agents.find((option) => option.value === field.value)) || null as any}
                    onChange={(_, selectedOption) => {
                      field.onChange(selectedOption.value);
                    }}
                    {...getFieldProps('agent', errors)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <Divider
          sx={{
            my: 2,
          }}
        />

        <div>
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Traveler Info
          </Typography>
          <Typography color="text.secondary">
            Please enter the traveler&apos;s information. This is necessary to identify them in our system.
          </Typography>

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                {...register('givenName')}
                {...getFieldProps('givenName', errors)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                {...register('familyName')}
                {...getFieldProps('familyName', errors)}

              />
            </Grid>
          </Grid>
        </div>

        <Divider
          sx={{
            my: 2,
          }}
        />

        {/* Total Trip Price */}
        <div>
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Total Trip Price
          </Typography>

          <Grid container columnSpacing={2}>
            <Grid item mobile={4} desktop={2}>
              <TextField
                fullWidth
                label="Currency"
                select
                {...register('price.currencyCode')}
                {...getFieldProps('price.currencyCode', errors)}
                InputProps={{
                  defaultValue: defaultValues?.price?.currencyCode,
                  readOnly: readOnlyCurrency,
                }}
                sx={{
                  mt: 1,
                }}
              >
                <MenuItem key="USD" value="USD">USD</MenuItem>
                <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                <MenuItem key="GBP" value="GBP">GBP</MenuItem>
              </TextField>
            </Grid>

            <Grid item mobile={8} desktop={10}>
              <Controller
                control={control}
                name="price.amount"
                render={({ field }) => (
                  <CurrencyInput
                    label="Amount"
                    fullWidth
                    required
                    {...field}
                    {...getFieldProps('price.amount', errors)}
                    defaultValue={defaultValues?.price?.amount}
                    numericFormatProps={{
                      allowNegative: false,
                      currencyCode: currency,
                    }}
                    sx={{
                      mt: 1,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} useFlexGap>
            <Typography variant="caption">
              Keep in mind that you can make changes to these details until the end of the month. After that,
              if there are any further changes, you can submit a sales adjustment for the necessary modifications.
            </Typography>
          </Stack>
        </div>

        <Divider
          sx={{
            my: 2,
          }}
        />

        {/* Commission to Zicasso */}
        <div>
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Commission to Zicasso
          </Typography>

          <Grid container columnSpacing={2}>
            <Grid item mobile={4} desktop={2}>
              <Controller
                control={control}
                name="commission.currencyCode"
                defaultValue={defaultValues?.commission?.currencyCode}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Currency"
                    InputProps={{
                      readOnly: true,
                    }}
                    select
                    {...field}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <MenuItem key="USD" value="USD">USD</MenuItem>
                    <MenuItem key="EUR" value="EUR">EUR</MenuItem>
                    <MenuItem key="GBP" value="GBP">GBP</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item mobile={8} desktop={10}>
              <Controller
                control={control}
                name="commission.amount"
                render={({ field }) => (
                  <CurrencyInput
                    label="Amount"
                    fullWidth
                    required
                    {...field}
                    {...getFieldProps('commission.amount', errors)}
                    defaultValue={defaultValues?.commission?.amount}
                    numericFormatProps={{
                      allowNegative: false,
                      currencyCode: currency,
                    }}
                    sx={{
                      mt: 1,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Stack spacing={2} useFlexGap>

            <TextField
              label="Comments *"
              multiline
              minRows={6}
              {...register('priceComments')}
              {...getFieldProps('priceComments', errors)}
              sx={{
                mb: 0,
              }}
            />
            <Typography variant="caption">
              Please provide details about the trip price and how you calculated the commissionable portion of the trip.
              For example, Commissionable portion of the trip ($8,000) = Total trip price ($10,000) -
              non-commissionable flights ($2.000). This information will not be shown to the traveler.
            </Typography>
            <Typography variant="caption" component="div">
              For offline Repeat / Referral sales, the commission due to Zicasso is:
              <ul>
                <li>
                  4% of the total land package for Custom Tours
                </li>
                <li>
                  20% for Cruises or Escorted Group Tours
                </li>
                <li>
                  Commissionable airfares for Repeat / Referral sales is 20% of your gross profit.
                </li>
              </ul>
            </Typography>
            <Typography variant="caption">
              For more info, see Section 5 of the
              {' '}
              <Typography variant="caption">
                <LinkExternal
                  sx={{ textDecoration: 'underline' }}
                  href={`${config.Z3_URL}tc-terms-agreement`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Travel Company Agreement
                </LinkExternal>
              </Typography>
              .
            </Typography>
          </Stack>
        </div>

        {submissionError && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting the form.</AlertTitle>
            Please try again.
          </Alert>
        )}

        <Stack
          direction="row"
          spacing={3}
          sx={(theme) => ({
            marginTop: 2,
            [theme.breakpoints.down('desktop')]: {
              flexDirection: 'column-reverse',
              '& > :not(:first-of-type)': {
                margin: theme.spacing(0, 0, 2.5, 0),
              },
            },
          })}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Save
          </LoadingButton>

          {onCancel && (
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;

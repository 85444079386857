import React, { createContext, useEffect, useMemo } from 'react';
import { Conversation } from '@marageti/z4-sdk/lib/travel';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { SearchResult } from '@marageti/z4-sdk/lib/entity';
import { enqueueSnackbar } from 'notistack';
import { ProviderProps } from '../../types';
import { ValueProps } from './types';
import {
  setConversationsError,
  setConversationsLoading,
  setConversationsMap,
  setUnreadCount,
} from './actions';
import useConversationsReducer from './use-conversations-reducer';

export const ConversationsStore = createContext({} as ValueProps);

export const UNREAD_COUNT_PARAMS = { hasNewOnly: true, limit: 0 };

export const ConversationsProvider = ({ children, showUnreadOnly }: ProviderProps) => {
  const [conversationsState, conversationsDispatch] = useConversationsReducer();
  const apiClient = useApiClient();

  const params = {
    hasNewOnly: showUnreadOnly,
    page: 1,
    limit: 30,
  };

  const {
    data,
    error,
    isLoading,
    mutate,
  } = useFetch<SearchResult<Conversation>>(apiClient.conversationsClient.getParticipatingConversations, [{ params }], true);
  const {
    data: unreadData,
    error: unreadError,
    isLoading: unreadIsLoading,
    mutate: unreadMutate,
  } = useFetch<SearchResult<Conversation>>(apiClient.conversationsClient.getParticipatingConversations, [{ params: UNREAD_COUNT_PARAMS }], true);

  const refreshConversationList = () => {
    mutate();
    unreadMutate();
  };

  const markConversationRead = (id: string) => {
    apiClient.conversationsClient.putConversationRead(id)
      .then(() => {
        enqueueSnackbar('Marked conversation as read', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to mark conversation as read', { variant: 'default' });
      })
      .finally(() => {
        refreshConversationList();
      });
  };

  const markConversationUnread = (id: string) => {
    apiClient.conversationsClient.putConversationUnread(id)
      .then(() => {
        enqueueSnackbar('Marked conversation as unread', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to mark conversation as unread', { variant: 'default' });
      })
      .finally(() => {
        refreshConversationList();
      });
  };

  useEffect(() => {
    if (data?.hits) {
      const conversationsMap = data.hits.reduce((acc, cS) => ({
        ...acc,
        [cS.id]: cS,
      }), {});
      conversationsDispatch(setConversationsMap(conversationsMap));
    }
    if (unreadData) {
      conversationsDispatch(setUnreadCount(unreadData.totalHits));
    }
  }, [data, unreadData]);

  useEffect(() => {
    conversationsDispatch(setConversationsLoading(isLoading || unreadIsLoading));
    conversationsDispatch(setConversationsError(error || unreadError));
  }, [isLoading, unreadIsLoading, error, unreadError]);

  const value: ValueProps = useMemo(
    () => ({
      ...conversationsState,
      conversationsDispatch,
      markConversationRead,
      markConversationUnread,
      refreshConversationList,
    }),
    [conversationsState],
  );

  return (
    <ConversationsStore.Provider value={value}>
      {children}
    </ConversationsStore.Provider>
  );
};

import React, { useState } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { ErrorBox, ErrorTitle, ErrorMessage } from '@marageti/z4-lib';
import { GoogleLoginRequest } from '@marageti/z4-sdk/lib/auth/Token';

interface GoogleLoginViewProps {
  onSuccess: (d: GoogleLoginRequest) => void;
}

const GoogleLoginView = ({
  onSuccess,
}: GoogleLoginViewProps): React.ReactElement => {
  const [googleError, setGoogleError] = useState(false);

  const onFailure = (): void => {
    setGoogleError(true);
  };

  const handleSuccess = (googleUserToken: CredentialResponse): void => {
    setGoogleError(false);
    const loginRequest: GoogleLoginRequest = {
      googleToken: googleUserToken.credential || '',
    };
    onSuccess(loginRequest);
  };

  return (
    <>
      <GoogleLogin
        logo_alignment="left"
        onError={onFailure}
        onSuccess={handleSuccess}
        size="large"
        text="signin_with"
        width="333px"
      />
      {googleError && (
      <ErrorBox>
        <ErrorTitle>
          There was an error logging in to your Google account.
        </ErrorTitle>
        <ErrorMessage>
          Please check your credentials
        </ErrorMessage>
      </ErrorBox>
      )}
    </>
  );
};

export default GoogleLoginView;

import * as React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';
import Navigation from '../../components/navigation';
import Banners from '../../components/banners';

const defaultProps = {
  hideNav: false,
};

type DefaultProps = Partial<typeof defaultProps>;

type Props = {
  hideNav?: boolean;
} & DefaultProps;

const LayoutWrapper = ({
  hideNav = false,
}: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <ScrollRestoration />
    <Banners />
    {hideNav ? null : <Navigation />}
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Outlet />
    </Box>
  </Box>
);

export default LayoutWrapper;

import React, {
  useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Doc } from '@marageti/z4-sdk/lib/documents';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { DocumentType } from '@marageti/z4-sdk/lib/documents/Document';
import { useSnackbar } from 'notistack';
import DocsView from './docs-view';
import useDocumentDownload from '../../../hooks/use-document-download';
import { useAuthStore } from '../../../store';

const DocsContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loggedInAgent } = useAuthStore();
  const { tripMatchId } = useParams();
  const apiClient = useApiClient();
  const [onDownloadDocument, docError] = useDocumentDownload();
  const [openDialog, setOpenDialog] = useState('');

  const {
    data: docs,
    isLoading,
    mutate,
  } = useFetch<Doc[]>(apiClient.documentsClient.getDocuments, [{ params: { owner: tripMatchId, all: true } }], true);

  const [submissionError, setSubmissionError] = useState<ApiError>();
  const [docToDelete, setDocToDelete] = useState<Doc | null>(null);

  const onCancelDelete = () => {
    setDocToDelete(null);
    setSubmissionError(undefined);
  };

  const onClickDocument = (doc: Doc) => {
    if (doc.sourceURI) {
      window.open(doc.sourceURI, '_blank');
    } else {
      onDownloadDocument(doc);
    }
  };

  const {
    generalDocuments,
    sampleItineraryDocuments,
  } = useMemo(
    () => {
      const sortedDocuments = docs?.sort((a, b) => {
        // sort by date created
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;

        // if dates are equal, sort by title
        if (a.title > b.title) return 1;
        if (a.title < b.title) return -1;

        return 0;
      });

      return {
        generalDocuments: sortedDocuments?.filter((doc) => doc.documentType === DocumentType.DocumentGeneral),
        sampleItineraryDocuments: sortedDocuments?.filter((doc) => doc.documentType === DocumentType.DocumentSampleItinerary),
      };
    },
    [docs],
  );

  const onConfirmDelete = async () => {
    try {
      if (docToDelete?.id) await apiClient.documentsClient.deleteDocument(docToDelete.id);
      if (docToDelete?.documentType === DocumentType.DocumentSampleItinerary) {
        enqueueSnackbar('Itinerary removed.', { variant: 'success' });
      } else {
        enqueueSnackbar('Document removed.', { variant: 'success' });
      }
      mutate();
    } catch (err) {
      setSubmissionError(err as ApiError);
    } finally {
      setDocToDelete(null);
    }
  };

  const handleDelete = (doc: Doc) => {
    setDocToDelete(doc);
  };

  const closeDialog = () => setOpenDialog('');

  const refreshDocuments = () => {
    mutate();
  };

  return (
    <DocsView
      closeDialog={closeDialog}
      docs={generalDocuments as Doc[]}
      docError={docError}
      isLoading={isLoading}
      itineraries={sampleItineraryDocuments as Doc[]}
      docToDelete={docToDelete}
      handleDelete={handleDelete}
      onCancelDelete={onCancelDelete}
      onClickDocument={onClickDocument}
      onConfirmDelete={onConfirmDelete}
      openDialog={openDialog}
      refreshDocuments={refreshDocuments}
      setOpenDialog={setOpenDialog}
      submissionError={submissionError}
      tripMatchId={tripMatchId || ''}
      partnerId={loggedInAgent?.partnerId as string}
    />
  );
};

export default DocsContainer;

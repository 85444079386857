import React from 'react';
import { useRouteError } from 'react-router-dom';
import DefaultError from './DefaultError';
// React Router Dom recommends to replace the default error element to be returned.
// https://reactrouter.com/en/main/route/error-element#default-error-element
const ErrorBoundaryRouter = () => {
  const error = useRouteError();

  if (error) {
    console.error(error);
  }

  return <DefaultError />;
};

export default ErrorBoundaryRouter;

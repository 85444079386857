/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  useForm,
  getSubmitButtonProps,
  getFormProps,
  getFieldProps,
  CountrySelector,
  Inputs,
  FormElement,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel/Partner';
import { validateEmail } from '../../../../utils/helpers';
import MultiEmailInput from '../../../../components/multi-email-input';

const validateEmails = (emails: string[]) => {
  const invalidEmails = emails.filter((email) => !validateEmail(email));
  if (invalidEmails.length > 0) {
    return `Invalid email(s): ${invalidEmails.join(', ')}`;
  }
  return true;
};

const DEFAULT_VALUES = {
  name: '',
  emails: [],
  street: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
} as BillingInfoFormValues;

type BillingInfoFormValues = {
  name: string | undefined,
  emails: string[] | undefined,
  street: string | undefined,
  city: string | undefined,
  state: string | undefined,
  postalCode: string | undefined,
  country: string | undefined,
};

const schema = z.object({
  name: z.string().min(1, 'Please enter a company name'),
  emails: z.array(z.string()).min(1, 'Please add at least one email'),
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
});

type BillingInfoFormProps = {
  partner: Partner,
  onSubmit: (data: BillingInfoFormValues) => void,
  submissionError: boolean,
  onCancel?: () => void,
};

const BillingInfoForm = ({
  partner,
  onSubmit,
  submissionError,
  onCancel = undefined,
}: BillingInfoFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: {
      isSubmitting, errors, isValid,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: partner?.billingAddress?.name || '',
      emails: partner?.billingAddress?.emails || [],
      street: partner?.billingAddress?.street || '',
      city: partner?.billingAddress?.city || '',
      state: partner?.billingAddress?.state || '',
      postalCode: partner?.billingAddress?.postalCode || '',
      country: partner?.billingAddress?.country || '',
    } || DEFAULT_VALUES,
    resolver: zodResolver(schema),
  });

  const billingInfo = partner?.billingAddress;

  return (
    <Stack
      spacing={3}
    >
      <FormElement
        name="billing-info"
        {...getFormProps(handleSubmit(onSubmit))}
      >
        <Stack>
          <Typography variant="h2" sx={{ mb: 3 }}>Edit Billing Info</Typography>
          <TextField
            id="name"
            label="Company Name"
            InputLabelProps={{ required: true }}
            {...register('name')}
            {...getFieldProps('name', errors)}
          />
          <Controller
            name="emails"
            control={control}
            rules={{ validate: validateEmails }}
            render={({ field, fieldState }) => (
              <MultiEmailInput
                value={field.value || []}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                }}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : 'Invoices and billing notifications will be sent to this email(s).'}
                name="emails"
              />
            )}
          />
          <Inputs.Text
            defaultValue={billingInfo?.street}
            error={!!errors?.street}
            helperText={errors?.street?.message as string}
            id="street"
            label="Address"
            {...register('street')}
          />
          <Inputs.Text
            defaultValue={billingInfo?.city}
            error={!!errors?.city}
            helperText={errors?.city?.message as string}
            id="city"
            label="City"
            {...register('city')}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Inputs.Text
                defaultValue={billingInfo?.state}
                error={!!errors?.state}
                helperText={errors?.state?.message as string}
                id="state"
                label="State/Province"
                {...register('state')}
              />
            </Grid>
            <Grid item xs={6}>
              <Inputs.Text
                defaultValue={billingInfo?.postalCode}
                error={!!errors?.postalCode}
                helperText={errors?.postalCode?.message as string}
                id="postalCode"
                label="Postal/Zip Code"
                {...register('postalCode')}
              />
            </Grid>
          </Grid>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <CountrySelector
                {...field}
                {...getFieldProps('country', errors)}
                label="Country"
                onChange={(_: any, newValue: any) => {
                  field.onChange(newValue?.value || null);
                }}
              />
            )}
          />
          {submissionError && (
            <Alert severity="error">
              <AlertTitle>There was an error submitting the form.</AlertTitle>
              Please try again.
            </Alert>
          )}
          <Stack
            direction="row"
            spacing={2}
            sx={(theme) => ({
              marginTop: 3,
              [theme.breakpoints.down('desktop')]: {
                marginTop: 3,
                flexDirection: 'column-reverse',
                '& > :not(:first-of-type)': {
                  margin: theme.spacing(0, 0, 2.5, 0),
                },
              },
            })}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              {...getSubmitButtonProps(isSubmitting, isValid)}
            >
              Save
            </LoadingButton>
            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </FormElement>
    </Stack>
  );
};

export default BillingInfoForm;

import React, { useState, useMemo } from 'react';
import {
  TripMatch, SalesRecording, Partner,
} from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  DocumentType, DocumentRequest, MediaType, OwnerType,
} from '@marageti/z4-sdk/lib/documents/Document';
import { useSnackbar } from 'notistack';
import SalesAdjustmentFormView from './sales-adjustment-form-view';
import putS3Document from '../../utils/s3-documents';

const createDocumentRequest = (document: File) => ({
  mediaType: document.type as MediaType,
  documentType: DocumentType.DocumentItinerary,
  sourceFileName: document.name,
  title: document.name.replace(/\./g, ' '),
} as DocumentRequest);

type SalesAdjustmentFormContainerProps = {
  onCancel: () => void
  onSuccess: (createdUpdatedSalesRecordingId: string) => void
  salesAdjustment?: SalesRecording | undefined
  tripMatch: TripMatch
  tripMatchSalesRecordings: SalesRecording[]
};

const SalesAdjustmentFormContainer = ({
  onCancel,
  onSuccess,
  salesAdjustment = undefined,
  tripMatch,
  tripMatchSalesRecordings,
}: SalesAdjustmentFormContainerProps) => {
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const partner = useFetch<Partner>(apiClient.partnersClient.getPartner, [tripMatch.partnerId], !!tripMatch.partnerId);
  const generalSalesRecording = tripMatchSalesRecordings.find((sr) => sr.salesRecordingType === SalesRecordingType.General);
  // Remove empty strings from countries, very rare but it can happen
  const sanitizedTripMatch = {
    ...tripMatch,
    countries: tripMatch.countries.reduce((acc, str) => {
      if (str.trim() !== '') {
        acc.push(str);
      }
      return acc;
    }, [] as string[]),
  };

  const totalsOfSalesRecordings = useMemo(() => {
    if (!tripMatchSalesRecordings
      || !tripMatchSalesRecordings
      || tripMatchSalesRecordings.length === 0) {
      return {
        price: {
          amount: 0,
        },
        commission: {
          amount: 0,
        },
      };
    }

    return tripMatchSalesRecordings.reduce((acc, sr) => {
      // Don't include the current sales adjustment in the totals
      if (sr.id === salesAdjustment?.id) {
        return acc;
      }
      acc.price.amount += sr.price.amount;
      acc.commission.amount += sr.commission.amount;
      return acc;
    }, { price: { amount: 0 }, commission: { amount: 0 } });
  }, [tripMatchSalesRecordings]);

  const addSalesAdjustment = async (formValues: any) => {
    const file = formValues.document ? formValues.document[0] : undefined;
    const documentRequest = file ? createDocumentRequest(file) : undefined;

    // 1. Create Sales Adjustment
    // 2. Upload Document to S3 using preSignedURL
    const salesRecordingRequest = {
      tripRequestId: tripMatch.tripRequestId,
      tripMatchId: tripMatch.id,
      travelers: tripMatch.travelers,
      countries: formValues.countries,
      salesRecordingType: SalesRecordingType.Adjustment,
      price: formValues.price,
      commission: formValues.commission,
      priceComments: formValues.priceComments,
      document: documentRequest,
    };

    try {
      const response = await apiClient.tripMatchesClient.postTripMatchSalesRecording(tripMatch.id, salesRecordingRequest);
      if (documentRequest) {
        await putS3Document(response.preSignedURL.url, file);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSalesAdjustment = async (id: string, previousSalesRecording: SalesRecording, formValues: any) => {
    const file = formValues.document ? formValues.document[0] : undefined;
    const documentRequest = file ? createDocumentRequest(file) : undefined;

    // 1. Update Sales Adjustment
    // 2. Replace Document in S3 using preSignedURL
    const salesAdjustmentUpdate = {
      ...previousSalesRecording,
      priceComments: formValues.priceComments,
      price: { ...previousSalesRecording.price, ...formValues.price },
      commission: { ...previousSalesRecording.commission, ...formValues.commission },
    };

    const putSalesRecordingResponse = await apiClient.salesRecordingsClient.putSalesRecording(id, salesAdjustmentUpdate);

    if (documentRequest) {
      const putSalesRecordingDocumentResponse = await apiClient.salesRecordingsClient
        .putSalesRecordingDocument(previousSalesRecording.id, {
          ...documentRequest,
          owners: {
            [OwnerType.OwnerMatch]: tripMatch.id,
          },
        });

      const putS3DocumentResponse = await putS3Document(putSalesRecordingDocumentResponse.preSignedURL.url, file);
      return ([putSalesRecordingResponse, putS3DocumentResponse]);
    } if (previousSalesRecording.document) {
      const deleteSalesRecordingDocumentResponse = await apiClient.salesRecordingsClient.deleteSalesRecordingDocument(
        previousSalesRecording.id,
      );

      return ([putSalesRecordingResponse, deleteSalesRecordingDocumentResponse]);
    }

    return ([putSalesRecordingResponse]);
  };

  const updateTripMatch = (id: string, previousTripMatch: TripMatch, values: any) => {
    const tripMatchUpdate = {
      countries: values.countries,
      departureDate: values.departureDate,
      totalNights: values.totalNights,
      numberOfAdults: values.numberOfAdults,
      numberOfChildren: values.numberOfChildren,
    };

    return apiClient.tripMatchesClient.putTripMatch(id, {
      ...previousTripMatch,
      ...tripMatchUpdate,
    });
  };

  const handleSubmit = async (formValues: any) => {
    try {
      await updateTripMatch(tripMatch.id, tripMatch, formValues);
      // Create or Update Sales Adjustment
      if (!salesAdjustment) {
        const response = await addSalesAdjustment(formValues);
        enqueueSnackbar('Sales Adjustment added.', { variant: 'success' });
        onSuccess(response.salesRecording.id);
      } else {
        await updateSalesAdjustment(salesAdjustment.id, salesAdjustment, formValues);
        enqueueSnackbar('Sales Adjustment edited.', { variant: 'success' });
        onSuccess(salesAdjustment.id);
      }
    } catch (error) {
      setSubmissionError(true);
    }
  };

  return (
    <SalesAdjustmentFormView
      generalSalesRecording={generalSalesRecording}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      partnerCountries={partner.data?.countries}
      salesAdjustment={salesAdjustment}
      submissionError={submissionError}
      totalsOfSalesRecordings={totalsOfSalesRecordings}
      tripMatch={sanitizedTripMatch}
    />
  );
};

export default SalesAdjustmentFormContainer;

import * as PusherTypes from 'pusher-js';
import { Actions } from '../../types';

export const ADD_PUSHER_CHANNEL = 'ADD_PUSHER_CHANNEL';
export const addPusherChannel = (channelName: string, channel: PusherTypes.Channel): Actions => ({
  type: ADD_PUSHER_CHANNEL,
  channel,
  channelName,
});

export const REMOVE_PUSHER_CHANNEL = 'REMOVE_PUSHER_CHANNEL';
export const removePusherChannel = (channelName: string): Actions => ({
  type: REMOVE_PUSHER_CHANNEL,
  channelName,
});

export const SET_PUSHER_READY = 'SET_PUSHER_READY';
export const setPusherReady = (ready: boolean): Actions => ({
  type: SET_PUSHER_READY,
  ready,
});

export const SET_PUSHER_ERROR = 'SET_PUSHER_ERROR';
export const setPusherError = (error: string): Actions => ({
  type: SET_PUSHER_ERROR,
  error,
});

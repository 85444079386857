import React, { useState } from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';
import UsersView from './users-view';
import { useAuthStore } from '../../../store';
import { getMatchingUser } from '../../../utils/auth';

const UsersContainer = () => {
  const {
    loggedInAgent,
  } = useAuthStore();

  const apiClient = useApiClient();
  const partnerId = loggedInAgent?.partnerId;
  const {
    data: agents,
    isLoading,
    error,
  } = useFetch<Agent[]>(apiClient.partnersClient.getPartnerAgents, [partnerId], !!partnerId);
  const filteredList = agents?.filter((agent) => getMatchingUser(agent.email, agent.userInfo)?.status !== 'DISABLED') || [];

  const [showDisabled, setShowDisabled] = useState(false);

  const toggleShowDisabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDisabled(e.target.checked);
  };

  return (
    <UsersView
      agents={showDisabled ? agents || [] : filteredList}
      showDisabled={showDisabled}
      toggleShowDisabled={toggleShowDisabled}
      error={error}
      loading={isLoading}
    />
  );
};

export default UsersContainer;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { extractParams } from '@marageti/z4-lib';
import { useAuthStore } from '../../store';

const Logout = () => {
  const { removeAuthenticatedAgent } = useAuthStore();
  const { search } = useLocation();

  useEffect(() => {
    removeAuthenticatedAgent()
      .then(() => {
        const { redirect } = extractParams(search);
        window.location.href = `/login${redirect ? search : ''}`;
      });
  }, []);

  return null;
};

export default Logout;

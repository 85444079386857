import React from 'react';
import { ConversationLinkProps } from './ConversationLink';
import { ConversationLinksCardProps } from './ConversationLinksCard';
import TripMatchConversationLinks from './TripMatchConversationLinks';

export type ConversationLinksProps = (
  | {
    tripMatchId: string;
    renderConversationLink: ConversationLinksCardProps['renderConversationLink']
    getConversationTypeTitle?: ConversationLinkProps['getConversationTypeTitle']
  }
  | {
    tripMatchId?: never;
    renderConversationLink: ConversationLinksCardProps['renderConversationLink']
    getConversationTypeTitle?: ConversationLinkProps['getConversationTypeTitle']
  }
);

const ConversationLinks = ({
  tripMatchId,
  renderConversationLink,
  getConversationTypeTitle,
}: ConversationLinksProps) => {
  if (tripMatchId) {
    return (
      <TripMatchConversationLinks
        tripMatchId={tripMatchId}
        renderConversationLink={renderConversationLink}
        getConversationTypeTitle={getConversationTypeTitle}
      />
    );
  }

  return null;
};

export default ConversationLinks;

import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import config from '../config';

const useHotjar = () => {
  useEffect(() => {
    const siteId = config.HOTJAR_SITE_ID;
    const version = config.HOTJAR_VERSION;
    if (!siteId || !version) {
      return;
    }
    Hotjar.init(siteId, version);
  }, []);
};

export default useHotjar;

import React, { useEffect, useRef, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import {
  ChipOption,
  useApiClient,
  useFetch,
  InfiniteScroll,
} from '@marageti/z4-lib';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import {
  Partner,
} from '@marageti/z4-sdk/lib/travel';
import { SalesStage, TripMatchStatus } from '@marageti/z4-sdk/lib/travel/TripMatch';
import AllFilters from './all-filters';
import { useAuthStore } from '../../../store';
import AllTripsCard from '../../../components/trips-card/all-card';
import useTripMatchSearch from '../../../hooks/use-trip-match-search';
import useURLSearchQuery from '../../../hooks/use-url-for-search-query';

const canceledStatusesValue = 'CANCELED';

const sortByOptions: { title:string, value:string }[] = [
  { title: 'New to Old', value: 'createdAt' },
  { title: 'Total Budget', value: 'budget' },
  { title: 'Departure Date (Upcoming)', value: 'departureDateUpcoming' },
  { title: 'Departure Date (Past)', value: 'departureDatePast' },
  { title: 'Departure Date (All)', value: 'departureDateAll' },
];

const sortByValues: { [key: string]: { field: string; order: string } } = {
  createdAt: { field: 'createdAt', order: 'desc' },
  budget: { field: 'budget', order: 'desc' },
  departureDateUpcoming: { field: 'departureDate', order: 'asc' },
  departureDatePast: { field: 'departureDate', order: 'desc' },
  departureDateAll: { field: 'departureDate', order: 'asc' },
};

interface DefaultSearch extends Record<string, string | string[]> {
  fullText: string;
  countries: string[];
  salesStage: string[];
  status: string[];
  assignedTo: string;
  sort: string;
}

const defaultSearch: DefaultSearch = {
  fullText: '',
  countries: [],
  salesStage: [],
  status: [],
  assignedTo: 'all',
  sort: 'createdAt',
};

const TripsView = () => {
  const { loggedInAgent } = useAuthStore();
  const isAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN));
  const isSupportAgent = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_SUPPORT));

  // Fetch partner data for country filter, use the partner's country list
  const partner = useFetch<Partner>(
    useApiClient().partnersClient.getPartner,
    [loggedInAgent?.partnerId],
    !!loggedInAgent?.partnerId,
  );
  const pDestinations = partner?.data?.countries || [];
  const partnerCountryCodes = pDestinations.map((destination) => ({ country: destination.country }));
  const defaultStatusChips = [
    ...((isAdmin) ? ['PENDING'] : []),
    'ACCEPTED',
    'ACTIVE',
  ];

  const [queryValues, updateURLQuery] = useURLSearchQuery<DefaultSearch>({
    ...defaultSearch,
    status: defaultStatusChips,
    assignedTo: isAdmin || isSupportAgent ? 'all' : loggedInAgent?.id || '',
  });

  const {
    fullText: searchTerm,
    countries: selectedCountries,
    assignedTo: selectedAssignee,
    sort: sortBy,
    salesStage: selectedStageChips,
    status: selectedStatusChips,
  } = queryValues;

  /**
   * Values needed to insure the search input is refreshed when the search term changes via history navigation
   */
  const [refreshKey, setRefreshKey] = useState(0);
  const searchRef = useRef(searchTerm || '');
  useEffect(() => {
    if (searchTerm !== searchRef.current) {
      setRefreshKey((prev) => prev + 1);
    }
  }, [searchTerm, setRefreshKey]);

  const {
    tripMatches,
    error,
    hasMore,
    isLoading,
    loadMore,
    totalHits,
  } = useTripMatchSearch({
    fullText: searchTerm,
    countries: selectedCountries,
    salesStage: selectedStageChips,
    status: selectedStatusChips,
    assignedTo: selectedAssignee,
    sort: sortBy,
    order: sortByValues[sortBy as string]?.order || 'asc',
  });

  // Handle filter changes
  const onChangeFilter = (name: string, v: string | boolean) => {
    if (name === 'sortBy') {
      updateURLQuery({ sort: v as string });
    }
  };

  // Update destinations on change
  const onCountriesChange = (e: React.SyntheticEvent<Element, Event>, v: string[]) => {
    updateURLQuery({ countries: v });
  };

  // Update assignee on change
  const onAssigneeChange = (v: string) => {
    if (v === 'none') {
      updateURLQuery({
        assignedTo: v,
        status: [],
        salesStage: [],
        sort: 'createdAt',
      });
      return;
    }
    updateURLQuery({ assignedTo: v });
  };

  // Update chips on change
  const onChipChange = (chipType: string, selectedChips: string[]) => {
    if (chipType === 'stage') {
      updateURLQuery({
        status: selectedStatusChips,
        salesStage: selectedChips,
      });
    } else if (chipType === 'status') {
      const params = {
        status: selectedChips,
        salesStage: selectedStageChips,
      };
      if (!selectedChips.includes('active') && selectedStageChips && selectedStageChips.length > 0) {
        params.salesStage = [];
      }
      updateURLQuery(params);
    }
  };

  const onSearchChange = (v: string) => {
    updateURLQuery({ fullText: v });
    searchRef.current = v;
  };

  const trCount = totalHits as number || 0;

  const showAll = isAdmin && (selectedAssignee === 'all' || selectedAssignee === 'none');

  const statusChips: ChipOption[] = [
    ...((showAll) ? [{ key: 0, label: 'New', value: TripMatchStatus.MatchPending }] : []),
    { key: 1, label: 'Accepted', value: TripMatchStatus.MatchAccepted },
    { key: 2, label: 'Active', value: TripMatchStatus.MatchActive },
    { key: 3, label: 'Sold', value: TripMatchStatus.MatchSold },
    { key: 4, label: 'Postponed', value: TripMatchStatus.MatchPostponed },
    { key: 5, label: 'Inactive', value: TripMatchStatus.MatchInactive },
    { key: 6, label: 'Canceled', value: canceledStatusesValue },
  ];

  const saleStageChips: ChipOption[] = [
    { key: 0, label: 'Needs Follow Up', value: SalesStage.NeedsFollowUp },
    { key: 1, label: 'Needs Proposal', value: SalesStage.NeedsProposal },
    { key: 2, label: 'Proposal Sent', value: SalesStage.ProposalSent },
  ];

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          py: { desktop: 4, mobile: 2 },
        }}
      >
        <AllFilters
          // Search Props
          refreshKey={refreshKey}
          searchValue={searchTerm || ''}
          onSearchChange={onSearchChange}
          // Countries Props
          selectedCountries={selectedCountries || []}
          countryOptions={partnerCountryCodes}
          onCountriesChange={onCountriesChange}
          // Assignee Props
          selectedAssignee={selectedAssignee || ''}
          onAssigneeChange={onAssigneeChange}
          // Sort and Order Props
          sortBy={sortBy || ''}
          sortByOptions={sortByOptions}
          onOrderSortChange={onChangeFilter}
          // Chip Props
          statusChips={statusChips}
          selectedStatusChips={selectedStatusChips || []}
          stageChips={saleStageChips}
          selectedStageChips={selectedStageChips || []}
          onChipChange={onChipChange}
        />
        { (!isLoading) && (
        <Typography variant="bodyBold" sx={{ mb: 2 }}>
          {`${trCount === 10000 ? `${trCount}+` : trCount} ${trCount === 1 ? 'Result' : 'Results'}`}
        </Typography>
        )}
        <InfiniteScroll
          error={error}
          hasMoreItems={hasMore}
          isLoading={isLoading}
          loadMoreItems={loadMore}
        >
          {tripMatches?.map((tripMatch) => (
            <AllTripsCard
              key={tripMatch.id}
              tripMatch={tripMatch}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </Container>
  );
};

export default TripsView;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import SalesTable from './table';
import ZSpinner from '../../../components/z-lib/z-spinner';

type Props = {
  isLoading: boolean,
  sales: SalesRecording[] | undefined,
};

const TotalAndTable = ({
  isLoading,
  sales,
}: Props) => {
  const totalSales = useMemo(() => {
    if (sales && sales.length > 0) {
      const ts = sales.reduce((total, hit) => hit.price.amount + total, 0);
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: sales[0].price.currencyCode }).format(ts);
    }
    return undefined;
  }, [sales]);

  const totalCommission = useMemo(() => {
    if (sales && sales.length > 0) {
      const tc = sales.reduce((total, hit) => hit.commission.amount + total, 0);
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: sales[0].commission.currencyCode }).format(tc);
    }
    return undefined;
  }, [sales]);

  // No sales recordings but ineligible for Confirm Deposit
  if (!isLoading && sales?.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="body" color="primary.light" textAlign="center">
            There are no sales recordings.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Stack direction={{ desktop: 'row' }} spacing={2.5}>
        <Card sx={{ flex: 1, mb: { mobile: 3, desktop: 'none' } }}>
          {isLoading ? (
            <Skeleton variant="rounded" height={116} />
          ) : (
            <>
              <CardHeader title={totalSales} titleTypographyProps={{ variant: 'display' }} />
              <CardContent sx={{ pt: 0 }}>
                <Typography variant="caption">
                  Total Trip Price (
                  {sales?.[0]?.price.currencyCode}
                  )
                </Typography>
              </CardContent>
            </>
          )}
        </Card>
        <Card sx={{ flex: 1 }}>
          {isLoading ? (
            <Skeleton variant="rounded" height={116} />
          ) : (
            <>
              <CardHeader title={totalCommission} titleTypographyProps={{ variant: 'display' }} />
              <CardContent sx={{ pt: 0 }}>
                <Typography variant="caption">
                  Total Zicasso Commission (
                  {sales?.[0]?.commission.currencyCode}
                  )
                </Typography>
              </CardContent>
            </>
          )}
        </Card>
      </Stack>
      {isLoading ? (
        <Stack>
          <ZSpinner />
        </Stack>
      ) : (
        <SalesTable
          sales={sales || []}
          totalCommission={totalCommission}
          totalSales={totalSales}
        />
      )}
    </>
  );
};

export default TotalAndTable;

import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import Form from './form';

type RepeatReferralFormViewProps = {
  repeatReferral?: SalesRecording | undefined
  agents: {
    title: string,
    value: string
  }[] | undefined
  defaultAgentId: string | undefined
  handleSubmit: (data: any) => void
  submissionError: boolean
  onCancel: () => void
};

const RepeatReferralFormView = ({
  repeatReferral = undefined,
  agents,
  defaultAgentId,
  handleSubmit,
  submissionError,
  onCancel,
}:RepeatReferralFormViewProps) => {
  const formTitle = repeatReferral ? 'Edit Repeat / Referral Sale' : 'New Repeat / Referral Sale';

  return (
    <Stack
      spacing={2}
      width="100%"
    >
      <Typography variant="h2">{formTitle}</Typography>
      <Form
        defaultValues={{
          salesRecordingType: repeatReferral?.salesRecordingType,
          agent: repeatReferral?.assignedTo || defaultAgentId,
          givenName: repeatReferral?.travelers?.[0]?.givenName,
          familyName: repeatReferral?.travelers?.[0]?.familyName,
          price: {
            currencyCode: repeatReferral?.price.currencyCode || 'USD',
            amount: repeatReferral?.price.amount,
          },
          commission: {
            currencyCode: repeatReferral?.commission.currencyCode || 'USD',
            amount: repeatReferral?.commission.amount,
          },
          priceComments: repeatReferral?.priceComments,
        }}
        agents={agents}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submissionError={submissionError}
        readOnlyCurrency={!!repeatReferral}
      />
    </Stack>
  );
};

export default RepeatReferralFormView;

import React, { useState } from 'react';
import { useReviews, useReviewsHistogram } from '@marageti/z4-lib';
import UseInfiniteScroll from 'react-infinite-scroll-hook';
import ReviewsView from './reviews-view';
import { useAuthStore } from '../../store';

const ReviewsContainer = () => {
  const { loggedInAgent } = useAuthStore();
  const [selectedRatingFilter, setSelectedRatingFilter] = useState<number | undefined>();
  const { histogramAggregation, loading: loadingHistogram, error: errorHistogram } = useReviewsHistogram({ partnerId: loggedInAgent?.partnerId });
  const {
    reviews, loading: loadingReviews, error: errorReviews, loadMore: loadMoreReviews, hasNextPage,
  } = useReviews({
    searchItemId: loggedInAgent?.partnerId, searchItemFieldName: 'partnerId', rating: selectedRatingFilter, itemsPerPage: 30,
  });

  const [sentryRef] = UseInfiniteScroll({
    disabled: !!errorReviews || !!errorHistogram,
    loading: loadingReviews,
    hasNextPage,
    onLoadMore: loadMoreReviews,
  });

  const handleRatingHistogramOnSelect = (newRatingFilter: number) => {
    setSelectedRatingFilter((previousRatingFilter: number | undefined) => (previousRatingFilter === newRatingFilter ? undefined : newRatingFilter));
  };

  const clearFilter = () => (setSelectedRatingFilter(undefined));

  return (
    <ReviewsView
      histogramAggregation={histogramAggregation}
      loadingHistogram={loadingHistogram}
      errorHistogram={errorHistogram}
      onHistogramSelect={handleRatingHistogramOnSelect}
      reviews={reviews}
      errorReviews={errorReviews || errorHistogram}
      sentryRef={sentryRef}
      hasNextPage={hasNextPage}
      selectedRatingFilter={selectedRatingFilter}
      clearFilter={clearFilter}
    />
  );
};

export default ReviewsContainer;

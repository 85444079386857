import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  Stack,
} from '@mui/material';
import {
  ConversationSummary,
} from '@marageti/z4-sdk/lib/travel';
import ConversationLink, { ConversationLinkProps } from './ConversationLink';
import { ConversationLinkMap, ConversationSummaryDetails, HybridConversationLink } from './types';

type RenderConversationLink = ({
  conversationSummary,
  tripMatchId,
}: {
  conversationSummary: ConversationSummary,
  tripMatchId?: string;
}) => string;

export type ConversationLinksCardProps = {
  title?: string;
  conversationSummaries: ConversationLinkMap
  tripMatchId?: string;
  getConversationTypeTitle: ConversationLinkProps['getConversationTypeTitle']
  renderConversationLink: RenderConversationLink
};

const getConversationInContext = (
  conversationSummaryDetails: HybridConversationLink,
  tripMatchId: string | undefined,
  renderConversationLink: RenderConversationLink,
): ConversationSummaryDetails => ({
  to: renderConversationLink({
    conversationSummary: conversationSummaryDetails.defaultContext,
    tripMatchId,
  }),
  conversationSummary: conversationSummaryDetails.defaultContext,
  badgeColor: 'info',
});

const ConversationLinksCard = ({
  title,
  conversationSummaries,
  tripMatchId,
  getConversationTypeTitle,
  renderConversationLink,
}: ConversationLinksCardProps) => {
  const conversationLinksToRender = Object.entries(conversationSummaries).map(
    ([, conversationSummaryDetails]) => getConversationInContext(
      conversationSummaryDetails,
      tripMatchId,
      renderConversationLink,
    ),
  );

  if (!conversationLinksToRender.length) {
    return null;
  }

  return (
    <Card>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: 'bodyBold' }}
        />
      )}
      <CardContent>
        <List component={Stack} spacing={2} disablePadding>
          {conversationLinksToRender.map(({ to, badgeColor, conversationSummary }) => (
            <ConversationLink
              key={conversationSummary.conversationId}
              to={to}
              badgeColor={badgeColor}
              conversationSummary={conversationSummary}
              getConversationTypeTitle={getConversationTypeTitle}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ConversationLinksCard;

import * as React from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { TripMatch } from '@marageti/z4-sdk/lib/travel/TripMatch';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

enum D7TripType {
  TRIP_MATCH = 'tm',
  TRIP_REQUEST = 'tr',
}

const D7TripRedirectWrapper = () => {
  const [searchParams] = useSearchParams();
  const apiClient = useApiClient();

  const d7trip = searchParams.get('d7trip');
  const d7id = searchParams.get('d7id');

  const { data: trip, isLoading } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [d7id], !!d7trip && !!d7id);

  if (trip?.referenceId === d7id && d7trip === D7TripType.TRIP_MATCH) {
    return <Navigate to={`/trip-matches/${trip.id}`} replace />;
  }

  if (d7trip && d7id && !isLoading) { // No trip found or Unauthorized to access trip
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default D7TripRedirectWrapper;

import React from 'react';
import { TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import {
  ContextMenuComplete,
  useApiClient,
  useFetch,
  TravelerTagChips,
  TripMatchChips,
  OtherChips,
  BackButton,
} from '@marageti/z4-lib';
import { useParams } from 'react-router-dom';
import { Skeleton, Stack } from '@mui/material';
import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import { ReferralType } from '@marageti/z4-sdk/lib/travel/TripRequest';
import SubNavigation from '../sub-navigation';
import { ViewProps } from './types';
import { useStandardsStore } from '../../store';
import { formatCountryCodes } from '../../utils/helpers';

const { ChipVIP } = TravelerTagChips;
const { ChipRepeat, ChipPRL } = OtherChips;
const { getTripMatchStatusChip } = TripMatchChips;

const tagIcons = {
  VIP: <ChipVIP />,
  RPT: <ChipRepeat />,
  PRL: <ChipPRL />,
};

const TripSecondaryAppbarView = ({
  tabs,
  onClickPostpone,
  onClickInactive,
}: ViewProps) => {
  const apiClient = useApiClient();
  const { tripMatchId } = useParams<{ tripMatchId: string }>();
  const { countryCodes } = useStandardsStore();

  const {
    data: tripMatch,
  } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], true);

  const traveler = tripMatch?.travelers[0];
  const travelerName = traveler && traveler.fullName;
  const countries = tripMatch && countryCodes ? formatCountryCodes(tripMatch.countries, countryCodes) : <Skeleton width={100} />;
  const getContextMenuItems = () => {
    const items = [];

    if (tripMatch?.status && [TripMatchStatus.MatchActive].includes(tripMatch.status)) {
      items.push({
        action: onClickPostpone,
        label: 'Postpone',
      });
    }

    if (tripMatch?.status && [TripMatchStatus.MatchActive].includes(tripMatch.status)) {
      items.push({
        action: onClickInactive,
        label: 'Mark as Inactive',
      });
    }

    return items;
  };

  const contextMenuItems = getContextMenuItems();

  return (
    <SubNavigation
      tabs={tabs}
      profile={{
        primary: travelerName ? `${travelerName}'s Trip` : <Skeleton width={250} />,
        secondary: countries,
        decoration: (
          <Stack direction="row" spacing={0.75}>
            {tripMatch?.status ? getTripMatchStatusChip(tripMatch?.status, 'small') : null}
            {tripMatch?.travelers[0]?.tags?.includes(TravelerTags.VIP) ? tagIcons.VIP : null}
            {tripMatch?.repeatTraveler ? tagIcons.RPT : null}
            {tripMatch?.referralType === (ReferralType.OneTimePartnerReferral || ReferralType.LifetimePartnerReferral) ? tagIcons.PRL : null}
          </Stack>
        ),
      }}
      startAdornment={(
        <BackButton
          aria-label="back to trip matches"
          defaultPath="/trip-matches"
          edge="start"
        />
      )}
      endAdornment={contextMenuItems.length > 0 && (
        <ContextMenuComplete
          id="trip-match-context-menu"
          items={getContextMenuItems()}
        />
      )}
    />
  );
};

export default TripSecondaryAppbarView;

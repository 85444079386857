/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
  InputPhone,
  Validate,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { Phone } from '@marageti/z4-sdk/lib/standards';

type EditFormDefaultValues = {
  name: string | undefined,
  yearFounded: string | undefined,
  usPhone: Phone | undefined,
  primaryPhone: Phone | undefined,
  email: string | undefined,
  website: string | undefined,
};

const DEFAULT_VALUES = {
  name: '',
  yearFounded: '',
  usPhone: undefined,
  primaryPhone: undefined,
  email: '',
  website: '',
} as EditFormDefaultValues;

const schema = z.object({
  name: z.string().min(1, 'Required'),
  yearFounded: z.string().min(1, 'Required').refine((val) => {
    const req = /^[0-9]+$/;
    return (req.test(val) && val.length === 4);
  }, 'Please enter a valid year'),
  usPhone: z.object({
    prefix: z.string(),
    number: z.string(),
  }).refine((value) => {
    if (!value.number) return true;
    return Validate.phone(`${value.prefix}${value.number}`);
  }, 'Invalid phone number'),
  primaryPhone: z.object({
    prefix: z.string(),
    number: z.string(),
  }).refine((value) => {
    if (!value.number) return true;
    return Validate.phone(`${value.prefix}${value.number}`);
  }, 'Invalid phone number'),
  email: z.string().email().min(1, 'Required'),
  website: z.string().refine((val) => {
    if (val.length === 0) return true;
    return Validate.url(val);
  }, 'Invalid Url'),
});

type EditFormProps = {
  partner: Partner
  onSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
};

const EditForm = ({
  partner,
  onSubmit,
  submissionError,
  onCancel = undefined,
}:EditFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    control,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: partner.name,
      yearFounded: partner.yearFounded?.toString(),
      usPhone: partner.usPhone,
      primaryPhone: partner.primaryPhone,
      email: partner.email,
      website: partner.website,
    } || DEFAULT_VALUES,
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Edit Info</Typography>
      <form {...getFormProps(handleSubmit(onSubmit))}>
        <Stack spacing={3}>
          <Stack>
            <TextField
              label="Name"
              {...register('name')}
              {...getFieldProps('name', errors)}
            />
            <TextField
              label="Founded"
              {...register('yearFounded')}
              {...getFieldProps('yearFounded', errors)}
            />
            <Controller
              control={control}
              name="usPhone"
              defaultValue={partner.usPhone}
              render={({ field }) => (
                <InputPhone
                  id="usPhone"
                  label="US Phone"
                  {...getFieldProps('usPhone', errors)}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="primaryPhone"
              defaultValue={partner.primaryPhone}
              render={({ field }) => (
                <InputPhone
                  id="primaryPhone"
                  label="Primary Phone"
                  {...getFieldProps('primaryPhone', errors)}
                  {...field}
                />
              )}
            />
            <TextField
              label="Email"
              {...register('email')}
              {...getFieldProps('email', errors)}
            />
            <TextField
              label="Website"
              {...register('website')}
              {...getFieldProps('website', errors)}
            />
          </Stack>

          {submissionError && (
            <Alert severity="error">
              <AlertTitle>There was an error submitting the form.</AlertTitle>
              Please try again.
            </Alert>
          )}

          <Stack
            direction="row"
            spacing={2}
            sx={(theme) => ({
              [theme.breakpoints.down('desktop')]: {
                marginTop: 'auto',
                flexDirection: 'column-reverse',
                '& > :not(:first-of-type)': {
                  margin: theme.spacing(0, 0, 2.5, 0),
                },
              },
            })}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              {...getSubmitButtonProps(isSubmitting, isValid)}
            >
              Save
            </LoadingButton>

            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default EditForm;

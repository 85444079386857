interface Config {
  PARTNERS_URL: string;
  Z3_URL: string;
  GOOGLE_CLIENT_ID: string;
  PUSHER_APP_KEY: string;
  PUSHER_APP_CLUSTER: string;
  HOTJAR_SITE_ID?: number;
  HOTJAR_VERSION?: number;
}

const dev: Config = {
  PARTNERS_URL: 'http://localhost:3000/',
  Z3_URL: 'https://www-staging.zicasso.com/',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
  PUSHER_APP_KEY: '06182979eb72c66e3ee0',
  PUSHER_APP_CLUSTER: 'us3',
};

const staging: Config = {
  PARTNERS_URL: 'https://partners-staging.zicasso.com/',
  Z3_URL: 'https://www-staging.zicasso.com/',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
  PUSHER_APP_KEY: '06182979eb72c66e3ee0',
  PUSHER_APP_CLUSTER: 'us3',
  HOTJAR_SITE_ID: 3744985,
  HOTJAR_VERSION: 6,
};

const qa: Config = {
  PARTNERS_URL: 'https://partners-qa.zicasso.com/',
  Z3_URL: 'https://www-qa.zicasso.com/',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
  PUSHER_APP_KEY: '06182979eb72c66e3ee0',
  PUSHER_APP_CLUSTER: 'us3',
};

const prod: Config = {
  PARTNERS_URL: 'https://partners.zicasso.com/',
  Z3_URL: 'https://www.zicasso.com/',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
  PUSHER_APP_KEY: '88f77513b2a5e074a7e5',
  PUSHER_APP_CLUSTER: 'us3',
  HOTJAR_SITE_ID: 3729916,
  HOTJAR_VERSION: 6,
};

export const z3ApiEndpoint: { [key: string]: string } = {
  dev: 'https://api-staging.zicasso.com',
  prod: 'https://api.zicasso.com',
  production: 'https://api.zicasso.com',
  qa: 'https://api-qa.zicasso.com',
  staging: 'https://api-staging.zicasso.com',
  local: 'http://api-staging.zicasso.com',
};

export const z3Endpoint: { [key: string]: string } = {
  dev: 'https://www-staging.zicasso.com',
  prod: 'https://www.zicasso.com',
  production: 'https://www.zicasso.com',
  qa: 'https://www-qa.zicasso.com',
  staging: 'https://www-staging.zicasso.com',
  local: 'http://www-staging.zicasso.com',
};

// eslint-disable-next-line import/no-mutable-exports
let config: Config;
if (process.env.REACT_APP_STAGE === 'prod') {
  config = { ...prod };
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = { ...staging };
} else if (process.env.REACT_APP_STAGE === 'qa') {
  config = { ...qa };
} else {
  config = { ...dev };
}

export default config;

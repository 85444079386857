import { Theme } from '@mui/material';
import { StyleSheet } from '../../../utils/style/types';

const styles = (theme: Theme): StyleSheet => ({
  avatarBox: {
    display: 'flex',
    margin: '0 auto 24px auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridWrapper: {
    maxWidth: '1116px',
    minHeight: {
      desktop: 'calc(100vh - 298px)',
    },
    flexDirection: {
      desktop: 'column',
      mobile: 'column',
    },
  },
  profileCard: {
    border: 'none',
    boxShadow: 'none',
    margin: '0 auto',
    padding: '0',
    width: '100%',
  },
  profileButtonLink: {
    textDecoration: 'none',
    width: '100%',
  },
  dividerDesktop: {
    borderColor: theme.palette.divider,
    display: {
      desktop: 'flex',
      mobile: 'none',
    },
  },
  dividerMobile: {
    borderColor: theme.palette.divider,
    display: {
      desktop: 'none',
      mobile: 'flex',
    },
  },
});

export default styles;

import React from 'react';

const DefaultError = () => (
  <div style={{ padding: '20px', textAlign: 'center' }}>
    <h1>Something went wrong.</h1>
    <p>We&apos;re sorry, but there was an issue loading this page.</p>
    <p>Please try refreshing the page, or contact the support team if the issue persists.</p>
    <button type="button" onClick={() => window.location.reload()}>Refresh Page</button>
  </div>
);

export default DefaultError;

import {
  Box, Container, Alert, AlertTitle,
} from '@mui/material';
import React from 'react';
import { BackButton } from '@marageti/z4-lib';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import RepeatReferralForm from '../../../components/repeat-referral-form';

type RepeatReferralViewProps = {
  isLoading: boolean
  isError: boolean
  repeatReferral: SalesRecording | undefined
  onSuccess: (createdUpdatedRepeatReferralId: string) => void
  onCancel: () => void
};

const RepeatReferralView = ({
  isLoading,
  isError,
  repeatReferral,
  onSuccess,
  onCancel,
}: RepeatReferralViewProps) => (
  <Box
    py={{
      mobile: 3,
      desktop: 6,
    }}
  >
    <Container maxWidth="md">
      <BackButton
        defaultPath="../"
        edge="start"
        sx={{
          mb: 2,
        }}
      />
      {isLoading && null}

      {!isLoading && isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          There was an error getting the data. Please try again later.
        </Alert>
      )}

      {!isLoading && !isError && (
        <RepeatReferralForm
          repeatReferral={repeatReferral}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      )}
    </Container>
  </Box>
);

export default RepeatReferralView;

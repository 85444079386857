import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch, useApiClient } from '@marageti/z4-lib';
import { Template, TemplateType } from '@marageti/z4-sdk/lib/templates/Template';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import TemplatesView from './templates-view';

const TemplatesContainer = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const { data: templates, mutate } = useFetch<Template[]>(apiClient.templatesClient.getTemplates, [], true);
  const templatesByType = useMemo(() => ({
    [TemplateType.PersonalTemplate]: templates?.filter((t) => t.templateType === TemplateType.PersonalTemplate) || [],
    [TemplateType.PartnerTemplate]: templates?.filter((t) => t.templateType === TemplateType.PartnerTemplate) || [],
  }), [templates]);

  const handleAdd = (templateType: TemplateType) => navigate(`add?type=${templateType}`);
  const handleEdit = (template: Template) => navigate(`${template.id}`);

  const handleRemove = (id: string, type: TemplateType) => {
    confirm({
      title: type === TemplateType.PersonalTemplate ? 'Remove Template' : 'Remove Team Template',
      description: type === TemplateType.PersonalTemplate
        ? 'This will permanently remove this template. This action cannot be undone.'
        : 'This will permanently remove this template for your entire team. This action cannot be undone.',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: 'Remove',
      dialogProps: {
        PaperProps: {
          sx: {
            maxWidth: '370px',
          },
        },
      },
    })
      .then(() => apiClient.templatesClient.deleteTemplate(id))
      .then(() => {
        mutate();
        enqueueSnackbar('Template removed successfully.', { variant: 'default' });
      })
      .catch((e: ApiError) => {
        if (e !== undefined) enqueueSnackbar('There was an error removing this template.', { variant: 'default' });
      });
  };

  return (
    <TemplatesView
      onClickAdd={handleAdd}
      onClickEdit={handleEdit}
      onClickRemove={handleRemove}
      templates={templatesByType}
    />
  );
};

export default TemplatesContainer;

import React, { useEffect, useRef, useState } from 'react';
import {
  useApiClient,
  useFetch,
  ChipOption,
} from '@marageti/z4-lib';
import { SelectChangeEvent } from '@mui/material';
import { SalesRecording, SalesRecordingStatus } from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { FilterConstraint, FilterOp } from '@marageti/z4-sdk/lib/entity/Search';
import { SearchResult, SearchRequest } from '@marageti/z4-sdk/lib/entity';
import { format, isValid, sub } from 'date-fns';
import AllSalesView, { Option } from './all-sales-view';
import { useAuthStore } from '../../../store';
import useURLSearchQuery from '../../../hooks/use-url-for-search-query';

const today = new Date();
const todayMinusTwoMonths = sub(today, { months: 2 });

const sortByOptions: Option[] = [
  { title: 'Recording Date', value: 'createdAt' },
  { title: 'Total Price', value: 'price.amount' },
];

const dateOrderByOptions: Option[] = [
  { title: 'New to Old', value: 'desc' },
  { title: 'Old to New', value: 'asc' },
];

const priceOrderByOptions: Option[] = [
  { title: 'High to Low', value: 'desc' },
  { title: 'Low to High', value: 'asc' },
];

const typeValues: any = {
  standardSale: [SalesRecordingType.General],
  repeat: [SalesRecordingType.PartnerRepeat],
  referral: [SalesRecordingType.TravelerReferral],
  adjustment: [SalesRecordingType.Adjustment],
  other: [SalesRecordingType.PlanningFee, SalesRecordingType.CommissionAfterTravel, SalesRecordingType.RefundCredit],
};

const statusValues: any = {
  invoiced: [SalesRecordingStatus.InvoicedStatus],
  uninvoiced: [SalesRecordingStatus.SoldStatus, SalesRecordingStatus.VerifiedStatus],
};

const chipTypeOptions: ChipOption[] = [
  { key: 0, label: 'Standard Sale', value: 'standardSale' },
  { key: 1, label: 'Repeat', value: 'repeat' },
  { key: 2, label: 'Referral', value: 'referral' },
  { key: 3, label: 'Adjustment', value: 'adjustment' },
  { key: 4, label: 'Other', value: 'other' },
];

const chipStatusOptions: ChipOption[] = [
  { key: 0, label: 'Invoiced', value: 'invoiced' },
  { key: 1, label: 'Uninvoiced', value: 'uninvoiced' },
];

interface DefaultSearch extends Record<string, string | string[]> {
  endDate: string;
  fullText: string;
  order: string;
  salesRecordingType: string[];
  sort: string;
  startDate: string;
  status: string[];
  assignedTo: string;
}

const defaultSearch: DefaultSearch = {
  endDate: format(today, 'yyyy-MM-dd'),
  fullText: '',
  assignedTo: 'all',
  order: dateOrderByOptions[0].value,
  salesRecordingType: [],
  sort: sortByOptions[0].value,
  startDate: format(todayMinusTwoMonths, 'yyyy-MM-dd'),
  status: [],
};

const MonthlySalesContainer = () => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const [queryValues, updateURLQuery] = useURLSearchQuery<DefaultSearch>(defaultSearch);
  const {
    endDate: endDay,
    fullText: searchTerm,
    order: orderBy,
    salesRecordingType: selectedTypeChips,
    sort: sortBy,
    startDate: startDay,
    status: selectedStatusChips,
    assignedTo,
  } = queryValues;

  /**
   * Values needed to insure the search input is refreshed when the search term changes via history navigation
   */
  const [refreshKey, setRefreshKey] = useState(0);
  const searchRef = useRef(searchTerm || '');
  useEffect(() => {
    if (searchTerm !== searchRef.current) {
      setRefreshKey((prev) => prev + 1);
    }
  }, [searchTerm, setRefreshKey]);

  const [orderByOptions, setOrderByOptions] = useState<Option[]>(dateOrderByOptions);

  const formattedDate = format(today, 'yyyy-MM-dd');

  const getSearchRequest = ():SearchRequest => {
    const startDate = startDay ? format(new Date(startDay), 'yyyy-MM-dd') : formattedDate;
    const endDate = endDay ? format(new Date(endDay), 'yyyy-MM-dd') : undefined;
    // Build date filter
    const dateFilter:FilterConstraint[] = [];
    if (startDate) {
      dateFilter.push({
        field: 'createdAt',
        op: FilterOp.FilterOpLTE,
        value: [`${endDate}||/d`],
      });
    }
    if (endDate) {
      dateFilter.push({
        field: 'createdAt',
        op: FilterOp.FilterOpGTE,
        value: [`${startDate}||/d`],
      });
    }
    // Build agent filter
    const agentFilter:FilterConstraint[] = [];
    if (assignedTo !== 'all') {
      agentFilter.push({
        field: 'assignedTo',
        value: [assignedTo || ''],
      });
    }

    // Build type filter values
    const typeFilterValues:string[] = [];
    selectedTypeChips!.forEach((chip) => {
      if (typeValues[chip]) {
        typeFilterValues.push(...typeValues[chip]);
      }
    });

    const statusFilterValues:string[] = [];
    selectedStatusChips!.forEach((chip) => {
      if (statusValues[chip]) {
        statusFilterValues.push(...statusValues[chip]);
      }
    });

    const filterBy:FilterConstraint[] = [
      {
        field: 'partnerId',
        value: [loggedInAgent?.partnerId!],
      },
      ...dateFilter,
      ...agentFilter,
    ];

    // Add type filter if type is selected
    if (typeFilterValues.length > 0) {
      filterBy.push({ field: 'salesRecordingType', value: typeFilterValues });
    }

    // Add status filter if status is selected
    if (statusFilterValues.length > 0) {
      filterBy.push({ field: 'status', value: statusFilterValues });
    }

    return {
      filterBy,
      sortBy: [
        { field: sortBy!, order: orderBy! },
      ],
      fullText: searchTerm?.length ? searchTerm : undefined,
      pagination: { limit: 10000, page: 1 },
    };
  };

  const searchRequest = getSearchRequest();
  const { data: salesRecordings, isLoading } = useFetch<SearchResult<SalesRecording>>(apiClient.salesRecordingsClient.postSalesRecordingSearch, [searchRequest], !!loggedInAgent?.partnerId);

  const onChangeStartDay = (value: Date | null) => {
    // Check if the value is a valid Date object or null
    if (value === null || isValid(value)) {
      updateURLQuery({ startDate: (value || today).toString() });
    } else {
      updateURLQuery({ startDate: todayMinusTwoMonths.toString() });
    }
  };

  const onChangeEndDay = (value: Date | null) => {
    // Check if the value is a valid Date object or null
    if (value === null || isValid(value)) {
      const formattedEndDay = value ? value.toString() : '';
      updateURLQuery({ endDate: formattedEndDay });
    } else {
      updateURLQuery({ endDate: today.toString() });
    }
  };

  const onChangeAgent = (value: string) => {
    updateURLQuery({ assignedTo: value });
  };

  const onChangeSortBy = (event: SelectChangeEvent) => {
    const v = event.target.value;
    const newSortBy = sortByOptions.find((o) => o.value === v) || sortByOptions[0];
    updateURLQuery({ sort: newSortBy.value });

    // Update orderByOptions based on selected sortBy
    if (newSortBy.value === 'price.amount') {
      setOrderByOptions(priceOrderByOptions);
    } else {
      setOrderByOptions(dateOrderByOptions);
    }
  };

  const onChangeOrderBy = (event: SelectChangeEvent) => {
    const v = event.target.value;
    updateURLQuery({ order: v });
  };

  const onChipsChange = (value: string[], type: 'status' | 'type') => {
    if (type === 'status') {
      updateURLQuery({ status: value });
    }
    if (type === 'type') {
      updateURLQuery({ salesRecordingType: value });
    }
  };

  const onSearchChange = (newValue: string) => {
    updateURLQuery({ fullText: newValue });
    searchRef.current = newValue;
  };

  return (
    <AllSalesView
      chipTypeOptions={chipTypeOptions}
      chipStatusOptions={chipStatusOptions}
      handleSearchInputChange={onSearchChange}
      isLoading={isLoading}
      onChangeAgent={onChangeAgent}
      onChangeEndDay={onChangeEndDay}
      onChangeOrderBy={onChangeOrderBy}
      onChangeSortBy={onChangeSortBy}
      onChangeStartDay={onChangeStartDay}
      onChipsChange={onChipsChange}
      orderBy={orderBy || 'desc'}
      orderByOptions={orderByOptions}
      refreshKey={refreshKey}
      sales={salesRecordings?.hits}
      searchTerm={searchTerm || ''}
      selectedAgent={assignedTo || ''}
      selectedEndDay={new Date(endDay!)}
      selectedStartDay={new Date(startDay!)}
      selectedTypeChips={selectedTypeChips || []}
      selectedStatusChips={selectedStatusChips || []}
      sortBy={sortBy || 'createdAt'}
      sortByOptions={sortByOptions}
    />
  );
};

export default MonthlySalesContainer;

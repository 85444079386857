import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useApiClient } from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import MyProfileFormView from './security-form-view';
import { useAuthStore } from '../../store';

const SecurityFormContainer = ({
  onSuccessfulPasswordChange,
}:{
  onSuccessfulPasswordChange: () => void | undefined;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const [passwordSubmissionError, setPasswordSubmissionError] = useState<ApiError | false>(false);

  const {
    loggedInAgent,
  } = useAuthStore();

  const deleteToken = async (accessToken: string) => {
    try {
      await apiClient.authClient.deleteToken(accessToken);
    } catch (deleteTokenError: any) {
      console.warn('Failed to delete token');
    }
  };

  const checkCurrentPassword = async (username: string, currentPassword: string) => {
    try {
      const loginResponse = await apiClient.authClient.login({
        username,
        password: currentPassword,
      });

      await deleteToken(loginResponse.accessToken);
    } catch (error: any) {
      throw new Error('Current password is incorrect.');
    }
  };

  const changePassword = async (userId: string, newPassword: string) => {
    try {
      await apiClient.authClient.putUserPassword(userId, {
        id: userId,
        password: newPassword,
      });
    } catch (error: any) {
      throw new Error('An error occurred while changing your password.');
    }
  };

  const handleSubmitPassword = async (values: any, onSuccess: any) => {
    setPasswordSubmissionError(false);

    try {
      const username = loggedInAgent?.email;
      const userId = loggedInAgent?.userInfo[0].id;
      if (!username || !userId) {
        throw new Error('Please try again.');
      } else {
        await checkCurrentPassword(username, values.currentPassword);
        await changePassword(userId, values.newPassword);
        enqueueSnackbar('Password updated', { variant: 'success' });
        onSuccess();
        if (onSuccessfulPasswordChange) {
          onSuccessfulPasswordChange();
        }
      }
    } catch (error: any) {
      setPasswordSubmissionError(error);
    }
  };

  return (
    <MyProfileFormView
      passwordSubmissionError={passwordSubmissionError}
      handleSubmitPassword={handleSubmitPassword}
    />
  );
};

export default SecurityFormContainer;

import {
  SalesRecording, SalesRecordingStatus, TripMatch, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { isSameMonth } from 'date-fns';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { RoleName } from '@marageti/z4-sdk/lib/auth';

export enum AlertType {
  FirstMessageReminder = 'FirstMessageReminder',
  DepositConfirmation = 'DepositConfirmation',
  SalesRecordingEdit = 'SalesRecordingEdit',
  SalesAdjustment = 'SalesAdjustment',
  SalesAdjustmentEdit = 'SalesAdjustmentEdit',
  Postponed = 'Postponed',
  Inactive = 'Inactive',
  MatchSoldCanceled = 'MatchSoldCanceled',
  MatchRefundedCanceled = 'MatchRefundedCanceled',
}

export type AlertObj = {
  type: AlertType;
  url?: string | undefined;
  createdAt?: string | undefined;
  handleReactivation?: () => void;
};

export const createAlertObj = (tripMatch: TripMatch | undefined, salesRecordings: SalesRecording[] | undefined, agent: Agent | null, handleReactivation?: () => void): AlertObj | undefined => {
  // Return FirstMessageReminder if tripMatch status is ACCEPTED and no messages have been sent.
  if (tripMatch?.status === TripMatchStatus.MatchAccepted && agent && agent.userInfo.some((userInfo) => !userInfo.roles?.includes(RoleName.AGENT_SUPPORT))) {
    const acceptedAlert = {
      type: AlertType.FirstMessageReminder,
      url: `/trip-matches/${tripMatch.id}/messages/${tripMatch.travelerToPartnerId}`,
      createdAt: tripMatch.createdAt,
    };
    return acceptedAlert;
  }

  if (tripMatch?.status === TripMatchStatus.MatchPostponed) {
    const postponedAlert = {
      type: AlertType.Postponed,
      handleReactivation,
    };
    return postponedAlert;
  }

  if (tripMatch?.status === TripMatchStatus.MatchInactive) {
    const inactiveAlert = {
      type: AlertType.Inactive,
      handleReactivation,
    };
    return inactiveAlert;
  }

  // Return DepositConfirmation if tripMatch status is ACTIVE and there are no sales recordings.
  if (tripMatch?.status === TripMatchStatus.MatchActive && !salesRecordings?.length) {
    const depositConfirmationAlert = {
      type: AlertType.DepositConfirmation,
      url: `/trip-matches/${tripMatch.id}/sales/recording`,
    };
    return depositConfirmationAlert;
  }
  // Return SalesRecordingEdit if a single sales recording exists with status SOLD and type GENERAL, and tripMatch status is SOLD, and the sales recording was created this month.
  if (
    salesRecordings?.length === 1
    && salesRecordings[0].status === SalesRecordingStatus.SoldStatus
    && salesRecordings[0].salesRecordingType === SalesRecordingType.General
    && (tripMatch?.status === TripMatchStatus.MatchSold)
    && isSameMonth(new Date(salesRecordings[0].createdAt), new Date())
  ) {
    const salesRecordingEditAlert = {
      type: AlertType.SalesRecordingEdit,
      url: `/trip-matches/${tripMatch.id}/sales/recording/${salesRecordings[0].id}`,
    };
    return salesRecordingEditAlert;
  }
  // Return SalesAdjustmentEdit if tripMatch status is SOLD and a sales recording with type ADJUSTMENT and status SOLD exists.
  if (
    tripMatch?.status === TripMatchStatus.MatchSold
    && salesRecordings?.some((recording) => recording.salesRecordingType === SalesRecordingType.Adjustment && recording.status === SalesRecordingStatus.SoldStatus)
  ) {
    const salesAdjustmentEditAlert = {
      type: AlertType.SalesAdjustmentEdit,
      url: `/trip-matches/${tripMatch.id}/sales/adjustment/${salesRecordings[salesRecordings.length - 1].id}`,
    };
    return salesAdjustmentEditAlert;
  }

  // Return SalesAdjustment if tripMatch status is SOLD, sales recordings exist and the most recent sales recording status is INVOICED.
  if (
    tripMatch?.status === TripMatchStatus.MatchSold
    && salesRecordings !== undefined
    && salesRecordings.length > 0
    && salesRecordings[salesRecordings.length - 1].status === SalesRecordingStatus.InvoicedStatus
  ) {
    const salesAdjustmentAlert = {
      type: AlertType.SalesAdjustment,
      url: `/trip-matches/${tripMatch.id}/sales/adjustment`,
    };
    return salesAdjustmentAlert;
  }

  if (tripMatch?.status === TripMatchStatus.MatchSoldCanceled) {
    const soldCanceledAlert = {
      type: AlertType.MatchSoldCanceled,
    };
    return soldCanceledAlert;
  }

  if (tripMatch?.status === TripMatchStatus.MatchRefundedCanceled) {
    const refundedCanceledAlert = {
      type: AlertType.MatchRefundedCanceled,
    };
    return refundedCanceledAlert;
  }

  return undefined;
};

import React, { useEffect, useState } from 'react';
import {
  LocalStorageService,
  OutlinedIcons,
} from '@marageti/z4-lib';
import {
  Box, Typography, Fab, Collapse, Stack,
} from '@mui/material';

const LocalStorage = new LocalStorageService();

const MasqueradeUser = ({ title, email }: {
  title: string;
  email: string;
}) => (
  <Box
    sx={{ ml: 1 }}
  >
    <Typography
      variant="captionBold"
      noWrap
    >
      {title}
      {' '}
      {email}
    </Typography>
  </Box>
);

const MasqueradeBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [superEmail, setSuperEmail] = useState<string | null>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // Get the value of superEmail from local storage
    const superEmailFromLocalStorage = LocalStorage.getFromLocalStorage('superEmail');
    const emailFromLocalStorage = LocalStorage.getFromLocalStorage('email');
    setEmail(emailFromLocalStorage);
    setSuperEmail(superEmailFromLocalStorage);
  }, []);

  if (superEmail) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Fab
          disabled
          variant="extended"
        >
          <OutlinedIcons.OutlinedEyeOffIcon color="warning" />
          <Collapse
            orientation="horizontal"
            in={isHovered}
          >
            <Stack direction="row" spacing={1}>
              {email && <MasqueradeUser title="Masquerading as:" email={email} />}
              {superEmail && <MasqueradeUser title="Superuser:" email={superEmail} />}
            </Stack>
          </Collapse>
        </Fab>
      </Box>
    );
  }
  return null;
};

export default MasqueradeBanner;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  Z4ThemeProvider, FetchSWRConfig, notistackConfig, materialUiConfirmConfig,
} from '@marageti/z4-lib';
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';
import { ConfirmProvider, ConfirmProviderProps } from 'material-ui-confirm';
import { CssBaseline, Button } from '@mui/material';
import './assets/sass/reset.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Router from './routes';
import { themeOptions } from './theme';
import config from './config';
import { AuthProvider } from './store/contexts/auth';
import HubSpotScript from './components/hubspot-script';
import ErrorBoundary from './components/error-boundary/ErrorBoundaryComponent';

const SnackbarOkayButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handleClick = () => closeSnackbar(snackbarKey);

  return (
    <Button color="inherit" onClick={handleClick}>
      Ok
    </Button>
  );
};

const defaultDismissAction = (key: SnackbarKey) => (
  <SnackbarOkayButton snackbarKey={key} />
);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <Z4ThemeProvider themeOptions={themeOptions}>
        <FetchSWRConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              Components={notistackConfig.Components}
              autoHideDuration={notistackConfig.autoHideDuration}
              hideIconVariant={notistackConfig.hideIconVariant}
              action={defaultDismissAction}
            >
              <ConfirmProvider
                defaultOptions={{
                  ...materialUiConfirmConfig,
                } as ConfirmProviderProps['defaultOptions']}
              >
                <AuthProvider>
                  <HubSpotScript />
                  <Router />
                  <CssBaseline />
                </AuthProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </FetchSWRConfig>
      </Z4ThemeProvider>
    </GoogleOAuthProvider>
  </ErrorBoundary>,
);

import { RoleName } from '@marageti/z4-sdk/lib/auth';
import { UserInfo } from '@marageti/z4-sdk/lib/people';

export const authorizedRoles: RoleName[] = [
  RoleName.AGENT,
  RoleName.AGENT_ADMIN,
  RoleName.AGENT_SUPPORT,
  RoleName.ADMIN,
];

export const hasAuthorizedRole = (roles: UserInfo['roles']): boolean => {
  if (!roles) return false;
  for (let i = 0; i < authorizedRoles.length; i += 1) {
    if (roles.includes(authorizedRoles[i])) return true;
  }
  return false;
};

export const getMatchingUser = (email: string, userInfo: UserInfo[]): UserInfo | undefined => (
  userInfo.find((user: UserInfo) => user.email === email)
);

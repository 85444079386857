const formatCountryCodesToString = (countryCodes: string[], countryCodeMap: { [key: string]: string }) => {
  let countryNames: string[] = [];
  if (countryCodes) {
    countryNames = countryCodes.map((c) => countryCodeMap[c]);
  }

  if (countryNames.length > 2) {
    const firstTwoCountries = countryNames.slice(0, 2).join(' and ');
    return `${firstTwoCountries} and ${countryNames.length - 2}+`;
  }

  return countryNames.join(' and ');
};

const getFirstNameAndLastInitial = (fullName: string) => {
  const trimmedName = fullName.trim();
  if (!trimmedName) {
    return '-';
  }
  const names = trimmedName.split(' ');
  if (names.length > 1) {
    return `${names[0]} ${names[names.length - 1].charAt(0)}.`;
  }
  return fullName;
};

export { formatCountryCodesToString, getFirstNameAndLastInitial };

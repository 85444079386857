import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFetch, useApiClient, useFetchImmutable } from '@marageti/z4-lib';
import { TripMatch, SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { SalesRecordingStatus, SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { useConfirm } from 'material-ui-confirm';
import { List, ListItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import SalesRecordingView from './sales-recording-view';
import useDocumentDownload from '../../../hooks/use-document-download';

const SalesRecordDescription = () => (
  <>
    Are you sure you want to remove this sales record? If you proceed, the following will occur:
    <List sx={{ listStyle: 'decimal', pl: 2.5 }}>
      <ListItem key="one" sx={{ display: 'list-item', pl: 1 }}>
        This trip will not be included in your next invoice.
      </ListItem>
      <ListItem key="two" sx={{ display: 'list-item', pl: 1 }}>
        It will no longer be marked as sold.
      </ListItem>
      <ListItem key="three" sx={{ display: 'list-item', pl: 1 }}>
        This sale will be removed from your sales conversion statistics.
      </ListItem>
    </List>
  </>
);

const confirmRecord = {
  title: 'Remove Standard Sale',
  description: <SalesRecordDescription />,
};

const confirmCommissionAfterTravel = {
  title: 'Remove Commission After Travel',
  description: <SalesRecordDescription />,
};

const confirmAdjustment = {
  title: 'Remove Sales Adjustment',
  // eslint-disable-next-line max-len
  description: 'Are you sure you want to remove this sales adjustment? If you proceed, the trip\'s total sales will be adjusted to reflect the state before this adjustment was made. Please note that this action won\'t affect the trip\'s sold status, invoice, or sales conversion rate.',
};

const confirmPlanningFee = {
  title: 'Remove Planning Fee',
  // eslint-disable-next-line max-len
  description: 'Are you sure you want to remove this planning fee recording? Please note that this action won\'t affect the trip\'s status, and the recording will be removed from the next invoice.',
};

const today = new Date();

const SalesRecordingContainer = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { tripMatchId, salesRecordingId } = useParams();
  const [saleUnderReview, setSaleUnderReview] = useState(false);

  const { data: countryCodes, isLoading: isLoadingCC } = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const { data: tripMatch, isLoading: isLoadingTM, mutate } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], true);
  const {
    data: salesRecording, isLoading: isLoadingSR, error,
  } = useFetch<SalesRecording>(apiClient.salesRecordingsClient.getSalesRecording, [salesRecordingId, { params: { trip_match: tripMatchId } }], true);

  const getRemovalConfirmationType = (salesRecordingType: SalesRecordingType) => {
    switch (salesRecordingType) {
      case SalesRecordingType.Adjustment:
        return confirmAdjustment;
      case SalesRecordingType.CommissionAfterTravel:
        return confirmCommissionAfterTravel;
      case SalesRecordingType.PlanningFee:
        return confirmPlanningFee;
      default:
        return confirmRecord;
    }
  };

  const [onClickDocument, docError] = useDocumentDownload();

  const onClickEdit = () => {
    if (salesRecording?.salesRecordingType === SalesRecordingType.General) {
      navigate(`/trip-matches/${tripMatchId}/sales/recording/${salesRecordingId}`);
    }
    if (salesRecording?.salesRecordingType === SalesRecordingType.Adjustment) {
      navigate(`/trip-matches/${tripMatchId}/sales/adjustment/${salesRecordingId}`);
    }
  };

  const onClickDownload = () => {
    if (salesRecording?.document) {
      onClickDocument(salesRecording.document);
    }
  };

  const onClickRemove = () => {
    if (salesRecording) {
      const confirmationType = getRemovalConfirmationType(salesRecording.salesRecordingType);
      confirm({
        ...confirmationType,
        confirmationButtonProps: {
          variant: 'contained',
          color: 'error',
        },
        confirmationText: 'Remove',
        dialogProps: {
          PaperProps: {
            sx: {
              maxWidth: '409px',
            },
          },
        },
      })
        .then(() => apiClient.salesRecordingsClient.deleteSalesRecording(salesRecordingId!))
        .then(() => {
          mutate();
          enqueueSnackbar('Sales Recording removed.', { variant: 'success' });
          navigate(`/trip-matches/${tripMatchId}/sales`);
        })
        .catch((e: ApiError) => {
          if (e !== undefined) enqueueSnackbar('There was an error deleting this sales recording.', { variant: 'default' });
        });
    }
  };

  //  Set banner based on sales status.
  useEffect(() => {
    let underReview = false;
    if (salesRecording) {
      const salesRecordingMonth = new Date(salesRecording.updatedAt);
      if (salesRecordingMonth.getMonth() !== today.getMonth()) {
        underReview = salesRecording.status === SalesRecordingStatus.SoldStatus;
      }
    }
    setSaleUnderReview(underReview);
  }, [salesRecording]);

  if (error?.code === 404) {
    return <Navigate to={`/trip-matches/${tripMatchId}/sales`} replace />;
  }

  return (
    <SalesRecordingView
      countryCodes={countryCodes}
      docError={docError}
      isLoading={isLoadingCC || isLoadingTM || isLoadingSR}
      onClickDownload={onClickDownload}
      onClickEdit={onClickEdit}
      onClickRemove={onClickRemove}
      salesRecording={salesRecording}
      saleUnderReview={saleUnderReview}
      tripMatch={tripMatch}
    />
  );
};

export default SalesRecordingContainer;

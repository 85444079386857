import { useApiClient, useFetchInfiniteES } from '@marageti/z4-lib';
import {
  FilterOp,
  SearchResult,
  FilterConstraint,
} from '@marageti/z4-sdk/lib/entity';
import { ChecklistItem, TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import { useMemo } from 'react';

const refineSoldFilters: { [key: string]: FilterConstraint[] } = {
  preTravel: [{ field: 'departureDate', op: FilterOp.FilterOpGT, value: ['now'] }],
  inCountry: [
    { field: 'departureDate', op: FilterOp.FilterOpLTE, value: ['now'] },
    { field: 'returnDate', op: FilterOp.FilterOpGTE, value: ['now'] },
  ],
  requestReview: [
    { field: 'returnDate', op: FilterOp.FilterOpLT, value: ['now'] },
    { field: 'checklist', value: [ChecklistItem.NeedsZicassoReviewRequest] },
  ],
  completedTrip: [{ field: 'returnDate', op: FilterOp.FilterOpLT, value: ['now'] }],
};

// selecting a single Canceled status chip should filter for any of the 5 Canceled statuses
export const canceledStatusesValue = 'CANCELED';
export const canceledStatuses: string[] = [
  TripMatchStatus.MatchZicassoCanceled,
  TripMatchStatus.MatchPartnerCanceled,
  TripMatchStatus.MatchTravelerCanceled,
  TripMatchStatus.MatchSoldCanceled,
  TripMatchStatus.MatchRefundedCanceled,
];

// departureDate and returnDate have optional 'ops' fields. The format for that is field=value|op
interface PossibleSearchFields {
  checklist?: string[];
  countries?: string[];
  departureDate?: string;
  fullText?: string;
  order?: string;
  partner?: string;
  assignedTo?: string;
  refineSold?: string[];
  returnDate?: string;
  salesStage?: string[];
  sort?: string;
  status?: string[];
  traveler?: string;
}

const useTripMatchSearch = ({
  checklist = [],
  countries = [],
  departureDate = '',
  fullText = '',
  order = '',
  partner = 'all',
  assignedTo = 'all',
  refineSold = [],
  returnDate = '',
  salesStage = [],
  sort = '',
  status = [],
  traveler = '',
}: PossibleSearchFields) => {
  const apiClient = useApiClient();

  const getSearchRequest = () => {
    const filterBy: FilterConstraint[] = [];
    const sortBy: { field: string; order: string }[] = [];

    if (partner && partner !== 'all' && partner !== 'none') {
      filterBy.push({ field: 'partnerId', value: [partner] });
    }

    if (assignedTo && assignedTo !== 'all' && assignedTo !== 'none') {
      filterBy.push({ field: 'assignedTo', value: [assignedTo] });
    }

    // handle unassigned filter
    if (assignedTo && assignedTo === 'none') {
      filterBy.push({ field: 'assignedTo', value: [''] });
    }

    if (traveler) {
      filterBy.push({ field: 'travelers.id', value: [traveler] });
    }

    if (countries.length) {
      filterBy.push({ field: 'countries', value: countries });
    }

    if (status.length) {
      if (status.includes(canceledStatusesValue)) {
        const statuses = [...status.filter((s) => s !== canceledStatusesValue), ...canceledStatuses];
        filterBy.push({ field: 'status', value: [...statuses] });
      } else {
        filterBy.push({ field: 'status', value: [...status] });
      }
    }
    if (salesStage.length) {
      filterBy.push({ field: 'salesStage', value: [...salesStage] });
    }

    if (checklist.length) {
      filterBy.push({ field: 'checklist', value: checklist });
    }

    if (departureDate) {
      filterBy.push({ field: 'departureDate', value: [departureDate] });
    }

    if (returnDate) {
      filterBy.push({ field: 'returnDate', value: [returnDate] });
    }

    if (refineSold.length) {
      refineSold.forEach((refine) => {
        filterBy.push(...refineSoldFilters[refine]);
      });
    }

    if (sort) {
      switch (sort) {
        case 'departureDateUpcoming':
          // Today & Future trips, most recent at top
          filterBy.push({ field: 'departureDate', op: FilterOp.FilterOpGTE, value: ['now'] });
          sortBy.push({ field: 'departureDate', order });
          break;
        case 'departureDatePast':
          // Past trips with the most recent at top.
          filterBy.push({ field: 'departureDate', op: FilterOp.FilterOpLT, value: ['now'] });
          sortBy.push({ field: 'departureDate', order });
          break;
        case 'departureDateAll':
          // All trips regardless of the date
          sortBy.push({ field: 'departureDate', order });
          break;
        default:
          sortBy.push({ field: sort, order });
      }
    }

    return {
      filterBy,
      sortBy,
      fullText: fullText.length > 2 ? fullText : undefined,
      pagination: { limit: 30, page: 1 },
    };
  };

  // Fetch and exicute search request
  const searchRequest = getSearchRequest();

  const {
    data,
    error,
    hasMore,
    isLoading,
    loadMore,
  } = useFetchInfiniteES<TripMatch>(apiClient.tripMatchesClient.postTripMatchSearches, searchRequest, { revalidateOnFocus: false });

  const tripMatches = useMemo(() => (
    data?.flatMap((searchResult: SearchResult<TripMatch>) => searchResult.hits)
  ), [data]);

  return {
    tripMatches,
    error,
    hasMore,
    isLoading,
    loadMore,
    totalHits: data?.[0]?.totalHits || 0,
  };
};

export default useTripMatchSearch;

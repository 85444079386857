import React, { useContext } from 'react';
import { AuthStore } from './contexts/auth';
import { ConversationsStore } from './contexts/conversations';
import { StandardsProvider, StandardsStore } from './contexts/standards';
import { PusherProvider, PusherStore } from './contexts/pusher';
import { InboxStore } from './contexts/inbox';

export interface ProviderProps {
  children: React.ReactNode;
}

const StoreProvider = ({ children }: ProviderProps) => (
  <StandardsProvider>
    <PusherProvider>
      {children}
    </PusherProvider>
  </StandardsProvider>
);

export const useAuthStore = () => useContext(AuthStore);
export const usePusherStore = () => useContext(PusherStore);
export const useStandardsStore = () => useContext(StandardsStore);
// ConversationsProvider and InboxProvider are not app wide and must be manually injected
export const useConversationsStore = () => useContext(ConversationsStore);
export const useInboxStore = () => useContext(InboxStore);

export default StoreProvider;

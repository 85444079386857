import { CountryCodeMap, TimeZoneMap } from '@marageti/z4-sdk/lib/standards';
import { Actions } from '../../types';

export const SET_STANDARDS_ERROR = 'SET_STANDARDS_ERROR';
export const setStandardsError = (error: boolean): Actions => ({
  type: SET_STANDARDS_ERROR,
  error,
});

export const SET_COUNTRY_CODES = 'SET_COUNTRY_CODES';
export const setCountryCodes = (countryCodes: CountryCodeMap): Actions => ({
  type: SET_COUNTRY_CODES,
  countryCodes,
});

export const SET_TIME_ZONES = 'SET_TIME_ZONES';
export const setTimeZones = (timeZones: TimeZoneMap): Actions => ({
  type: SET_TIME_ZONES,
  timeZones,
});

import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ViewProps } from './types';
import ProfileCard from './cards/profile-card';
import ProfileDetailCard from './cards/profile-detail-card';
import OfficeCard from './cards/office-card';
import stylesT from './styles';
import ConfirmDelete from '../../../components/confirm-delete';
import BillingInfoCard from './cards/billing-info-card';

const profileDetails = [
  {
    attributeName: 'aboutUs',
    displayName: 'About Us',
  },
  {
    attributeName: 'billingInfo',
    displayName: 'Billing Info',
  },
];

const View = ({
  contextMenuAnchors,
  contextMenusOpen,
  onCloseContextMenu,
  onOpenContextMenu,
  onRemoveOffice,
  partner,
  isAdmin,
  indexToDelete,
  onCancelDelete,
  onConfirmDelete,
  countryCodes,
}: ViewProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = stylesT(theme);

  return (
    <Container maxWidth="md">
      <Box sx={{
        py: {
          desktop: 6,
          mobile: 3,
        },
      }}
      >
        <Grid container spacing={5} sx={styles.gridWrapper}>
          <Grid item>
            <Typography variant="h2" sx={{ display: { mobile: 'none', desktop: 'inherit' } }}>Company Profile</Typography>
          </Grid>
          <Grid item>
            <Box>
              <ProfileCard
                contextMenuAnchor={contextMenuAnchors.default}
                contextMenuOpen={!!contextMenusOpen.default}
                onCloseContextMenu={() => onCloseContextMenu('default')}
                onOpenContextMenu={(e) => onOpenContextMenu('default', e)}
                onClickEdit={() => navigate('edit')}
                partner={partner}
                isAdmin={isAdmin}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h3" mb={1}>About</Typography>
            <Box>
              <ProfileDetailCard
                contextMenuAnchor={contextMenuAnchors.aboutUs}
                contextMenuOpen={!!contextMenusOpen.aboutUs}
                onCloseContextMenu={() => onCloseContextMenu('aboutUs')}
                onOpenContextMenu={(e) => onOpenContextMenu('aboutUs', e)}
                onClickEdit={() => navigate('about-us')}
                partner={partner}
                partnerDetail={profileDetails[0]}
                isAdmin={isAdmin}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h3" mb={1}>Billing Info</Typography>
            <Box>
              <BillingInfoCard
                partner={partner}
                isAdmin={isAdmin}
                contextMenuAnchor={contextMenuAnchors.billingInfo}
                contextMenuOpen={!!contextMenusOpen.billingInfo}
                onCloseContextMenu={() => onCloseContextMenu('billingInfo')}
                onOpenContextMenu={(e) => onOpenContextMenu('billingInfo', e)}
                onClickEdit={() => navigate('billing-info')}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h3" mb={1}>Offices</Typography>
            {partner?.offices?.map((office, i) => (
              <Grid item key={office.city ? `${office.city}-${i}` : `default-office-card-${i}`} mb={1.5}>
                <OfficeCard
                  countryCodes={countryCodes}
                  id={`${office.city || 'default-city'}-${i}`}
                  contextMenuAnchor={contextMenuAnchors[`${office.city || 'default-city'}-${i}`]}
                  contextMenuOpen={!!contextMenusOpen[`${office.city || 'default-city'}-${i}`]}
                  onCloseContextMenu={() => onCloseContextMenu(`${office.city || 'default-city'}-${i}`)}
                  onOpenContextMenu={(e) => onOpenContextMenu(`${office.city || 'default-city'}-${i}`, e)}
                  onClickEdit={() => navigate(`./office?index=${i}`)}
                  onClickRemove={() => onRemoveOffice(i)}
                  office={office}
                  isAdmin={isAdmin}
                />
              </Grid>
            ))}
            <Button
              sx={{
                mt: '.5rem',
                visibility: `${isAdmin ? 'inherit' : 'hidden'}`,
                width: { desktop: 'fit-content', mobile: '100%' },
              }}
              variant="outlined"
              onClick={() => navigate('./office?index=add')}
            >
              Add Office
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDelete
        open={indexToDelete !== undefined}
        onCancel={onCancelDelete}
        onRemove={onConfirmDelete}
        title="Remove Office"
      />
    </Container>
  );
};

export default View;

import React, { useMemo } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Box,
} from '@mui/material';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { format } from 'date-fns';
import { SalesRecordingType, SalesRecordingStatus } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import CopyButton from '../../../components/copy-button';

type Props = {
  sales: SalesRecording[] | undefined,
};

const typeLabel = {
  [SalesRecordingType.Adjustment]: 'Sales Adjustment',
  [SalesRecordingType.General]: 'Standard Sale',
  [SalesRecordingType.PartnerRepeat]: 'Repeat Sale',
  [SalesRecordingType.TravelerReferral]: 'Referral Sale',
  [SalesRecordingType.CommissionAfterTravel]: 'Commission After Travel',
  [SalesRecordingType.PlanningFee]: 'Planning Fee',
  [SalesRecordingType.RefundCredit]: 'Refund Credit',
};

type Row = {
  id: string;
  dateSold: string;
  referenceId: string;
  traveler: string;
  agent: string;
  type: string;
  totalPrice: string;
  commission: string;
  salesRecordingDetailUrl: string | null;
  action: React.ReactNode;
};

const AllSalesTable = ({
  sales,
}: Props) => {
  const navigate = useNavigate();
  const rows: Row[] = useMemo(() => {
    if (!sales) return [];
    return sales.map((sale) => {
      const getAction = () => {
        switch (sale.status) {
          case SalesRecordingStatus.InvoicedStatus:
            return <em>Invoiced</em>;
          case SalesRecordingStatus.VerifiedStatus:
            return <em>Approved</em>;
          case SalesRecordingStatus.SoldStatus:
            if (sale.salesRecordingType === SalesRecordingType.PlanningFee || sale.salesRecordingType === SalesRecordingType.CommissionAfterTravel) {
              return null;
            }

            if (sale.salesRecordingType === SalesRecordingType.Adjustment) {
              return (
                <Link
                  component={RouterLink}
                  to={`/trip-matches/${sale.tripMatchId}/sales/adjustment/${sale.id}`}
                  variant="captionBold"
                  underline="none"
                >
                  Edit
                </Link>
              );
            }

            if (sale.salesRecordingType === SalesRecordingType.General) {
              return (
                <Link
                  component={RouterLink}
                  to={`/trip-matches/${sale.tripMatchId}/sales/recording/${sale.id}`}
                  variant="captionBold"
                  underline="none"
                >
                  Edit
                </Link>
              );
            }

            if (sale.salesRecordingType === SalesRecordingType.PartnerRepeat || sale.salesRecordingType === SalesRecordingType.TravelerReferral) {
              return (
                <Link
                  component={RouterLink}
                  to={`/sales/repeat-referral/${sale.id}/edit`}
                  variant="captionBold"
                  underline="none"
                >
                  Edit
                </Link>
              );
            }

            return 'Sold';
          default:
            return null;
        }
      };

      const getSalesRecordingDetails = () => {
        switch (sale.salesRecordingType) {
          case SalesRecordingType.Adjustment:
          case SalesRecordingType.General:
          case SalesRecordingType.PlanningFee:
          case SalesRecordingType.CommissionAfterTravel:
            return `/trip-matches/${sale.tripMatchId}/sales/${sale.id}`;
          case SalesRecordingType.PartnerRepeat:
          case SalesRecordingType.TravelerReferral:
            return `/sales/repeat-referral/${sale.id}`;
          default:
            return null;
        }
      };

      const firstTraveler = sale.travelers?.[0];
      const salesRecordingDetailUrl = getSalesRecordingDetails();
      const action = getAction();

      return {
        id: sale.id,
        dateSold: format(new Date(sale.createdAt), 'MMM dd, yyyy'),
        referenceId: sale.referenceId,
        traveler: firstTraveler && firstTraveler.fullName ? firstTraveler.fullName : '-',
        agent: sale.assignedToName,
        type: typeLabel[sale.salesRecordingType],
        totalPrice: new Intl.NumberFormat('en-US', { style: 'currency', currency: sale.price.currencyCode }).format(sale.price.amount),
        commission: new Intl.NumberFormat('en-US', { style: 'currency', currency: sale.commission.currencyCode }).format(sale.commission.amount),
        salesRecordingDetailUrl,
        action,
      };
    });
  }, [sales]);

  const handleRowClick = (row: Row) => () => {
    if (row.salesRecordingDetailUrl) {
      navigate(row.salesRecordingDetailUrl);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Recorded</TableCell>
            <TableCell>Trip ID</TableCell>
            <TableCell>Traveler</TableCell>
            <TableCell>Agent</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Total Price</TableCell>
            <TableCell>Commission</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              key={row.id}
              onClick={handleRowClick(row)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <TableCell sx={{ minWidth: '130px' }}>{row.dateSold}</TableCell>
              <TableCell sx={{ minWidth: { desktop: '130px', mobile: '180px' } }}>
                {
                  row.referenceId ? (
                    <Box component="span" display="flex" alignItems="center">
                      {row.referenceId}
                      <CopyButton textToCopy={row.referenceId} size="small" />
                    </Box>
                  ) : '-'
                }
              </TableCell>
              <TableCell>{row.traveler}</TableCell>
              <TableCell sx={{ minWidth: '160px' }}>{row.agent}</TableCell>
              <TableCell sx={{ minWidth: '130px' }}>{row.type}</TableCell>
              <TableCell sx={{ color: row.totalPrice.startsWith('-') ? 'error.main' : undefined }}>{row.totalPrice}</TableCell>
              <TableCell sx={{ color: row.totalPrice.startsWith('-') ? 'error.main' : undefined }}>{row.commission}</TableCell>
              <TableCell
                sx={{
                  cursor: 'default',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {row.action}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllSalesTable;

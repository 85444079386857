import React from 'react';
import MessagesInboxContainer from './messages-inbox-container';
import { ConversationsProvider } from '../../../store/contexts/conversations';
import { useInboxStore } from '../../../store';
import { InboxProvider } from '../../../store/contexts/inbox';

const ConversationsWrapper = () => {
  const { showUnreadOnly } = useInboxStore();
  return (
    <ConversationsProvider showUnreadOnly={showUnreadOnly}>
      <MessagesInboxContainer />
    </ConversationsProvider>
  );
};

const MessagesInboxWrapper = () => (
  <InboxProvider>
    <ConversationsWrapper />
  </InboxProvider>
);

export default MessagesInboxWrapper;

import { Agent, TeamMember } from '@marageti/z4-sdk/lib/people';
import { Actions } from '../../types';

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const setIsAuthenticated = (isAuthenticated: boolean): Actions => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated,
});

export const SET_GOOGLE_USER_TOKEN = 'SET_GOOGLE_USER_TOKEN';
export const setGoogleUserToken = (googleUserToken: any): Actions => ({
  type: SET_GOOGLE_USER_TOKEN,
  googleUserToken,
});

export const RESET_AUTHENTICATED_AGENT = 'RESET_AUTHENTICATED_TEAM_MEMBER';
export const resetAuthenticatedAgent = (): Actions => ({
  type: RESET_AUTHENTICATED_AGENT,
});

export const SET_AUTHENTICATED_AGENT = 'SET_AUTHENTICATED_AGENT';
export const setAuthenticatedAgent = (agent: Agent | TeamMember): Actions => ({
  type: SET_AUTHENTICATED_AGENT,
  payload: agent,
});

interface Key {
  ttl: number,
  data: string,
}

export default class LocalStorageService {
  // eslint-disable-next-line class-methods-use-this
  supportsLocalStorage() {
    return !!(typeof window !== 'undefined' && window.localStorage);
  }

  hasExpired(key: string): boolean {
    if (this.supportsLocalStorage()) {
      let item: string | null = window.localStorage.getItem(key);
      try {
        if (item) {
          const itemParsed: Key = JSON.parse(item);
          if (typeof itemParsed === 'object' && itemParsed?.ttl && (itemParsed.ttl < Date.now())) {
            return true;
          }
        }
      } catch (err) {
        item = window.localStorage.getItem(key);
        return !!item;
      }
    }
    return false;
  }

  getFromLocalStorage(key: string): string | null {
    if (!this.supportsLocalStorage()) return null;
    const item: string | null = window.localStorage.getItem(key);
    let itemParsed: Key;

    try {
      if (item) {
        itemParsed = JSON.parse(item);
        return itemParsed && itemParsed.data;
      }
    } catch (err) {
      return item;
    }
    return item;
  }

  addToLocalStorage(key: string, data: string | number, ttl = null): void {
    try {
      if (this.supportsLocalStorage()) {
        window.localStorage.setItem(key, JSON.stringify({
          ttl: Date.now() + (ttl || (((60 * 60 * 24) * 30) * 1000)),
          data,
        }));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  removeFromLocalStorage(key: string): void {
    if (this.supportsLocalStorage()) {
      window.localStorage.removeItem(key);
    }
  }

  RefreshLocalStorage(): null {
    //  Remove all expired items
    if (this.supportsLocalStorage()) {
      Object.keys(window.localStorage).forEach((key: string): void => {
        if (this.hasExpired(key)) {
          this.removeFromLocalStorage(key);
        }
      });
    }
    return null;
  }
}

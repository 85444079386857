import React, { useMemo } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ProfileCardContent,
  LabelAndValueElement,
  ContextMenu,
  ContextMenuButton,
  ContextMenuItem,
} from '@marageti/z4-lib';
import { OfficeCardProps } from '../types';
import { getPartnerOfficePhoneNumber } from '../../../../utils/helpers';
import stylesT from '../styles';

const OfficeCard = ({
  id,
  contextMenuAnchor,
  contextMenuOpen,
  countryCodes,
  office,
  onCloseContextMenu,
  onOpenContextMenu,
  onClickEdit,
  onClickRemove,
  isAdmin,
}: OfficeCardProps) => {
  const theme = useTheme();
  const styles = stylesT(theme);
  const menuId = `office-card-context-menu-${id}`;
  const buttonId = `ffice-card-context-button-${id}`;

  const officeCountry = useMemo(() => {
    if (!countryCodes) {
      return '-';
    }
    if (!office || !office.country) {
      return '-';
    }
    return countryCodes[office.country];
  }, [office, countryCodes]);

  return (
    <Card sx={styles.profileCard}>
      <ProfileCardContent>
        <Stack spacing="24px" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <LabelAndValueElement
              title="City"
              value={office?.city || '-'}
              key="city"
            />
            { isAdmin && (
              <>
                <ContextMenuButton
                  id={buttonId}
                  open={contextMenuOpen}
                  onClick={onOpenContextMenu}
                  menuId={menuId}
                />
                <ContextMenu
                  anchorEl={contextMenuAnchor}
                  buttonId={buttonId}
                  id={menuId}
                  onClose={onCloseContextMenu}
                  open={contextMenuOpen}
                >
                  <ContextMenuItem onClick={onClickEdit}>
                    Edit
                  </ContextMenuItem>
                  <ContextMenuItem onClick={onClickRemove}>
                    Remove
                  </ContextMenuItem>
                </ContextMenu>
              </>
            )}
          </Stack>
          <LabelAndValueElement
            title="Country"
            value={officeCountry}
            key="country"
          />
          <LabelAndValueElement
            title="Phone"
            value={getPartnerOfficePhoneNumber(office)}
            key="phone"
          />
        </Stack>
      </ProfileCardContent>
    </Card>
  );
};

export default OfficeCard;

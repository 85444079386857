/**
 * Upload data to an S3 bucket using a presigned URL.
 * @param presignedUrl: The presigned URL to upload the data to.
 * @param data: The data to upload.
 */
const putS3Document = async (presignedUrl: string, data: any) => {
  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: data,
  });

  if (!response.ok) {
    throw new Error(`Error uploading file: ${response.statusText}`);
  }
};

export default putS3Document;

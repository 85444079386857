/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Stack,
  TextField,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
} from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';

type ChangePasswordFormDefaultValues = {
  currentPassword: string | undefined,
  newPassword: string | undefined,
  confirmNewPassword: string | undefined,
};

const DEFAULT_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
} as ChangePasswordFormDefaultValues;

const schema = z.object({
  currentPassword: z.string().min(1, 'Required'),
  newPassword: z.string().min(8, 'Password must be at least 8 characters').nonempty('Please enter a new password'),
  confirmNewPassword: z.string(),
}).refine((data) => data.newPassword === data.confirmNewPassword, {
  message: 'Passwords do not match',
  path: ['confirmNewPassword'],
}).refine((data) => data.currentPassword !== data.newPassword, {
  message: 'New password should not match the current password',
  path: ['newPassword'],
});

type ChangePasswordFormProps = {
  onSubmit: (data: any, onSuccess: any) => void,
  submissionError: ApiError | false
};

const ChangePasswordForm = ({
  onSubmit: submit,
  submissionError,
}: ChangePasswordFormProps) => {
  const {
    register,
    handleSubmit: createSubmitHandler,
    formState: {
      isSubmitting, errors, isValid,
    },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const onSuccess = () => {
    reset(DEFAULT_VALUES);
  };

  const onSubmit = (data: any) => submit(data, onSuccess);

  return (
    <form {...getFormProps(createSubmitHandler(onSubmit))}>
      <Stack spacing={2}>
        <Stack useFlexGap>
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Change Password
          </Typography>
          <TextField
            type="password"
            label="Current Password"
            {...register('currentPassword')}
            {...getFieldProps('currentPassword', errors)}
            autoComplete="current-password"
          />
          <TextField
            type="password"
            label="New Password"
            {...register('newPassword')}
            {...getFieldProps('newPassword', errors)}
            autoComplete="new-password"
          />
          <TextField
            type="password"
            label="Confirm New Password"
            {...register('confirmNewPassword')}
            {...getFieldProps('confirmNewPassword', errors)}
          />
        </Stack>

        {submissionError && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting the form.</AlertTitle>
            {submissionError.message || 'Please try again.'}
          </Alert>
        )}

        <div>
          <LoadingButton
            variant="contained"
            type="submit"
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            Change Password
          </LoadingButton>
        </div>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;

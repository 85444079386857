import React, { useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  ChipSelectList,
  ChipOption,
  OutlinedIcons,
  MultiCountrySelector,
  DebouncedSearchInput,
} from '@marageti/z4-lib';
import AssignedToSelect from '../../../components/assignee-input';

const {
  OutlinedFilterIcon,
} = OutlinedIcons;

export type Option = {
  title: string;
  value: string;
};

export type ExtendedOption = Option & {
  field?: string;
};

export type AssigneeOption = Option & {
  status?: string;
};

type AllFiltersProps = {
  onSearchChange: (v: string) => void;
  searchValue: string;
  refreshKey: number;
  sortBy: string;
  sortByOptions: Option[];
  onOrderSortChange: (n: string, v: string) => void;
  selectedCountries: string[];
  countryOptions: { country:string }[];
  onCountriesChange: (event: React.SyntheticEvent, value: string[]) => void;
  selectedAssignee: string;
  onAssigneeChange: (value: string) => void;
  statusChips: ChipOption[];
  selectedStatusChips: string[];
  stageChips: ChipOption[];
  selectedStageChips: string[];
  onChipChange: (chipType: string, selectedChips: string[]) => void;
};

const AllFilters = ({
  // Search Props
  searchValue,
  onSearchChange,
  refreshKey,
  // Destination Props
  selectedCountries,
  countryOptions,
  onCountriesChange,
  // Assignee Props
  selectedAssignee,
  onAssigneeChange,
  // Sort and Order Props
  sortBy,
  sortByOptions,
  onOrderSortChange,
  // Chip Props
  statusChips,
  selectedStatusChips,
  stageChips,
  selectedStageChips,
  onChipChange,
}: AllFiltersProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('desktop'));
  const disableStageChips = !selectedStatusChips.includes('ACTIVE');
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box sx={{ mb: 4 }}>
      <Box
        sx={{
          display: { mobile: 'flex', desktop: 'none' },
          flexDirection: 'row-reverse',
        }}
      >
        <IconButton
          sx={{
            color: 'primary.main',
            border: '1px solid',
            borderRadius: '0.5rem',
          }}
          onClick={() => { setShowFilters(!showFilters); }}
        >
          <OutlinedFilterIcon />
        </IconButton>
      </Box>
      {(matches || showFilters) && (
      <>
        <Grid
          container
          columnSpacing={2}
        >
          <Grid item mobile={12} desktop={3}>
            <DebouncedSearchInput
              fullWidth
              label="Search"
              name="fullText"
              onChange={onSearchChange}
              defaultValue={searchValue}
              key={`refreshKey-${refreshKey}`}
            />
          </Grid>
          <Grid item mobile={12} desktop={3}>
            <MultiCountrySelector
              key="destination-select"
              label="Countries"
              name="countries"
              onChange={onCountriesChange}
              value={selectedCountries}
              countries={countryOptions}
            />
          </Grid>
          <Grid item mobile={12} desktop={3}>
            <AssignedToSelect
              assignedTo={selectedAssignee as string}
              onChange={onAssigneeChange}
              showCount
            />
          </Grid>
          <Grid item mobile={12} desktop={3}>
            <TextField
              fullWidth
              disabled={searchValue !== ''}
              key="sort-by-select"
              label="Sort By"
              name="sortBy"
              onChange={(e) => onOrderSortChange('sortBy', e.target.value)}
              select
              value={sortBy}
            >
              {sortByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {(selectedAssignee !== 'none') && (
          <Stack direction={{ mobile: 'column', desktop: 'row' }} spacing={2}>
            <ChipSelectList
              label="Status"
              chipOptions={statusChips}
              selectedChips={selectedStatusChips}
              onChange={(selectedChips) => onChipChange('status', selectedChips)}
            />
            <ChipSelectList
              label="Sales Stage"
              chipOptions={stageChips}
              selectedChips={selectedStageChips}
              onChange={(selectedChips) => onChipChange('stage', selectedChips)}
              chipProps={{
                disabled: disableStageChips,
              }}
            />
          </Stack>
        )}
      </>
      )}
    </Box>
  );
};

export default AllFilters;

import React from 'react';
import {
  Container,
  Typography,
  Box,
} from '@mui/material';
import {
  ConversationSummary, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import ConversationLinks from '../../../components/conversation-links';

const getConversationTypeTitle = (conversationType: ConversationType) => {
  switch (conversationType) {
    case ConversationType.TravelerToPartner:
      return 'Traveler';
    case ConversationType.ZicassoToPartner:
      return 'Zicasso';
    default:
      return undefined;
  }
};

const MessagesView = ({
  tripMatchId,
}:{ tripMatchId: string }) => {
  const renderConversationLink = ({
    conversationSummary,
  }: {
    conversationSummary: ConversationSummary,
  }) => `./${conversationSummary.conversationId}`;

  return (
    <Container maxWidth="md" sx={{ mb: '100px' }}>
      <Box sx={{ pt: { mobile: 3, desktop: 11 } }}>
        <Typography variant="h2" sx={{ mb: 3 }}>Messages</Typography>
        <ConversationLinks
          getConversationTypeTitle={getConversationTypeTitle}
          renderConversationLink={renderConversationLink}
          tripMatchId={tripMatchId}
        />
      </Box>
    </Container>
  );
};
export default MessagesView;

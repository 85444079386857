import { useLocation, useSearchParams } from 'react-router-dom';

// Each consumer will need to define their own defaultSearch object with the keys they expect in
// their search query. All values must be either strings or arrays of strings.
// interface DefaultSearch {
//   [key: string]: string | string[];
//   search: string;
//   sort: string;
//   order: string;
//   destinations: string[];
//   assignee: string;
//   tags: string[];
//   status: string[];
// }

type UpdateURLQuery = (params: Record<string, string | string[]>) => void;

// Parse URLSearchParams Map into an object with the same keys and values as defaultSearch
function parseURLSearchParamsToObject<T extends { [key: string]: string | string[] }>(query: URLSearchParams, defaultSearch: T): T {
  const q: { [key: string]: string | string[] } = {};
  const entries = Object.keys(defaultSearch);
  // Loop over default search keys.
  // Any key that is not in the query will have the empty value which may be different than the default value
  entries.forEach((key) => {
    // Handle arrays default values
    if (Array.isArray(defaultSearch[key])) {
      q[key] = query.getAll(key);
    } else {
      // Handle string default values
      const value = query.get(key);
      q[key] = value === null ? defaultSearch[key] : value;
    }
  });
  return q as T;
}

// Pass in a default search query which is an object with query params and their default values
// The default search must have keys for each potential query param with empty values if no default is needed
// An object with these same keys will be returned with their current values
// updateURLQuery is also returned to update the URL query with new values
// emptySearch is required if a default search value is not empty but the user should be able to clear all search values.
function useURLSearchQuery<T extends { [key: string]: string | string[] }>(defaultSearch: T): [T, UpdateURLQuery] {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search || defaultSearch);
  const parsedQuery: T = location.search ? parseURLSearchParamsToObject<T>(searchParams, defaultSearch) : defaultSearch;

  // Accepts an object with keys and a string or an array of strings for values
  // If a value is present for that value type (array or string) the url will be updated
  // If no value is present for that key that key will be removed from the url
  const updateURLQuery = (params: Record<string, string | string[]>): void => {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        // Delete original key(s) to avoid duplicates and account for removed values
        searchParams.delete(key);
        if (value.length > 0) value.forEach((val) => searchParams.append(key, val));
      } else if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value as string);
      }
    });
    setSearchParams(searchParams);
  };
  return [
    parsedQuery,
    updateURLQuery,
  ];
}

export default useURLSearchQuery;

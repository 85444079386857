/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Card,
  CardContent,
  Skeleton,
  Stack,
} from '@mui/material';
import {
  ContextMenuComplete,
  ProfileCardTitle,
  RichTextDisplay,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';

export interface ProfileDetails {
  attributeName: string;
  displayName: string;
  routeName: string;
}

type PolicyAndTermsCardProps = {
  partner: Partner | undefined;
  agentAdmin: boolean;
  contextMenuProps: any;
  partnerDetail: ProfileDetails;
};

const LabelAndValueElementWithWhitespace = ({
  title,
  value,
}: any) => (value !== undefined ? (
  <Stack>
    <ProfileCardTitle sx={{ mb: 1 }}>{title}</ProfileCardTitle>
    <RichTextDisplay color="text.secondary">
      {value}
    </RichTextDisplay>
  </Stack>
) : (
  <Skeleton
    variant="rounded"
    animation="wave"
    sx={{
      margin: '0 auto',
      height: '44px',
      width: '100%',
    }}
  />
));

const PolicyAndTermsCardView = ({
  partner,
  partnerDetail,
  agentAdmin,
  contextMenuProps,
}: PolicyAndTermsCardProps) => (
  <Card>
    <CardContent>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <LabelAndValueElementWithWhitespace
          title={partnerDetail.displayName}
          value={partner?.[partnerDetail.attributeName as keyof typeof partner] as string || '-'}
          key={partnerDetail.attributeName}
        />
        {(agentAdmin && contextMenuProps) && (
        <ContextMenuComplete {...contextMenuProps} />
        )}
      </Stack>
    </CardContent>
  </Card>
);

export default PolicyAndTermsCardView;

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import UserView from './user-view';
import { useAuthStore } from '../../store';

const UserProfileContainer = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const {
    loggedInAgent,
  } = useAuthStore();
  const loggedInAgentIsAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN));
  const loggedInAgentIsSelf = loggedInAgent?.id === userId;

  const handleNavigateBack = () => {
    navigate('/settings/users');
  };

  const handleEditClick = () => {
    navigate(`/users/${userId}/edit`);
  };

  const editContextMenu = (loggedInAgentIsAdmin || loggedInAgentIsSelf) ? {
    id: 'user-profile-context-menu',
    items: [{
      label: 'Edit',
      action: handleEditClick,
    }],
  } : undefined;

  return (
    <UserView
      agentId={userId}
      onNavigateBack={handleNavigateBack}
      userInfoContextMenuProps={editContextMenu}
    />
  );
};

export default UserProfileContainer;

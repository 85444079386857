import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ConversationType, Message, MessageType, TripMatch, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import {
  TemplatePluginHandle,
  useApiClient,
  useFetch,
} from '@marageti/z4-lib';
import { useSnackbar } from 'notistack';
import { PersonType } from '@marageti/z4-sdk/lib/people';
import ZicassoChatView from './zicasso-chat-view';
import useZicassoChat from '../../hooks/use-zicasso-chat';
import useDocumentDownload from '../../hooks/use-document-download';
import { useAuthStore, useConversationsStore } from '../../store';
import { getConversationTypeFromTripMatch } from '../../utils/helpers';

const ZicassoChatContainer = () => {
  //  There will always be a conversationId in the params, but not always a tripMatchId in the params
  const { tripMatchId: tripMatchIdFromParams, conversationId } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const [onClickDocument] = useDocumentDownload();
  const { loggedInAgent } = useAuthStore();
  const {
    conversationsMap,
  } = useConversationsStore();
  const {
    conversation,
    isError,
    isLoading,
    isValidating,
    messages,
    participants,
    sendMessage,
    lastSeenMessageId,
    loadMoreMessages,
    hasMoreMessages,
    updateMessages,
  } = useZicassoChat({
    conversationId,
    viewerId: loggedInAgent?.id,
  });
  const viewer = {
    id: loggedInAgent?.id,
    type: PersonType.Agent,
  };

  //  Use the tripMatchId from the conversationMap if it exists, otherwise use the tripMatchId from the params
  let tripMatchId = tripMatchIdFromParams;
  if (conversationsMap !== undefined) {
    tripMatchId = conversationsMap[conversationId!]?.tripMatchId;
  }

  const [openDialog, setOpenDialog] = useState('');

  const { data: tripMatch } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const conversationType = getConversationTypeFromTripMatch(tripMatch, conversationId);
  const blockMessaging = conversationType === ConversationType.TravelerToPartner && tripMatch?.status === TripMatchStatus.MatchPending;
  const isInternal = conversationType !== ConversationType.ZicassoToPartner;
  const readOnly = tripMatch?.status === TripMatchStatus.MatchAccepted && !!loggedInAgent?.userInfo[0]?.roles?.includes(RoleName.AGENT_SUPPORT);

  const templatePluginRef = useRef<TemplatePluginHandle>(null);

  const setTemplateText = (value: string) => {
    if (templatePluginRef.current) {
      templatePluginRef.current.insertTemplate(value);
    }
  };

  const closeDialog = () => setOpenDialog('');

  const handleDocumentClick = async (message: Message) => {
    const documentId = message.entityId;
    try {
      if (documentId) {
        const document = await apiClient.documentsClient.getDocument(documentId);
        if (document.sourceURI) {
          window.open(document.sourceURI, '_blank');
        } else {
          onClickDocument(document);
        }
      }
    } catch (error: any) {
      const snackbarMessage = error?.code === 404 ? 'Document not found' : 'Error downloading document';
      enqueueSnackbar(snackbarMessage, { variant: 'default' });
    }
  };

  const handleSalesRecordingClick = async (message: Message) => {
    const salesRecordingId = message.entityId;
    if (salesRecordingId) {
      navigate(`/trip-matches/${tripMatchId}/sales/${salesRecordingId}`);
    }
  };

  const handleNonEntityViewClick = (message: Message) => {
    if (message.messageType === MessageType.TripMatchCanceled) {
      navigate(`/trip-matches/${tripMatchId}`);
    }
  };

  const handleViewClick = (message: Message) => {
    switch (message.entityType) {
      case 'Document':
        handleDocumentClick(message);
        break;
      case 'SalesRecording':
        handleSalesRecordingClick(message);
        break;
      default:
        handleNonEntityViewClick(message);
        break;
    }
  };

  const handleOnSend = (text: string) => {
    sendMessage({ conversationId, text });
  };

  return (
    <ZicassoChatView
      blockMessaging={blockMessaging}
      closeDialog={closeDialog}
      conversation={conversation}
      conversationType={conversationType}
      handleViewClick={handleViewClick}
      isError={isError}
      isInternal={isInternal}
      isLoading={isLoading}
      isValidating={isValidating}
      messages={messages}
      lastMessageSeenId={lastSeenMessageId}
      loadMoreMessages={loadMoreMessages}
      hasMoreMessages={hasMoreMessages}
      openDialog={openDialog}
      participants={participants}
      onSend={handleOnSend}
      readOnly={readOnly}
      setOpenDialog={setOpenDialog}
      setTemplateText={setTemplateText}
      templatePluginRef={templatePluginRef}
      tripMatchId={tripMatchId || ''}
      viewer={viewer}
      updateMessages={updateMessages}
    />
  );
};

export default ZicassoChatContainer;

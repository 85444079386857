import React, { useState } from 'react';
import { BearerToken } from '@marageti/z4-sdk/lib/auth';
import { useApiClient, LocalStorageService } from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { GoogleLoginRequest, LoginRequest } from '@marageti/z4-sdk/lib/auth/Token';
import { Agent } from '@marageti/z4-sdk/lib/people';
import LoginView from './login-view';
import { useAuthStore } from '../../store';
import { getMatchingUser, hasAuthorizedRole } from '../../utils/auth';

const ENV = process.env.REACT_APP_STAGE;

const LocalStorage = new LocalStorageService();

const LoginContainer = () => {
  const { removeAuthenticatedAgent, setIsAuthenticated, setAuthenticatedAgent } = useAuthStore();
  const apiClient = useApiClient();

  const [loginError, setLoginError] = useState<ApiError>();

  const [loading, setLoading] = useState(false);

  const onSubmit = (loginRequest: LoginRequest | GoogleLoginRequest): void => {
    setLoginError(undefined);
    setLoading(true);

    // Prevents non zicasso emails from being used
    if (ENV !== 'prod' && 'username' in loginRequest && !loginRequest.username.includes('zicasso')) {
      setIsAuthenticated(false);
      setLoginError({
        id: '',
        code: 401,
        message: 'Unauthorized',
      });
      setLoading(false);
      return;
    }

    apiClient.authClient.login(loginRequest)
      .then(async (data: BearerToken) => {
        apiClient.instance.setAuthToken(data.accessToken);
        const token = await apiClient.authClient.getToken(data.accessToken);

        LocalStorage.addToLocalStorage('accessToken', data.accessToken);
        LocalStorage.addToLocalStorage('email', token.email);
        if (token.superEmail) {
          LocalStorage.addToLocalStorage('superEmail', token.superEmail);
        }

        return apiClient.peopleClient.getAgent(token.email);
      })
      .then((data: Agent) => {
        const matchingUser = getMatchingUser(data.email, data.userInfo);
        if (!matchingUser || !hasAuthorizedRole(matchingUser.roles)) {
          throw new Error('Not Authorized');
        }
        setAuthenticatedAgent(data);
        setIsAuthenticated(true);
      })
      .catch((e: ApiError) => {
        removeAuthenticatedAgent();
        setIsAuthenticated(false);
        setLoginError(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <LoginView
      loading={loading}
      loginError={loginError}
      onSubmit={onSubmit}
    />
  );
};

export default LoginContainer;

import React from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import PoliciesAndTermsView from './policies-and-terms-view';
import { useAuthStore } from '../../../store';

const PoliciesAndTermsContainer = () => {
  const {
    loggedInAgent,
  } = useAuthStore();

  const apiClient = useApiClient();
  const partnerId = loggedInAgent?.partnerId;
  const {
    data: partner,
    isLoading,
    error,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);

  const isAdmin = () => {
    let agentAdmin = false;
    if (loggedInAgent?.userInfo && loggedInAgent?.userInfo?.length > 0) {
      agentAdmin = loggedInAgent.userInfo[0].roles?.includes(RoleName.AGENT_ADMIN) || false;
    }
    return agentAdmin;
  };

  return (
    <PoliciesAndTermsView
      partner={partner}
      error={error}
      loading={isLoading}
      isAdmin={isAdmin}
    />
  );
};

export default PoliciesAndTermsContainer;

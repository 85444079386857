import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useConversationsStore, useInboxStore } from '../../../store';
import InboxView from './messages-inbox-view';

const InboxContainer = () => {
  const {
    conversationsMap,
    isLoading,
    unreadCount,
  } = useConversationsStore();
  const {
    selectedInboxConversationId,
    setSelectedInboxConversationId,
    setShowUnreadOnly,
    showUnreadOnly,
  } = useInboxStore();

  const { conversationId } = useParams();
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);

  const toggleLeftSidebar = () => {
    setIsLeftSidebarOpen((prev: boolean) => !prev);
  };

  const onClickUnread = () => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  useEffect(() => {
    // If the url parameter doesn't align with the store, update the store
    if (conversationId !== selectedInboxConversationId) {
      setSelectedInboxConversationId(conversationId);
    }
  }, [conversationId, selectedInboxConversationId]);

  useEffect(() => {
    // If all messages have been read, deselect the filter
    if (unreadCount === 0) {
      setShowUnreadOnly(false);
    }
  }, [unreadCount]);

  return (
    <InboxView
      hasConversations={Object.keys(conversationsMap).length > 0}
      isLeftSidebarOpen={isLeftSidebarOpen}
      isLoading={isLoading}
      onClickUnread={onClickUnread}
      toggleLeftSidebar={toggleLeftSidebar}
      selectedInboxConversationId={selectedInboxConversationId}
      showUnreadOnly={showUnreadOnly}
      unreadCount={unreadCount}
    />
  );
};

export default InboxContainer;

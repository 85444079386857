import React from 'react';
import { OutlinedIcons } from '@marageti/z4-lib';
import { useSearchParams } from 'react-router-dom';
import { useAuthStore } from '../../store';
import { HomeLink } from './types';
import HomeView from './home-view';

const {
  OutlinedCogIcon,
  OutlinedInboxInIcon,
  OutlinedStarIcon,
  OutlinedCalendarIcon,
  OutlinedCalculatorIcon,
} = OutlinedIcons;

const HOME_LINKS: HomeLink[] = [
  {
    title: 'Trips',
    subTitle: 'Manage your trips',
    href: '/trip-matches',
    icon: <OutlinedInboxInIcon />,
  },
  {
    title: 'Reviews',
    subTitle: 'See reviews for your company',
    href: '/reviews',
    icon: <OutlinedStarIcon />,
  },
  {
    title: 'Availability',
    subTitle: 'Provide capacity updates',
    href: 'availability',
    icon: <OutlinedCalendarIcon />,
  },
  {
    title: 'Sales',
    subTitle: 'View trips sales and invoices',
    href: '/sales',
    icon: <OutlinedCalculatorIcon />,
  },
  {
    title: 'Settings',
    subTitle: 'Manage company & app settings',
    href: '/settings',
    icon: <OutlinedCogIcon />,
  },
];

const HomeContainer = () => {
  const {
    loggedInAgent,
  } = useAuthStore();
  const [searchParams] = useSearchParams();
  const welcome = searchParams.get('welcome');

  const welcomeMessage = ((welcome === 'true' && loggedInAgent) ? `Welcome to the Zicasso Partners Platform, ${loggedInAgent.givenName}!` : undefined);
  const today = new Date();
  const formatedDate = today.toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <HomeView
      links={HOME_LINKS}
      date={formatedDate}
      givenName={loggedInAgent?.givenName || ''}
      welcomeMessage={welcomeMessage}
    />
  );
};

export default HomeContainer;

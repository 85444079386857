import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import TripSubnavLayout from '../../pages/trip-match/subnav-layout';
import TripMessages from '../../pages/trip-match/messages';
import TripDocuments from '../../pages/trip-match/docs';
import TripReview from '../../pages/trip-match/review';
import TripMatchDetails from '../../pages/trip-match/details/details-container';
import TripMatchEdit from '../../pages/trip-match/edit/trip-match-edit-container';
import TripMatchSales from '../../pages/trip-match/sales';
import SalesRecording from '../../pages/trip-match/sales-recording';
import SalesRecordingForm from '../../pages/trip-match/sales/sales-recording-form';
import SalesAdjustmentForm from '../../pages/trip-match/sales/sales-adjustment-form';
import TripsSubnavLayout from '../../pages/trip-matches';
import TripsSold from '../../pages/trip-matches/sold';
import TripsAll from '../../pages/trip-matches/all';
import TripsInbox from '../../pages/trip-matches/messages-inbox';
import TripsInboxConversation from '../../pages/trip-matches/messages-inbox/conversation';
import AcceptTripSubnavLayout from '../../pages/trip-accept';
import AcceptTripMessages from '../../pages/trip-accept/messages';
import AcceptTrip from '../../pages/trip-accept/accept-lead';
import Traveler from '../../pages/traveler';

const useTripMatchesRoutes = () => (
  <>
    {/* Trip Matches list pages and inbox */}
    <Route path="/trip-matches" element={<TripsSubnavLayout />}>
      <Route index element={<TripsAll />} handle={{ tab: '/' }} />
      <Route path="sold" element={<TripsSold />} handle={{ tab: '/sold' }} />
      <Route path="messages" element={<TripsInbox />} handle={{ tab: '/messages' }}>
        <Route path=":conversationId" element={<TripsInboxConversation />} />
      </Route>
    </Route>
    {/* Accept trip match pages. Admin only */}
    <Route path="trip-matches/:tripMatchId/accept" element={<AcceptTripSubnavLayout />}>
      <Route index element={<AcceptTrip />} handle={{ tab: '/' }} />
      <Route path="messages" element={<AcceptTripMessages />} handle={{ tab: '/messages' }} />
    </Route>
    {/* Trip match details and management */}
    <Route path="trip-matches/:tripMatchId" element={<TripSubnavLayout />}>
      <Route index element={<Navigate to="details" replace />} handle={{ tab: '/details' }} />
      <Route path="details" element={<TripMatchDetails />} handle={{ tab: '/details' }} />
      <Route path="edit" element={<TripMatchEdit />} handle={{ tab: '/details' }} />
      <Route path="messages" element={<TripMessages />} handle={{ tab: '/messages' }} />
      <Route path="documents" element={<TripDocuments />} handle={{ tab: '/documents' }} />
      <Route path="review" element={<TripReview />} handle={{ tab: '/review' }} />
      <Route path="sales" element={<TripMatchSales />} handle={{ tab: '/sales' }} />
      <Route path="sales/:salesRecordingId" element={<SalesRecording />} handle={{ tab: '/sales' }} />
    </Route>
    <Route path="trip-matches/:tripMatchId/travelers/:travelerId" element={<Traveler />} />
    <Route path="trip-matches/:tripMatchId/sales/recording" element={<SalesRecordingForm />} />
    <Route path="trip-matches/:tripMatchId/sales/recording/:salesRecordingId" element={<SalesRecordingForm />} />
    <Route path="trip-matches/:tripMatchId/sales/adjustment" element={<SalesAdjustmentForm />} />
    <Route path="trip-matches/:tripMatchId/sales/adjustment/:salesAdjustmentId" element={<SalesAdjustmentForm />} />
  </>
);

export default useTripMatchesRoutes;

import React from 'react';
import {
  Box, Container, Stack, Typography,
} from '@mui/material';
import { ViewProps } from './types';
import LinkCard from '../../components/link-card';

const SettingsView = ({
  links,
}: ViewProps) => (
  <Container
    maxWidth="xl"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      sx={{
        py: { desktop: 7, mobile: 3 },
        minHeight: {
          desktop: 'calc(100vh - 508px)',
        },
        width: '100%',
        maxWidth: {
          desktop: '774px',
        },
      }}
    >
      <Typography component="h2" variant="h2" sx={{ pb: 3 }}>
        Settings
      </Typography>
      <Stack
        spacing={1.5}
        justifyContent={{
          desktop: 'space-between',
        }}
        style={{ width: '100%' }}
      >
        {links.map((ele) => (
          <LinkCard
            key={ele.title}
            href={ele.href}
            title={ele.title}
            subTitle={ele.subTitle}
            icon={ele.icon}
          />
        ))}
      </Stack>
    </Box>
  </Container>
);

export default SettingsView;

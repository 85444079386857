import React from 'react';
import { CustomIcons, OutlinedIcons } from '@marageti/z4-lib';
import {
  Box, Container, Stack, Typography,
} from '@mui/material';
import LinkCard, { LinkCardProps } from '../../components/link-card';

const {
  OutlinedCollectionIcon,
} = OutlinedIcons;

const SALES_LINKS: LinkCardProps[] = [
  {
    title: 'Invoices',
    subTitle: 'See details about your monthly invoices',
    href: 'invoices',
    icon: <CustomIcons.ReceiptIcon />,
  },
  {
    title: 'All Sales',
    subTitle: 'See all historical sales',
    href: 'all',
    icon: <OutlinedCollectionIcon />,
  },
];

const SalesHome = () => (
  <Container
    maxWidth="xl"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      sx={{
        py: { desktop: 7, mobile: 3 },
        minHeight: {
          desktop: 'calc(100vh - 508px)',
        },
        width: '100%',
        maxWidth: {
          desktop: '774px',
        },
      }}
    >
      <Typography component="h2" variant="h2" sx={{ pb: 3 }}>
        Sales
      </Typography>
      <Stack
        spacing={1.5}
        justifyContent={{
          desktop: 'space-between',
        }}
        style={{ width: '100%' }}
      >
        {SALES_LINKS.map((ele) => (
          <LinkCard
            key={ele.title}
            href={ele.href}
            title={ele.title}
            subTitle={ele.subTitle}
            icon={ele.icon}
          />
        ))}
      </Stack>
    </Box>
  </Container>
);

export default SalesHome;

import React, { Ref } from 'react';
import {
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  ZicassoChat,
  OutlinedIcons,
  TemplatePluginHandle,
} from '@marageti/z4-lib';
import {
  ConversationType, GetParticipantsResponse, Message, Conversation,
} from '@marageti/z4-sdk/lib/travel';
import { PersonType } from '@marageti/z4-sdk/lib/people';
import ChooseTemplateDialog from '../zicasso-dialogs/choose-template';
import AddDocumentsDialog from '../zicasso-dialogs/add-documents-dialog';
import AddItineraryDialog from '../zicasso-dialogs/add-itinerary';
import LogCallTextDialog from '../zicasso-dialogs/log-call';
import AddNoteDialog from '../zicasso-dialogs/note';

type Props = {
  blockMessaging: boolean,
  closeDialog: () => void,
  conversation: Conversation | undefined,
  conversationType: ConversationType | undefined,
  handleViewClick: (message: Message) => void,
  isError: boolean,
  isInternal: boolean,
  isLoading: boolean,
  isValidating: boolean,
  messages: Message[] | undefined,
  lastMessageSeenId: string | undefined,
  loadMoreMessages: () => void,
  hasMoreMessages: boolean,
  openDialog: string,
  participants: GetParticipantsResponse | undefined,
  onSend: (text: string) => void,
  readOnly: boolean,
  setOpenDialog: (value: string) => void,
  setTemplateText: (text: string) => void,
  templatePluginRef: Ref<TemplatePluginHandle>,
  tripMatchId: string,
  viewer: {
    id: string | undefined,
    type: PersonType,
  },
  updateMessages: () => void,

};

const ZicassoChatView = ({
  blockMessaging,
  closeDialog,
  conversation,
  conversationType,
  handleViewClick,
  isError,
  isInternal,
  isLoading,
  isValidating,
  messages,
  lastMessageSeenId,
  loadMoreMessages,
  hasMoreMessages,
  openDialog,
  participants,
  onSend,
  readOnly,
  setOpenDialog,
  setTemplateText,
  templatePluginRef,
  tripMatchId,
  viewer,
  updateMessages,
}: Props) => {
  const contextMenuItems = [
    {
      icon: <OutlinedIcons.OutlinedUploadIcon color="primary" />,
      action: () => setOpenDialog('addDocuments'),
      label: 'Upload Travel Documents',
    },
    {
      icon: <OutlinedIcons.OutlinedPhoneOutgoingIcon color="primary" />,
      action: () => setOpenDialog('logTextCall'),
      label: 'Log Text/Call',
    },
    {
      icon: <OutlinedIcons.OutlinedPencilAltIcon color="primary" />,
      action: () => setOpenDialog('addNote'),
      label: conversationType === ConversationType.ZicassoToPartner ? 'Add Note' : 'Add Internal Note',
    },
    {
      icon: <OutlinedIcons.OutlinedLocationMarkerIcon color="primary" />,
      action: () => setOpenDialog('addItinerary'),
      label: 'Add Itinerary',
    },
  ];
  // Hide 'Upload Travel Documents' and 'Add Itinerary' for some conversation types
  const filteredContextMenuItems = isLoading ? [] : contextMenuItems.filter((item) => {
    if (conversationType !== ConversationType.TravelerToPartner) {
      return item.label !== 'Upload Travel Documents' && item.label !== 'Add Itinerary';
    }
    return true;
  });

  return (
    <>
      {blockMessaging ? (
        <Alert severity="error">
          <AlertTitle>This Trip Has Not Been Accepted</AlertTitle>
          Once accepted, you will be able to message the traveler.
        </Alert>
      ) : (
        <ZicassoChat
          conversation={conversation}
          contextMenuItems={filteredContextMenuItems}
          insertTemplateProps={{
            onShowTemplateModal: () => setOpenDialog('chooseTemplate'),
            ref: templatePluginRef,
          }}
          isError={isError}
          isLoading={isLoading}
          isValidating={isValidating}
          messagingAlert={(
            <Alert
              severity="info"
              sx={{
                borderColor: 'divider', px: 1.5, py: 1, ml: 4.25, mb: 1.5,
              }}
              variant="outlined"
            >
              Always communicate through this messaging platform to ensure communication is recorded.
            </Alert>
          )}
          messages={messages}
          lastMessageSeenId={lastMessageSeenId}
          onSend={onSend}
          onLoadPreviousPage={loadMoreMessages}
          readOnly={readOnly}
          loadPreviousPages={hasMoreMessages}
          onViewClick={handleViewClick}
          participants={participants}
          viewer={viewer}
        />
      )}
      <ChooseTemplateDialog
        closeDialog={closeDialog}
        onSubmit={setTemplateText}
        open={openDialog === 'chooseTemplate'}
      />
      <AddDocumentsDialog
        closeDialog={closeDialog}
        onDocumentUploaded={updateMessages}
        open={openDialog === 'addDocuments'}
        tripMatchId={tripMatchId}
      />
      <AddItineraryDialog
        closeDialog={closeDialog}
        onDocumentUploaded={updateMessages}
        open={openDialog === 'addItinerary'}
        tripMatchId={tripMatchId}
      />
      <LogCallTextDialog
        closeDialog={closeDialog}
        open={openDialog === 'logTextCall'}
        onSuccess={updateMessages}
      />
      <AddNoteDialog
        closeDialog={closeDialog}
        isInternal={isInternal}
        onSuccess={updateMessages}
        open={openDialog === 'addNote'}
      />
    </>
  );
};

export default ZicassoChatView;

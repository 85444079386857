import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFetch, useApiClient } from '@marageti/z4-lib';
import {
  SalesRecording, TripMatch, TripMatchStatus, TripRequest,
} from '@marageti/z4-sdk/lib/travel';
import { RoleName, UserStatus } from '@marageti/z4-sdk/lib/auth';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { enqueueSnackbar } from 'notistack';
import DetailsView from './details-view';
import { useAuthStore } from '../../../store';
import { createAlertObj } from '../../../components/alerts/utils';

const DetailsContainer = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { loggedInAgent } = useAuthStore();
  const { tripMatchId } = useParams();

  const { data: tripMatch, mutate } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], true);
  const { tripRequestId, partnerId } = tripMatch || {};

  const { data: tripRequest } = useFetch<TripRequest>(apiClient.tripRequestsClient.getTripRequest, [tripRequestId], !!tripRequestId);
  const { data: agents } = useFetch<Agent[]>(apiClient.partnersClient.getPartnerAgents, [partnerId], !!partnerId);
  const { data: salesRecordings } = useFetch<SalesRecording[]>(apiClient.tripMatchesClient.getTripMatchSalesRecordings, [tripMatchId], !!tripMatchId);

  const assignableAgents = agents?.filter((agent) => agent.userInfo.some((userInfo) => userInfo.status === UserStatus.ENABLED && !userInfo.roles?.includes(RoleName.AGENT_SUPPORT)));
  const loggedInAgentIsAdmin = loggedInAgent?.userInfo.some((userInfo) => userInfo?.roles?.includes(RoleName.AGENT_ADMIN));

  const getAssignedSpecialist = () => {
    if (tripMatch?.assignedTo && agents) {
      return agents.find((agent) => agent.id === tripMatch?.assignedTo);
    }
    return undefined;
  };

  const handleReactivation = async () => {
    if (tripMatchId && tripMatch) {
      try {
        await apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId, {
          id: tripMatchId,
          status: TripMatchStatus.MatchActive,
          statusInfo: {
            reason: 'Agent Reactivated Trip',
          },
        });
        mutate();
        enqueueSnackbar('Trip reactivated.', { variant: 'success' });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);

  const alert = createAlertObj(tripMatch, salesRecordings, loggedInAgent, handleReactivation);

  // Redirect to accept page if match is pending
  if (tripMatch?.status === TripMatchStatus.MatchPending) {
    return <Navigate replace to={`/trip-matches/${tripMatchId}/accept`} />;
  }

  return (
    <DetailsView
      agents={assignableAgents?.sort((a, b) => a.fullName.localeCompare(b.fullName)) || []}
      alert={alert}
      assignedSpecialist={getAssignedSpecialist()}
      isAdmin={!!loggedInAgentIsAdmin}
      onClickEdit={() => navigate('../edit')}
      salesRecordings={salesRecordings}
      showModal={showModal}
      toggleModal={toggleModal}
      travelers={tripMatch?.travelers}
      tripMatch={tripMatch}
      tripMatchId={tripMatchId}
      tripRequest={tripRequest}
    />
  );
};

export default DetailsContainer;

import React from 'react';
import { Outlet } from 'react-router-dom';
import TripSubNavigation from '../../../components/trip-match-sub-navigation';

const View = () => (
  <>
    <TripSubNavigation />
    <Outlet />
  </>
);

export default View;

import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Conversation } from '@marageti/z4-sdk/lib/travel/Conversation';

export const SET_CONVERSATIONS_LOADING = 'SET_CONVERSATIONS_LOADING';
export const setConversationsLoading = (bool: boolean) => ({
  type: SET_CONVERSATIONS_LOADING,
  payload: bool,
});

export const SET_CONVERSATIONS_ERROR = 'SET_CONVERSATIONS_ERROR';
export const setConversationsError = (error: ApiError) => ({
  type: SET_CONVERSATIONS_ERROR,
  payload: error,
});

export const SET_CONVERSATIONS_MAP = 'SET_CONVERSATIONS_MAP';
export const setConversationsMap = (conversationsMap: Record<string, Conversation>) => ({
  type: SET_CONVERSATIONS_MAP,
  payload: conversationsMap,
});

export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const setUnreadCount = (unreadCount: number) => ({
  type: SET_UNREAD_COUNT,
  payload: unreadCount,
});

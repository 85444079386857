import React from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Traveler } from '@marageti/z4-sdk/lib/people';
import { TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import TravelerView from './traveler-view';

const TravelerContainer = () => {
  const { tripMatchId, travelerId } = useParams();
  const apiClient = useApiClient();
  const { data: traveler, isLoading: isLoadingTr, error: errorTr } = useFetch<Traveler>(apiClient.tripMatchesClient.getTripMatchTraveler, [tripMatchId, travelerId], !!travelerId);
  const {
    data: tripMatchData,
    error: tripMatchError,
    isLoading: isLoadingTripMatch,
  } = useFetch<TripMatch>(
    apiClient.tripMatchesClient.getTripMatch,
    [tripMatchId],
    true,
  );

  return (
    <TravelerView
      error={errorTr || tripMatchError}
      hidePhone={tripMatchData?.status === TripMatchStatus.MatchPending || tripMatchData?.status === TripMatchStatus.MatchAccepted}
      isLoadingTr={isLoadingTr || isLoadingTripMatch}
      traveler={traveler}
    />
  );
};

export default TravelerContainer;

import React from 'react';
import {
  Box, Button, Container, Stack, Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundSVG } from '../../assets/svg/404@2x.svg';

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const goHome = () => navigate('/');

  return (
    <Container maxWidth="xl">
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        sx={{
          height: {
            desktop: 'calc(100vh - 73px)',
            mobile: 'calc(100vh - 56px)',
          },
        }}
      >
        <NotFoundSVG
          style={{
            marginBottom: '26px',
          }}
        />
        <Typography variant="body1">Sorry, we couldn&apos;t find this page</Typography>
        <Typography variant="body1">
          Please
          <span style={{ fontWeight: 'bold', color: '#0D1718', cursor: 'pointer' }} onClick={goBack}>&nbsp;go back</span>
        &nbsp;or
          <Link style={{ fontWeight: 'bold', color: '#0D1718', textDecoration: 'none' }} to="/"> return home</Link>
        </Typography>
        <Stack
          spacing={1}
          style={{
            maxWidth: '342px', width: '100%', position: 'fixed', bottom: '1.5rem',
          }}
        >
          <Button variant="outlined" onClick={goBack}>
            Go Back
          </Button>
          <Button variant="contained" onClick={goHome}>
            Return Home
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default NotFound;

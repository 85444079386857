import React from 'react';
import {
  Alert,
  AlertTitle,
  Box, Container, Stack,
} from '@mui/material';
import {
  Card,
  ListItemCardContent,
  ListItemCardTitle,
  ListItemCardSubtitle,
  Link,
} from '@marageti/z4-lib';
import { HomeLink, ViewProps } from './types';

const LinkCard = ({
  href,
  title,
  subTitle,
  icon,
}:HomeLink) => (
  <Link
    to={href}
    sx={{
      maxWidth: {
        desktop: '510px',
      },
      width: {
        desktop: '100%',
      },
    }}
  >
    <Card
      sx={{
        '&:hover': {
          bgcolor: 'grey.A100',
        },
      }}
    >
      <ListItemCardContent>
        <Stack>
          <ListItemCardTitle>{title}</ListItemCardTitle>
          <ListItemCardSubtitle>{subTitle}</ListItemCardSubtitle>
        </Stack>
        {icon && (icon)}
      </ListItemCardContent>
    </Card>
  </Link>
);

const HomeView = ({
  links,
  welcomeMessage,
}: ViewProps) => (
  <Container
    maxWidth="xl"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      sx={{
        py: { desktop: 7, mobile: 3 },
        minHeight: {
          desktop: 'calc(100vh - 508px)',
        },
        width: '100%',
        maxWidth: {
          desktop: '510px',
        },
      }}
    >
      {welcomeMessage && (
      <Alert
        severity="success"
        sx={{
          mb: 3,
        }}
      >
        <AlertTitle>
          {welcomeMessage}
        </AlertTitle>
      </Alert>
      )}
      <Stack
        spacing={1.5}
        justifyContent={{
          desktop: 'space-between',
        }}
        style={{ width: '100%' }}
      >

        { links.map((ele) => (
          <LinkCard
            key={ele.title}
            href={ele.href}
            title={ele.title}
            subTitle={ele.subTitle}
            icon={ele.icon}
          />
        ))}
      </Stack>
    </Box>
  </Container>
);

export default HomeView;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  FormElement,
  Inputs,
  Validate,
  getSubmitButtonProps,
  useApiClient,
} from '@marageti/z4-lib';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import ZSpinner from '../../z-lib/z-spinner';

interface EmailLinkFormProps {
  email?: string;
  onSuccess: () => void;
  type: 'activate' | 'reset';
}

const emailPattern = Validate.emailRegex();

const EmailLinkForm = ({
  email = undefined,
  onSuccess,
  type,
}: EmailLinkFormProps) => {
  const apiClient = useApiClient();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm();

  const onSubmit = (data: FieldValues): void => {
    setLoading(true);

    // All cases handled as successful submits.
    // Only emails with accounts will receive an email.
    apiClient.authClient.postUserReset(data.email)
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
        onSuccess();
      });
  };

  return (
    <>
      { loading && (
      <ZSpinner />
      )}
      <FormElement
        name="send-link-form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: '100%',
        }}
      >
        <Stack spacing="24px" alignItems="center">
          <Stack spacing="12px" width="100%">
            <Inputs.Email
              defaultValue={email}
              readOnly={type === 'activate'}
              error={!!errors?.email}
              helperText={errors?.email ? 'Please enter a valid email address' : undefined}
              id="email"
              label="Email"
              {...register('email', { required: true, pattern: emailPattern })}
            />
          </Stack>
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ width: '100%' }}
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            {type === 'reset' ? 'Reset Password' : 'Send New Link'}
          </LoadingButton>
        </Stack>
      </FormElement>
    </>
  );
};

export default EmailLinkForm;

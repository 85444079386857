import React from 'react';
import {
  Alert,
  AlertTitle,
  Skeleton,
  Typography,
} from '@mui/material';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import EditBillingInfoForm from './edit-billing-info-form';

type BillingInfoEditViewProps = {
  partnerData: Partner
  isLoading: boolean
  partnerError: any
  onSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
};

const EditBillingInfoView = ({
  partnerError,
  isLoading,
  partnerData,
  onSubmit,
  submissionError,
  onCancel = undefined,
}: BillingInfoEditViewProps) => {
  if (isLoading || !partnerData) {
    return (
      <>
        <Typography variant="h2" sx={{ mb: 3 }}>Edit Billing Info</Typography>
        <Skeleton variant="rounded" height={500} />
      </>
    );
  }

  if (partnerError) {
    return (
      <Alert severity="error">
        <AlertTitle>Billing Info Error</AlertTitle>
        There was an error getting the billing info.
      </Alert>
    );
  }

  return (
    <EditBillingInfoForm
      partner={partnerData}
      submissionError={submissionError}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default EditBillingInfoView;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
  InputPhone,
  InputComboBoxObjectOption,
  Validate,
} from '@marageti/z4-lib';
import { PartnerOffice } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap, Phone } from '@marageti/z4-sdk/lib/standards';

type EditFormDefaultValues = {
  city: string | undefined,
  country: any,
  phone: Phone | undefined,
};

const DEFAULT_VALUES = {
  city: '',
  country: {
    label: '',
    value: '',
  },
  phone: {
    prefix: '',
    number: '',
  },
} as EditFormDefaultValues;

const schema = z.object({
  city: z.string().min(1, 'Required'),
  country: z.object({
    label: z.string().min(1, 'Required'),
    value: z.string().min(1, 'Required'),
  }),
  phone: z.object({
    prefix: z.string(),
    number: z.string(),
  }).refine((value) => {
    if (!value) return true;
    if (!value.number) return true;
    return Validate.phone(`${value.prefix}${value.number}`);
  }, 'Invalid phone number'),
});

type EditFormProps = {
  countryCodes: CountryCodeMap
  office: PartnerOffice
  onSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
  titleFragment?: string
};

const getFormValues = (office: PartnerOffice | undefined, countryCodes: CountryCodeMap, defaultValues: EditFormDefaultValues): any => {
  if (!office) return defaultValues;
  const countryVal = office.country ? {
    label: countryCodes[office.country],
    value: office.country,
  } : defaultValues.country;
  return {
    city: office?.city || defaultValues.city,
    country: countryVal,
    phone: office?.phone || defaultValues.phone,
  };
};

const EditForm = ({
  office,
  countryCodes,
  onSubmit,
  submissionError,
  onCancel = undefined,
  titleFragment = 'Edit',
}:EditFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    control,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: getFormValues(office, countryCodes, DEFAULT_VALUES),
  });

  const countryOptions = useMemo(() => {
    if (!countryCodes) {
      return [{
        label: '',
        value: '',
      }];
    }

    const list = Object.entries(countryCodes || {}).map(([code, label]) => (
      {
        label,
        value: code,
      }
    ));
    return list;
  }, [countryCodes]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{`${titleFragment} Office`}</Typography>
      <form {...getFormProps(handleSubmit(onSubmit))}>
        <Stack spacing={3}>
          <Stack>
            <TextField
              label="City"
              {...register('city')}
              {...getFieldProps('city', errors)}
              required
            />
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <InputComboBoxObjectOption
                  error={!!errors?.country}
                  helperText={errors?.country?.message as string}
                  id="country"
                  label="Country"
                  options={countryOptions}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  {...field}
                  onChange={(_: any, data:any) => {
                    const v = data || DEFAULT_VALUES.country;
                    setValue('country', v, { shouldValidate: true });
                  }}
                  value={field.value || null}
                  required
                />
              )}
              rules={{ required: 'Please select a country' }}
            />
            <Controller
              control={control}
              name="phone"
              defaultValue={office.phone}
              render={({ field }) => (
                <InputPhone
                  id="phone"
                  label="Phone Number"
                  {...getFieldProps('phone', errors)}
                  {...field}
                />
              )}
            />
          </Stack>

          {submissionError && (
            <Alert severity="error">
              <AlertTitle>There was an error submitting the form.</AlertTitle>
              Please try again.
            </Alert>
          )}

          <Stack
            direction="row"
            spacing={2}
            sx={(theme) => ({
              [theme.breakpoints.down('desktop')]: {
                marginTop: 'auto',
                flexDirection: 'column-reverse',
                '& > :not(:first-of-type)': {
                  margin: theme.spacing(0, 0, 2.5, 0),
                },
              },
            })}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              {...getSubmitButtonProps(isSubmitting, isValid)}
            >
              Save
            </LoadingButton>

            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default EditForm;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Message, MessageType,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import LogView from './log-view';
import useChat from '../../../hooks/use-chat';

type LogContainerProps = {
  closeDialog: () => void,
  onSuccess: () => void,
  open: boolean,
};

const LogContainer = ({
  closeDialog,
  onSuccess,
  open,
}: LogContainerProps) => {
  const { conversationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<Error | null>(null);

  const {
    sender,
    sendMessage,
  } = useChat({
    conversationId,
  });

  const reset = () => {
    setError(null);
  };

  const handleSubmit = ({
    message,
    action,
  }: {
    message: string;
    action: {
      title: string;
      value: string;
    };
  }) => {
    reset();

    const actionString = action.value === 'call' ? 'Phone call log' : 'Text Message log';
    const senderName = sender.data?.fullName;
    const title = [actionString, senderName].filter(Boolean).join(' by ');

    const messageToSend: Partial<Message> = {
      conversationId,
      text: message,
      title,
      messageType: MessageType.PhoneCall,
    };

    return sendMessage(messageToSend)
      .then(() => {
        onSuccess();
        enqueueSnackbar(`${actionString} created.`, {
          variant: 'success',
        });
        closeDialog();
      })
      .catch((e: Error) => {
        setError(e);
      });
  };

  return (
    <LogView
      closeDialog={closeDialog}
      error={error}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};

export default LogContainer;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Avatar,
  AvatarProps,
  Skeleton,
  Box,
  IconButton,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material';
import { OutlinedIcons } from '@marageti/z4-lib';

export type AvatarCardProps = AvatarProps & {
  alt?: string,
  children: React.ReactNode
  diameter: number;
  disableSkeleton?: boolean;
  loading?: boolean;
  src?: string;
  sx?: SxProps<Theme>;
  variant?: 'text' | 'circular' | 'rounded' | 'rectangular' | undefined;
};

const {
  OutlinedPencilAltIcon,
} = OutlinedIcons;

const getSx = (diameter: number) => ({
  width: `${diameter}px`,
  height: `${diameter}px`,
  backgroundColor: 'grey.A100',
  color: 'divider',
  fontWeight: 700,
  fontSize: `${0.25 * diameter + 6}px`,
});

const AvatarCard = ({
  alt = undefined,
  children,
  diameter,
  disableSkeleton = false,
  loading = false,
  src = undefined,
  sx = {},
  variant = 'circular',
  ...props
}: AvatarCardProps) => {
  if (disableSkeleton && loading) return null;
  if (!loading) {
    return (
      <Avatar
        alt={alt}
        src={src}
        sx={{ ...getSx(diameter), ...sx }}
        variant={variant}
        {...props}
      >
        {children}
      </Avatar>
    );
  }
  return (
    <Skeleton
      animation="wave"
      variant={variant}
      sx={{ width: `${diameter}px`, height: `${diameter}px`, ...sx }}
    />
  );
};

export type LogoCardProps = AvatarProps & {
  alt?: string;
  children: React.ReactNode;
  diameter: number;
  editable?: boolean;
  loading?: boolean;
  src?: string;
  sx?: SxProps<Theme>;
};

const LogoCard = ({
  alt = undefined,
  children,
  diameter,
  editable = false,
  loading = false,
  src = undefined,
  sx = {},
}: LogoCardProps) => {
  const theme = useTheme();
  return (
    <>
      {!loading && (
        <Box sx={{ position: 'relative' }}>
          <Avatar
            alt={alt}
            src={src}
            sx={{ ...getSx(diameter), ...sx }}
          >
            {children}
          </Avatar>
          {!!editable
            && (
              <IconButton
                aria-label="edit"
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  background: 'white',
                  border: `1px solid ${theme.palette.divider}`,
                  '> svg': {
                    paddingLeft: '1px',
                    paddingBottom: '1px',
                  },
                }}
              >
                <OutlinedPencilAltIcon color="primary" />
              </IconButton>
            )}
        </Box>
      )}
      {loading && (
        <Skeleton
          animation="wave"
          variant="circular"
          sx={{ width: `${diameter}px`, height: `${diameter}px`, ...sx }}
        />
      )}
    </>
  );
};

export { LogoCard, AvatarCard };

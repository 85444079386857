import React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Navigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TripMatchStatus, TripMatch } from '@marageti/z4-sdk/lib/travel';
import SubNavigationTripView from './trip-match-sub-navigation-view';
import { TabItem } from '../sub-navigation/types';
import { useAuthStore } from '../../store';

const TRIP_LINKS: TabItem[] = [
  {
    e2e: 'ztest-settings-details',
    to: 'details',
    label: 'details',
  },
  {
    e2e: 'ztest-settings-tab-messages',
    to: 'messages',
    label: 'messages',
  },
  {
    e2e: 'ztest-settings-tab-documents',
    to: 'documents',
    label: 'documents',
  },
  {
    e2e: 'ztest-settings-tab-reviews',
    to: 'review',
    label: 'review',
  },
  {
    e2e: 'ztest-settings-tab-invoice',
    to: 'sales',
    label: 'sales',
  },
];

const SubNavigationTripContainer = () => {
  const confirm = useConfirm();
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const { tripMatchId } = useParams();
  const { loggedInAgent } = useAuthStore();
  const tripMatch = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);

  const onClickPostpone = () => {
    confirm({
      title: 'Postpone Trip?',
      description: (
        <span>
          Postponing this trip will move it to the
          {' '}
          <em>Postpone</em>
          {' '}
          section of your queue. It can be reactivated at a later date.
        </span>
      ),
      confirmationText: 'Postpone',
    })
      .then(async () => {
        if (tripMatchId && loggedInAgent?.id) {
          try {
            await apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId, {
              id: tripMatchId,
              status: TripMatchStatus.MatchPostponed,
              statusInfo: {
                reason: 'Postponed by Agent',
              },
            });
            tripMatch.mutate();
            enqueueSnackbar('Trip postponed.', { variant: 'success' });
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch(() => {});
  };

  const onClickInactive = () => {
    confirm({
      title: 'Mark Trip as Inactive?',
      description: (
        <>
          Inactive trips can be reactivated in the future and remain visible to travelers, who can still send messages.
          Trips should only be marked as inactive after you have completed the follow-up timeline or the travel dates have passed.
        </>
      ),
      confirmationText: 'Confirm',
    })
      .then(async () => {
        if (tripMatchId) {
          try {
            await apiClient.tripMatchesClient.putTripMatchStatus(tripMatchId, {
              id: tripMatchId,
              status: TripMatchStatus.MatchInactive,
              statusInfo: {
                reason: 'Trip marked as inactive by Agent',
              },
            });
            tripMatch.mutate();
            enqueueSnackbar('Trip marked as inactive.', { variant: 'success' });
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch(() => {});
  };

  // New Trip Matches should be accepted before being able to navigate to trip match details
  if (tripMatch.data?.status === TripMatchStatus.MatchPending) {
    return <Navigate replace to={`/trip-matches/${tripMatchId}/accept`} />;
  }

  return (
    <SubNavigationTripView
      tabs={TRIP_LINKS}
      onClickPostpone={onClickPostpone}
      onClickInactive={onClickInactive}
    />
  );
};

export default SubNavigationTripContainer;

import React from 'react';
import {
  Box, Divider, IconButton, Skeleton, Stack, Typography,
} from '@mui/material';
import { OutlinedIcons, getFormattedDateShortMonthDDYYYY } from '@marageti/z4-lib';
import { Doc } from '@marageti/z4-sdk/lib/documents';

const {
  OutlinedLinkIcon,
  OutlinedDocumentDownloadIcon,
  OutlinedTrashIcon,
} = OutlinedIcons;

type DocumentListProps = {
  documents: Doc[];
  isLoading?: boolean;
  partnerId: string;
  onClickDocument?: (document: Doc) => void ;
  onClickDelete?: (document: Doc) => void;
  emptyList?: React.ReactNode;
};

const DocumentList = ({
  documents,
  isLoading = false,
  partnerId,
  onClickDocument = undefined,
  onClickDelete = undefined,
  emptyList = undefined,
}: DocumentListProps) => (
  <div>
    {isLoading && (
      <Skeleton variant="rectangular" width="100%" height={72} sx={{ borderRadius: 1 }} />
    )}

    {!isLoading && (!documents || documents?.length === 0) ? (
      emptyList || (
        <Typography variant="body1" sx={{ mb: 28 }}>
          There are no documents attached to this trip.&nbsp;
        </Typography>
      )
    ) : (
      <Stack spacing={2} divider={<Divider flexItem />} sx={{ mb: 4 }}>
        {documents?.map((doc: Doc) => (
          <Stack key={doc.id} direction="row" sx={{ alignItems: 'center' }}>
            <Stack
              onClick={() => onClickDocument && onClickDocument(doc)}
              direction="row"
              sx={{
                alignItems: 'center', width: '100%', cursor: 'pointer',
              }}
            >
              <IconButton
                sx={{
                  width: '44px',
                  height: '44px',
                  borderRadius: '8px',
                  backgroundColor: 'grey.A100',
                  color: 'primary.main',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '16px',
                  ml: '8px',
                }}
              >
                {doc.sourceURI ? (
                  <OutlinedLinkIcon sx={{ width: '24px', height: '24px' }} />
                ) : (
                  <OutlinedDocumentDownloadIcon sx={{ width: '24px', height: '24px' }} />
                )}
              </IconButton>
              <Box>
                <Typography variant="body1" color="primary.main">{doc.title}</Typography>
                <Typography variant="subtitle1" color="primary.light">{getFormattedDateShortMonthDDYYYY(doc.createdAt)}</Typography>
              </Box>
            </Stack>
            {partnerId === doc.owners.PARTNER && (
            <IconButton
              data-e2e="ztest-trip-match-docs-delete"
              onClick={() => onClickDelete && onClickDelete(doc)}
              sx={{ marginLeft: 'auto', mr: '8px', color: 'primary.main' }}
            >
              <OutlinedTrashIcon />
            </IconButton>
            )}
          </Stack>
        ))}
      </Stack>
    )}
  </div>

);

export default DocumentList;

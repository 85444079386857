import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { PartnerCountry, SalesRecording, TripMatch } from '@marageti/z4-sdk/lib/travel';
import Form from './form';

type SalesRecordingFormViewProps = {
  tripMatch: TripMatch,
  salesRecording?: SalesRecording | undefined,
  partnerCountries: PartnerCountry[] | undefined,
  handleSubmit: (data: any) => Promise<void>
  submissionError: boolean
  onCancel?: () => void
};

const SalesRecordingFormView = ({
  tripMatch,
  salesRecording = undefined,
  partnerCountries,
  handleSubmit,
  submissionError,
  onCancel = () => {},
}: SalesRecordingFormViewProps) => {
  const formTitle = salesRecording ? 'Edit Standard Sale' : 'Add Standard Sale';

  return (
    <Stack
      spacing={2}
      width="100%"
    >
      <Stack spacing={2}>
        <Typography variant="h2">{formTitle}</Typography>
        <Form
          countries={partnerCountries}
          defaultValues={{
            countries: salesRecording ? tripMatch.countries : undefined,
            departureDate: salesRecording && tripMatch.departureDate ? new Date(tripMatch.departureDate) : undefined,
            totalNights: salesRecording ? tripMatch.totalNights : undefined,
            numberOfAdults: salesRecording ? tripMatch.numberOfAdults : undefined,
            numberOfChildren: salesRecording ? tripMatch.numberOfChildren : undefined,
            price: {
              currencyCode: salesRecording?.price.currencyCode || 'USD',
              amount: salesRecording?.price.amount,
            },
            commission: {
              currencyCode: salesRecording?.commission.currencyCode || 'USD',
              amount: salesRecording?.commission.amount,
            },
            priceComments: salesRecording?.priceComments,
            document: salesRecording?.document ? [{
              name: salesRecording?.document.sourceFileName,
              type: salesRecording?.document.mediaType,
              size: salesRecording?.document.fileSize,
            }] : undefined,
          }}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          primaryTraveler={tripMatch.travelers[0]}
          readOnlyCurrency={!!salesRecording}
          submissionError={submissionError}
        />
      </Stack>
    </Stack>
  );
};

export default SalesRecordingFormView;

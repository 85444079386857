/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Controller, FieldValues, SubmitHandler,
} from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  DialogTitle,
  List,
  ListItem,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useForm,
  getSubmitButtonProps,
  BackButton,
  useApiClient,
  getFieldProps,
  useFetch,
} from '@marageti/z4-lib';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailabilityLimitType, Partner, PartnerAvailability } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { addYears } from 'date-fns';
import { useAuthStore } from '../../../store';

const EditEvent = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    clearErrors,
    trigger,
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [submissionError, setSubmissionError] = useState<ApiError>();
  const {
    loggedInAgent,
  } = useAuthStore();

  const {
    data: partner,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const {
    data: event, isLoading, mutate: mutatePartnerAvailabilities,
  } = useFetch<PartnerAvailability>(apiClient.partnersClient.getPartnerAvailability, [loggedInAgent?.partnerId, eventId], true);

  const today = dayjs().toDate();
  const maxDate = addYears(new Date(), 10);
  const [minEndDate, setMinEndDate] = useState(dayjs(event?.startDate).toDate());

  const [selectedType, setSelectedType] = useState(event?.limitType);

  useEffect(() => {
    if (event) {
      setSelectedType(event.limitType);
      trigger();
      if (event.limitType === AvailabilityLimitType.LIMIT_TRAVEL && errors.weeklyCapacity) clearErrors('weeklyCapacity');
    }
  }, [event]);

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as AvailabilityLimitType;
    setSelectedType(newType);
    if (newType === AvailabilityLimitType.LIMIT_LEADS) {
      setValue('weeklyCapacity', 0);
    }
    if (newType === AvailabilityLimitType.LIMIT_TRAVEL) {
      setValue('weeklyCapacity', null);
      if (errors.weeklyCapacity) clearErrors('weeklyCapacity');
    }
  };

  const handleStartDateChange = (
    newDate: Date | null,
    onChange: (date: Date | null) => void,
  ) => {
    onChange(newDate);
    if (newDate) {
      const newMinEndDate = dayjs(newDate).add(1, 'day').toDate();
      setMinEndDate(newMinEndDate);
      const currentEndDate = getValues('endDate');
      if (dayjs(currentEndDate).isBefore(newMinEndDate)) {
        setValue('endDate', newMinEndDate);
      }
    }
  };

  const navigateBack = () => {
    navigate('/availability');
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (event) {
      const updatedPartnerAvaData: PartnerAvailability = {
        ...event,
        comments: data.comments,
        endDate: data.endDate.toISOString(),
        leadsPerWeek: data.type === AvailabilityLimitType.LIMIT_TRAVEL ? null : data.weeklyCapacity,
        limitType: data.type,
        startDate: data.startDate.toISOString(),
      };
      await apiClient.partnersClient.putPartnerAvailability(loggedInAgent?.partnerId!, eventId!, updatedPartnerAvaData)
        .then(() => {
          mutatePartnerAvailabilities();
          enqueueSnackbar('Event details saved.', { variant: 'success' });
          navigateBack();
        })
        .catch((e: ApiError) => {
          if (e.code === 401) navigate('/logout');
          enqueueSnackbar('Event updated failed.', { variant: 'default' });
          setSubmissionError(e);
        });
    }
  };

  const validateLeadCapacity = (value: any) => {
    const numValue = Number(value);
    if (numValue < 0) return 'Please enter a number greater than or equal to 0';
    if (partner?.capacity && numValue >= partner.capacity) return `Must be less than the current Max Capacity of ${partner.capacity}`;
    return true;
  };

  const getHelperText = () => {
    if (errors.weeklyCapacity) {
      return errors.weeklyCapacity.message;
    } if (selectedType === AvailabilityLimitType.LIMIT_TRAVEL) {
      return 'Limit Trips Type required to set Trip Capacity';
    }
    return `Must be less than the current Max Capacity of ${partner?.capacity}`;
  };

  return (
    <Container maxWidth="md" sx={{ pt: { mobile: 3, desktop: 6 }, mb: 20 }}>
      <BackButton aria-label="back to availability" onClick={() => navigateBack()} edge="start" sx={{ mb: 2 }} />
      <DialogTitle sx={{ p: 0, mb: 3 }}>Edit Capacity</DialogTitle>
      <form name="edit-availability-form" onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={3}>
          {!isLoading ? (
            <Box>
              <Stack spacing={2}>
                <TextField
                  required
                  defaultValue={event?.limitType}
                  label="Type"
                  select
                  {...register('type')}
                  {...getFieldProps('type', errors)}
                  onChange={handleTypeChange}
                >
                  <MenuItem key={AvailabilityLimitType.LIMIT_LEADS} value={AvailabilityLimitType.LIMIT_LEADS}>Lead Capacity Restriction</MenuItem>
                  <MenuItem key={AvailabilityLimitType.LIMIT_TRAVEL} value={AvailabilityLimitType.LIMIT_TRAVEL}>Travel Date Restriction</MenuItem>
                </TextField>
                <List sx={{ mt: '0 !important', listStyleType: 'disc', pl: 3 }}>
                  <ListItem sx={{
                    p: 0, display: 'list-item', '&::marker': { color: 'primary.light' }, lineHeight: '1rem',
                  }}
                  >
                    <Typography variant="caption">
                      Use&nbsp;
                      <Typography variant="captionBold">Lead Capacity Restriction</Typography>
                  &nbsp;to restrict your lead flow on a specific period of time (e.g.: too busy, out of office, etc.)
                    </Typography>
                  </ListItem>
                  <ListItem sx={{
                    p: 0, display: 'list-item', '&::marker': { color: 'primary.light' }, lineHeight: '1rem',
                  }}
                  >
                    <Typography variant="caption">
                      Use&nbsp;
                      <Typography variant="captionBold">Travel Dates Restriction</Typography>
                  &nbsp;for travel dates you are unable to accommodate (e.g.: cherry blossom season is sold out)
                    </Typography>
                  </ListItem>
                </List>
                {selectedType === AvailabilityLimitType.LIMIT_LEADS && (
                <TextField
                  type="number"
                  defaultValue={event?.leadsPerWeek}
                  inputProps={{ min: 0, max: partner?.capacity && partner.capacity - 1 }}
                  label="Weekly Capacity"
                  {...register('weeklyCapacity', { valueAsNumber: true, validate: validateLeadCapacity })}
                  {...getFieldProps('weeklyCapacity', errors)}
                  helperText={getHelperText() as string}
                />
                )}
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={dayjs(event?.startDate).toDate()}
                  render={({
                    field: {
                      onChange, onBlur, value, ref, name,
                    },
                  }) => (
                    <DatePicker
                      label="Start Date"
                      onChange={(newDate) => handleStartDateChange(newDate, onChange)}
                      minDate={today}
                      maxDate={maxDate}
                      readOnly={dayjs(event?.startDate).isBefore(today, 'day')}
                      value={value}
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          name,
                          onBlur,
                          error: !!errors.startDate,
                          sx: {
                            width: '100%',
                            '& .MuiInputBase-readOnly': {
                              backgroundColor: dayjs(event?.startDate).isBefore(today, 'day') ? '#f0f0f0' : 'transparent',
                              color: dayjs(event?.startDate).isBefore(today, 'day') ? 'rgba(0, 0, 0, 0.6)' : 'inherit',
                            },
                          },
                          helperText: errors.startDate?.message as string,
                        },
                      }}
                      inputRef={ref}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={dayjs(event?.endDate).toDate()}
                  render={({
                    field: {
                      onChange, onBlur, value, ref, name,
                    },
                  }) => (
                    <DatePicker
                      label="End Date"
                      onChange={onChange}
                      minDate={minEndDate}
                      maxDate={maxDate}
                      readOnly={dayjs(event?.endDate).isBefore(today, 'day')}
                      value={value}
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          name,
                          onBlur,
                          error: !!errors.endDate,
                          sx: { width: '100%' },
                          helperText: errors.endDate?.message as string,
                        },
                      }}
                      inputRef={ref}
                    />
                  )}
                />
                <TextField
                  defaultValue={event?.comments}
                  label="Comments"
                  minRows={6}
                  maxRows={12}
                  multiline
                  required
                  sx={{
                    '.MuiOutlinedInput-root': {
                      padding: '10px 12px',
                      '> textarea': {
                        padding: '0 !important',
                      },
                    },
                  }}
                  {...register('comments', {
                    validate: (value: string) => value?.trim() !== '' || 'Comments are required',
                  })}
                  {...getFieldProps('comments', errors)}
                  error={Boolean(errors.comments)}
                  helperText={errors.comments ? errors.comments.message as string : ''}
                />
              </Stack>
            </Box>
          ) : <Skeleton variant="rounded" height={375} />}
          <Container
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: {
                mobile: 'column-reverse',
                desktop: 'row',
              },
              px: '0 !important',
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                width: { desktop: 'fit-content' },
              }}
              {...getSubmitButtonProps(isSubmitting, isValid)}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => navigateBack()}
              sx={{
                width: { desktop: 'fit-content' },
              }}
            >
              Cancel
            </Button>
          </Container>
          {submissionError && (
          <Alert severity="error">
            <AlertTitle>
              There was an error saving your event
            </AlertTitle>
            {submissionError.message}
            {submissionError.code && `Code: ${submissionError.code} `}
            {submissionError.id && `ID: ${submissionError.id}`}
          </Alert>
          )}
        </Stack>
      </form>
    </Container>
  );
};

export default EditEvent;

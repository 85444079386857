import React from 'react';
import {
  Alert,
  AlertTitle,
  Container,
  Typography,
  Box,
  Skeleton,
} from '@mui/material';
import { ConversationSummary, TripMatch } from '@marageti/z4-sdk/lib/travel';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { getConversationIcon } from '../../../components/conversation-links';
import { MessageConversationLinksCard, MessageConversationLink } from '../../../components/message-conversation-links';

type MessagesViewProps = {
  disabledTravelerLink: boolean;
  error?: ApiError;
  loading: boolean;
  travelerConversation?: ConversationSummary;
  tripMatch?: TripMatch;
  tripMatchId: string;
  zicassoConversation?: ConversationSummary;
};

const MessagesView = ({
  disabledTravelerLink,
  error = undefined,
  loading,
  travelerConversation = undefined,
  tripMatch = undefined,
  tripMatchId,
  zicassoConversation = undefined,
}: MessagesViewProps) => (
  <Container maxWidth="md" sx={{ mb: '100px' }}>
    <Box sx={{ pt: { mobile: 3, desktop: 9 } }}>
      <Typography variant="h2" sx={{ mb: 3 }}>Messages</Typography>
      {(loading || (!travelerConversation && !zicassoConversation)) && (
        <Skeleton variant="rounded" height={184} />
      )}
      {(!loading && travelerConversation && zicassoConversation) && (
        <MessageConversationLinksCard>
          <MessageConversationLink
            disabled={disabledTravelerLink}
            disabledMessage="Once accepted, you will be able to message the traveler."
            hasNewMessages={travelerConversation.hasNewMessages}
            icon={getConversationIcon(travelerConversation)}
            key={travelerConversation.conversationId}
            primary="Traveler"
            secondary={`You, ${tripMatch?.travelers[0].givenName}`}
            to={disabledTravelerLink ? '' : `/trip-matches/${tripMatchId}/messages/${travelerConversation.conversationId}`}
          />
          <MessageConversationLink
            hasNewMessages={zicassoConversation.hasNewMessages}
            icon={getConversationIcon(zicassoConversation)}
            key={zicassoConversation.conversationId}
            primary="Zicasso"
            secondary="You, Zicasso"
            to={`/trip-matches/${tripMatchId}/messages/${zicassoConversation.conversationId}`}
          />
        </MessageConversationLinksCard>
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>
            There was an error loading trip information
          </AlertTitle>
          {error.message}
          {error.code && `Code: ${error.code} `}
          {error.id && `ID: ${error.id}`}
        </Alert>
      )}
    </Box>
  </Container>
);

export default MessagesView;

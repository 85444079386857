import React from 'react';
import { Stack } from '@mui/material';
import TestingBanner from './testing-banner';
import MasqueradeBanner from './masquerade-banner';

const Banners = () => (
  <Stack
    spacing={1}
    sx={{
      position: 'fixed',
      bottom: 100,
      right: 16,
      zIndex: 1000,
    }}
  >
    <MasqueradeBanner />
    <TestingBanner />
  </Stack>
);

export default Banners;

import { useState } from 'react';
import { useApiClient } from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Doc, DocStatus } from '@marageti/z4-sdk/lib/documents';

function useDocumentDownload() {
  const [apiError, setApiError] = useState<ApiError>();
  const apiClient = useApiClient();

  const onClickDocument = async (customDocument: Doc) => {
    if (customDocument.status !== DocStatus.AvailableStatus) return;
    try {
      const downloadURL = await apiClient.documentsClient.getDocumentDownloadUrl(customDocument.id);
      const newTab = window.open(downloadURL.url, '_blank');
      if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        // Fallback to download the document
        const anchor = document.createElement('a');
        anchor.href = downloadURL.url;
        anchor.download = `${customDocument.title}.pdf`;
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    } catch (err) {
      setApiError(err as ApiError);
    }
  };

  return [onClickDocument, apiError, setApiError] as const;
}

export default useDocumentDownload;

import React from 'react';
import SubNavigationSettingsView from './settings-sub-navigation-view';
import { TabItem } from '../sub-navigation/types';

const SETTINGS_LINKS: TabItem[] = [
  {
    e2e: 'ztest-settings-tab-users',
    to: 'users',
    label: 'users',
  },
  {
    e2e: 'ztest-settings-tab-company-profile',
    to: 'company-profile',
    label: 'company profile',
  },
  {
    e2e: 'ztest-settings-tab-policies-terms',
    to: 'policies-and-terms',
    label: 'policies & terms',
  },
  {
    e2e: 'ztest-settings-tab-countries',
    to: 'countries',
    label: 'countries',
  },
  {
    e2e: 'ztest-settings-tab-templates',
    to: 'templates',
    label: 'templates',
  },
];

const SubNavigationSettingsContainer = () => (
  <SubNavigationSettingsView
    tabs={SETTINGS_LINKS}
  />
);

export default SubNavigationSettingsContainer;

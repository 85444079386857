import {
  Dispatch,
  Reducer,
  useReducer,
} from 'react';
import { Actions } from '../../types';
import {
  SET_STANDARDS_ERROR,
  SET_COUNTRY_CODES,
  SET_TIME_ZONES,
} from './actions';
import { StandardsProps } from './types';

const initialState: StandardsProps = {
  error: false,
  countryCodes: undefined,
  timeZones: undefined,
};

const reducer: Reducer<StandardsProps, Actions> = (state, action): StandardsProps => {
  switch (action.type) {
    case SET_STANDARDS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.countryCodes,
      };
    case SET_TIME_ZONES:
      return {
        ...state,
        timeZones: action.timeZones,
      };
    default:
      return state;
  }
};

const useStandardsReducer = () => {
  const [standards, standardsDispatch] = useReducer(reducer, initialState);

  return [standards, standardsDispatch] as [StandardsProps, Dispatch<Actions>];
};

export default useStandardsReducer;

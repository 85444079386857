import React, { useState } from 'react';
import SendLinkView from './send-link-view';

const SendLinkContainer = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSuccess = (): void => {
    setIsSubmitted(true);
  };

  return (
    <SendLinkView
      isSubmitted={isSubmitted}
      onSuccess={onSuccess}
    />
  );
};

export default SendLinkContainer;

import React, { useState, useEffect, useMemo } from 'react';

import { Agent } from '@marageti/z4-sdk/lib/people';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { ParticipantInfo } from '@marageti/z4-sdk/lib/travel';
import { UserStatus } from '@marageti/z4-sdk/lib/auth';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditParticipantsView from './edit-participants-view';

const EditParticipantsContainer = ({
  open,
  onClose,
  onSuccess,
  partnerId,
  assignedTo,
  travelerId,
  participants,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  partnerId: string | undefined;
  assignedTo: string | undefined;
  travelerId: string | undefined;
  participants: ParticipantInfo[];
}) => {
  const apiClient = useApiClient();
  const { conversationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedParticipants, setSelectedParticipants] = useState<Set<string>>(new Set());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const agents = useFetch<Agent[]>(apiClient.partnersClient.getPartnerAgents, [partnerId], partnerId !== undefined);
  const filteredParticipants = useMemo(() => participants.filter((p) => p.personType !== 'teamMember'), [participants]);

  const filteredAgents = useMemo(() => {
    if (!agents.data) return [];
    const participantIds = participants.map((p) => p.id);
    return agents.data
      .filter((agent) => !participantIds.includes(agent.id) && agent.userInfo[0].status === UserStatus.ENABLED)
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
  }, [agents.data, participants]);

  useEffect(() => {
    setSelectedParticipants(new Set(participants.map((p) => p.id)));
  }, [participants]);

  const handleToggle = (participant: ParticipantInfo | Agent) => {
    setSelectedParticipants((prev) => {
      const next = new Set(prev);
      if (next.has(participant.id)) {
        next.delete(participant.id);
      } else {
        next.add(participant.id);
      }
      return next;
    });
  };

  const handleConfirm = async () => {
    if (!conversationId) return;

    setIsSubmitting(true);

    try {
      const participantsToRemove = participants
        .filter((p) => !selectedParticipants.has(p.id))
        .map((p) => p.id);

      const participantsToAdd = filteredAgents
        .filter((p) => selectedParticipants.has(p.id))
        .map((p) => p.id);

      await Promise.all([
        participantsToRemove.length > 0 && apiClient.conversationsClient.removeConversationPeople(
          conversationId,
          participantsToRemove,
        ),
        participantsToAdd.length > 0 && apiClient.conversationsClient.addConversationPeople(
          conversationId,
          participantsToAdd,
        ),
      ]);

      enqueueSnackbar('Participants list updated successfully.', { variant: 'success' });
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Error updating participants.', { variant: 'default' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <EditParticipantsView
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      onToggleParticipant={handleToggle}
      participants={filteredParticipants}
      agents={filteredAgents}
      selectedParticipantIds={selectedParticipants}
      assignedTo={assignedTo}
      travelerId={travelerId}
      isSubmitting={isSubmitting}
    />
  );
};

export default EditParticipantsContainer;

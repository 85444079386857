import React from 'react';
import { useParams } from 'react-router-dom';
import MessagesView from './messages-view';

const MessagesContainer = () => {
  const { tripMatchId } = useParams();

  return (
    <MessagesView tripMatchId={tripMatchId!} />
  );
};

export default MessagesContainer;

import React from 'react';
import {
  Typography, Container, Box, Skeleton, useMediaQuery, useTheme, Grid, Stack, Alert, AlertTitle,
} from '@mui/material';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import PolicyAndTermsCardView, { ProfileDetails } from '../../../components/policies-and-terms-card';

export const profileDetails: ProfileDetails[] = [
  {
    attributeName: 'cancellationPolicy',
    displayName: 'Cancellation Policy',
    routeName: 'cancellation-policy',
  },
  {
    attributeName: 'paymentOptions',
    displayName: 'Payment Options',
    routeName: 'payment-options',
  },
  {
    attributeName: 'paymentTerms',
    displayName: 'Payment Terms',
    routeName: 'payment-terms',
  },
];

type ViewProps = {
  error?: ApiError;
  partner: Partner | undefined;
  loading: boolean;
  isAdmin: () => boolean;
};

const PoliciesAndTermsView = ({
  partner,
  error = undefined,
  loading,
  isAdmin,
}: ViewProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Container maxWidth="md">
      <Box sx={{
        py: {
          desktop: 6,
          mobile: 3,
        },
      }}
      >
        <Typography
          variant={isMobile ? 'h3' : 'h2'}
          sx={{
            marginBottom: 3,
            marginTop: { mobile: 3, desktop: 0 },
          }}
        >
          {!loading ? 'Policies & Terms' : <Skeleton width="100%" variant="rounded" />}
        </Typography>
        <Grid item sx={{ flex: 3 }}>
          {loading && <Skeleton width="100%" height={300} variant="rounded" />}
          {(!loading && partner) && (
            <Stack spacing={2.5}>
              { profileDetails.map((partnerDetail) => (
                <Box key={partnerDetail.attributeName}>
                  <PolicyAndTermsCardView
                    partner={partner}
                    partnerDetail={partnerDetail}
                    agentAdmin={isAdmin()}
                  />
                </Box>
              ))}
            </Stack>
          )}
          {(!loading && error) && (
          <Alert severity="error">
            <AlertTitle>There was an error getting policies & terms.</AlertTitle>
            {`Code: ${error.code}: ${error.message}`}
            <br />
            {error.id && `ID: ${error.id}`}
          </Alert>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default PoliciesAndTermsView;

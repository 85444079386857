import React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useApiClient,
  useFetch,
  BackButton,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import EditView from './edit-about-view';
import { useAuthStore } from '../../../../store';

const EditContainer = () => {
  const { loggedInAgent } = useAuthStore();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();

  const partnerId = loggedInAgent?.partnerId;
  const {
    data: partner, isLoading, mutate, error,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);

  const goBack = () => navigate('/settings/company-profile');

  const handleSubmit = async (data: any) => {
    if (partner && partnerId) {
      const updatePartner = {
        ...partner,
        aboutUs: data.aboutUs,
      };

      try {
        await apiClient.partnersClient.putPartner(partnerId, updatePartner);
        enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
        mutate();
        goBack();
      } catch (e) {
        setSubmissionError(true);
      }
    }
  };

  return (
    <Container
      maxWidth="md"
    >
      <Box
        sx={{
          py: {
            desktop: 6,
            mobile: 3,
          },
        }}
      >
        <BackButton onClick={goBack} edge="start" sx={{ mb: 2 }} />
        <EditView
          partner={partner || {} as Partner}
          isLoading={isLoading}
          partnerError={error}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={goBack}
        />
      </Box>
    </Container>
  );
};

export default EditContainer;

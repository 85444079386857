import React from 'react';
import {
  Box, Container, Stack, Skeleton,
} from '@mui/material';
import { ContextMenuComplete, TripMatchNotesCard } from '@marageti/z4-lib';
import { TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import { ViewProps } from './types';
import TripDetailCard, { getCanEditTripDetails } from '../../../components/trip-detail-card';
import ReassignForm from '../../../components/assign-agent-form';
import SpecialistCard from './specialist-card/specialist-card';
import Alerts from './alerts';
import TravelerCard from '../../../components/traveler-card';
import TripMatchSalesCard from '../../../components/trip-match-sales-card';
import { shouldShowSalesCard } from '../../../utils/sales';

const DetailsView = ({
  agents,
  alert,
  assignedSpecialist,
  isAdmin,
  onClickEdit,
  showModal,
  toggleModal,
  travelers,
  tripMatch,
  tripMatchId,
  tripRequest,
  salesRecordings,
}: ViewProps) => {
  const canEditTripDetails = getCanEditTripDetails(tripMatch);

  return (
    <Container
      maxWidth="md"
      sx={{
        paddingBottom: {
          desktop: 5,
          mobile: 4.5,
        },
      }}
    >
      <Box
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Stack spacing={2.5}>
          {alert && <Alerts alert={alert} />}
          {travelers ? (
            <TravelerCard
              hidePhone={tripMatch?.status === TripMatchStatus.MatchPending || tripMatch?.status === TripMatchStatus.MatchAccepted}
              travelers={travelers}
              baseRoute={`/trip-matches/${tripMatchId}`}
            />
          ) : (<Skeleton variant="rounded" height={296} />)}
          <SpecialistCard specialist={assignedSpecialist} isAdmin={isAdmin} openModal={toggleModal} />
          <ReassignForm
            open={showModal}
            agents={agents}
            onClose={toggleModal}
            tripMatchId={tripMatchId || ''}
          />
          <TripDetailCard
            title="Trip Details"
            titleTypographyProps={{ variant: 'h3' }}
            tripMatch={tripMatch}
            tripRequest={tripRequest}
            action={canEditTripDetails && (
              <ContextMenuComplete
                id="edit-trip-match"
                items={[{ action: onClickEdit, label: 'Edit' }]}
              />
            )}
          />
          {tripMatch && shouldShowSalesCard(tripMatch) && <TripMatchSalesCard tripMatch={tripMatch} salesRecordings={salesRecordings} />}
          <TripMatchNotesCard title="Notes from Zicasso" notes={{ ...tripRequest?.matchingNotes }} />
        </Stack>
      </Box>
    </Container>
  );
};
export default DetailsView;

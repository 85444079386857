import { ThemeOptions, createTheme, Theme } from '@mui/material';
import SatoshiBlack from './fonts/Satoshi-Black.woff2';
import SatoshiBold from './fonts/Satoshi-Bold.woff2';
import SatoshiRegular from './fonts/Satoshi-Regular.woff2';
import SatoshiMedium from './fonts/Satoshi-Medium.woff2';
import SatoshiLight from './fonts/Satoshi-Light.woff2';

export const themeOptions: ThemeOptions = {
  palette: {
    dividerLight: '#D9D9D9',
  },
  breakpoints: {
    values: {
      mobile: 0,
      desktop: 1100,
      xs: 0,
      sm: 530,
      md: 934,
      lg: 1464,
      xl: 1728,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 77,
      '@media (min-width:0px)': {
        '@media (orientation: landscape)': {
          minHeight: 77,
        },
      },
      '@media (min-width:1100px)': {
        minHeight: 93,
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Satoshi'), local('Satoshi-Regular'), url(${SatoshiRegular}), format('woff2');
        }

        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Satoshi'), local('Satoshi-Medium'), url(${SatoshiMedium}), format('woff2');
        }

        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 100;
          src: local('Satoshi'), local('Satoshi-Light'), url(${SatoshiLight}), format('woff2');
        }

        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Satoshi'), local('Satoshi-Bold'), url(${SatoshiBold}), format('woff2');
        }

        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('Satoshi'), local('Satoshi-Black'), url(${SatoshiBlack}), format('woff2');
        }
        `,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ ownerState, theme }: { ownerState: any, theme: Theme }) => ({
          ...!ownerState.disableGutters && {
            padding: '0 24px',
            [theme.breakpoints.up('desktop')]: {
              padding: '0 80px',
            },
          },
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ ownerState, theme }: { ownerState: any, theme: Theme }) => ({
          maxWidth: theme.breakpoints.values.xl,
          width: '100%',
          ...!ownerState.disableGutters && {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            [theme.breakpoints.up('desktop')]: {
              paddingLeft: theme.spacing(10),
              paddingRight: theme.spacing(10),
            },
          },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(1),
        }),
      },
    },
  },
};
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
    desktop: true;
  }
  interface Palette {
    dividerLight: string;
  }
  interface PaletteOptions {
    dividerLight?: string;
  }
}

const theme = createTheme(themeOptions);

export default theme;

import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Review } from '@marageti/z4-sdk/lib/travel';
import DetailView from './review-view';

const DetailContainer = () => {
  const { state } = useLocation();
  const localStateReview = state?.data?.review;
  const { reviewId } = useParams();
  const apiClient = useApiClient();
  // The service returns a 200 but no response if they agent isn't allowed to view the review which makes review an empty string.
  // That was breaking the view, hence explicity passing undefined on line 18
  const { data: review, isLoading, error } = useFetch<Review>(apiClient.reviewsClient.getReview, [reviewId], !localStateReview && !!reviewId);

  return (
    <DetailView
      review={localStateReview || review || undefined}
      loading={isLoading}
      error={error}
    />
  );
};

export default DetailContainer;

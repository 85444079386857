import React, { useState } from 'react';
import {
  styled,
  IconButton,
  Box,
  SwipeableDrawer as MuiSwipeableDrawer,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Icons, OutlinedIcons, PrimaryAppBar,
} from '@marageti/z4-lib';
import {
  MainNavListDesktop, MainNavListMobile, UserMenuDesktop, UserNavListMobile,
} from './menus-view';
import { ReactComponent as ZicassoSVG } from '../../assets/svg/zicasso.svg';

const {
  OutlinedUserIcon,
  OutlinedMenuAlt3Icon,
} = OutlinedIcons;

const Drawer = styled(MuiSwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: theme.palette.background,
    color: theme.palette.primary,
    boxSizing: 'border-box',
    width: '100%',
  },
}));

const NavigationView = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [desktopUserMenuAnchor, setDesktopUserMenuAnchor] = useState<null | HTMLElement>(null);
  const userMenuOpen = !!desktopUserMenuAnchor;

  const handleClickAvatar = (e: React.MouseEvent<HTMLElement>) => {
    setDesktopUserMenuAnchor(e.currentTarget);
  };

  const handleCloseUserMenuItems = () => {
    setDesktopUserMenuAnchor(null);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };
  const handleOpen = () => {
    setDrawerOpen(true);
  };

  return (
    <>
      <PrimaryAppBar
        position="sticky"
        color="default"
        ToolbarProps={{ variant: 'light' }}
        startAdornment={isMobile && (
          <IconButton
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
            edge="start"
          >
            <OutlinedMenuAlt3Icon color="primary" />
          </IconButton>
        )}
        endAdornment={(
          <Stack direction="row" spacing={isMobile ? 1 : 4}>
            {!isMobile && (<MainNavListDesktop />)}

            {isMobile ? (
              <IconButton
                component={Link}
                color="inherit"
                data-e2e="ztest-user-menu"
                to="/my-profile"
                edge="end"
              >
                <OutlinedUserIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton
                aria-controls="user-menu"
                aria-expanded={userMenuOpen ? 'true' : 'false'}
                aria-haspopup="true"
                aria-label="user menu"
                color="inherit"
                data-e2e="ztest-user-menu"
                onClick={handleClickAvatar}
              >
                <OutlinedUserIcon color="primary" />
              </IconButton>
            )}
          </Stack>
        )}
      >
        <Box display="flex" component={Link} to="/">
          <ZicassoSVG />
        </Box>
      </PrimaryAppBar>
      <UserMenuDesktop
        anchorEl={desktopUserMenuAnchor}
        open={userMenuOpen}
        handleClose={handleCloseUserMenuItems}
      />
      <Drawer
        anchor="left"
        hideBackdrop
        ModalProps={{ keepMounted: true }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={drawerOpen}
        variant="temporary"
      >
        <Box
          onClick={handleClose}
          sx={{
            display: 'flex',
            p: 5,
            justifyContent:
              'space-between',
            alignItems: 'flex-start',
            height: '100%',
          }}
        >
          <Box
            component="nav"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              flex: 1,
            }}
          >
            <MainNavListMobile />
            <UserNavListMobile />
          </Box>
          <IconButton
            color="inherit"
          >
            <Icons.X />
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationView;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  Card,
  Icons,
  ProfileCardSubtitle,
  ProfileCardTitle,
} from '@marageti/z4-lib';
import { CapacityCardProps } from './types';

type ProfileCardElementProps = {
  title: any;
  toolTipText: string;
  value: any;
};

export const LabelAndValueElementExtra = ({
  title,
  toolTipText,
  value,
}: ProfileCardElementProps) => (value !== undefined ? (
  <Stack>
    <ProfileCardTitle>
      {title}
      <Tooltip title={toolTipText} placement="top" enterTouchDelay={50}>
        <span>
          <Icons.ExclamationCircle
            color="primary"
            sx={{
              width: '16px',
              height: '16px',
              ml: 1,
              verticalAlign: 'text-top',
            }}
          />
        </span>
      </Tooltip>
    </ProfileCardTitle>
    <ProfileCardSubtitle>{value}</ProfileCardSubtitle>
  </Stack>
) : (
  <Skeleton
    variant="rounded"
    animation="wave"
    sx={{
      margin: '0 auto',
      height: '44px',
      width: '100%',
    }}
  />
));

const CapacityCard = ({
  title,
  toolTipText,
  value,
}: CapacityCardProps) => (
  <Card
    sx={{
      minWidth: '325px',
      flexGrow: { desktop: 1, mobile: 0 },
      flexBasis: { desktop: 0, mobile: 'auto' },
    }}
  >
    <Stack spacing="24px" width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <LabelAndValueElementExtra
          title={title}
          toolTipText={toolTipText}
          value={`${value} per week`}
          key={title}
        />
      </Stack>
    </Stack>
  </Card>
);

export default CapacityCard;

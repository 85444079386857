/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Stack,
  TextField,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  useForm,
  getFieldProps,
  getSubmitButtonProps,
  getFormProps,
  InputPhone,
  TimezoneSelector,
  Validate,
} from '@marageti/z4-lib';
import {
  Controller,
} from 'react-hook-form';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { Phone, TimeZone } from '@marageti/z4-sdk/lib/standards';

type UserFormDefaultValues = {
  givenName: string | undefined,
  familyName: string | undefined,
  title: string | undefined,
  email: string | undefined,
  phone: Phone | undefined,
  timeZone: TimeZone,
};

const DEFAULT_VALUES = {
  givenName: '',
  familyName: '',
  title: '',
  email: '',
  phone: undefined,
  timeZone: {
    name: '',
    rawOffsetInMinutes: 0,
  },
} as UserFormDefaultValues;

const schema = z.object({
  givenName: z.string().nonempty('Required'),
  familyName: z.string().nonempty('Required'),
  title: z.string(),
  phone: z.object({
    prefix: z.string(),
    number: z.string(),
  }).refine((value) => {
    if (!value.number) return true;
    return Validate.phone(`${value.prefix}${value.number}`);
  }),
  timeZone: z.object({
    name: z.string(),
    rawOffsetInMinutes: z.any(),
  })
    .refine((data) => data.name && data.rawOffsetInMinutes !== undefined, {
      message: 'Required',
      path: [],
    }),
});

type UserFormProps = {
  submitText?: string
  agent: Agent
  suggestedTimezoneOption: TimeZone | undefined
  onSubmit: (data: any) => void,
  submissionError: boolean
  onCancel?: () => void
};

const UserForm = ({
  submitText = 'Save',
  agent,
  suggestedTimezoneOption,
  onSubmit,
  submissionError,
  onCancel = undefined,
}: UserFormProps) => {
  const defaultValues = {
    givenName: agent.givenName || DEFAULT_VALUES.givenName,
    familyName: agent.familyName || DEFAULT_VALUES.familyName,
    title: agent.title || DEFAULT_VALUES.title,
    email: agent.email || DEFAULT_VALUES.email,
    phone: agent.phone || DEFAULT_VALUES.phone,
    timeZone: agent.timeZone.name ? agent.timeZone : suggestedTimezoneOption,
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    control,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [suggestedTimezoneOption]);

  return (
    <form {...getFormProps(handleSubmit(onSubmit))}>
      <Stack spacing={3}>
        <Stack>
          <TextField
            label="First Name"
            {...register('givenName')}
            {...getFieldProps('givenName', errors)}
            required
          />
          <TextField
            label="Last Name"
            {...register('familyName')}
            {...getFieldProps('familyName', errors)}
            required
          />
          <TextField
            label="Title"
            {...register('title')}
            {...getFieldProps('title', errors)}
          />
          <TextField
            label="Email"
            {...register('email')}
            {...getFieldProps('email', errors)}
            InputProps={{
              readOnly: true,
            }}
          />
          <Controller
            control={control}
            name="phone"
            defaultValue={agent.phone}
            render={({ field }) => (
              <InputPhone
                id="phone"
                label="Phone"
                {...getFieldProps('phone', errors)}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={agent.timeZone}
            name="timeZone"
            render={({ field }) => (
              <TimezoneSelector
                required
                id="timeZone-selector"
                label="Timezone"
                {...getFieldProps('timeZone', errors)}
                {...field}
              />
            )}
          />
        </Stack>

        {submissionError && (
          <Alert severity="error">
            <AlertTitle>There was an error submitting the form.</AlertTitle>
            Please try again.
          </Alert>
        )}

        <Stack
          direction="row"
          spacing={2}
          sx={(theme) => ({
            [theme.breakpoints.down('desktop')]: {
              marginTop: 'auto',
              flexDirection: 'column-reverse',
              '& > :not(:first-of-type)': {
                margin: theme.spacing(0, 0, 2.5, 0),
              },
            },
          })}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            {...getSubmitButtonProps(isSubmitting, isValid)}
          >
            {submitText}
          </LoadingButton>

          {onCancel && (
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default UserForm;

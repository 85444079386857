import React from 'react';
import { Route } from 'react-router-dom';
import Settings from '../../pages/settings';
import SubnavLayout from '../../pages/settings/subnav-layout';
import Users from '../../pages/settings/users';
import CompanyProfile from '../../pages/settings/company-profile';
import EditCompanyProfile from '../../pages/settings/edit/edit-company-profile';
import EditAbout from '../../pages/settings/edit/edit-about';
import EditOffice from '../../pages/settings/edit/edit-office';
import PoliciesAndTerms from '../../pages/settings/policies-and-terms';
import Countries from '../../pages/settings/countries';
import EditPoliciesAndTerms from '../../pages/settings/edit/edit-policies-and-terms';
import SettingsTemplates from '../../pages/settings/templates';
import SettingsAddTemplate from '../../pages/settings/templates/add';
import SettingsEditTemplate from '../../pages/settings/templates/edit';
import EditBillingInfo from '../../pages/settings/edit/edit-billing-info';

const useSettingsRoutes = () => (
  <>
    <Route path="settings" element={<Settings />} />
    <Route path="settings/*" element={<SubnavLayout />}>
      <Route path="users" element={<Users />} handle={{ tab: '/users' }} />
      <Route path="company-profile" element={<CompanyProfile />} handle={{ tab: '/company-profile' }} />
      <Route path="policies-and-terms" element={<PoliciesAndTerms />} handle={{ tab: '/policies-and-terms' }} />
      <Route path="countries" element={<Countries />} handle={{ tab: '/countries' }} />
      <Route path="templates" element={<SettingsTemplates />} handle={{ tab: '/templates' }} />
    </Route>
    <Route path="settings/company-profile/edit" element={<EditCompanyProfile />} />
    <Route path="settings/company-profile/billing-info" element={<EditBillingInfo />} />
    <Route path="settings/company-profile/office" element={<EditOffice />} />
    <Route path="settings/company-profile/:fieldName" element={<EditAbout />} />
    <Route path="settings/policies-and-terms/:fieldName" element={<EditPoliciesAndTerms />} />
    <Route path="settings/templates/add" element={<SettingsAddTemplate />} />
    <Route path="settings/templates/:templateId" element={<SettingsEditTemplate />} />
  </>
);

export default useSettingsRoutes;

import React, { useMemo } from 'react';
import {
  ContextMenuComplete,
  getTotalBudgetString,
} from '@marageti/z4-lib';
import {
  SalesRecording, SalesRecordingStatus, TripMatch, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import {
  Box,
  Typography,
  Divider,
  styled,
  CardHeader,
  Card,
  CardContent,
  Button,
  Stack,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CurrencyAmount } from '@marageti/z4-sdk/lib/standards/CurrencyAmount';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { salesRecordingTypeLabel } from '../../utils/sales';

const SaleRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const TripMatchSalesList = ({
  salesRecordings,
  calculatedTotalSales,
}: {
  salesRecordings: SalesRecording[],
  calculatedTotalSales: CurrencyAmount
}) => (
  <>
    <Stack direction="column" spacing={2}>
      {salesRecordings.map((sale: SalesRecording) => (
        <SaleRow key={sale.referenceId}>
          <Typography>
            {salesRecordingTypeLabel[sale.salesRecordingType]}
          </Typography>
          <Typography textAlign="right">
            {getTotalBudgetString(sale.price.amount, 2, 2, sale.price.currencyCode)}
          </Typography>
        </SaleRow>
      ))}
    </Stack>
    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    <SaleRow>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography fontWeight="bold">
          Total Trip Price
        </Typography>
        <Tooltip title="Total Trip Price will be shown to the traveler" placement="top-end">
          <InfoIcon sx={{ color: 'common.black50' }} fontSize="small" />
        </Tooltip>
      </Box>
      <Typography fontWeight="bold" textAlign="right">
        {calculatedTotalSales && getTotalBudgetString(calculatedTotalSales.amount, 2, 2, calculatedTotalSales.currencyCode)}
      </Typography>
    </SaleRow>
  </>
);

const InitialSalePrompt = ({ tripMatchId }: { tripMatchId: string }) => (
  <Stack spacing={3} alignItems="flex-start">
    <Typography variant="body1">
      Please record your sold trip once you have received the initial deposit. If you need any assistance, contact us via chat or visit
      {' '}
      <a href="https://zicademy.zicasso.com/_hcms/mem/login?redirect_url=/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>Zicademy</a>
      .
    </Typography>
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={`/trip-matches/${tripMatchId}/sales/recording`}
    >
      Record Sold Trip
    </Button>
  </Stack>
);

const TripMatchSalesCard = ({ tripMatch, salesRecordings }: { tripMatch: TripMatch, salesRecordings: SalesRecording[] | undefined }) => {
  const navigate = useNavigate();

  const calculatedTotalSales = useMemo(
    () => salesRecordings?.reduce((sum, sale) => ({
      ...sale.price,
      amount: sum.amount + sale.price.amount,
      amountUSD: sum.amountUSD + sale.price.amountUSD,
    }), { ...salesRecordings?.[0]?.price, amount: 0, amountUSD: 0 }),
    [salesRecordings],
  );

  const hasOpenStandardSale = salesRecordings?.length === 1
    && salesRecordings[0].status === SalesRecordingStatus.SoldStatus
    && salesRecordings[0].salesRecordingType === SalesRecordingType.General
    && tripMatch?.status === TripMatchStatus.MatchSold;

  const lastSaleIsInvoiced = tripMatch?.status === TripMatchStatus.MatchSold
    && salesRecordings
    && salesRecordings?.length > 0
    && salesRecordings[salesRecordings.length - 1].status === SalesRecordingStatus.InvoicedStatus;

  const hasAdjustment = tripMatch?.status === TripMatchStatus.MatchSold
    && salesRecordings
    && salesRecordings?.length > 0
    && salesRecordings[salesRecordings.length - 1].salesRecordingType === SalesRecordingType.Adjustment
    && salesRecordings[salesRecordings.length - 1].status === SalesRecordingStatus.SoldStatus;

  const actions = [];

  if (hasAdjustment) {
    actions.push({
      action: () => navigate(`/trip-matches/${tripMatch?.id}/sales/adjustment/${salesRecordings[salesRecordings.length - 1].id}`),
      label: 'Edit Sales Adjustment',
    });
  } else if (hasOpenStandardSale) {
    actions.push({
      action: () => navigate(`/trip-matches/${tripMatch?.id}/sales/recording/${salesRecordings[0].id}`),
      label: 'Edit Standard Sale',
    });
  } else if (lastSaleIsInvoiced) {
    actions.push({
      action: () => navigate(`/trip-matches/${tripMatch?.id}/sales/adjustment`),
      label: 'Add Sales Adjustment',
    });
  }

  return (
    <Card>
      <CardHeader
        title="Sales"
        action={actions.length ? (
          <ContextMenuComplete
            id="edit-trip-match"
            items={actions}
          />
        ) : undefined}
      />
      <CardContent>
        {!salesRecordings && (
          <Skeleton variant="rectangular" height={20} />
        )}
        {salesRecordings && salesRecordings?.length === 0 && (
          <InitialSalePrompt tripMatchId={tripMatch?.id || ''} />
        )}
        {salesRecordings && salesRecordings?.length > 0 && (
          <TripMatchSalesList
            salesRecordings={salesRecordings}
            calculatedTotalSales={calculatedTotalSales!}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default TripMatchSalesCard;

import React from 'react';
import styles from './z-spinner.module.scss';

const ZSpinnerView = () => (
  <div className={styles['lds-ellipsis']}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default ZSpinnerView;

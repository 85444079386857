import React from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Calendar,
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  OutlinedIcons,
  ZSpinner,
} from '@marageti/z4-lib';
import CapacityCard from '../../components/capacity-card';
import { ViewProps } from './types';
import './styles.scss';
import EventCard from '../../components/event-card';
import ConfirmDelete from '../../components/confirm-delete';

const {
  OutlinedCalendarIcon,
} = OutlinedIcons;

const AvailabilityView = ({
  activeDate,
  capacity,
  error,
  eventToDelete,
  fetchHistoricAvailabilityData,
  handleActiveDateChange,
  isAdmin,
  leadLimit,
  loading,
  onCancelDelete,
  onConfirmDelete,
  onRemoveEvent,
  partnerAvailability,
  partnerAvailabilityList,
}: ViewProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          py: { desktop: 4.5, mobile: 6.5 },
          width: { desktop: '774px' },
          margin: { desktop: '0 auto 56px' },
        }}
      >
        <Stack direction="row" sx={{ mb: 4.5 }} justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Availability</Typography>
          <Button variant="contained" onClick={() => navigate('./add')}>Update Capacity</Button>
        </Stack>
        <Stack>
          <Box>
            {error ? (
              <ErrorBox>
                <ErrorTitle>
                  There was an error on our end.
                </ErrorTitle>
                <ErrorMessage>
                  {`Code: ${error.code}: ${error.message}`}
                </ErrorMessage>
              </ErrorBox>
            ) : (
              <Stack
                spacing={{ desktop: 2, mobile: 2 }}
                direction={{ desktop: 'row', mobile: 'column' }}
                mb={{ desktop: '56px', mobile: '24px' }}
              >
                <CapacityCard
                  title="Current Capacity"
                  value={leadLimit}
                  toolTipText="Maximum capacity set by availability"
                />
                <CapacityCard
                  title="Max Capacity"
                  value={capacity}
                  toolTipText="Maximum capacity set by Zicasso"
                />

              </Stack>
            )}
          </Box>
          <Box className="calendar" display="flex" justifyContent="center" alignItems="center">
            <Calendar availabilityData={partnerAvailability || []} handlePrevButton={fetchHistoricAvailabilityData} onActiveDateChange={handleActiveDateChange} />
          </Box>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 8,
              }}
            >
              <ZSpinner />
            </Box>
          ) : (
            <>
              {partnerAvailabilityList?.length !== 0 && (
              <Box sx={{ mt: { desktop: '56px', mobile: '26px' } }}>
                <Typography variant="h4" sx={{ mb: 1 }}>{`Events for ${activeDate.toLocaleString('default', { month: 'long' })}`}</Typography>
                {partnerAvailabilityList && partnerAvailabilityList.map((listItem) => (
                  <EventCard
                    key={listItem.limitType + listItem.startDate + listItem.endDate}
                    availabilityItem={listItem}
                    capacity={capacity}
                    admin={isAdmin!}
                    onClickEdit={() => navigate(`./${listItem.id}/edit`)}
                    onClickRemove={() => onRemoveEvent(listItem.id)}
                  />
                ))}
              </Box>
              )}
              {partnerAvailabilityList?.length === 0 && !loading && (
              <Box sx={{ pt: '48px', pb: '56px' }}>
                <Stack justifyContent="center" alignContent="center" alignItems="center">
                  <OutlinedCalendarIcon sx={{
                    color: theme.palette.common.black50, width: '48px', height: '48px', strokeWeight: '1px !important',
                  }}
                  />
                  <Typography variant="body" sx={{ mb: 1, color: theme.palette.common.black75 }}>There are no events for this month</Typography>
                </Stack>
              </Box>
              )}
            </>
          )}

        </Stack>
      </Box>
      <ConfirmDelete
        open={eventToDelete !== undefined}
        onCancel={onCancelDelete}
        onRemove={onConfirmDelete}
        title="Remove Event"
        helperText="The event will be removed from your calendar and any lead or travel reduction will be removed for that time period."
      />
    </Container>
  );
};

export default AvailabilityView;

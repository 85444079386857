import React, { createContext, useMemo, useEffect } from 'react';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { Token } from '@marageti/z4-sdk/lib/auth';
import { useApiClient } from '@marageti/z4-lib';
import { ProviderProps } from '../../types';
import { ValueProps } from './types';
import {
  setAuthenticatedAgent, setIsAuthenticated, resetAuthenticatedAgent,
} from './actions';
import useAuthReducer from './use-auth-reducer';
import LocalStorageService from '../../../utils/local-storage-service';
import {
  hasAuthorizedRole,
  getMatchingUser,
} from '../../../utils/auth';

const getCookieValue = (name: string): any | undefined => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) {
    try {
      return JSON.parse(decodeURIComponent(match[2]));
    } catch (error) {
      // If the cookie value is not a valid JSON string, return it as is
      return decodeURIComponent(match[2]);
    }
  }
  return undefined;
};

const LocalStorageClient = new LocalStorageService();

export const AuthStore = createContext({} as ValueProps);

export const AuthProvider = ({ children }: ProviderProps) => {
  const [authState, authDispatch] = useAuthReducer();
  const apiClient = useApiClient();

  const removeAuthenticatedAgent = () => apiClient.authClient.logout()
    .finally(() => {
      LocalStorageClient.removeFromLocalStorage('accessToken');
      LocalStorageClient.removeFromLocalStorage('email');
      LocalStorageClient.removeFromLocalStorage('superEmail');
      authDispatch(setIsAuthenticated(false));
      authDispatch(resetAuthenticatedAgent());
    });

  const getAccessToken = (): string | undefined => {
    const userSessionCookie = getCookieValue('user_session');
    const userSessionAccessToken = userSessionCookie?.sessionId;
    const localStorageAccessToken = LocalStorageClient.getFromLocalStorage('accessToken');
    return userSessionAccessToken || localStorageAccessToken;
  };

  const setAccessToken = (accessToken: string) => {
    // Set Bearer token. This is required for when accessing the GetToken method through a user_session cookie.
    LocalStorageClient.addToLocalStorage('accessToken', accessToken);
    apiClient.instance.setAuthToken(accessToken);
  };

  const authorizeUserFromToken = (accessToken: string) => {
    // Check token status and get user
    apiClient.authClient
      .getToken(accessToken)
      .then((data: Token) => apiClient.peopleClient.getAgent(data.email))
      .then((data: Agent) => {
        const matchingUser = getMatchingUser(data.email, data.userInfo);
        if (!matchingUser || !hasAuthorizedRole(matchingUser.roles)) {
          throw new Error('Not Authorized');
        }
        LocalStorageClient.addToLocalStorage('email', data.email);
        authDispatch(setAuthenticatedAgent(data as Agent));
        authDispatch(setIsAuthenticated(true));
      })
      .catch(() => {
        removeAuthenticatedAgent();
      });
  };

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      setAccessToken(accessToken);
      authorizeUserFromToken(accessToken);
    } else {
      removeAuthenticatedAgent();
    }
  }, []);

  const value: ValueProps = useMemo(() => ({
    ...authState,
    authDispatch,
    removeAuthenticatedAgent,
    setIsAuthenticated: (isAuthenticated: boolean) => authDispatch(setIsAuthenticated(isAuthenticated)),
    setAuthenticatedAgent: (agent: Agent) => authDispatch(setAuthenticatedAgent(agent)),
  }), [authState, authDispatch]);

  return (
    <AuthStore.Provider value={value}>
      {children}
    </AuthStore.Provider>
  );
};

import React from 'react';
import { Typography } from '@mui/material';
import SubNavigation from '../sub-navigation';
import { TabItem } from '../sub-navigation/types';

const SALES_LINKS: TabItem[] = [
  {
    e2e: 'ztest-sales-tab-monthly',
    to: 'invoices',
    label: 'invoices',
  },
  {
    e2e: 'ztest-sales-tab-all',
    to: 'all',
    label: 'all sales',
  },
];

const SubNavigationSettingsContainer = () => (
  <SubNavigation
    startAdornment={(
      <Typography variant="h3" color="primary">
        Sales
      </Typography>
    )}
    tabs={SALES_LINKS}
    endAdornment={undefined}
  />
);

export default SubNavigationSettingsContainer;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ErrorBox,
  ErrorMessage,
  ErrorTitle,
  FormCancelButton,
  FormDialogTitle,
  getFieldProps, getFormProps, getSubmitButtonProps, OutlinedIcons, useForm, Validate,
} from '@marageti/z4-lib';
import { FieldValues } from 'react-hook-form';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { LoadingButton } from '@mui/lab';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  open: boolean,
  closeDialog: () => void,
  submissionError: ApiError | undefined,
  onSubmitFile: (data: any) => Promise<void>
  onSubmitURL: (data: any) => Promise<void>
};

const {
  OutlinedDocumentIcon,
  OutlinedTrashIcon,
} = OutlinedIcons;

// eslint-disable-next-line max-len
const acceptedMimeTypes = ['text/plain', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];

const documentSchema = z.object({
  title: z.string().min(1, 'Required'),
  document: z
    .any()
    .refine((files) => files?.[0]?.type && acceptedMimeTypes.includes(files?.[0]?.type), { message: 'Please upload a valid file type. Supported types: TXT, PDF, DOC, DOCX, PNG, and JPG' })
    .refine((files) => files?.[0]?.size <= 20 * 1024 * 1024, { message: 'File size must not exceed 20MB' }),
});

const urlSchema = z.object({
  title: z.string().min(1, 'Required'),
  sourceURI: z.string().refine((string) => Validate.urlSimpleRegex().test(string), { message: 'Please enter a valid URL' }),
});

const UploadDocFormDialog = ({
  open,
  closeDialog,
  submissionError,
  onSubmitFile,
  onSubmitURL,
}: Props) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const [uploadType, setUploadType] = useState('upload');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    watch,
    setValue,
    reset,
    trigger,
  } = useForm<FieldValues>({
    resolver: zodResolver(uploadType === 'upload' ? documentSchema : urlSchema),
  });

  const uploadedDocument = watch('document');

  const handleTrashIconClick = () => {
    setValue('document', undefined);
  };

  const submitCallback = async (data: any) => {
    if (uploadType === 'upload') {
      await onSubmitFile(data);
    } else {
      await onSubmitURL(data);
    }
    reset();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={isMobile}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '390px',
          width: '100%',
          mx: 'auto',
        },
      }}
    >
      <FormDialogTitle handleClose={closeDialog}>
        Upload Travel Document
      </FormDialogTitle>
      <DialogContent>
        <Typography
          color="info.dark"
          data-e2e="ztest-trip-match-docs-warning"
          sx={{ fontStyle: 'italic', pb: 2.5 }}
          variant="subtitle1"
        >
          This file will be shared with the traveler.
          <br />
          <strong>
            Sensitive Information Notice: Please avoid sharing sensitive details (e.g., passports, credit card numbers)
            directly. Use a secure link via Google Drive, OneDrive, Dropbox, etc., to ensure data protection.
          </strong>
        </Typography>
        <form name="upload-document" {...getFormProps(handleSubmit(submitCallback))}>
          <Stack spacing={3}>
            <RadioGroup
              aria-labelledby="upload-types"
              defaultValue="upload"
              name="upload-types-group"
              value={uploadType}
              onChange={(event) => setUploadType(event.target.value)}
              sx={{ mb: 3, flexDirection: 'row' }}
            >
              <FormControlLabel
                value="upload"
                control={(
                  <Radio sx={{ p: 0, mr: 1 }} />
            )}
                label="Upload File"
                sx={{ ml: '-2px', mb: 1 }}
              />
              <FormControlLabel
                value="url"
                control={(
                  <Radio sx={{ p: 0, mr: 1 }} />
            )}
                label="Add URL"
                sx={{ ml: '-2px', mb: 1 }}
                disabled={uploadedDocument && uploadedDocument.length > 0}
              />
            </RadioGroup>
            {(uploadedDocument && uploadedDocument.length > 0) && (
            <Stack spacing={1}>
              <ListItem
                disableGutters
                secondaryAction={(
                  <IconButton
                    data-e2e="ztest-trip-match-docs-popup-delete"
                    onClick={handleTrashIconClick}
                    color="primary"
                  >
                    <OutlinedTrashIcon />
                  </IconButton>
              )}
              >
                <ListItemAvatar>
                  <Avatar variant="rounded">
                    <OutlinedDocumentIcon sx={{ width: '24px', height: '24px' }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={uploadedDocument[0].name}
                />
              </ListItem>
              {(uploadedDocument && uploadedDocument.length > 0) && errors && errors.document && (
              <Typography color="error" variant="caption">
                {errors.document.message}
              </Typography>
              )}
            </Stack>
            )}

            {uploadType === 'upload' && (
              (!uploadedDocument || (uploadedDocument.length <= 0)) && (
                <label htmlFor="document">
                  <input
                    id="document"
                    type="file"
                    accept="text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg"
                    {...register('document')}
                    onChange={(event) => {
                      register('document').onChange(event);
                      trigger('document'); // manually trigger validation on file inputs
                    }}
                    style={{ display: 'none' }}
                  />
                  <Button variant="outlined" component="span" sx={{ px: '20px', py: 1 }}>
                    Browse...
                  </Button>
                </label>
              )
            )}

            {uploadType === 'url' && (
              <TextField
                id="url"
                label="URL"
                {...register('sourceURI', {
                  required: 'Please enter a URL',
                  pattern: {
                    value: Validate.urlSimpleRegex(),
                    message: 'Please enter a valid URL',
                  },
                })}
                {...getFieldProps('sourceURI', errors)}
              />
            )}

            <TextField
              id="title"
              label="Title"
              {...register('title', { required: 'Please enter a title' })}
              {...getFieldProps('title', errors)}
            />

            {submissionError && (
            <ErrorBox>
              <ErrorTitle>
                Submission Error
              </ErrorTitle>
              <ErrorMessage>
                {submissionError.message}
              </ErrorMessage>
            </ErrorBox>
            )}
          </Stack>
          <Divider sx={{ mx: '-32px', mt: '40px' }} />
          <Box
            sx={{
              py: 3,
            }}
          >
            <Stack
              direction={{ mobile: 'column', desktop: 'row-reverse' }}
              justifyContent="flex-end"
              spacing={2.5}
              width="100%"
            >
              <FormCancelButton
                data-e2e="ztest-trip-match-docs-cancel"
                onClick={closeDialog}
              />
              <LoadingButton
                data-e2e="ztest-trip-match-docs-submit"
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  width: { mobile: '100%', desktop: 'fit-content' },
                }}
                {...getSubmitButtonProps(isSubmitting, isValid)}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDocFormDialog;

import React from 'react';
import {
  Box,
  Stack,
  Avatar,
} from '@mui/material';
import { ContextMenuComplete, useCurrentTime } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';
import DetailsCard from './DetailsCard';
import { getPhoneNumber, getRoleDisplayName, formatTimeWithTimeZoneName } from '../../../utils/helpers';

type ContextMenuCompleteProps = Parameters<typeof ContextMenuComplete>[0];

type UserProfileProps = {
  agent: Agent;
  contextMenuProps?: ContextMenuCompleteProps
};

const UserProfile = ({ agent, contextMenuProps = undefined }: UserProfileProps) => {
  const [time] = useCurrentTime(agent?.timeZone?.name);

  return (
    <Stack
      direction="column"
      spacing={3}
    >
      <Box
        display="flex"
        justifyContent="center"
      >
        <Avatar
          sx={{
            height: 138,
            width: 138,
          }}
        >
          {`${agent?.givenName?.[0] || ''}${agent?.familyName?.[0] || ''}`}
        </Avatar>
      </Box>

      <DetailsCard
        items={[
          {
            title: 'Name',
            content: agent.fullName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            action: contextMenuProps && <ContextMenuComplete {...contextMenuProps} />,
          },
          {
            title: 'Title',
            content: agent.title,
          },
          {
            title: 'Role',
            content: getRoleDisplayName(agent.userInfo![0].roles),
          },
          {
            title: 'Email',
            content: agent.email,
          },
          {
            title: 'Phone',
            content: getPhoneNumber(agent),
          },
          {
            title: 'Timezone',
            content: agent?.timeZone ? formatTimeWithTimeZoneName(agent?.timeZone, time) : '-',
          },
        ]}
      />
    </Stack>
  );
};

export default UserProfile;

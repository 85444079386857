/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack, ListItem, SxProps, Tooltip,
} from '@mui/material';
import {
  ListItemCardSubtitle,
  ListItemCardTitle, LinkCard, ContextMenuButton, ContextMenu, ContextMenuItem,
} from '@marageti/z4-lib';
import { UserStatus } from '@marageti/z4-sdk/lib/auth';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { Avatar } from '../avatar';
import { getRoleDisplayName } from '../../utils/helpers';
import { peopleStatusIcons } from '../status-icons';
import { getMatchingUser } from '../../utils/auth';

type Props<T> = {
  loading?: boolean;
  individual: T;
  to: string;
  sx?: SxProps;
};

const UserLinkCard = <T extends Agent>({
  individual,
  loading = false,
  to,
  sx = {},
}: Props<T>) => {
  const menuId = 'user-context-menu';
  const buttonId = 'user-context-button';
  const userInfo = getMatchingUser(individual.email, individual.userInfo);
  return (
    <ListItem disablePadding sx={sx}>
      <LinkCard
        key={individual.id}
        to={to}
        state={individual}
        sx={{
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            gap={2}
          >
            <Avatar
              alt={individual.fullName}
              diameter={40}
              loading={loading}
              src={individual.avatar && `data:image/png;base64,${individual.avatar}`}
            >
              {`${individual.givenName?.[0] || ''}${individual.familyName?.[0] || ''}`}
            </Avatar>
            <Stack alignItems="space-between" minHeight="45px" sx={{ marginLeft: '16px' }}>
              <ListItemCardTitle>
                {`${individual.givenName} ${individual.familyName}`}
              </ListItemCardTitle>
              <ListItemCardSubtitle>
                {getRoleDisplayName(individual.userInfo![0].roles)}
              </ListItemCardSubtitle>
            </Stack>
          </Stack>
          {userInfo && (
            <Tooltip title={peopleStatusIcons[userInfo.status].displayName} placement="right">
              <span>
                {peopleStatusIcons[userInfo.status].icon}
              </span>
            </Tooltip>
          )}
        </Stack>
      </LinkCard>
    </ListItem>
  );
};

export default UserLinkCard;

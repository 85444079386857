import React from 'react';
import { AvatarCard, ContextMenuComplete } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { Card, CardHeader } from '@mui/material';

type SpecialistCardProps = {
  isAdmin: boolean,
  openModal: () => void
  specialist: Agent | undefined,
};

const SpecialistCard = ({ isAdmin, openModal, specialist = {} as Agent }: SpecialistCardProps) => {
  const {
    id, fullName, avatar, givenName, familyName,
  } = specialist;

  const initials = `${givenName?.charAt(0) || ''}${familyName?.charAt(0) || ''}`;

  const contextMenuAction = isAdmin ? (
    <ContextMenuComplete
      id="re-assign-agent"
      items={[{ action: openModal, label: 'Reassign' }]}
    />
  ) : null;

  return (
    <Card
      key={id}
      sx={{ width: '100%', minWidth: { desktop: '662px' } }}
    >
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={fullName}
        subheader="Assigned Specialist"
        avatar={(

          <AvatarCard
            alt={fullName}
            src={avatar}
            loading={!specialist}
            size={40}
          >
            {initials}
          </AvatarCard>
        )}
        action={contextMenuAction}
      />
    </Card>
  );
};

export default SpecialistCard;

import React from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { useSnackbar } from 'notistack';
import { TimeZone } from '@marageti/z4-sdk/lib/standards';
import UserProfileEditFormView from './user-profile-edit-form-view';

type UserProfileEditFormContainerProps = {
  agentId: string | undefined
  title?: string
  subtitle?: string
  submitText?: string
  suggestedTimezoneOption?: TimeZone | undefined
  onSuccess?: () => void
  onCancel?: () => void
};

const UserProfileEditFormContainer = ({
  title = undefined,
  subtitle = undefined,
  submitText = undefined,
  agentId,
  suggestedTimezoneOption = undefined,
  onSuccess = undefined,
  onCancel = undefined,
}: UserProfileEditFormContainerProps) => {
  const apiClient = useApiClient();
  const agent = useFetch<Agent>(apiClient.peopleClient.getAgent, [agentId], !!agentId);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: any) => {
    if (agent.data && agentId) {
      const updateAgent = {
        ...agent.data,
        givenName: data.givenName,
        familyName: data.familyName,
        title: data.title,
        phone: data.phone,
        timeZone: data.timeZone,
      };

      try {
        await apiClient.peopleClient.putAgent(agentId, updateAgent);
        enqueueSnackbar('User updated successfully.', { variant: 'success' });
        onSuccess?.();
        agent.mutate();
      } catch (error) {
        setSubmissionError(true);
      }
    }
  };

  return (
    <UserProfileEditFormView
      title={title}
      subtitle={subtitle}
      submitText={submitText}
      agent={agent}
      suggestedTimezoneOption={suggestedTimezoneOption}
      handleSubmit={handleSubmit}
      submissionError={submissionError}
      onCancel={onCancel}
    />
  );
};

export default UserProfileEditFormContainer;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { TripMatch, Review } from '@marageti/z4-sdk/lib/travel';
import DetailView from './review-view';

const DetailContainer = () => {
  const { tripMatchId } = useParams();
  const apiClient = useApiClient();
  const tripMatch = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const reviews = useFetch<[Review]>(apiClient.tripMatchesClient.getTripMatchReviews, [tripMatchId], true);

  return (
    <DetailView
      tripMatch={tripMatch.data}
      reviews={reviews.data}
      loading={reviews.isLoading || tripMatch.isLoading}
      error={reviews.error || tripMatch.error}
    />
  );
};

export default DetailContainer;

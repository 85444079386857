export const SET_SELECTED_INBOX_CONVERSATION_ID = 'SET_SELECTED_INBOX_CONVERSATION_ID';
export const setSelectedInboxConversationId = (selectedInboxConversationId: string | undefined) => ({
  type: SET_SELECTED_INBOX_CONVERSATION_ID,
  payload: selectedInboxConversationId,
});

export const SET_SHOW_UNREAD_ONLY = 'SET_SHOW_UNREAD_ONLY';
export const setShowUnreadOnly = (showUnreadOnly: boolean) => ({
  type: SET_SHOW_UNREAD_ONLY,
  payload: showUnreadOnly,
});

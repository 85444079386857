import React from 'react';
import { SecondaryAppBar } from '@marageti/z4-lib';
import { ViewProps } from './types';
import TabsView from './tabs-view';

const SubNavigationView = ({
  profile = undefined,
  startAdornment = undefined,
  endAdornment = undefined,
  tabs,
  ...secondaryAppbarProps
}: ViewProps) => (
  <SecondaryAppBar
    collapsing
    position="sticky"
    color="default"
    profile={profile}
    startAdornment={startAdornment}
    endAdornment={endAdornment}
    tabs={
      tabs ? <TabsView tabs={tabs} /> : undefined
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...secondaryAppbarProps}
  />
);

export default SubNavigationView;

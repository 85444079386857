import {
  Dispatch,
  Reducer,
  useReducer,
} from 'react';
import { Actions } from '../../types';
import {
  SET_CONVERSATIONS_ERROR,
  SET_CONVERSATIONS_LOADING,
  SET_CONVERSATIONS_MAP,
  SET_UNREAD_COUNT,
} from './actions';
import { ConversationsStateProps } from './types';

const initialState: ConversationsStateProps = {
  conversationsMap: {},
  error: undefined,
  isLoading: true,
  unreadCount: 0,
};

const reducer: Reducer<ConversationsStateProps, Actions> = (state, action): ConversationsStateProps => {
  switch (action.type) {
    case SET_CONVERSATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_CONVERSATIONS_MAP:
      return {
        ...state,
        conversationsMap: action.payload,
      };
    case SET_CONVERSATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
};

const useConversationsReducer = () => {
  const [conversations, dispatchConversations] = useReducer(reducer, initialState);
  return [conversations, dispatchConversations] as [ConversationsStateProps, Dispatch<Actions>];
};

export default useConversationsReducer;

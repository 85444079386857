import { useApiClient } from '@marageti/z4-lib';
import { DocumentType, DocumentRequest } from '@marageti/z4-sdk/lib/documents/Document';
import putS3Document from '../utils/s3-documents';

const useDocumentUpload = () => {
  const apiClient = useApiClient();
  /**
   * Upload a file to a specific trip match.
   * @param file: The file to upload.
   * @param matchId: The ID of the trip match where the document will be uploaded.
   * @param documentType: The type of document being uploaded.
   * @param title: The title for the uploaded file.
   */
  const uploadFile = async (file: File, matchId: string, documentType: DocumentType, docTitle: string) => {
    if (!file) throw new Error('No file provided');
    if (!matchId) throw new Error('No match ID provided');
    if (!documentType) throw new Error('No document type provided');
    if (!docTitle) throw new Error('No document title provided');

    const z4Req = {
      mediaType: file.type,
      documentType,
      sourceFileName: file.name,
      title: docTitle,
    };

    return apiClient.tripMatchesClient
      .postTripMatchDocument(matchId, z4Req as DocumentRequest)
      .then((res) => putS3Document(res.preSignedURL.url, file));
  };

  /**
   * Upload a URL of a file to a specific trip match.
   * @param url The url of the file to upload.
   * @param matchId The ID of the trip match where the document will be uploaded.
   * @param documentType The type of document being uploaded.
   * @param docTitle The title for the uploaded file.
   */
  const uploadURL = async (url: string, matchId: string, documentType: DocumentType, docTitle: string) => {
    if (!url) throw new Error('No file url provided');
    if (!matchId) throw new Error('No match ID provided');
    if (!documentType) throw new Error('No document type provided');
    if (!docTitle) throw new Error('No document title provided');

    const z4Req = {
      mediaType: 'application/pdf',
      documentType,
      sourceURI: url,
      title: docTitle,
    };

    return apiClient.tripMatchesClient
      .postTripMatchDocument(matchId, z4Req as DocumentRequest);
  };

  return {
    uploadFile,
    uploadURL,
  };
};

export default useDocumentUpload;

import {
  ConversationSummaries, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import React from 'react';
import { OutlinedIcons } from '@marageti/z4-lib';

const getConversationSummaryByConversationId = (conversationId: string, conversationSummaries: ConversationSummaries) => conversationSummaries && conversationSummaries.find(
  (summary) => summary.conversationId === conversationId,
);

const getConversationSummaryByConversationType = (conversationType: ConversationType, conversationSummaries: ConversationSummaries) => conversationSummaries && conversationSummaries.find(
  (summary) => summary.conversationType === conversationType,
);

const getConversationIcon = ({
  conversationType,
}: {
  conversationType: ConversationType
}) => {
  switch (conversationType) {
    case ConversationType.TravelerToPartner:
      return <OutlinedIcons.OutlinedBriefcaseIcon />;
    case ConversationType.ZicassoToPartner:
      return <OutlinedIcons.OutlinedOfficeBuildingIcon />;
    default:
      return <OutlinedIcons.OutlinedHeartIcon />;
  }
};

const getConversationTypeTitle = ({
  conversationType,
}: {
  conversationType: ConversationType
}) => {
  switch (conversationType) {
    case ConversationType.TravelerToPartner:
      return 'Traveler';
    case ConversationType.ZicassoToPartner:
      return 'Partner';
    default:
      return 'Unknown Conversation Type';
  }
};

const getConversationParticipants = ({
  conversationType,
  travelerName,
  partnerName,
}: {
  conversationType: ConversationType,
  travelerName: string | undefined,
  partnerName: string | undefined,
}) => {
  const getParticipantsNames = () => {
    switch (conversationType) {
      case ConversationType.TravelerToPartner:
        return [travelerName, partnerName];
      case ConversationType.ZicassoToPartner:
        return [partnerName, 'Zicasso'];
      default:
        return [];
    }
  };

  return getParticipantsNames().filter(Boolean).join(', ');
};

export {
  getConversationSummaryByConversationId,
  getConversationSummaryByConversationType,
  getConversationIcon,
  getConversationTypeTitle,
  getConversationParticipants,
};

import React, { useState } from 'react';
import {
  OutlinedIcons,
} from '@marageti/z4-lib';
import {
  Box, Stack, Typography, Card, CardContent, Button,
} from '@mui/material';

const TestingBanner = () => {
  const [isExpanded, setExpanded] = useState(true);
  const stage = process.env.REACT_APP_STAGE;

  const toggleCollapse = () => {
    setExpanded(!isExpanded);
  };

  if (stage === 'prod') {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      onClick={toggleCollapse}
    >
      <Card
        sx={{
          cursor: 'pointer',
          borderRadius: isExpanded ? '16px' : '50%',
          backgroundColor: (theme) => theme.palette.warning.light,
          boxShadow: (theme) => theme.shadows[1],
          transition: 'border-radius 0.2s',
        }}
      >
        <CardContent sx={{
          display: 'flex',
          paddingLeft: isExpanded ? 3 : 2,
          paddingRight: isExpanded ? 3 : 2,
          '&:last-child': {
            paddingBottom: isExpanded ? 3 : 2,
          },
          '&:first-of-type': {
            paddingTop: isExpanded ? 3 : 2,
          },
        }}
        >
          <OutlinedIcons.OutlinedExclamationIcon color="warning" />
          <Stack
            spacing={0.5}
            sx={{
              overflow: 'hidden',
              mx: isExpanded ? 2 : 0,
              width: isExpanded ? 'auto' : 0,
              height: isExpanded ? 'auto' : 0,
              transition: 'margin-left 0.2s, margin-right 0.2s',
            }}
          >
            <Typography variant="bodyBold">
              This platform is for testing purposes only
            </Typography>
            <Typography>
              Click the button to access the live Zicasso Partners platform
            </Typography>
            <Button
              sx={{ mt: 2, maxWidth: '250px' }}
              variant="contained"
              href="https://partners.zicasso.com"
            >
              Go to Partners
            </Button>
          </Stack>
          <OutlinedIcons.OutlinedXIcon
            color="action"
            sx={{
              display: isExpanded ? 'block' : 'none',
              transition: 'display 0.5s',
            }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default TestingBanner;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Stack,
  Button,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import {
  useForm,
  getSubmitButtonProps,
  getFormProps,
  RichTextEditor,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { useParams } from 'react-router-dom';
import { kebabToCamelCase, kebabToTitleCase } from '../../utils/strings';

type DetailEditFormProps = {
  partner: Partner,
  onSubmit: (data: any) => void,
  submissionError: boolean
  onCancel?: () => void
  title?: string,
};

const DetailEditForm = ({
  partner,
  onSubmit,
  submissionError,
  onCancel = undefined,
  title = undefined,
}: DetailEditFormProps) => {
  const { fieldName } = useParams();
  const attributeName = kebabToCamelCase(fieldName!);
  const displayName = kebabToTitleCase(fieldName!);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    defaultValues: {
      [attributeName]: partner[attributeName as keyof typeof partner] as string,
    },
  });

  return (
    <Stack
      component="form"
      sx={{
        boxSizing: 'border-box',
        height: { mobile: 'calc(100vh - 181px)' },
      }}
      spacing={3}
      {...getFormProps(handleSubmit(onSubmit))}
    >
      <Typography variant="h2" sx={{ mb: 3 }}>{ title || 'Edit' }</Typography>
      <Controller
        control={control}
        name={attributeName}
        render={({ field }) => (
          <RichTextEditor
            defaultValue={partner[attributeName as keyof typeof partner] as string}
            editorName={attributeName}
            error={!!errors[attributeName]}
            helperText={errors[attributeName]?.message}
            label={displayName}
            onChange={field.onChange}
            sx={{
              maxHeight:
              { mobile: 'calc(100% - 192px)', desktop: '500px' },
              flex: 1,
            }}
            useLinks
            useLists
          />
        )}
      />
      {submissionError && (
      <Alert severity="error">
        <AlertTitle>There was an error submitting the form.</AlertTitle>
        Please try again.
      </Alert>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={(theme) => ({
          [theme.breakpoints.down('desktop')]: {
            marginTop: 3,
            flexDirection: 'column-reverse',
            '& > :not(:first-of-type)': {
              margin: theme.spacing(0, 0, 2.5, 0),
            },
          },
        })}
      >
        <LoadingButton
          variant="contained"
          type="submit"
          {...getSubmitButtonProps(isSubmitting, isValid)}
        >
          Save
        </LoadingButton>

        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailEditForm;

import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Avatar,
} from '@mui/material';
import { ContextMenuComplete } from '@marageti/z4-lib';
import {
  Conversation, ConversationMessageState, ConversationStateEntry, ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAuthStore, useConversationsStore, useInboxStore } from '../../../store';

type ConversationAvatarProps = {
  conversationType: ConversationType;
  hasNew: boolean;
  travelerName: string;
};

const getInitials = (name: string) => name.split(' ').slice(0, 2).map((n) => n[0]).join('');

const getTitle = (conversationType: ConversationType, travelerName: string) => {
  if (conversationType === ConversationType.TravelerToPartner) {
    return travelerName;
  }
  return `Zicasso | ${travelerName}`;
};

const getDate = (conversation: Conversation, agentId: string) => {
  const date = conversation.messageState.find((c: ConversationStateEntry) => c.personId !== '' && c.personId === agentId)?.lastMessageReceivedAt;
  // if date is not valid (0001-01-01T00:00:00Z), return empty string
  if (!date || date === '0001-01-01T00:00:00Z') return '';
  return format(new Date(date), 'MMM d');
};

const getHasNew = (id: string, messageState: ConversationMessageState) => {
  const userMessageState = messageState.find((c: ConversationStateEntry) => c.personId !== '' && c.personId === id);
  return userMessageState?.hasNew || false;
};

const ConversationAvatar = ({ conversationType, hasNew, travelerName }: ConversationAvatarProps) => {
  if (conversationType === ConversationType.TravelerToPartner) {
    return (
      <Avatar
        alt={travelerName}
        sx={{
          height: '40px',
          position: 'relative',
          width: '40px',
          overflow: 'unset',
          ...hasNew && {
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: 'info.main',
            },
          },
        }}
      >
        {getInitials(travelerName)}
      </Avatar>
    );
  }
  return (
    <Avatar
      sx={{
        backgroundColor: 'secondary.main',
        height: '40px',
        position: 'relative',
        width: '40px',
        overflow: 'unset',
        ...hasNew && {
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: 'info.main',
          },
        },
      }}
    >
      Z
    </Avatar>
  );
};

const ConversationList = () => {
  const navigate = useNavigate();
  const {
    conversationsMap,
    markConversationRead,
    markConversationUnread,
  } = useConversationsStore();
  const {
    selectedInboxConversationId,
    setSelectedInboxConversationId,
  } = useInboxStore();
  const { loggedInAgent } = useAuthStore();
  const { id } = loggedInAgent || {};

  const handleSelectConversation = (conversationId: string) => {
    // if the conversation is already selected, deselect it
    if (selectedInboxConversationId === conversationId) {
      setSelectedInboxConversationId(undefined);
      navigate('/trip-matches/messages');
      return;
    }
    setSelectedInboxConversationId(conversationId);
    navigate(conversationId);
  };

  const handleClickRead = (conversationId: string) => {
    if (selectedInboxConversationId === conversationId) {
      setSelectedInboxConversationId(undefined);
      navigate('/trip-matches/messages');
    }
    markConversationRead(conversationId);
  };

  const handleClickUnread = (conversationId: string) => {
    if (selectedInboxConversationId === conversationId) {
      setSelectedInboxConversationId(undefined);
      navigate('/trip-matches/messages');
    }
    markConversationUnread(conversationId);
  };

  return (
    <List
      component={Stack}
      spacing={1.5}
      sx={{
        width: '100%',
        flex: 1,
        overflowY: { desktop: 'auto' },
      }}
    >
      {Object.values(conversationsMap).map((conversation: Conversation) => (
        <ListItem
          key={conversation.id}
          sx={{
            p: 0,
            pr: 2,
            backgroundColor: selectedInboxConversationId === conversation.id ? 'neutral.main' : 'initial',
            borderRadius: '12px',
            '&:hover': {
              backgroundColor: 'background.paper',
            },
          }}
        >
          <ListItemButton
            onClick={() => handleSelectConversation(conversation.id)}
            sx={{ borderRadius: '12px', p: 2, pr: 1 }}
          >
            <ListItemIcon>
              <ConversationAvatar
                conversationType={conversation.conversationType}
                travelerName={conversation.primaryTravelerName || 'Traveler'}
                hasNew={getHasNew(id!, conversation.messageState)}
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap={1}
                >
                  <Typography variant="bodyBold" noWrap>
                    {getTitle(conversation.conversationType, conversation.primaryTravelerName || 'Traveler')}
                  </Typography>

                  <Box display="flex" alignItems="center" columnGap={0.5}>
                    <Typography variant="caption" noWrap>
                      {getDate(conversation, id!)}
                    </Typography>
                  </Box>
                </Box>
              )}
              disableTypography
            />
          </ListItemButton>
          <ContextMenuComplete
            id={`${conversation.id}-menu`}
            items={getHasNew(id!, conversation.messageState) ? [
              {
                label: 'Mark as Read',
                action: () => { handleClickRead(conversation.id); },
              },
            ] : [
              {
                label: 'Mark as Unread',
                action: () => { handleClickUnread(conversation.id); },
              },
            ]}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ConversationList;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { useApiClient, useFetch, useFetchImmutable } from '@marageti/z4-lib';
import { RoleName, UserStatus } from '@marageti/z4-sdk/lib/auth';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { useAuthStore } from '../../store';
import { titleCase } from '../../utils/helpers';

type AssigneeOption = {
  title: string;
  value: string;
  status?: string;
};

const AssignedToSelect = ({
  assignedTo,
  onChange,
  showCount = false,
  label = 'Assignee',
}: {
  assignedTo: string,
  onChange: (v: string) => void,
  showCount?: boolean,
  label?: string,
}) => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const { data: agents } = useFetchImmutable<Agent[]>(apiClient.partnersClient.getPartnerAgents, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const getUnAssignedSearchRequest = (): any => (
    {
      filterBy: [{ field: 'assignedTo', value: [''] }],
      pagination: { limit: 0, page: 0 },
    }
  );

  // Fetch unassigned trip matches to display a count.
  const unAssignedSearchRequest = getUnAssignedSearchRequest();
  const {
    data: unAssignedData,
  } = useFetch<any>(apiClient.tripMatchesClient.postTripMatchSearches, [unAssignedSearchRequest], showCount);

  const unAssignedCount = unAssignedData?.totalHits as number || 0;

  const agentsAsOptions = useMemo(() => {
    if (!agents) return [{ title: 'All', value: 'all' }];
    const defOptions = [{ title: 'All', value: 'all' }];
    const options: Record<UserStatus, AssigneeOption[]> = {
      ENABLED: [],
      PENDING: [],
      DISABLED: [],
    } as Record<UserStatus, AssigneeOption[]>;
      // Status field is used for option grouping and title
    agents.forEach((agent) => {
      if (!agent.userInfo[0].roles?.includes(RoleName.AGENT_SUPPORT)) {
        options[agent.userInfo[0].status].push({
          title: agent.fullName,
          value: agent.id,
          status: agent.userInfo[0].status === UserStatus.ENABLED
            ? '--- Active ---' : `--- ${titleCase(agent.userInfo[0].status)} ---`,
        });
      }
    });
    options.ENABLED.sort((a, b) => a.title.localeCompare(b.title));
    options.PENDING.sort((a, b) => a.title.localeCompare(b.title));
    options.DISABLED.sort((a, b) => a.title.localeCompare(b.title));
    if (loggedInAgent?.userInfo[0].roles?.includes(RoleName.AGENT_ADMIN)) {
      defOptions.push({ title: 'Not Assigned', value: 'none' });
    }
    return [...defOptions, ...options.ENABLED, ...options.PENDING, ...options.DISABLED];
  }, [agents, loggedInAgent]);

  const assigneeName = agentsAsOptions?.find((op) => op.value === assignedTo)?.title || '';
  const selectedAssignee: AssigneeOption = agents && assignedTo ? { title: assigneeName, value: assignedTo } : { title: 'All', value: 'all' };

  return (
    <Autocomplete
      getOptionKey={(o) => o.value}
      getOptionLabel={(o) => o.title}
      // @ts-ignore
      groupBy={(o) => o.status || ''}
      id="assignee"
      isOptionEqualToValue={(o, v) => o.value === v.value}
      onChange={(_, v) => onChange(v?.value || '')}
      options={agentsAsOptions}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          name={label}
        />
      )}
      disableClearable
      value={selectedAssignee}
      renderOption={({ key, ...props }, option) => {
        if (option.value === 'none' && showCount) {
          return (
            // @ts-ignore
            <Box {...props} key={key}>
              <Typography variant="body1">{option.title}</Typography>
              <Avatar
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                  backgroundColor: '#D4E0FF',
                  mx: '0.5rem',
                }}
              >
                <Typography variant="captionBold" sx={{ color: '#0089FC' }}>{unAssignedCount}</Typography>
              </Avatar>
            </Box>
          );
        }
        return (
          // @ts-ignore
          <Box {...props} key={key}>
            <Typography width="100%">{option.title}</Typography>
          </Box>
        );
      }}
    />
  );
};

export default AssignedToSelect;

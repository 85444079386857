/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { useSnackbar } from 'notistack';
import { DocumentType } from '@marageti/z4-sdk/lib/documents/Document';
import DocumentUploadFormDialog from './add-documents-form-dialog';
import useDocumentUpload from '../../../hooks/use-document-upload';

type Props = {
  open: boolean,
  closeDialog: () => void,
  onDocumentUploaded: () => void,
  tripMatchId: string,
};

const AddDocumentsContainer = ({
  open,
  closeDialog,
  onDocumentUploaded,
  tripMatchId,
}: Props) => {
  const [submissionError, setSubmissionError] = useState<ApiError>();
  const { uploadURL, uploadFile } = useDocumentUpload();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: any) => {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    setSubmissionError({ message: errorMessage });
  };

  const onSubmitURL = async (data: any) => {
    setSubmissionError(undefined);
    try {
      if (tripMatchId) {
        await uploadURL(data.sourceURI, tripMatchId, DocumentType.DocumentGeneral, data.title);
        onDocumentUploaded();
        enqueueSnackbar('Your document has been saved.', { variant: 'success' });
        closeDialog();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const onSubmitFile = async (data: any) => {
    setSubmissionError(undefined);
    const file = data.document[0];
    const newFile = new File([file], data.title, { type: file.type });
    try {
      if (tripMatchId) {
        await uploadFile(newFile, tripMatchId, DocumentType.DocumentGeneral, data.title);
        onDocumentUploaded();
        enqueueSnackbar('Your document has been saved.', { variant: 'success' });
        closeDialog();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <DocumentUploadFormDialog
      open={open}
      closeDialog={closeDialog}
      submissionError={submissionError}
      onSubmitFile={onSubmitFile}
      onSubmitURL={onSubmitURL}
    />
  );
};

export default AddDocumentsContainer;

import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
} from '@mui/material';

type DetailCardProps = {
  title?: string,
  content?: string,
  items: {
    title: React.ReactNode,
    content?: React.ReactNode,
    action?: React.ReactNode
  }[]
};

const DetailCard = ({ title = undefined, content = undefined, items }: DetailCardProps) => (
  <Card>
    {title && (<CardHeader title={title} />)}
    <CardContent>
      {content && (<Typography>{content}</Typography>)}

      <Stack
        spacing={2}
      >
        {items
          .map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} display="flex" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="column"
              >
                <Typography variant="caption" fontWeight="bold" color="textPrimary">
                  {item.title}
                </Typography>
                <Typography color="text.secondary">
                  {item.content || '-'}
                </Typography>
              </Box>
              {item.action}
            </Box>

          ))}
      </Stack>
    </CardContent>
  </Card>
);

export default DetailCard;

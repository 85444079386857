import React from 'react';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { SWRResponse } from 'swr';
import UserProfile from './UserProfile/UserProfile';
import UserProfileSkeleton from './UserProfile/UserProfileSkeleton';
import UserProfileError from './UserProfile/UserProfileError';

type UserProfileViewProps = {
  agent: SWRResponse<Agent, any, any>
  contextMenuProps: Parameters<typeof UserProfile>[0]['contextMenuProps']
};

const UserProfileView = ({
  agent,
  contextMenuProps,
}: UserProfileViewProps) => {
  if (agent.isLoading) {
    return <UserProfileSkeleton />;
  }

  if (agent.error) {
    return <UserProfileError error={agent.error} />;
  }

  if (agent.data) {
    return (
      <UserProfile
        agent={agent.data}
        contextMenuProps={contextMenuProps}
      />
    );
  }

  return null;
};

export default UserProfileView;

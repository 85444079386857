import React from 'react';
import { Outlet } from 'react-router-dom';
import SalesSubNavigation from '../../components/sales-sub-nav';

const View = () => (
  <>
    <SalesSubNavigation />
    <Outlet />
  </>
);

export default View;

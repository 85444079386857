/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Divider,
  IconButton,
  Box,
  Avatar,
  AvatarGroup,
  Tooltip,
  Skeleton,
} from '@mui/material';
import {
  OutlinedIcons,
  useReviewsHistogram,
  useCurrentTime,
  getRatingData,
  copyToClipboard,
  LabelAndValueElement,
  formatPhoneNumber,
  formatTimeWithTimeZoneName,
} from '@marageti/z4-lib';
import { useNavigate, Link } from 'react-router-dom';
import { Traveler } from '@marageti/z4-sdk/lib/people';
import { UserStatus } from '@marageti/z4-sdk/lib/auth';

const {
  OutlinedDocumentDuplicateIcon,
} = OutlinedIcons;

type TravelerProps = {
  hidePhone?: boolean;
  baseRoute?: string;
  travelers: Traveler[];
  variant?: 'default' | 'compact';
};

type RatingsProps = {
  travelerId: string;
  baseRoute: string; // Base route to append /travelers/:id to
};

type RepeatTripsProps = {
  tripsCount: number;
};

const TravelerRepeatTrips = ({ tripsCount }: RepeatTripsProps) => (
  <Typography
    variant="captionBold"
    color="primary"
    component="span"
  >
    {tripsCount && tripsCount > 0 ? (
      `Previous Trips (${tripsCount})`
    ) : (
      'Previous Trips (0)'
    )}
  </Typography>
);

const TravelerReviewsCount = ({ travelerId, baseRoute }: RatingsProps) => {
  const { histogramAggregation, loading, error } = useReviewsHistogram({ travelerId });
  const { total } = histogramAggregation ? getRatingData(histogramAggregation) : { total: 0 };

  if (loading) return null;

  return (
    <Link
      to={`${baseRoute}/travelers/${travelerId}`}
      style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}
    >
      <Typography
        variant="captionBold"
        color="primary"
        component="span"
        sx={{
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {!loading && error && 'Reviews (0)'}
        {!loading && !error && (
          total === 0 ? (
            'Reviews (0)'
          ) : (
            `Reviews (${total})`
          ))}
      </Typography>
    </Link>
  );
};

const TravelerCardSkeleton = () => (
  <Card variant="loose">
    <Box sx={{ pt: 3, pl: 3 }} display="flex">
      <Skeleton variant="circular" width={40} height={40} />
    </Box>
    <Box sx={{ px: 3, pb: 2, pt: 1.5 }}>
      <Skeleton variant="text" width={150} height={32} />
      <Skeleton variant="text" width={100} height={20} />
    </Box>
    <Divider sx={{ mx: 3 }} />
    <CardContent sx={{ '&&': { pt: 2 } }}>
      <Stack spacing={1}>
        <LabelAndValueElement
          title="Phone"
          value={<Skeleton variant="text" width={120} height={24} />}
          key="primary-phone"
        />
        <LabelAndValueElement
          title="Timezone"
          value={<Skeleton variant="text" width={120} height={24} />}
          key="timezone"
        />
      </Stack>
    </CardContent>
    <CardContent
      sx={{
        display: 'flex',
        gap: '24px',
        '&&': {
          pt: '16px',
        },
      }}
    >
      <Skeleton variant="text" width={120} height={20} />
      <Skeleton variant="text" width={80} height={20} />
    </CardContent>
  </Card>
);

const TravelerCard = ({
  hidePhone = false,
  baseRoute = '',
  travelers,
  variant = 'default',
}: TravelerProps) => {
  const primaryTraveler = travelers[0];
  const phoneNumber = formatPhoneNumber(primaryTraveler?.phone?.prefix, primaryTraveler?.phone?.number);
  const [time] = useCurrentTime(primaryTraveler?.timeZone?.name);
  const navigate = useNavigate();

  if (!travelers?.length) {
    return (
      <TravelerCardSkeleton />
    );
  }

  const getTravelerName = (traveler: Traveler, isPrimaryTraveler: boolean, isPending: boolean) => {
    let suffix = '';
    if (!isPrimaryTraveler) {
      suffix = isPending ? ' (Pending Confirmation)' : ' (Co-Traveler)';
    }

    return `${traveler.givenName} ${traveler.familyName}${suffix}`;
  };

  return (
    <Card variant="loose">
      <Box sx={{ pt: 3, pl: 3 }} display="flex">
        <AvatarGroup>
          {travelers.map((traveler, index) => {
            const isPrimaryTraveler = index === 0;
            const isPendingCoTraveler = !isPrimaryTraveler && traveler.userInfo[0]?.status === UserStatus.PENDING;
            const travelerName = getTravelerName(traveler, isPrimaryTraveler, isPendingCoTraveler);
            return (
              <Avatar
                key={traveler.id}
                alt={traveler.fullName}
                sx={{
                  width: '40px', height: '40px', backgroundColor: 'common.black50',
                }}
                onClick={() => {
                  navigate(`${baseRoute}/travelers/${traveler.id}`);
                }}
                style={{ cursor: 'pointer', opacity: isPendingCoTraveler ? 0.4 : 1 }}
              >
                <Typography variant="captionBold" color="white">
                  <Tooltip title={travelerName} placement="top">
                    <span>
                      {`${traveler.givenName?.[0].toUpperCase() || ''}${traveler.familyName?.[0].toUpperCase() || ''}`}
                    </span>
                  </Tooltip>
                </Typography>
              </Avatar>
            );
          })}
        </AvatarGroup>
      </Box>
      <Box sx={{ px: 3, pb: 2, pt: 1.5 }}>
        <Link to={`${baseRoute}/travelers/${primaryTraveler?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant={variant === 'compact' ? 'bodyBold' : 'h3'} color="primary" sx={{ '&:hover': { textDecoration: 'underline' } }}>
            {primaryTraveler?.fullName}
          </Typography>
          <Typography variant="caption" color="primary">
            Primary Traveler
          </Typography>
        </Link>
      </Box>
      <Divider sx={{ mx: 3 }} />
      <CardContent sx={{ '&&': { pt: 2 } }}>
        <Stack spacing={1}>
          <LabelAndValueElement
            title="Phone"
            value={
              phoneNumber ? (
                !hidePhone ? (
                  <Box component="span" display="flex" alignItems="center">
                    {phoneNumber}
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(phoneNumber)}
                      aria-label="copy phone number"
                    >
                      <OutlinedDocumentDuplicateIcon color="primary" fontSize="inherit" />
                    </IconButton>
                  </Box>
                ) : (
                  <Typography component="span" variant="bodyItalic">Available After First Message is Sent</Typography>
                )
              ) : (
                '-'
              )
            }
            key="primary-phone"
          />
          <LabelAndValueElement
            title="Timezone"
            value={primaryTraveler?.timeZone ? formatTimeWithTimeZoneName(primaryTraveler?.timeZone, time) : '-'}
            key="timezone"
          />
        </Stack>
      </CardContent>
      <CardContent
        sx={{
          display: 'flex',
          gap: '24px',
          '&&': {
            pt: '16px',
          },
        }}
      >
        <TravelerRepeatTrips tripsCount={primaryTraveler?.totalZicassoTrips} />
        <TravelerReviewsCount travelerId={primaryTraveler?.id} baseRoute={baseRoute} />
      </CardContent>
    </Card>
  );
};

export default TravelerCard;

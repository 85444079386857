import React, { useMemo } from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { SearchResult } from '@marageti/z4-sdk/lib/entity';
import { Conversation } from '@marageti/z4-sdk/lib/travel';
import { UNREAD_COUNT_PARAMS } from '../../store/contexts/conversations';
import SubNavigation from '../sub-navigation';
import { TabItem } from '../sub-navigation/types';

const TRIP_MATCHES_TABS: TabItem[] = [
  {
    label: 'All Trips',
    to: '',
    replace: false,
  },
  {
    label: 'Sold Trips',
    to: 'sold',
    replace: false,
  },
  {
    label: 'Messages (0)',
    to: 'messages',
    replace: false,
  },
];

const TripsSubNavigationContainer = () => {
  const apiClient = useApiClient();
  const { data: unreadCount } = useFetch<SearchResult<Conversation>>(
    apiClient.conversationsClient.getParticipatingConversations,
    [{ params: UNREAD_COUNT_PARAMS }],
    true,
    { refreshInterval: 1000 * 60 * 3 },
  );

  const tabs = useMemo(() => {
    if (unreadCount?.totalHits !== undefined) {
      return [
        ...TRIP_MATCHES_TABS.slice(0, 2),
        {
          ...TRIP_MATCHES_TABS[2],
          label: `Messages (${unreadCount?.totalHits ?? '-'})`,
        },
      ];
    }
    return TRIP_MATCHES_TABS;
  }, [unreadCount?.totalHits]);

  return (
    <SubNavigation
      profile={{
        primary: 'Trips',
        primaryTypographyProps: {
          variant: 'h3',
        },
      }}
      tabs={tabs}
    />
  );
};
export default TripsSubNavigationContainer;

import { useApiClient, useFetch } from '@marageti/z4-lib';
import React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import RepeatReferralView from './repeat-referral-view';

const RepeatReferralContainer = () => {
  const {
    repeatReferralId,
  } = useParams<{
    repeatReferralId: string,
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const {
    isLoading: repeatReferralIsLoading,
    data: repeatReferral,
    error: repeatReferralError,
    mutate: mutateRepeatReferral,
  } = useFetch<SalesRecording>(apiClient.salesRecordingsClient.getSalesRecording, [repeatReferralId], !!repeatReferralId);

  const onSuccess = (createdUpdatedRepeatReferralId: string) => {
    mutateRepeatReferral();

    if (location.key === 'default') {
      navigate(`/sales/repeat-referral/${createdUpdatedRepeatReferralId}`);
    } else {
      navigate(-1);
    }
  };

  const onCancel = () => {
    // Fallback to the home page if the user cannot go back to the previous page
    if (location.key === 'default') {
      navigate('/sales/monthly');
    } else {
      navigate(-1);
    }
  };

  return (
    <RepeatReferralView
      isLoading={repeatReferralIsLoading}
      isError={repeatReferralError}
      repeatReferral={repeatReferral}
      onSuccess={onSuccess}
      onCancel={onCancel}
    />
  );
};

export default RepeatReferralContainer;

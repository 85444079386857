import { useApiClient, useFetch } from '@marageti/z4-lib';
import { Agent, Traveler } from '@marageti/z4-sdk/lib/people';
import React, { useState } from 'react';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import { useAuthStore } from '../../store';
import RepeatReferralFormView from './repeat-referral-form-view';

type RepeatReferralFormContainerProps = {
  repeatReferral?: SalesRecording | undefined
  onSuccess: (createUpdateRepeatReferralId: string) => void
  onCancel: () => void
};

const RepeatReferralFormContainer = ({
  repeatReferral = undefined,
  onSuccess,
  onCancel,
}: RepeatReferralFormContainerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const { data: agents } = useFetch<Agent[]>(apiClient.partnersClient.getPartnerAgents, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const agentOptions = agents?.map((agent: Agent) => ({
    title: agent.fullName,
    value: agent.id,
  }));

  const addRepeatReferral = (formValues: any) => {
    const salesRecordingRequest = {
      salesRecordingType: formValues.salesRecordingType,
      travelers: [{
        givenName: formValues.givenName,
        familyName: formValues.familyName,
        fullName: `${formValues.givenName} ${formValues.familyName}`,
      }] as Traveler[],
      assignedTo: formValues.agent,
      assignedToName: agents?.find((agent: Agent) => agent.id === formValues.agent)?.fullName,
      price: formValues.price,
      commission: formValues.commission,
      priceComments: formValues.priceComments,
    };

    return apiClient.salesRecordingsClient.postSalesRecording(salesRecordingRequest);
  };

  const updateRepeatReferral = (id: string, previousRepeatReferral: SalesRecording, formValues: any) => {
    const repeatReferralUpdate = {
      ...previousRepeatReferral,
      salesRecordingType: formValues.salesRecordingType,
      travelers: [{
        givenName: formValues.givenName,
        familyName: formValues.familyName,
        fullName: `${formValues.givenName} ${formValues.familyName}`,
      }],
      assignedTo: formValues.agent,
      assignedToName: agents?.find((agent: Agent) => agent.id === formValues.agent)?.fullName,
      price: { ...previousRepeatReferral.price, ...formValues.price },
      commission: { ...previousRepeatReferral.commission, ...formValues.commission },
      priceComments: formValues.priceComments,
    };

    return apiClient.salesRecordingsClient.putSalesRecording(id, repeatReferralUpdate);
  };

  const handleSubmit = async (formValues: any) => {
    setSubmissionError(false);
    try {
      if (!repeatReferral) {
        const addedSalesRecording = await addRepeatReferral(formValues);
        onSuccess(addedSalesRecording.salesRecording.id);
        enqueueSnackbar('Repeat / Referral sale added.', { variant: 'success' });
      } else {
        await updateRepeatReferral(repeatReferral.id, repeatReferral, formValues);
        enqueueSnackbar('Repeat / Referral sale edited.', { variant: 'success' });
        onSuccess(repeatReferral.id);
      }
    } catch (error) {
      setSubmissionError(true);
    }
  };

  return (
    <RepeatReferralFormView
      repeatReferral={repeatReferral}
      handleSubmit={handleSubmit}
      agents={agentOptions}
      defaultAgentId={loggedInAgent?.id}
      submissionError={submissionError}
      onCancel={onCancel}
    />
  );
};

export default RepeatReferralFormContainer;

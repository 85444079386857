import React from 'react';
import {
  Container,
  Grid,
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';
import { BackNavigationAppBar, AvatarCard } from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Traveler } from '@marageti/z4-sdk/lib/people';
import TravelerReviews from './traveler-reviews';
import TravelerCard from './traveler-card';

type Props = {
  error?: ApiError;
  hidePhone: boolean;
  isLoadingTr: boolean;
  traveler?: Traveler;
};

const TravelerView = ({
  error = undefined,
  hidePhone,
  isLoadingTr,
  traveler = undefined,
}: Props) => (
  <>
    <BackNavigationAppBar
      defaultPath="../../"
      primary=""
    />
    <Container maxWidth="lg">
      <Grid container spacing={7.5}>
        <Grid item mobile={12} desktop={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {!error && (
            <>
              {isLoadingTr
                ? <Skeleton variant="circular" width={138} height={138} sx={{ mb: 3 }} />
                : (
                  <AvatarCard size={138} src={traveler?.avatar} sx={{ mb: 3 }}>
                    {`${traveler?.givenName[0] || ''}${traveler?.familyName[0] || ''}`}
                  </AvatarCard>
                )}
              {isLoadingTr ? (
                <Skeleton height={364} width={337} variant="rounded" sx={{ mb: 3 }} />
              ) : (
                <TravelerCard hidePhone={hidePhone} traveler={traveler!} />
              )}
            </>
          )}
          {error && (
          <Alert severity="error">
            <AlertTitle>There was an error getting the traveler.</AlertTitle>
            {`Code: ${error.code}: ${error.message}`}
            <br />
            {error.id && `ID: ${error.id}`}
          </Alert>
          )}
        </Grid>
        <Grid item mobile={12} desktop={7}>
          <TravelerReviews travelerName={traveler?.givenName || ''} />
        </Grid>
      </Grid>
    </Container>
  </>
);

export default TravelerView;

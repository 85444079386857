import React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useApiClient,
  BackButton,
  useFetch,
} from '@marageti/z4-lib';
import { useSnackbar } from 'notistack';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { BillingAddress } from '@marageti/z4-sdk/lib/travel/Partner';
import EditBillingInfoView from './edit-billing-info-view';
import { useAuthStore } from '../../../../store';

const EditBillingInfoContainer = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const { enqueueSnackbar } = useSnackbar();

  const partnerId = loggedInAgent?.partnerId;
  const {
    data: partner,
    mutate,
    isLoading,
    error,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);

  const goBack = () => navigate('/settings/company-profile');

  const handleSubmit = async (data: any) => {
    if (partner && partnerId) {
      const updateBillingInfo: BillingAddress = {
        ...partner.billingAddress,
        name: data.name,
        emails: data.emails,
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country,
      };

      try {
        await apiClient.partnersClient.putPartnerBillingAddress(partnerId, updateBillingInfo);
        mutate();
        enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
        goBack();
      } catch (e: any) {
        if (e.code === 401) navigate('/logout');
        if (e !== undefined) enqueueSnackbar('Something went wrong on our end. Please try again.', { variant: 'default' });
        setSubmissionError(true);
      }
    }
  };

  return (
    <Container
      maxWidth="sm"
    >
      <Box
        sx={{
          py: {
            desktop: 6,
            mobile: 3,
          },
        }}
      >
        <BackButton onClick={goBack} edge="start" sx={{ mb: 2 }} />
        <EditBillingInfoView
          partnerData={partner || {} as Partner}
          isLoading={isLoading}
          partnerError={error}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={goBack}
        />
      </Box>
    </Container>
  );
};

export default EditBillingInfoContainer;

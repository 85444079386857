import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  Stack,
} from '@mui/material';

export type ConversationLinksCardProps = {
  title?: string;
  children: React.ReactNode;
};

const ConversationLinksCard = ({
  title = undefined,
  children,
}: ConversationLinksCardProps) => (
  <Card>
    {title && (
    <CardHeader
      title={title}
      titleTypographyProps={{ variant: 'bodyBold' }}
    />
    )}
    <CardContent>
      <List component={Stack} spacing={2} disablePadding>
        {children}
      </List>
    </CardContent>
  </Card>
);

export default ConversationLinksCard;

import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Stack,
} from '@mui/material';
import {
  ContextMenuComplete,
  TripMatchNotesCard,
} from '@marageti/z4-lib';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Traveler } from '@marageti/z4-sdk/lib/people';
import {
  TripMatch, TripMatchStatus, TripRequest, SalesRecording,
} from '@marageti/z4-sdk/lib/travel';
import Alerts from './alerts';
import { AlertObj } from '../alerts/utils';
import TravelerCard from '../traveler-card';
import { getChipsFromTripMatch } from '../../utils/chips';
import TripDetailCard from '../trip-detail-card';
import TripMatchSalesCard from '../trip-match-sales-card';
import { shouldShowSalesCard } from '../../utils/sales';

type TripSidebarViewProps = {
  alert: AlertObj | undefined;
  error: ApiError;
  travelers: Traveler[];
  tripMatch: TripMatch | undefined;
  tripRequest: TripRequest | undefined;
  isTravelerConversation: boolean;
  canEditTripDetails: boolean | undefined;
  onClickEdit: () => void;
  salesRecordings: SalesRecording[] | undefined;
};

const TripSidebarView = ({
  alert,
  error,
  travelers,
  tripMatch,
  tripRequest,
  isTravelerConversation,
  canEditTripDetails,
  salesRecordings,
  onClickEdit,
}: TripSidebarViewProps) => (
  <>
    {error && (
      <Alert severity="error">
        <AlertTitle>
          There was an error loading trip information
        </AlertTitle>
        {error.message}
        {error.code && `Code: ${error.code} `}
        {error.id && `ID: ${error.id}`}
      </Alert>
    )}
    <Box sx={{ pb: 3 }}>
      {isTravelerConversation && alert && <Alerts alert={alert} />}
    </Box>
    <Stack spacing={2}>
      <TravelerCard
        hidePhone={tripMatch?.status === TripMatchStatus.MatchPending || tripMatch?.status === TripMatchStatus.MatchAccepted}
        travelers={travelers}
        baseRoute={`/trip-matches/${tripMatch?.id}`}
        variant="compact"
      />

      {tripMatch && (
        <TripDetailCard
          title="Trip Details"
          titleTypographyProps={{ variant: 'h3' }}
          chips={getChipsFromTripMatch(tripMatch)}
          tripMatch={tripMatch}
          tripRequest={tripRequest}
          action={canEditTripDetails && (
            <ContextMenuComplete
              id="edit-trip-match"
              items={[{ action: onClickEdit, label: 'Edit' }]}
            />
          )}
        />
      )}
      {tripMatch && shouldShowSalesCard(tripMatch) && (
        <TripMatchSalesCard tripMatch={tripMatch} salesRecordings={salesRecordings} />
      )}
      <TripMatchNotesCard title="Notes from Zicasso" notes={{ ...tripRequest?.matchingNotes }} />
    </Stack>
  </>
);

export default TripSidebarView;

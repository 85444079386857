import React, { useState } from 'react';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { enqueueSnackbar } from 'notistack';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Agent } from '@marageti/z4-sdk/lib/people/Agent';
import { useNavigate } from 'react-router-dom';
import { TripMatch } from '@marageti/z4-sdk/lib/travel';
import AssignToView from './assign-to-view';

export type ContainerProps = {
  agents: Agent[] | undefined;
  onSuccess?: () => void;
  onClose: () => void;
  open: boolean;
  tripMatchId: string;
};

const AssignToContainer = ({
  agents,
  onSuccess,
  onClose,
  open,
  tripMatchId,
}: ContainerProps) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const tripMatch = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reassignTripMatch = (agentId: string) => {
    setIsSubmitting(true);
    apiClient.tripMatchesClient.putTripMatchAssignment(tripMatchId, agentId)
      .then(() => {
        tripMatch.mutate();
        enqueueSnackbar('Trip match reassigned successfully', { variant: 'success' });
        onSuccess?.();
      })
      .catch((e: ApiError) => {
        if (e.code === 401) navigate('/logout');
        enqueueSnackbar('Trip match reassignment failed.', { variant: 'default' });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <AssignToView
      agents={agents}
      currentAssignedToId={tripMatch.data?.assignedTo}
      isSubmitting={isSubmitting}
      onAgentClick={reassignTripMatch}
      onCancel={onClose}
      open={open}
    />
  );
};
export default AssignToContainer;

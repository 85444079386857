import React, { useState } from 'react';
import {
  BackButton,
  useApiClient,
  useFetch,
} from '@marageti/z4-lib';
import { Partner, TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Skeleton,
  Typography,
  Stack,
} from '@mui/material';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import TripMatchEditForm from './trip-match-edit-form';
import { useAuthStore } from '../../../store';

const TripMatchEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { tripMatchId } = useParams();
  const { loggedInAgent } = useAuthStore();

  const {
    data: tripMatch, isLoading, error: tmError, mutate,
  } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], true);
  const { data: partner, isLoading: isLoadingPartner, error: partnerError } = useFetch<Partner>(apiClient.partnersClient.getPartner, [loggedInAgent?.partnerId], !!loggedInAgent?.partnerId);

  const [submissionError, setSubmissionError] = useState<ApiError>();
  // African Safari is only available for active matches and cannot be used once a trip is SOLD.
  const partnerCountries = TripMatchStatus.MatchActive === tripMatch?.status ? partner?.countries : partner?.countries.filter((c) => c.country !== 'ZZ-AFR');

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setSubmissionError(undefined);

    try {
      if (!tripMatch) {
        throw new Error('No tripMatch found');
      }

      const tm: TripMatch = {
        ...tripMatch,
        ...data,
      };
      await apiClient.tripMatchesClient.putTripMatch(tripMatchId!, tm);
      mutate();
      enqueueSnackbar('Match details saved.', { variant: 'success' });
      navigate(`/trip-matches/${tripMatchId}`);
    } catch (e: any) {
      if (e.code === 401) navigate('/logout');
      setSubmissionError(e);
    }
  };

  const onCancel = () => {
    if (location.key === 'default') {
      navigate(`/trip-matches/${tripMatchId}`, { relative: 'path' });
    } else {
      navigate(-1);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ pt: 6, pb: 7 }}>
        <BackButton
          aria-label="back to trip"
          defaultPath={`/trip-matches/${tripMatchId}`}
        />
        <Typography variant="h2" width="100%" textAlign="left" position="relative" pt="20px">
          Edit Trip Details
        </Typography>
        <Typography variant="body" width="100%" textAlign="left" position="relative" pt="8px" sx={{ color: 'text.secondary' }}>
          Please note that the trip details entered will be shown to the traveler.
        </Typography>
        {(tmError || partnerError) && (
        <Alert severity="error">
          <AlertTitle>There was an error on our end.</AlertTitle>
          {`Code: ${(tmError).code}: ${(tmError).message}`}
          <br />
          {(tmError).id && `ID: ${(tmError).id}`}
        </Alert>
        )}
        {(isLoading || isLoadingPartner) ? (
          <Stack spacing={2} mt="20px">
            {Array(10).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={index} variant="rounded" height="43px" width="100%" />
            ))}
          </Stack>
        ) : (
          <TripMatchEditForm
            countries={partnerCountries || []}
            onCancel={onCancel}
            onSubmit={onSubmit}
            submissionError={submissionError}
            tripMatch={tripMatch}
          />
        )}
      </Box>
    </Container>

  );
};

export default TripMatchEdit;

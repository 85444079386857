import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Message, MessageType,
} from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import NoteView from './note-view';
import useChat from '../../../hooks/use-chat';

type NoteContainerProps = {
  closeDialog: () => void,
  isInternal?: boolean,
  onSuccess: () => void,
  open: boolean,
};

const NoteContainer = ({
  closeDialog,
  isInternal = false,
  onSuccess,
  open,
}: NoteContainerProps) => {
  const { conversationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<Error | null>(null);

  const {
    sender,
    sendMessage,
  } = useChat({
    conversationId,
  });

  const reset = () => {
    setError(null);
  };

  const handleSubmit = ({
    message,
  }: {
    message: string;
  }) => {
    reset();

    const senderName = sender.data?.fullName;
    const title = `Note from ${senderName}`;

    const messageToSend: Partial<Message> = {
      conversationId,
      text: message,
      title,
      messageType: MessageType.PartnerNote,
    };

    return sendMessage(messageToSend)
      .then(() => {
        onSuccess();
        enqueueSnackbar('Note created.', {
          variant: 'success',
        });
        closeDialog();
      })
      .catch((e: Error) => {
        setError(e);
      });
  };

  return (
    <NoteView
      closeDialog={closeDialog}
      error={error}
      isInternal={!!isInternal}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};

export default NoteContainer;

/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Box, Button, Container, Skeleton, Stack, Typography, useMediaQuery,
  Alert,
  AlertTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BackNavigationAppBar, ContextMenuComplete, useApiClient, useFetch,
} from '@marageti/z4-lib';
import { TripMatch, ConversationType } from '@marageti/z4-sdk/lib/travel';
import { useConversationsStore, useInboxStore } from '../../../../store';
import ZicassoChat from '../../../../components/zicasso-chat';
import RightSidebar from './right-sidebar';

const getTitle = (conversationType: ConversationType, travelerName: string) => {
  if (conversationType === ConversationType.TravelerToPartner) {
    return travelerName;
  }
  return `Zicasso | ${travelerName}`;
};

export const ConversationContainer = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const {
    conversationsMap,
    error,
  } = useConversationsStore();
  const {
    selectedInboxConversationId,
    setSelectedInboxConversationId,
  } = useInboxStore();

  const conversation = conversationsMap[selectedInboxConversationId!];
  const { data: tripMatch } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [conversation?.tripMatchId], !!conversation?.tripMatchId);

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen((prev: boolean) => !prev);
  };

  const navigateToTripMatch = () => {
    navigate(`/trip-matches/${tripMatch?.id}`);
  };

  const onClickBack = () => {
    setSelectedInboxConversationId(undefined);
    navigate('/trip-matches/messages');
  };

  if (isMobile) {
    return (
      <Container
        sx={{
          position: 'fixed',
          inset: 0,
          backgroundColor: 'primary.contrastText',
          zIndex: 1200,
          padding: 0,
        }}
      >
        <BackNavigationAppBar
          primary={getTitle(conversation?.conversationType, conversation?.primaryTravelerName || 'Traveler') || <Skeleton width={75} />}
          endAdornment={(
            <ContextMenuComplete
              id="trip-match-messageing-context-menu"
              items={[
                {
                  label: 'View Trip Details',
                  action: navigateToTripMatch,
                },
              ]}
            />
          )}
          onClick={onClickBack}
        />
        {error ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.code && `Code: ${error.code} `}
            {error.message || 'Please contact support'}
            <br />
            {error.id && `ID: ${error.id}`}
          </Alert>
        ) : (
          <Container
            maxWidth="xl"
            sx={{
              display: 'flex',
              flex: 1,
              p: 0,
              minHeight: 'calc(100vh - 72px)',
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              height: 'calc(100dvh - 72px)',
              px: 3,
            }}
            >
              <ZicassoChat />
            </Box>
          </Container>
        )}
      </Container>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
          ...isRightSidebarOpen && {
            maxWidth: 'calc(100% - 336px)',
          },
        }}
      >
        <Stack spacing="2px">
          <Typography variant="h3">{getTitle(conversation?.conversationType, conversation?.primaryTravelerName || 'Traveler')}</Typography>
        </Stack>
        <Box sx={{ height: 'calc(100% - 50px)' }}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>There was an error loading this conversation</AlertTitle>
              {error.code && `Code: ${error.code} `}
              {error.message || 'Please contact support'}
              <br />
              {error.id && `ID: ${error.id}`}
            </Alert>
          ) : (
            <ZicassoChat />
          )}
        </Box>
        <Button
          onClick={toggleRightSidebar}
          sx={{
            justifySelf: 'flex-end',
            py: '4px',
            px: '8px',
            position: 'absolute',
            top: '0',
            right: isRightSidebarOpen ? '353px' : '50px',
            transition: 'right 300ms ease',
            fontSize: '13px',
            fontWeight: 700,
            lineHeight: '20px',
            borderColor: 'divider',
          }}
          variant="outlined"
        >
          {!isRightSidebarOpen ? 'Show Details' : 'Hide Details'}
        </Button>
      </Box>
      <RightSidebar isRightSidebarOpen={isRightSidebarOpen} />
    </>
  );
};

export default ConversationContainer;

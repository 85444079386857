import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const getCurrencyPrefix = (code: string | undefined) => {
  switch (code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

/**
 * This component takes a numeric value and returns a numeric value, which is how
 * the Travel Service stores currency amounts.
 * Internally it formats the value as a currency string.
 * onChangeForm is used to update the form value and works with or without react-hook-form
 * onChange is the native MUI TextField onChange event handler.
 * Wrapping the NumericFormat component in a TextField component provides the theme style.
 */
const CurrencyInput = React.forwardRef((
  props: any,
  ref: React.Ref<HTMLInputElement>,
) => {
  const {
    currencyCode,
    onChange,
    onChangeForm,
    ...numericFormatProps
  } = props;
  const currencyPrefix = getCurrencyPrefix(currencyCode);

  const handleValueChange = (values: any) => {
    onChangeForm(values.floatValue);
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  };

  return (
    <NumericFormat
      allowLeadingZeros={false}
      decimalScale={2}
      decimalSeparator={currencyCode === 'EUR' ? ',' : '.'}
      fixedDecimalScale
      getInputRef={ref}
      onValueChange={handleValueChange}
      prefix={currencyPrefix}
      thousandSeparator={currencyCode === 'EUR' ? '.' : ','}
      valueIsNumericString
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...numericFormatProps}
    />
  );
});

type Props = TextFieldProps & {
  numericFormatProps: NumericFormatProps & { currencyCode?: string }
};

const WrappedCurrencyInput = React.forwardRef((
  {
    numericFormatProps,
    ...textFieldProps
  }: Props,
  ref: React.Ref<HTMLInputElement>,
) => {
  const { onChange, ...textFieldPropsWithoutOnChange } = textFieldProps;
  return (
    <TextField
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...textFieldPropsWithoutOnChange}
      ref={ref}
      InputProps={{
        inputComponent: CurrencyInput,
        inputProps: {
          ...numericFormatProps as InputBaseComponentProps,
          onChangeForm: onChange,
        },
      }}
    />
  );
});

export default WrappedCurrencyInput;

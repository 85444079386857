import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { FilterOp } from '@marageti/z4-sdk/lib/entity/Search';
import { SearchResult, SearchRequest } from '@marageti/z4-sdk/lib/entity';
import { format, isValid } from 'date-fns';
import InvoicesView from './invoices-view';
import { useAuthStore } from '../../../store';

const today = new Date();
const useQuery = () => new URLSearchParams(useLocation().search);
const formatDateToYYYYMM = (date: Date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
const parseDateFromYYYYMM = (dateString: string) => {
  const [year, month] = dateString.split('-').map(Number);
  return new Date(year, month - 1, 1);
};

const InvoicesContainer = () => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  const initialMonth = query.get('month');
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(initialMonth ? new Date(initialMonth) : today);
  const formattedDate = format(selectedMonth || today, 'yyyy-MM');

  //  This Elasticsearch query specifies a date range with custom intervals.
  //  "/M" typically represents a monthly interval, so ElasticSearch will round the given date
  //  down to the start of the month for gte queries and up to the end of the month for lte queries.
  // value: [2023-10-20||/M, 2023-10-20||/M] = 2023-10-01T00:00:00.000Z to 2023-10-31T23:59:59.999Z
  const searchRequest: SearchRequest = {
    filterBy: [{
      field: 'partnerId',
      value: [loggedInAgent?.partnerId!],
    },
    {
      field: 'invoiceDate',
      op: FilterOp.FilterOpBetween,
      value: [`${formattedDate}||/M`, `${formattedDate}||/M`],
    },
    ],
    sortBy: [{ field: 'createdAt', order: 'asc' }],
    pagination: { limit: 10000, page: 1 },
  };

  const { data: salesRecordings, isLoading } = useFetch<SearchResult<SalesRecording>>(apiClient.salesRecordingsClient.postSalesRecordingSearch, [searchRequest], !!loggedInAgent?.partnerId);

  const totalSales = useMemo(() => {
    if (salesRecordings && salesRecordings.hits.length > 0) {
      const ts = salesRecordings.hits.reduce((total, hit) => hit.price.amountUSD + total, 0);
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ts);
    }
    return undefined;
  }, [salesRecordings]);

  const totalCommission = useMemo(() => {
    if (salesRecordings && salesRecordings.hits.length > 0) {
      const tc = salesRecordings.hits.reduce((total, hit) => hit.commission.amountUSD + total, 0);
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tc);
    }
    return undefined;
  }, [salesRecordings]);

  const navigateWithParams = (month: Date | null) => {
    const formattedMonth = month ? formatDateToYYYYMM(month) : '';
    navigate(`/sales/invoices?month=${formattedMonth}`, { replace: true });
  };

  const onChangeMonth = (value: Date | null) => {
    if (value === null || isValid(value)) {
      setSelectedMonth(value);
      navigateWithParams(value);
    } else {
      setSelectedMonth(today);
      navigateWithParams(today);
    }
  };

  useEffect(() => {
    const monthFromUrl = query.get('month');
    if (monthFromUrl) {
      setSelectedMonth(parseDateFromYYYYMM(monthFromUrl));
    } else {
      setSelectedMonth(today);
    }
  }, [location.search]);

  return (
    <InvoicesView
      isLoading={isLoading}
      onChangeMonth={onChangeMonth}
      sales={salesRecordings?.hits}
      selectedMonth={selectedMonth}
      totalCommission={totalCommission}
      totalSales={totalSales}
    />
  );
};

export default InvoicesContainer;

import {
  Alert, AlertTitle, Box, Container,
} from '@mui/material';
import React from 'react';
import { BackButton } from '@marageti/z4-lib';
import { SalesRecording, TripMatch } from '@marageti/z4-sdk/lib/travel';
import SalesRecordingForm from '../../../../components/sales-recording-form';

const SalesRecordingView = ({
  isLoading,
  isError,
  tripMatch,
  salesRecordingToEdit,
  formDisabledReason,
  onSuccess,
  onCancel,
}: {
  isLoading: boolean
  isError: boolean
  tripMatch: TripMatch | undefined
  salesRecordingToEdit: SalesRecording | undefined
  formDisabledReason: string | false
  onSuccess: (createdUpdatedSalesRecordingId: string) => void
  onCancel: () => void
}) => (
  <Box
    py={{
      mobile: 3,
      desktop: 6,
    }}
  >
    <Container maxWidth="md">
      <BackButton
        onClick={onCancel}
        edge="start"
        sx={{
          mb: 2,
        }}
      />
      {isLoading && null}

      {!isLoading && isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          There was an error getting the data. Please try again later.
        </Alert>
      )}

      {!isLoading && !isError && formDisabledReason && (
        <Alert severity="error">
          <AlertTitle>Cannot Display Sales Recording Form</AlertTitle>
          {formDisabledReason}
        </Alert>
      )}

      {!isLoading && !isError && !formDisabledReason && tripMatch && (
        <SalesRecordingForm
          tripMatch={tripMatch}
          salesRecording={salesRecordingToEdit}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      )}
    </Container>
  </Box>
);

export default SalesRecordingView;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert, AlertTitle, Button, Typography,
} from '@mui/material';
import { calculateRemainingTime } from '../../utils/helpers';

type SendFirstMessageProps = {
  url: string;
  createdAt: string;
  showButton?: boolean;
};

const SendFirstMessage = ({ url, createdAt, showButton = true }: SendFirstMessageProps) => {
  const remainingTime = calculateRemainingTime(createdAt);
  return (
    <Alert severity={(remainingTime === 0 ? 'error' : 'warning')}>
      <AlertTitle>
        {`Send First Message (${remainingTime === 0 ? 'Overdue' : `${remainingTime}h left`})`}
      </AlertTitle>
      Please send your first message to the traveler.
      {showButton && (
      <Button component={Link} variant="contained" to={url}>
        Message Traveler
      </Button>
      )}
    </Alert>
  );
};

const ConfirmDeposit = ({ url = 'recording' }: { url: string }) => (
  <Alert severity="info">
    <AlertTitle>Confirm Sold Trip</AlertTitle>
    <span>
      Please record your sold trip once you have received the initial deposit. If you need any assistance, contact us via chat or visit
      {' '}
      <a
        href="https://zicademy.zicasso.com/_hcms/mem/login?redirect_url=/"
        rel="noopener noreferrer"
        style={{ color: 'inherit' }}
        target="_blank"
      >
        Zicademy
      </a>
      , our Knowledge Base.
    </span>
    <Button component={Link} variant="contained" to={url}>Record Sold Trip</Button>
  </Alert>
);

const AddAdjustment = ({ url = 'adjustment' }: { url: string }) => (
  <Alert severity="info">
    <AlertTitle>Sales Adjustment</AlertTitle>
    Please add any necessary sales adjustments to update the accurate trip price.
    <Button component={Link} variant="contained" to={url}>Add Adjustment</Button>
  </Alert>
);

const EditSalesRecording = ({ url }: { url: string }) => (
  <Alert severity="info">
    <AlertTitle>Edit Standard Sale</AlertTitle>
    You can edit your sales recording through the end of the month. After invoice, you can submit a sales adjustment.
    <Button component={Link} variant="contained" to={url}>Edit Recording</Button>
  </Alert>
);

const EditAdjustment = ({ url }: { url: string }) => (
  <Alert severity="info">
    <AlertTitle>Edit Sales Adjustment</AlertTitle>
    You can edit your sales adjustment until the end of the month.
    <Button component={Link} variant="contained" to={url}>Edit Adjustment</Button>
  </Alert>
);

const Postponed = ({ handleReactivation }: { handleReactivation: () => void }) => (
  <Alert severity="warning">
    <AlertTitle>Postponed Trip</AlertTitle>
    <span>
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      This trip has been marked as <em>Postponed</em> and has been moved into the postponed section of your queue.
      This means it is not currently being processed.
      When you are ready to resume communication with this traveler, reactivate this trip to move it back into your trips queue.
    </span>
    <Button variant="contained" onClick={handleReactivation}>Reactivate</Button>
  </Alert>
);

const Inactive = ({ handleReactivation }: { handleReactivation: () => void }) => (
  <Alert severity="warning">
    <AlertTitle>Inactive Trip</AlertTitle>
    <span>
      You may reactivate this trip and move it back into the Assigned Specialist&apos;s active queue.
    </span>
    <Button variant="contained" onClick={handleReactivation}>Reactivate</Button>
  </Alert>
);

const BookingCanceledAlert = () => (
  <Alert severity="error">
    <AlertTitle>Canceled Trip</AlertTitle>
    <Typography>
      This trip has been canceled without a refund from Zicasso&apos;s commission. Therefore, sales conversion will not be impacted.
    </Typography>
  </Alert>
);

const BookingRefundedAlert = () => (
  <Alert severity="error">
    <AlertTitle>Canceled and Refunded Trip</AlertTitle>
    <Typography>
      This trip has been canceled with a full or partial refund from Zicasso&apos;s commission, included as a credit on partner&apos;s upcoming invoice. Please note
      that canceled trips with refunds will impact sales conversion.
    </Typography>
  </Alert>
);

export {
  SendFirstMessage, AddAdjustment, ConfirmDeposit, EditSalesRecording, EditAdjustment, Postponed, Inactive, BookingCanceledAlert, BookingRefundedAlert,
};

import React from 'react';
import DefaultError from './DefaultError';

interface ErrorBoundaryComponentProps {
  children: React.ReactNode;
  fallback?: React.ReactNode | undefined
}

interface ErrorBoundaryComponentState {
  hasError: boolean;
}

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryComponentProps, ErrorBoundaryComponentState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // TODO: log error to error tracking service
  componentDidCatch(error: any, info: any) {
    console.log(error, info);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      const { fallback } = this.props;
      if (fallback === undefined) {
        return <DefaultError />;
      }
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundaryComponent;

/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Box, Divider, Stack, Typography, Link, Collapse, IconButton,
} from '@mui/material';
import { Link as routerLink } from 'react-router-dom';
import {
  CopyButton,
  formatPhoneNumber,
  formatTimeWithTimeZoneName,
  getBudgetPerPersonString,
  getFormattedDateShortMonthDDYYYY, getTotalBudgetString, OtherChips, OutlinedIcons, TravelerTagChips, useApiClient, useCurrentTime, useFetch, useFetchImmutable,
} from '@marageti/z4-lib';
import { TripMatch, TripRequest } from '@marageti/z4-sdk/lib/travel';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import { ReferralType } from '@marageti/z4-sdk/lib/travel/TripRequest';
import { useConversationsStore, useInboxStore } from '../../../../store';
import { formatCountryCodesToString } from '../../../../components/trips-card/utils';
import useFetchItineraryPath from '../../../../hooks/use-fetch-itinerary-path';

const { OutlinedExternalLinkIcon } = OutlinedIcons;

const { ChipVIP } = TravelerTagChips;
const { ChipRepeat, ChipPRL } = OtherChips;

const tagIcons = {
  VIP: <ChipVIP />,
  RPT: <ChipRepeat />,
  PRL: <ChipPRL />,
};

export type SideBarItemsProps = {
  items: ({ title: string | React.ReactNode, content?: React.ReactNode } | React.ReactNode)[],
  chips?: React.ReactNode,
};

const truncateStyle: React.CSSProperties = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
};

const TripDescription = ({ description }: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded((ex) => !ex);
  };
  const SMALL_NOTE_LENGTH = 210;
  const notesLength = description?.length || 0;
  const isLongText = notesLength > SMALL_NOTE_LENGTH;
  const textStyle = (!expanded && isLongText) ? truncateStyle : {};

  return (
    <Stack>
      <Typography component="div" color="text.secondary" style={textStyle}>
        {notesLength === 0 ? (
          '-'
        ) : (
          description
        )}
      </Typography>
      {(isLongText) && (
      <Typography
        component="span"
        variant="captionBold"
        color="primary"
        style={{ cursor: 'pointer' }}
        onClick={handleExpandClick}
      >
        {expanded ? 'Show Less' : 'Show More'}
      </Typography>
      )}
    </Stack>
  );
};

const SideBarItems = ({ items, chips = undefined }: SideBarItemsProps) => (
  <>
    {chips && (
      <Stack direction="row" spacing={1}>
        {chips}
      </Stack>
    )}
    {items.map((item, index) => {
      if (typeof item === 'object' && item !== null && 'title' in item) {
        return (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography component="div" variant="caption" fontWeight="bold" color="textPrimary">{item.title}</Typography>
            <Typography component="div" color="text.secondary" display="flex" alignItems="center">{item.content || '-'}</Typography>
          </Box>
        );
      }
      return (
        <React.Fragment key={index}>
          {item}
        </React.Fragment>
      );
    })}
  </>
);

const SIDEBAR_OPEN_WIDTH = 320;

export type RightSidebarProps = {
  isRightSidebarOpen: boolean;
};

const RightSidebar = ({ isRightSidebarOpen }: RightSidebarProps) => {
  const apiClient = useApiClient();

  // Update to fetch conversation if not in store
  const { conversationsMap } = useConversationsStore();
  const { selectedInboxConversationId } = useInboxStore();
  const conversation = conversationsMap[selectedInboxConversationId!];
  const { data: tripRequest } = useFetch<TripRequest>(apiClient.tripRequestsClient.getTripRequest, [conversation?.tripRequestId], !!conversation?.tripRequestId);
  const { data: tripMatch } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [conversation?.tripMatchId], !!conversation?.tripMatchId);
  const countryCodes = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const [time] = useCurrentTime(tripMatch?.travelers[0]?.timeZone?.name);
  const itineraryPath = useFetchItineraryPath(tripRequest?.basedOnItineraryId);

  const basedOnItineraryContent = itineraryPath ? (
    <Link href={itineraryPath} target="_blank" rel="noopener noreferrer">Link to Itinerary</Link>
  ) : '-';

  return (
    <Collapse
      in={isRightSidebarOpen}
      orientation="horizontal"
      sx={{
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        ml: 2,
        mt: -2.5,
      }}
    >
      <Box
        width={SIDEBAR_OPEN_WIDTH}
        sx={{
          padding: '24px 20px',
          flex: 1,
          overflowY: { desktop: 'auto' },
          height: { desktop: '100%' },
          display: { desktop: 'flex' },
          flexDirection: 'column',
          pb: 4,
        }}
      >
        {(tripRequest && tripMatch && countryCodes.data) && (
          <>
            <SideBarItems
              items={[
                {
                  title: 'Phone',
                  content: tripMatch.travelers[0]?.phone.number ? (
                    <>
                      {formatPhoneNumber(tripMatch.travelers[0].phone.prefix, tripMatch.travelers[0].phone.number)}
                      <CopyButton textToCopy={`${tripMatch.travelers[0].phone.prefix} ${tripMatch.travelers[0].phone.number}`} iconFontSize="small" size="small" />
                    </>
                  ) : (
                    '-'
                  ),
                },
                { title: 'Timezone', content: formatTimeWithTimeZoneName(tripMatch?.travelers[0]?.timeZone, time) },
              ]}
              chips={[
                tripMatch.travelers[0]?.tags?.includes(TravelerTags.VIP) ? tagIcons.VIP : null,
                tripMatch.repeatTraveler ? tagIcons.RPT : null,
                tripMatch.referralType === (ReferralType.OneTimePartnerReferral || ReferralType.LifetimePartnerReferral) ? tagIcons.PRL : null,
              ]}
            />
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h4" sx={{ mr: 1 }}>
                {`${tripMatch?.travelers[0].fullName}'s Trip`}
              </Typography>
              <IconButton
                aria-label="To trip details page"
                component={routerLink}
                to={`/trip-matches/${tripMatch.id}`}
                target="_blank"
                color="inherit"
                size="small"
                sx={{ p: 0, minWidth: 'auto' }}
              >
                <OutlinedExternalLinkIcon sx={{ fontSize: '18px' }} />
              </IconButton>
            </Box>
            <SideBarItems
              items={[
                { title: 'Description', content: (<TripDescription description={tripRequest.tripDescription} />) },
                { title: 'Countries', content: countryCodes && formatCountryCodesToString(tripMatch.countries, countryCodes.data) },
                { title: 'When', content: `${getFormattedDateShortMonthDDYYYY(tripMatch.departureDate) || ''} (${tripMatch.totalNights} Nights)` },
                { title: 'Budget Per Person', content: `${getBudgetPerPersonString(tripMatch.budget, tripMatch.numberOfAdults, tripMatch.numberOfChildren)}` },
                { title: 'Total Budget', content: `${getTotalBudgetString(tripMatch.budget)}` },
                { title: 'Travelers', content: `${tripMatch.numberOfAdults} Adults, ${tripMatch.numberOfChildren} Children` },
                { title: 'Based on Itinerary', content: basedOnItineraryContent },
                { title: 'Last Updated', content: getFormattedDateShortMonthDDYYYY(tripMatch.updatedAt) || '' },
                {
                  title: 'Reference ID',
                  content: tripRequest?.referenceId && (
                  <>
                    {tripRequest.referenceId}
                    <CopyButton textToCopy={tripRequest.referenceId} iconFontSize="small" size="small" />
                  </>
                  ),
                },
              ]}
            />
          </>
        )}
      </Box>
    </Collapse>
  );
};

export default RightSidebar;

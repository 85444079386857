import { useEffect } from 'react';
import { LocalStorageService, useApiClient } from '@marageti/z4-lib';
import { useAuthStore } from '../../store';

// Hubspot types to make typescript happy
interface HubSpotConversationsSettings {
  loadImmediately?: boolean;
  identificationEmail?: string;
  identificationToken?: string;
}

interface HubSpotConversationsWidget {
  load: () => void;
  remove: () => void;
}

interface HubSpotConversations {
  widget: HubSpotConversationsWidget;
}

declare global {
  interface Window {
    hsConversationsSettings?: HubSpotConversationsSettings;
    HubSpotConversations?: HubSpotConversations;
    hsConversationsOnReady?: (() => void)[];
  }
}

const LocalStorageClient = new LocalStorageService();

const HubSpotScript = () => {
  const { isAuthenticated } = useAuthStore();
  const apiClient = useApiClient();

  const getIdentificationToken = async (): Promise<string | null> => {
    try {
      const response = await apiClient.hubspotClient.postVisitorIdentificationRequest({});
      return response.token;
    } catch (error) {
      return null;
    }
  };

  const initializeWidget = async (): Promise<void> => {
    const accessToken = LocalStorageClient.getFromLocalStorage('accessToken');
    const email = LocalStorageClient.getFromLocalStorage('email');
    if (!accessToken || !email) return;

    const token = await getIdentificationToken();
    if (!token) return;

    // Set the identification settings before loading the script
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: email,
      identificationToken: token,
    };

    // Remove existing script if it exists
    const existingScript = document.getElementById('hs-script-loader');
    if (existingScript) {
      existingScript.remove();
    }

    // Now that settings are configured, create and load the script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-na1.hs-scripts.com/2630270.js';
    script.onload = () => {
      window.hsConversationsOnReady = [
        () => {
          if (window.HubSpotConversations?.widget?.load) {
            window.HubSpotConversations.widget.load();
          }
        },
      ];
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (isAuthenticated) {
      initializeWidget();
    }

    return () => {
      const existingScript = document.getElementById('hs-script-loader');
      if (existingScript) {
        existingScript.remove();
      }

      if (window.HubSpotConversations?.widget?.remove) {
        window.HubSpotConversations.widget.remove();
      }

      delete window.hsConversationsSettings;
    };
  }, [isAuthenticated]);

  return null;
};

export default HubSpotScript;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Typography, Skeleton, Tooltip } from '@mui/material';
import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  ConversationType, GetParticipantsResponse, ParticipantInfo,
} from '@marageti/z4-sdk/lib/travel';
import { PersonType } from '@marageti/z4-sdk/lib/people';
import { useAuthStore } from '../../store';

const ConversationParticipantNames = ({
  conversationId,
  conversationType,
  typographyProps,
  skeletonProps,
  enableTooltip = false,
}: {
  conversationId: string | undefined
  conversationType: ConversationType
  typographyProps?: React.ComponentProps<typeof Typography>
  skeletonProps?: React.ComponentProps<typeof Skeleton>
  enableTooltip?: boolean
}) => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const {
    data: participants,
    error: participantsError,
    isLoading: isParticipantsLoading,
  } = useFetch<GetParticipantsResponse>(
    apiClient.conversationsClient.getParticipants,
    [conversationId, { params: { inactive: false } }],
    !!conversationId,
  );

  const participantsArray = useMemo(() => (participants ? Object.values(participants) : []), [participants]);

  const names = useMemo(() => {
    if (!participants || !conversationType) return '';

    const isLoggedInUserParticipant = participantsArray.some((p) => p.id === loggedInAgent?.id);
    const formatName = (p: ParticipantInfo) => `${p.givenName} ${p.familyName}`;

    let namesList = [];

    if (conversationType === ConversationType.TravelerToPartner) {
      namesList = participantsArray
        .filter((p) => p.id !== loggedInAgent?.id)
        .map(formatName);
    } else if (conversationType === ConversationType.ZicassoToPartner) {
      namesList = ['Zicasso', ...participantsArray
        .filter((p) => p.personType !== PersonType.TeamMember && p.id !== loggedInAgent?.id)
        .map(formatName)];
    } else {
      return 'Unknown participants';
    }

    if (isLoggedInUserParticipant) {
      namesList.push('You');
    }

    return namesList.join(', ');
  }, [participants, conversationId, loggedInAgent]);

  if (!conversationId || participantsError) {
    return null;
  }

  if (isParticipantsLoading) {
    return (
      <Typography {...typographyProps}>
        <Skeleton width="60%" {...skeletonProps} />
      </Typography>
    );
  }

  if (!participants) {
    return null;
  }

  return (
    <Tooltip title={names} disableHoverListener={!enableTooltip}>
      <Typography {...typographyProps}>
        {names}
      </Typography>
    </Tooltip>
  );
};

export default ConversationParticipantNames;

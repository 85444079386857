/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Icons } from '@marageti/z4-lib';
import { UserStatus } from '@marageti/z4-sdk/lib/auth';

export const peopleStatusIcons = {
  [UserStatus.ENABLED]: {
    displayName: 'Enabled',
    icon: <Icons.StatusOnline sx={{ color: 'success.dark', transform: 'scaleX(-1)' }} titleAccess="Enabled" />,
  },
  [UserStatus.PENDING]: {
    displayName: 'Pending',
    icon: <Icons.Sparkles sx={{ color: 'secondary.main', transform: 'scaleX(-1)' }} titleAccess="Pending" />,
  },
  [UserStatus.DISABLED]: {
    displayName: 'Disabled',
    icon: <Icons.StatusOffline color="disabled" titleAccess="Disabled" />,
  },
};

import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFetch, useApiClient } from '@marageti/z4-lib';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import RepeatReferralDetailsView from './repeat-referral-details-view';

const RepeatReferralDetailsContainer = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { repeatReferralId } = useParams();

  const { data: repeatReferral, isLoading, error } = useFetch<SalesRecording>(apiClient.salesRecordingsClient.getSalesRecording, [repeatReferralId], true);

  const onClickEdit = () => {
    navigate(`/sales/repeat-referral/${repeatReferralId}/edit`);
  };

  const salesRecordingType = (() => {
    switch (repeatReferral?.salesRecordingType) {
      case SalesRecordingType.PartnerRepeat:
        return 'Repeat Sale';
      case SalesRecordingType.TravelerReferral:
        return 'Referral Sale';
      default:
        return 'Repeat/ Referral Sale';
    }
  })();

  const onClickRemove = () => {
    confirm({
      title: `Remove ${salesRecordingType}`,
      description: `Are you sure you want to remove this ${salesRecordingType} recording? If you do, it will no longer count toward your sales conversion rate`,
      confirmationButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      confirmationText: 'Remove',
      dialogProps: {
        PaperProps: {
          sx: {
            maxWidth: '409px',
          },
        },
      },
    })
      .then(() => apiClient.salesRecordingsClient.deleteSalesRecording(repeatReferralId!))
      .then(() => {
        enqueueSnackbar('Repeat / Referral Sale removed.', { variant: 'success' });
        navigate('/sales/monthly');
      })
      .catch((e: ApiError) => {
        if (e !== undefined) enqueueSnackbar(`There was an error deleting this ${salesRecordingType}.`, { variant: 'default' });
      });
  };

  if (error?.code === 404) {
    return <Navigate to="/sales/monthly" replace />;
  }

  return (
    <RepeatReferralDetailsView
      isLoading={isLoading}
      onClickEdit={onClickEdit}
      onClickRemove={onClickRemove}
      repeatReferral={repeatReferral}
    />
  );
};

export default RepeatReferralDetailsContainer;

import { TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import React from 'react';
import {
  SvgIcon, Tooltip,
} from '@mui/material';
import { OutlinedIcons, CustomIcons, formatPhoneNumber } from '@marageti/z4-lib';
import { SalesStage } from '@marageti/z4-sdk/lib/travel/TripMatch';
import { useAuthStore } from '../../../store';
import { calculateRemainingTime } from '../../../utils/helpers';
import { ReactComponent as MessageNoNewIcon } from '../../../assets/svg/message-nonew.svg';
import { ReactComponent as SalesStage0 } from '../../../assets/svg/sales-00.svg';
import { ReactComponent as SalesStage1 } from '../../../assets/svg/sales-01.svg';
import { ReactComponent as SalesStage2 } from '../../../assets/svg/sales-02.svg';
import { ReactComponent as SalesStage3 } from '../../../assets/svg/sales-03.svg';

const {
  OutlinedPhoneIcon,
  OutlinedPhoneMissedCallIcon,
} = OutlinedIcons;

const {
  MessageHrLeftIcon,
  MessageNewIcon,
} = CustomIcons;

export const PhoneIndicator = ({
  tripMatch,
}: {
  tripMatch: TripMatch
}) => {
  const {
    prefix,
    number,
  } = tripMatch.travelers[0]?.phone || {};
  const phoneNumber = formatPhoneNumber(prefix, number);

  return (
    <Tooltip title={phoneNumber ? 'Phone Number Provided' : 'Missing Phone Number'}>
      <span>
        <SvgIcon
          color={phoneNumber ? 'primary' : 'disabled'}
          component={phoneNumber ? OutlinedPhoneIcon : OutlinedPhoneMissedCallIcon}
        />
      </span>
    </Tooltip>
  );
};

export const ChatIndicator = ({
  tripMatch,
}: {
  tripMatch: TripMatch
}) => {
  const { loggedInAgent } = useAuthStore();
  const agentMessageState = tripMatch.tripMessageState.find((m) => m.personId !== '' && m.personId === loggedInAgent?.id);
  const hasNewMessage = agentMessageState?.hasNew || false;
  const timeRemaining = calculateRemainingTime(tripMatch.createdAt);
  const showTimeRemaining = tripMatch.status === TripMatchStatus.MatchAccepted;
  const MessageIcon = hasNewMessage ? MessageNewIcon : MessageNoNewIcon;

  if (showTimeRemaining) {
    return (
      <Tooltip title={timeRemaining > 0 ? `${timeRemaining}h left to send the first message` : 'Overdue'}>
        <span>
          <MessageHrLeftIcon />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={hasNewMessage ? 'New message received' : 'No new messages'}>
      <span>
        <MessageIcon color={hasNewMessage ? 'primary' : 'disabled'} />
      </span>
    </Tooltip>
  );
};

const salesStageIcons = {
  [SalesStage.NeedsFollowUp]: { icon: SalesStage1, tooltip: 'Needs Follow Up' },
  [SalesStage.NeedsProposal]: { icon: SalesStage2, tooltip: 'Needs Proposal' },
  [SalesStage.ProposalSent]: { icon: SalesStage3, tooltip: 'Proposal Sent' },
};

export const SalesStageIndicator = ({ tripMatch }: {
  tripMatch: TripMatch
}) => {
  const { salesStage } = tripMatch;
  const { icon, tooltip } = salesStageIcons[salesStage] || { icon: SalesStage0, tooltip: 'Undefined Sales Stage' };

  return (
    <Tooltip title={tooltip}>
      <SvgIcon component={icon} />
    </Tooltip>
  );
};

import React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useApiClient,
  useFetch,
  BackButton,
} from '@marageti/z4-lib';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import EditView from './edit-company-profile-view';
import { useAuthStore } from '../../../../store';

const EditContainer = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const { enqueueSnackbar } = useSnackbar();

  const partnerId = loggedInAgent?.partnerId;

  const swrRes = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);

  const goBack = () => navigate('/settings/company-profile');

  const handleSubmit = async (data: any) => {
    if (swrRes.data && partnerId) {
      const updatePartner = {
        ...swrRes.data,
        name: data.name,
        yearFounded: parseInt(data.yearFounded, 10),
        usPhone: data.usPhone,
        primaryPhone: data.primaryPhone,
        email: data.email,
        website: data.website,
      };

      try {
        await apiClient.partnersClient.putPartner(partnerId, updatePartner);
        enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
        swrRes.mutate();
        goBack();
      } catch (error) {
        setSubmissionError(true);
      }
    }
  };

  return (
    <Container
      maxWidth="sm"
    >
      <Box
        sx={{
          py: {
            desktop: 6,
            mobile: 3,
          },
        }}
      >
        <BackButton onClick={goBack} edge="start" sx={{ mb: 2 }} />
        <EditView
          partnerData={swrRes.data || {} as Partner}
          isLoading={swrRes.isLoading}
          partnerError={swrRes.error}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={goBack}
        />
      </Box>
    </Container>
  );
};

export default EditContainer;

import React from 'react';
import { Chip, Stack } from '@mui/material';
import { ReferralType, TripMatch, TripMatchStatus } from '@marageti/z4-sdk/lib/travel';
import {
  TravelerTagChips,
  TripMatchChips,
  OtherChips,
} from '@marageti/z4-lib';
import { TravelerTags } from '@marageti/z4-sdk/lib/people';
import { isBefore, isAfter, isSameDay } from 'date-fns';

const {
  ChipPending,
  ChipAccepted,
  ChipPartnerDeclined,
  ChipActive,
  ChipSold,
  ChipPostponed,
  ChipInactive,
  ChipTravelerCanceled,
  ChipPartnerCanceled,
  ChipZicassoCanceled,
  ChipSoldCanceled,
  ChipRefundedCanceled,
} = TripMatchChips;

const TripMatchStatusChip = ({ tripMatch }: { tripMatch: TripMatch }) => {
  switch (tripMatch.status) {
    case TripMatchStatus.MatchPending:
      return <ChipPending />;
    case TripMatchStatus.MatchAccepted:
      return <ChipAccepted />;
    case TripMatchStatus.MatchPartnerDeclined:
      return <ChipPartnerDeclined />;
    case TripMatchStatus.MatchActive:
      return <ChipActive />;
    case TripMatchStatus.MatchSold:
      return <ChipSold />;
    case TripMatchStatus.MatchPostponed:
      return <ChipPostponed />;
    case TripMatchStatus.MatchInactive:
      return <ChipInactive />;
    case TripMatchStatus.MatchTravelerCanceled:
      return <ChipTravelerCanceled />;
    case TripMatchStatus.MatchPartnerCanceled:
      return <ChipPartnerCanceled />;
    case TripMatchStatus.MatchZicassoCanceled:
      return <ChipZicassoCanceled />;
    case TripMatchStatus.MatchSoldCanceled:
      return <ChipSoldCanceled />;
    case TripMatchStatus.MatchRefundedCanceled:
      return <ChipRefundedCanceled />;
    default:
      return null;
  }
};

const {
  ChipPRL,
  ChipRepeat,
} = OtherChips;
const {
  ChipVIP,
} = TravelerTagChips;

const TripMatchTravelerChips = ({ tripMatch }: { tripMatch: TripMatch }) => {
  const primaryTraveler = tripMatch.travelers[0];
  const chips = [];

  if (primaryTraveler?.tags) {
    primaryTraveler.tags.forEach((tag: string) => {
      chips.push(tag);
    });
  }

  if (tripMatch.referralType) {
    chips.push(tripMatch.referralType);
  }

  if (tripMatch.repeatTraveler) {
    chips.push('REPEAT_TRAVELER');
  }

  if (chips?.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
    >
      {chips?.map((chip: string, index: number) => {
        const key = `${chip}-${index}`;
        switch (chip) {
          case ReferralType.OneTimePartnerReferral:
          case ReferralType.LifetimePartnerReferral:
            return <ChipPRL key={key} />;
          case TravelerTags.VIP:
            return <ChipVIP key={key} />;
          case 'REPEAT_TRAVELER':
            return <ChipRepeat key={key} />;
          default:
            return null;
        }
      })}
    </Stack>
  );
};

const RefineSoldChip = ({ tripMatch }: { tripMatch: TripMatch }) => {
  const now = new Date();
  const departureDate = new Date(tripMatch.departureDate);
  const returnDate = new Date(tripMatch.returnDate);

  if (tripMatch.status !== TripMatchStatus.MatchSold) {
    return null;
  }

  const isPreTravel = isBefore(now, departureDate);
  if (isPreTravel) {
    return <Chip variant="outlined" size="small" label="PRE TRAVEL" />;
  }

  const isInCountry = (isAfter(now, departureDate) || isSameDay(now, departureDate)) && isBefore(now, returnDate);
  if (isInCountry) {
    return <Chip variant="outlined" size="small" label="IN COUNTRY" />;
  }

  const isTripComplete = isAfter(now, returnDate);
  if (isTripComplete) {
    return <Chip variant="outlined" size="small" label="COMPLETED TRIP" />;
  }

  return null;
};
export { TripMatchStatusChip, TripMatchTravelerChips, RefineSoldChip };

import * as React from 'react';
import {
  Card,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ContextMenu,
  ContextMenuButton,
  ContextMenuItem,
  ProfileCardContent,
  LabelAndValueElement,
  useFetchImmutable,
  useApiClient,
} from '@marageti/z4-lib';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import { ProfileCardProps as BillingInfoCardProps } from '../types';
import stylesT from '../styles';
import { formatCountryCodes } from '../../../../utils/helpers';

type AddressLabelAndValueElementProps = {
  title: string;
  country?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  state?: string;
  titleSx?: SxProps;
  subtitleSx?: SxProps;
};

const AddressLabelAndValueElement = ({
  title,
  country,
  city,
  street,
  postalCode,
  state,
  titleSx = {},
  subtitleSx = {},
}: AddressLabelAndValueElementProps) => {
  const hasAddress = country || city || street || postalCode || state;

  return hasAddress ? (
    <Stack>
      <Typography sx={titleSx} variant="subtitle2">{title}</Typography>
      <Stack sx={subtitleSx}>
        {street && (
          <Typography variant="body1" color="text.secondary">
            {street}
          </Typography>
        )}
        {(city || state || postalCode) && (
          <Typography variant="body1" color="text.secondary">
            {[city, state, postalCode].filter(Boolean).join(', ')}
          </Typography>
        )}
        {country && (
          <Typography variant="body1" color="text.secondary">
            {country}
          </Typography>
        )}
      </Stack>
    </Stack>
  ) : (
    <Skeleton
      variant="rounded"
      animation="wave"
      sx={{
        margin: '0 auto',
        height: '44px',
        width: '100%',
      }}
    />
  );
};

type LabelAndValueListElementProps = {
  title: string;
  value: string[] | string | undefined;
  titleSx?: SxProps;
  subtitleSx?: SxProps;
};

const LabelAndValueListElement = ({
  title,
  value,
  titleSx = {},
  subtitleSx = {},
}: LabelAndValueListElementProps) => (value !== undefined ? (
  <Stack>
    <Typography sx={titleSx} variant="subtitle2">{title}</Typography>
    {Array.isArray(value) ? (
      <Stack>
        {value.map((email) => (
          <Typography key={email} sx={subtitleSx} variant="body1" color="text.secondary">
            {email}
          </Typography>
        ))}
      </Stack>
    ) : (
      <Typography sx={subtitleSx} variant="body1" color="text.secondary">
        {value}
      </Typography>
    )}
  </Stack>
) : (
  <Skeleton
    variant="rounded"
    animation="wave"
    sx={{
      margin: '0 auto',
      height: '44px',
      width: '100%',
    }}
  />
));

const BillingInfoCard = ({
  partner,
  isAdmin,
  contextMenuAnchor,
  contextMenuOpen,
  onCloseContextMenu,
  onOpenContextMenu,
  onClickEdit,
}: BillingInfoCardProps) => {
  const theme = useTheme();
  const styles = stylesT(theme);
  const menuId = 'billing-info-context-menu';
  const buttonId = 'billing-info-context-button';
  const billingInfo = partner?.billingAddress;

  const apiClient = useApiClient();
  const { data: countryCodes } = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);
  const countryString = countryCodes && billingInfo && formatCountryCodes([billingInfo.country], countryCodes);

  return (
    <Card sx={styles.profileCard}>
      <ProfileCardContent>
        <Stack spacing="24px" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <LabelAndValueElement
              title="Company Name"
              value={billingInfo?.name || '-'}
              key="billing-info-name"
            />
            {isAdmin && (
              <>
                <ContextMenuButton
                  id={buttonId}
                  open={contextMenuOpen}
                  onClick={onOpenContextMenu}
                  menuId={menuId}
                />
                <ContextMenu
                  anchorEl={contextMenuAnchor}
                  buttonId={buttonId}
                  id={menuId}
                  onClose={onCloseContextMenu}
                  open={contextMenuOpen}
                >
                  <ContextMenuItem onClick={onClickEdit}>
                    Edit
                  </ContextMenuItem>
                </ContextMenu>
              </>
            )}
          </Stack>
          <LabelAndValueListElement
            title="Email"
            value={billingInfo?.emails || '-'}
            key="email"
          />
          <AddressLabelAndValueElement
            title="Address"
            country={countryString || '-'}
            city={billingInfo?.city}
            street={billingInfo?.street}
            postalCode={billingInfo?.postalCode}
            state={billingInfo?.state}
            key="address"
          />
        </Stack>
      </ProfileCardContent>
    </Card>
  );
};

export default BillingInfoCard;

import React from 'react';
import {
  Avatar,
  Badge,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';

const DisabledTooltip = ({
  children,
  disabled,
  disabledMessage = undefined,
}: { children: React.ReactElement; disabled: boolean; disabledMessage?: string }) => (
  disabled ? (
    <Tooltip
      title={disabledMessage || ''}
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            maxWidth: '221px',
          },
        },
      }}
      placement="top"
    >
      <span>
        {children}
      </span>
    </Tooltip>
  ) : children
);

type CardLinkProps = {
  disabled?: boolean;
  disabledMessage?: string;
  hasNewMessages: boolean;
  icon: React.ReactNode;
  primary: string;
  secondary: string;
  to: string;
};

const CardLink = ({
  disabled = false,
  disabledMessage = undefined,
  hasNewMessages,
  icon,
  primary,
  secondary,
  to,
}: CardLinkProps) => (
  <DisabledTooltip disabled={!!disabled} disabledMessage={disabledMessage}>
    <ListItemButton
      component={Link}
      disabled={disabled}
      disableGutters
      sx={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={to}
    >
      <ListItemAvatar sx={{ p: 0 }}>
        <Badge
          color="error"
          invisible={!hasNewMessages}
          overlap="circular"
          variant="dot"
        >
          <div>
            <Avatar>
              {icon}
            </Avatar>
          </div>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          variant: 'bodyBold',
          sx: {
            textDecoration: 'none',
          },
        }}
        secondary={secondary}
        sx={{ m: 0 }}
      />
    </ListItemButton>
  </DisabledTooltip>
);

export default CardLink;

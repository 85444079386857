import {
  TeamMember, Agent, Traveler, UserInfo,
} from '@marageti/z4-sdk/lib/people';
import { CountryCodeMap, CurrencyAmount, TimeZone } from '@marageti/z4-sdk/lib/standards';
import {
  PartnerOffice,
  Partner,
  TripMatch,
  ConversationType,
} from '@marageti/z4-sdk/lib/travel';
import { RoleName } from '@marageti/z4-sdk/lib/auth';
import {
  formatPhoneNumber,
  getTimeZoneCity,
  getFormattedDateLongMonthYYYY,
  getTimezoneNameAndGMTOffset,
  Validate,
} from '@marageti/z4-lib';

export const getFormattedPartnerCountries = (partner: Partner, countryCodes: CountryCodeMap) => {
  let str = '';
  if (partner?.primaryCountries?.length > 0) {
    str += countryCodes[partner.primaryCountries[0].country];
  }
  if (partner?.secondaryCountries?.length > 0) {
    str += ' · ';
    str += countryCodes[partner.secondaryCountries[0].country];
  }
  return str;
};

export const formatCountryCodes = (countryCodes: string[], countryCodeMap: CountryCodeMap) => {
  let countryNames: string[] = [];
  if (countryCodes) {
    countryNames = countryCodes.map((c) => countryCodeMap[c]);
  }
  return countryNames.join(', ');
};

export const getPhoneNumber = (person: Agent | TeamMember | Traveler | undefined): string | undefined => {
  if (!person) return undefined;
  if (!person.phone
    || !person.phone.number) {
    return '-';
  }
  return formatPhoneNumber(person.phone.prefix, person.phone.number);
};

export const getPartnerUSPhoneNumber = (partner: Partner | undefined): string | undefined => {
  if (!partner) return undefined;
  if (!partner.usPhone
    || !partner.usPhone.number) {
    return '-';
  }
  return formatPhoneNumber(partner.usPhone.prefix, partner.usPhone.number);
};

export const getPartnerPrimaryPhoneNumber = (partner: Partner | undefined): string | undefined => {
  if (!partner) return undefined;
  if (!partner.primaryPhone
    || !partner.primaryPhone.number) {
    return '-';
  }
  return formatPhoneNumber(partner.primaryPhone.prefix, partner.primaryPhone.number);
};

export const getPartnerOfficePhoneNumber = (office: PartnerOffice | undefined): string | undefined => {
  if (!office) return undefined;
  if (!office.phone
    || !office.phone.number) {
    return '-';
  }
  return formatPhoneNumber(office.phone.prefix, office.phone.number);
};

export const getTimeZone = (person: Agent | Traveler | TeamMember | undefined): string | undefined => {
  if (!person) return undefined;
  if (!person.timeZone?.name || typeof person.timeZone.rawOffsetInMinutes !== 'number') {
    return '-';
  }
  return getTimezoneNameAndGMTOffset(person.timeZone.name, person.timeZone.rawOffsetInMinutes);
};

export const formatTimeWithTimeZoneName = (timeZone: TimeZone | undefined, time?: string): string => {
  if (!timeZone || !timeZone.name || timeZone.name === '') return '-';
  const city = getTimeZoneCity(timeZone.name);
  return `${city} (Currently ${time || '-'})`;
};

export const getPersonTime = (person: Agent | Traveler | TeamMember | undefined, time?: string): string | undefined => {
  if (!person) return undefined;
  return formatTimeWithTimeZoneName(person.timeZone, time);
};

export const getHistory = (person: Agent | Traveler | TeamMember | undefined): string | undefined | any => {
  if (!person) return undefined;
  if (!person.createdAt) return '-';
  return getFormattedDateLongMonthYYYY(person.createdAt);
};

export const getCountryOfResidence = (traveler: Traveler | undefined): string | undefined => {
  if (!traveler) return undefined;
  if (!traveler.countryOfResidence) return '-';
  return traveler.countryOfResidence;
};

export const getAvatarSrcAndAlt = (name: string, avatar?: string): { src: string | undefined, alt: string } => {
  const [first, last] = name.split(' ');
  return ({
    src: avatar && `data:image/png;base64,${avatar}`,
    alt: `${first[0] || ''}${last[0] || ''}`,
  });
};

export const getRatingNumber = (ratingPercentage: number | undefined): number => {
  if (!ratingPercentage) return 0;
  return Math.round(ratingPercentage / 20);
};

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

// This function compares the full names of two objects, and it used for sorting
// a list of names into alphabetical order
// The objects are expected to have a 'person' property with a 'fullName' string.
// The function returns -1 if the full name of the first object is alphabetically
// less than the full name of the second object, 1 if the full name of the first
// object is alphabetically greater than the full name of the second object,
// and 0 if the full names are equal.

// ***This function was refactored with ChatGPT.***
export const compareNames = (
  a: { fullName: string },
  b: { fullName: string },
) => (a.fullName.localeCompare(b.fullName));

export const compareFamilyNames = (
  a: { familyName: string },
  b: { familyName: string },
) => (a.familyName.localeCompare(b.familyName));

export const getRoleDisplayName = (roles: UserInfo['roles']): string => {
  if (!roles || roles.length === 0) return '';
  if (roles.includes(RoleName.AGENT_ADMIN)) return 'Manager';
  if (roles.includes(RoleName.AGENT)) return 'Specialist';
  if (roles.includes(RoleName.AGENT_SUPPORT)) return 'Support';
  if (roles.includes(RoleName.ADMIN)) return 'Admin';
  if (roles.includes(RoleName.AGENT_SUPPORT)) return 'Support';
  return '';
};

export const calculateRemainingTime = (createdAt: string) => {
  const creationTime = new Date(createdAt);
  const deadline = new Date(creationTime.getTime() + 24 * 60 * 60 * 1000); // 24 hours later
  const now = new Date();
  const remainingMilliseconds = deadline.getTime() - now.getTime();
  if (remainingMilliseconds <= 0) return 0;

  const remainingHours = Math.ceil(remainingMilliseconds / (60 * 60 * 1000));

  return remainingHours;
};

export const getCurrencyDetailsContent = (currencyAmount: CurrencyAmount) => {
  const USDAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currencyAmount.amountUSD);
  switch (currencyAmount.currencyCode) {
    case 'EUR': {
      const EURAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyAmount.currencyCode }).format(currencyAmount.amount);
      return `${EURAmount.replace(/[.,]/g, ((match) => (match === ',' ? '.' : ',')))} (${USDAmount})`;
    }
    case 'GBP': {
      const GBPAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyAmount.currencyCode }).format(currencyAmount.amount);
      return `${GBPAmount} (${USDAmount})`;
    }
    default:
      return USDAmount;
  }
};

export const getConversationTypeFromTripMatch = (tm: TripMatch | undefined, conversationId: string | undefined): ConversationType | undefined => {
  if (tm === undefined || conversationId === undefined) {
    return undefined;
  }
  if (tm.travelerToPartnerId === conversationId) {
    return ConversationType.TravelerToPartner;
  }
  if (tm.zicassoToPartnerId === conversationId) {
    return ConversationType.ZicassoToPartner;
  }
  return undefined;
};

export const validateEmail = (email: string) => {
  if (!email) return true;
  return Validate.email(email);
};

import React, { useState, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { BearerToken } from '@marageti/z4-sdk/lib/auth';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import { Agent } from '@marageti/z4-sdk/lib/people';
import {
  hasAuthorizedRole, LocalStorageService, useApiClient, useQueryParams,
} from '@marageti/z4-lib';
import SetPasswordView from './set-password-view';
import { useAuthStore } from '../../../store';
import { authorizedRoles, getMatchingUser } from '../../../utils/auth';

const LocalStorage = new LocalStorageService();

const SetPasswordContainer = () => {
  const {
    isAuthenticated,
    removeAuthenticatedAgent,
    setIsAuthenticated,
    setAuthenticatedAgent,
  } = useAuthStore();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const tokenExists = useLoaderData();
  const { email, token } = useQueryParams();

  const [loginError, setLoginError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(!tokenExists);
  const [newLinkSent, setNewLinkSent] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  const onResetSuccess = (bearerToken: BearerToken) => {
    const { accessToken } = bearerToken;
    LocalStorage.addToLocalStorage('accessToken', accessToken);
    LocalStorage.addToLocalStorage('email', email);
    apiClient.instance.setAuthToken(accessToken);

    apiClient.peopleClient.getAgent(email).then((data: Agent) => {
      const matchingUser = getMatchingUser(data.email, data.userInfo);
      if (!matchingUser || !hasAuthorizedRole(matchingUser.roles, authorizedRoles, true)) {
        throw new Error('Not Authorized');
      }
      setAuthenticatedAgent(data);
      setIsAuthenticated(true);
    })
      .catch((e: ApiError) => {
        removeAuthenticatedAgent();
        setIsAuthenticated(false);
        setLoginError(e);
      })
      .finally(() => setLoading(false));
  };

  const onSubmitPassword = ({ password }: FieldValues): void => {
    setLoading(true);
    const newPassword = { password };

    apiClient.authClient.putUserResetToken(email, token, newPassword, { withCredentials: true })
      .then(onResetSuccess)
      .catch(() => {
        setLoading(false);
        setTokenExpired(true);
      });
  };

  const onResendLinkSuccess = () => {
    setNewLinkSent(true);
  };

  return (
    <SetPasswordView
      email={email}
      loading={loading}
      loginError={loginError}
      newLinkSent={newLinkSent}
      onResendLinkSuccess={onResendLinkSuccess}
      onSubmit={onSubmitPassword}
      tokenExpired={tokenExpired}
    />
  );
};

export default SetPasswordContainer;

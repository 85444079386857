import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { ZSpinner } from '@marageti/z4-lib';
import { SalesRecording, SalesRecordingStatus } from '@marageti/z4-sdk/lib/travel';
import SalesTable from './invoices-table';

type Props = {
  isLoading: boolean;
  onChangeMonth: (value: Date | null) => void;
  sales: SalesRecording[] | undefined;
  selectedMonth: Date | null;
  totalSales: string | number | undefined;
  totalCommission: string | number | undefined;
};

const today = new Date();
const minDate = new Date('2008-01-01T00:00:00');

const InvoicesView = ({
  isLoading,
  onChangeMonth,
  sales,
  selectedMonth,
  totalSales,
  totalCommission,
}: Props) => {
  const navigate = useNavigate();
  const [salesUnderReview, setSalesUnderReview] = useState(false);
  const [allMonthlySalesApproved, setAllMonthlySalesApproved] = useState(false);

  const handleRecordRepeatReferralClick = () => {
    navigate('/sales/repeat-referral');
  };

  //  Set banner based on sales status.
  useEffect(() => {
    let allApproved = false;
    let underReview = false;

    if (sales && sales.length > 0) {
      const isCurrentMonth = selectedMonth && selectedMonth.getMonth() === today.getMonth();

      if (isCurrentMonth) {
        // Check for any "Sold" records.
        underReview = sales.some((sale) => sale.status === SalesRecordingStatus.SoldStatus);
        if (!underReview) {
          const allInvoiced = sales.every((sale) => sale.status === SalesRecordingStatus.InvoicedStatus);

          // Do not show a banner if all are invoiced.
          if (allInvoiced) {
            allApproved = false;
          } else {
            allApproved = true;
          }
        }
      }
    }

    setAllMonthlySalesApproved(allApproved);
    setSalesUnderReview(underReview);
  }, [sales, selectedMonth]);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          pt: { desktop: 4.5, mobile: 6.5 },
          pb: 20,
          maxWidth: { desktop: 'desktop' },
          margin: { desktop: '0 auto 56px' },
        }}
      >
        <Stack spacing={4}>
          <Stack direction={{ desktop: 'row' }} justifyContent="space-between" alignItems={{ desktop: 'center' }}>
            <Typography variant="h2" sx={{ mb: { mobile: 3, desktop: 0 } }}>Invoices</Typography>
            <Button variant="contained" onClick={handleRecordRepeatReferralClick}>
              Record Repeat / Referral
            </Button>
          </Stack>
          <Stack spacing={2}>
            <DatePicker
              label="Month"
              minDate={minDate}
              onChange={onChangeMonth}
              value={selectedMonth}
              views={['month', 'year']}
              slotProps={{
                textField: {
                  sx: {
                    maxWidth: { desktop: '304px' },
                    width: '100%',
                  },
                  className: 'custom-datePicker',
                },
              }}
            />
          </Stack>
          { salesUnderReview && (
            <Alert severity="warning">
              <AlertTitle>Sales Under Review</AlertTitle>
              Our team is currently reviewing your sales and preparing for invoice.
              Please do not make any changes to these sales records unless contacted by our team.
              Once your sales are approved, these records will be locked for invoicing.
            </Alert>
          )}
          { allMonthlySalesApproved && (
            <Alert severity="success">
              <AlertTitle>Recordings Approved</AlertTitle>
              Recordings for the selected month have been approved by Zicasso and can no longer be edited.
            </Alert>
          )}
          {(!isLoading && sales?.length === 0) ? (
            <Card>
              <CardContent>
                <Typography variant="body" color="primary.light" textAlign="center">
                  There are no sales recordings for the selected month.
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <>
              <Stack direction={{ desktop: 'row' }} spacing={2.5}>
                <Card sx={{ flex: 1, mb: { mobile: 3, desktop: 'none' } }}>
                  {isLoading ? (
                    <Skeleton variant="rounded" height={116} />
                  ) : (
                    <>
                      <CardHeader title={totalSales} titleTypographyProps={{ variant: 'display' }} />
                      <CardContent sx={{ pt: 0 }}>
                        <Typography variant="caption">
                          Total Sales Amount (USD)
                        </Typography>
                      </CardContent>
                    </>
                  )}
                </Card>
                <Card sx={{ flex: 1 }}>
                  {isLoading ? (
                    <Skeleton variant="rounded" height={116} />
                  ) : (
                    <>
                      <CardHeader title={totalCommission} titleTypographyProps={{ variant: 'display' }} />
                      <CardContent sx={{ pt: 0 }}>
                        <Typography variant="caption">
                          Total Zicasso Commission (USD)
                        </Typography>
                      </CardContent>
                    </>
                  )}
                </Card>
              </Stack>

              {isLoading ? (
                <Stack>
                  <ZSpinner />
                </Stack>
              ) : (
                <SalesTable
                  sales={sales || []}
                  totalCommission={totalCommission}
                  totalSales={totalSales}
                />
              )}

            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default InvoicesView;

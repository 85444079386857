import React from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import {
  ContextMenu,
  ContextMenuButton,
  ContextMenuItem,
  ProfileCardContent,
  LabelAndValueElement,
} from '@marageti/z4-lib';
import { ProfileCardProps } from '../types';
import {
  getPartnerPrimaryPhoneNumber,
  getPartnerUSPhoneNumber,
} from '../../../../utils/helpers';
import stylesT from '../styles';

const ProfileCard = ({
  contextMenuAnchor,
  contextMenuOpen,
  onCloseContextMenu,
  onOpenContextMenu,
  onClickEdit,
  partner,
  isAdmin,
}: ProfileCardProps) => {
  const theme = useTheme();
  const styles = stylesT(theme);
  const menuId = 'profile-context-menu';
  const buttonId = 'profile-context-button';
  return (
    <Card sx={styles.profileCard}>
      <ProfileCardContent>
        <Stack spacing="24px" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <LabelAndValueElement
              title="Company Name"
              value={partner?.name || '-'}
              key="name"
            />
            {isAdmin && (
              <>
                <ContextMenuButton
                  id={buttonId}
                  open={contextMenuOpen}
                  onClick={onOpenContextMenu}
                  menuId={menuId}
                />
                <ContextMenu
                  anchorEl={contextMenuAnchor}
                  buttonId={buttonId}
                  id={menuId}
                  onClose={onCloseContextMenu}
                  open={contextMenuOpen}
                >
                  <ContextMenuItem onClick={onClickEdit}>
                    Edit
                  </ContextMenuItem>
                </ContextMenu>
              </>
            )}
          </Stack>
          <LabelAndValueElement
            title="Founded"
            value={partner && (partner.yearFounded || '-')}
            key="founded"
          />
          <LabelAndValueElement
            title="US Phone"
            value={getPartnerUSPhoneNumber(partner)}
            key="us-phone"
          />
          <LabelAndValueElement
            title="Primary Phone"
            value={getPartnerPrimaryPhoneNumber(partner)}
            key="primary-phone"
          />
          <LabelAndValueElement
            title="Email"
            value={partner && (partner?.email || '-')}
            key="email"
          />
          <LabelAndValueElement
            title="Website"
            value={partner && (partner?.website || '-')}
            key="website"
          />
        </Stack>
      </ProfileCardContent>
    </Card>
  );
};

export default ProfileCard;

import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import {
  BackButton,
} from '@marageti/z4-lib';
import UserProfileEditForm from '../../../components/user-profile-edit-form';

type EditUserViewProps = {
  agentId: string | undefined
  onNavigateBack: () => void
};

const EditUserView = ({ agentId, onNavigateBack }: EditUserViewProps) => (
  <Container
    maxWidth="sm"
    sx={{ pb: 8 }}
  >
    <Box
      sx={{
        py: {
          desktop: 6,
          mobile: 3,
        },
      }}
    >
      <BackButton onClick={onNavigateBack} edge="start" sx={{ mb: 2 }} />
      <UserProfileEditForm
        agentId={agentId}
        onSuccess={onNavigateBack}
        onCancel={onNavigateBack}
      />
    </Box>
  </Container>
);

export default EditUserView;

import React from 'react';
import {
  Card,
  CardContent,
  Stack,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Traveler, TravelerTags } from '@marageti/z4-sdk/lib/people';
import {
  LabelAndValueElement,
  OutlinedIcons,
  copyToClipboard,
  formatPhoneNumber,
  useCurrentTime,
  OtherChips,
  TravelerTagChips,
} from '@marageti/z4-lib';
import { formatTimeWithTimeZoneName } from '../../utils/helpers';

const {
  OutlinedDocumentDuplicateIcon,
} = OutlinedIcons;

const { ChipRepeat } = OtherChips;
const { ChipVIP } = TravelerTagChips;

type TravelerProps = {
  hidePhone: boolean;
  traveler?: Traveler;
};

type RepeatTripsProps = {
  totalTrips: number | undefined;
};

const TravelerRepeatTrips = ({ totalTrips }: RepeatTripsProps) => {
  const showCount = totalTrips && totalTrips > 0;

  return (
    <LabelAndValueElement
      title="Previous Trips"
      value={showCount ? (
        `Previous Trips (${totalTrips})`
      ) : (
        'Previous Trips (0)'
      )}
      key="repeat-trips"
    />
  );
};

const TravelerCard = ({
  hidePhone,
  traveler = undefined,
}: TravelerProps) => {
  const phoneNumber = formatPhoneNumber(traveler?.phone?.prefix, traveler?.phone?.number);
  const [time] = useCurrentTime(traveler?.timeZone?.name);

  return (
    <Card variant="loose" sx={{ width: '100%' }}>
      <CardContent>
        <Stack spacing={3}>
          <LabelAndValueElement
            title="Name"
            value={traveler?.fullName}
          />
          <TravelerRepeatTrips totalTrips={traveler?.totalZicassoTrips} />
          <Stack spacing={1}>
            <Typography variant="captionBold" color="primary">Tags</Typography>
            <Stack direction="row" spacing={0.5}>
              {traveler?.tags?.includes(TravelerTags.VIP) && (
                <ChipVIP size="small" />
              )}
              {(typeof traveler?.totalZicassoTrips === 'number' && traveler?.totalZicassoTrips > 0) && (
                <ChipRepeat size="small" />
              )}
            </Stack>
          </Stack>
          {!hidePhone && (
            <LabelAndValueElement
              title="Phone"
              value={(
                <Box component="span" display="flex" alignItems="center">
                  {phoneNumber ? (
                    <>
                      {phoneNumber}
                      <IconButton
                        size="small"
                        onClick={() => phoneNumber && copyToClipboard(phoneNumber)}
                        aria-label="copy phone number"
                      >
                        <OutlinedDocumentDuplicateIcon color="primary" fontSize="inherit" />
                      </IconButton>
                    </>
                  ) : '-'}
                </Box>
              )}
              key="primary-phone"
            />
          )}
          <LabelAndValueElement
            title="Timezone"
            value={formatTimeWithTimeZoneName(traveler?.timeZone, time)}
            key="timezone"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TravelerCard;

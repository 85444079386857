import React from 'react';
import {
  Box,
  Container,
  Collapse,
  IconButton,
  Stack,
  Typography,
  Chip,
  useMediaQuery,
  Alert,
  AlertTitle,
  Button,
} from '@mui/material';
import { OutlinedIcons, ZSpinner } from '@marageti/z4-lib';
import { Outlet, Link } from 'react-router-dom';
import ConversationList from './conversation-list';

const {
  OutlinedChevronDoubleRightIcon,
  OutlinedChatIcon,
} = OutlinedIcons;

const SIDEBAR_OPEN_WIDTH = 320;

type InboxViewProps = {
  hasConversations: boolean;
  isLeftSidebarOpen: boolean;
  isLoading: boolean;
  onClickUnread: () => void;
  selectedInboxConversationId: string | undefined;
  showUnreadOnly: boolean,
  toggleLeftSidebar: () => void;
  unreadCount: number | undefined;
};

const InboxView = ({
  hasConversations,
  isLeftSidebarOpen,
  isLoading,
  onClickUnread,
  selectedInboxConversationId,
  showUnreadOnly,
  toggleLeftSidebar,
  unreadCount,
}: InboxViewProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('desktop'));
  if (isMobile && selectedInboxConversationId) return <Outlet />;
  return (
    <Container maxWidth="xl">
      {/* Left Sidebar */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ height: 'calc(100vh - 93px - 93px)' }}>
        <Collapse
          in={isLeftSidebarOpen}
          orientation="horizontal"
          sx={{
            mt: { mobile: 1, desktop: 0 },
            mb: 1,
            width: { mobile: '100%', desktop: SIDEBAR_OPEN_WIDTH },
            '.MuiCollapse-wrapper, .MuiCollapse-wrapperInner': {
              width: { mobile: '100%', desktop: SIDEBAR_OPEN_WIDTH },
            },
          }}
        >
          <Box
            width={{ mobile: '100%', desktop: SIDEBAR_OPEN_WIDTH }}
            sx={{
              height: { desktop: '100%' },
              display: { desktop: 'flex' },
              flexDirection: 'column',
            }}
          >
            <Stack spacing={{ mobile: 0, desktop: 1.5 }} mb={{ mobile: 1, desktop: 3 }} alignItems={{ mobile: 'flex-end', desktop: 'flex-start' }}>
              <Typography variant="label" sx={{ mb: 1.5, display: { mobile: 'none', desktop: 'inline-block' } }}>Filter By:</Typography>
              <Chip
                color={showUnreadOnly ? 'primary' : 'default'}
                disabled={unreadCount === 0}
                onClick={onClickUnread}
                label={`Unread (${unreadCount ?? '-'})`}
                variant={showUnreadOnly ? 'filled' : 'outlined'}
                sx={{ width: 'fit-content' }}
              />
            </Stack>
            {isLoading && (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <ZSpinner />
              </Box>
            )}
            {(!isLoading && isMobile && !hasConversations) ? (
              <Alert severity="info" sx={{ mt: 4 }}>
                <AlertTitle>You don&apos;t have any messages yet</AlertTitle>
                Send the first message to a traveler and start a conversation
                <Button component={Link} variant="contained" sx={{ marginTop: 1 }} to="/trip-matches">
                  Go to All Trips
                </Button>
              </Alert>
            ) : (
              <ConversationList />
            )}
          </Box>
        </Collapse>
        {!isMobile && (
          <Box
            display="flex"
            flex={1}
            paddingX={2}
            sx={{
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
              paddingLeft: 2,
              marginLeft: 2,
              paddingTop: 1,
              marginBottom: 1,
              position: 'relative',
              ...isLeftSidebarOpen && {
                maxWidth: 'calc(100% - 336px)',
              },
            }}
          >
            <IconButton
              aria-label={isLeftSidebarOpen ? 'Close Messages List' : 'Open Messages List'}
              size="small"
              onClick={toggleLeftSidebar}
              sx={{
                padding: 0,
                position: 'absolute',
                top: '20px',
                left: '-38px',
                ...isLeftSidebarOpen && {
                  transform: 'rotate(180deg)',
                },
              }}
            >
              <OutlinedChevronDoubleRightIcon color="primary" />
            </IconButton>
            {/* Header */}
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {selectedInboxConversationId ? (
                <Outlet />
              ) : (
                <Box width="100%">
                  {isLoading && (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <ZSpinner />
                    </Box>
                  )}
                  {(!isLoading && !hasConversations) ? (
                    <Alert severity="info" sx={{ maxWidth: '575px', ml: 2 }}>
                      <AlertTitle>You don&apos;t have any messages yet</AlertTitle>
                      Send the first message to a traveler and start a conversation
                      <Button component={Link} variant="contained" sx={{ marginTop: 1 }} to="/trip-matches">
                        Go to All Trips
                      </Button>
                    </Alert>
                  ) : (
                    <Stack spacing={3} marginTop="162px" alignItems="center" width="100%">
                      <OutlinedChatIcon color="primary" />
                      <Stack spacing={1}>
                        <Typography variant="h3">This is your Zicasso inbox</Typography>
                        <Typography>Select a conversation to see it here.</Typography>
                      </Stack>
                    </Stack>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default InboxView;

import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Typography,
  Stack,
  Skeleton,
  IconButton,
} from '@mui/material';
import {
  DetailCard, CopyButton, ContextMenuComplete, Icons,
} from '@marageti/z4-lib';
import { SalesRecording } from '@marageti/z4-sdk/lib/travel';
import { format } from 'date-fns';
import { SalesRecordingStatus, SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrencyDetailsContent } from '../../../utils/helpers';
import { formatInvoiceMonth } from '../../../utils/sales';

const formatDate = (timestamp: string) => format(new Date(timestamp), 'MMM d, yyyy');

type ViewProps = {
  isLoading: boolean;
  onClickEdit: () => void;
  onClickRemove: () => void;
  repeatReferral: SalesRecording | undefined;
};

const RepeatReferralDetailsView = ({
  isLoading,
  onClickEdit,
  onClickRemove,
  repeatReferral,
}: ViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.key === 'default') {
      navigate('/sales/all', { relative: 'path' });
    } else {
      navigate(-1);
    }
  };

  return (
    <Container
      maxWidth="md"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          pb: { desktop: 7, mobile: 3 },
        }}
      >
        <Stack spacing={3}>
          {isLoading && (
          <>
            <Skeleton height={32} width={200} />
            <Skeleton height={252} width={774} />
            <Skeleton height={480} width={774} />
          </>
          )}
          {(!isLoading && repeatReferral) && (
          <>
            <IconButton
              aria-label="back to all sales"
              onClick={handleBackClick}
              sx={{
                width: 'fit-content',
                pl: 0,
                color: 'primary.main',
              }}
              edge="start"
            >
              <Icons.ArrowLeft />
            </IconButton>
            <Stack direction="row" justifyContent="space-between">
              {repeatReferral.salesRecordingType === SalesRecordingType.PartnerRepeat && (
              <Typography variant="h2">Repeat Sale</Typography>
              )}
              {repeatReferral.salesRecordingType === SalesRecordingType.TravelerReferral && (
              <Typography variant="h2">Referral Sale</Typography>
              )}
              {repeatReferral.status === SalesRecordingStatus.SoldStatus && (
              <ContextMenuComplete
                id="repeat-referral-content"
                items={[
                  { label: 'Edit', action: onClickEdit },
                  { label: 'Remove', action: onClickRemove, sx: { color: 'error.main' } },
                ]}
              />
              )}
            </Stack>
            {repeatReferral.status !== SalesRecordingStatus.SoldStatus && (
            <Alert severity="error">
              <AlertTitle>Previously Invoiced</AlertTitle>
              This recording has already been invoiced, and can no longer be edited.
            </Alert>
            )}
            <DetailCard
              title="Traveler Info"
              items={[
                { title: 'Name', content: repeatReferral.travelers?.[0]?.fullName },
              ]}
            />
            <DetailCard
              title="Sales Details"
              items={[
                { title: 'Type', content: repeatReferral.salesRecordingType === SalesRecordingType.PartnerRepeat ? 'Repeat' : 'Referral' },
                { title: 'Date Recorded', content: formatDate(repeatReferral.createdAt) },
                { title: 'Total Trip Price', content: getCurrencyDetailsContent(repeatReferral.price) },
                { title: 'Commission to Zicasso', content: getCurrencyDetailsContent(repeatReferral.commission) },
                { title: 'Comments', content: repeatReferral.priceComments },
                { title: 'Invoice Month', content: formatInvoiceMonth(repeatReferral?.invoiceDate!) },
                {
                  title: 'Sales ID',
                  content: (
                    <>
                      {repeatReferral.id}
                      <CopyButton textToCopy={repeatReferral.id} iconFontSize="small" size="small" />
                    </>
                  ),
                },
              ]}
            />
          </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default RepeatReferralDetailsView;

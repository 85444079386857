import React from 'react';
import {
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import EditForm from '../../../../components/profile-edit-form';

type UserProfileEditViewProps = {
  partnerData: Partner
  isLoading: boolean
  partnerError: any
  onSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
};

const EditView = ({
  partnerError,
  isLoading,
  partnerData,
  onSubmit,
  submissionError,
  onCancel = undefined,
}: UserProfileEditViewProps) => {
  if (isLoading || !partnerData) {
    return (
      <Skeleton variant="rounded" height={500} />
    );
  }

  if (partnerError) {
    return (
      <Alert severity="error">
        <AlertTitle>Profile Error</AlertTitle>
        There was an error getting the profile.
      </Alert>
    );
  }

  return (
    <EditForm
      partner={partnerData}
      submissionError={submissionError}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default EditView;

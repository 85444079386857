import {
  Dispatch,
  Reducer,
  useReducer,
} from 'react';
import {
  ADD_PUSHER_CHANNEL,
  REMOVE_PUSHER_CHANNEL,
  SET_PUSHER_READY,
  SET_PUSHER_ERROR,
} from './actions';
import { PusherStateProps } from './types';
import { Actions } from '../../types';

const initialState: PusherStateProps = {
  error: '',
  ready: false,
  subscribedChannels: {},
};

const reducer: Reducer<PusherStateProps, Actions> = (state, action): PusherStateProps => {
  switch (action.type) {
    case ADD_PUSHER_CHANNEL:
      return {
        ...state,
        subscribedChannels: {
          ...state.subscribedChannels,
          [action.channelName]: action.channel,
        },
      };
    case REMOVE_PUSHER_CHANNEL: {
      const updatedChannels = { ...state.subscribedChannels };
      delete updatedChannels[action.channelName];
      return {
        ...state,
        subscribedChannels: updatedChannels,
      };
    }
    case SET_PUSHER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_PUSHER_READY:
      return {
        ...state,
        ready: action.ready,
      };
    default:
      return state;
  }
};

const usePusherReducer = () => {
  const [pusher, dispatchPusher] = useReducer(reducer, initialState);

  return [pusher, dispatchPusher] as [PusherStateProps, Dispatch<Actions>];
};

export default usePusherReducer;

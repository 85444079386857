import React from 'react';
import {
  Stack, Typography, Alert, AlertTitle,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SWRResponse } from 'swr';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { TimeZone } from '@marageti/z4-sdk/lib/standards';
import UserForm from './user-form';

type UserProfileEditFormViewProps = {
  title?: string
  subtitle?: string
  submitText?: string
  agent: SWRResponse<Agent, any, any>
  suggestedTimezoneOption?: TimeZone | undefined
  handleSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
};

const UserProfileEditFormView = ({
  title = undefined,
  subtitle = undefined,
  submitText = undefined,
  agent,
  suggestedTimezoneOption,
  handleSubmit,
  submissionError,
  onCancel = undefined,
}: UserProfileEditFormViewProps) => {
  const renderForm = () => {
    if (agent.isLoading || !agent.data) {
      return null;
    }

    if (agent.error) {
      return (
        <Alert severity="error">
          <AlertTitle>User Error</AlertTitle>
          There was an error getting user.
        </Alert>
      );
    }

    return (
      <UserForm
        submitText={submitText}
        onSubmit={handleSubmit}
        submissionError={submissionError}
        agent={agent.data}
        suggestedTimezoneOption={suggestedTimezoneOption}
        onCancel={onCancel}
      />
    );
  };

  return (
    <Stack
      spacing={2}
      width="100%"
    >
      <Stack spacing={1}>
        <Typography variant="h2">{title || 'Edit User'}</Typography>
        {subtitle && (<Typography variant="body1">{subtitle}</Typography>)}
      </Stack>
      {renderForm()}
    </Stack>
  );
};

export default UserProfileEditFormView;

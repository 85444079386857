import React from 'react';
import { Outlet } from 'react-router-dom';
import TripMatchesSubNavigation from '../../components/trip-matches-sub-navigation';

const View = () => (
  <>
    <TripMatchesSubNavigation />
    <Outlet />
  </>
);

export default View;

import { useApiClient, useFetch } from '@marageti/z4-lib';
import {
  TripMatch, SalesRecording, SalesRecordingStatus, TripMatchStatus,
} from '@marageti/z4-sdk/lib/travel';
import { SalesRecordingType } from '@marageti/z4-sdk/lib/travel/SalesRecording';
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SalesAdjustmentView from './sales-adjustment-view';

const SalesAdjustmentContainer = () => {
  const {
    tripMatchId,
    salesAdjustmentId,
  } = useParams<{
    tripMatchId: string,
    salesAdjustmentId?: string
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const {
    isLoading: tripMatchIsLoading,
    data: tripMatch,
    error: tripMatchError,
    mutate: mutateTripMatch,
  } = useFetch<TripMatch>(apiClient.tripMatchesClient.getTripMatch, [tripMatchId], !!tripMatchId);

  const {
    isLoading: salesAdjustmentIsLoading,
    data: salesAdjustment,
    error: salesAdjustmentError,
    mutate: mutateSalesAdjustment,
  } = useFetch<SalesRecording>(apiClient.salesRecordingsClient.getSalesRecording, [salesAdjustmentId, { params: { trip_match: tripMatchId } }], !!salesAdjustmentId);
  const {
    isLoading: salesRecordingsIsLoading,
    data: salesRecordings,
    error: salesRecordingsError,
  } = useFetch<SalesRecording[]>(apiClient.tripMatchesClient.getTripMatchSalesRecordings, [tripMatchId], !!tripMatchId);
  const salesAdjustmentToEdit = salesAdjustment;

  const getFormDisabledReason = (): string | false => {
    const isCreatingSalesAdjustment = !salesAdjustmentToEdit;
    const hasSoldSalesRecording = Boolean(salesRecordings?.find((sr) => sr.status === SalesRecordingStatus.SoldStatus));
    const hasNoSalesRecordings = !salesRecordings || salesRecordings.length === 0;

    if (salesAdjustmentId && !salesAdjustmentToEdit) {
      return 'No Sales Adjustment found.';
    }
    if (salesAdjustmentToEdit && salesAdjustmentToEdit.salesRecordingType !== SalesRecordingType.Adjustment) {
      return 'No Sales Adjustment found.';
    }
    if (tripMatch && ![
      TripMatchStatus.MatchActive,
      TripMatchStatus.MatchSold,
    ].includes(tripMatch.status)) {
      return 'Cannot create sales adjustment for this trip match.';
    }
    if (hasNoSalesRecordings) {
      return 'Please create a sales recording first before attempting to create a sales adjustment.';
    }
    if (isCreatingSalesAdjustment && hasSoldSalesRecording) {
      return 'An existing sales recording or adjustment already exists. Please edit it instead of creating a new one.';
    }
    if (salesAdjustmentToEdit && (salesAdjustmentToEdit.status === SalesRecordingStatus.InvoicedStatus || salesAdjustmentToEdit?.status === SalesRecordingStatus.VerifiedStatus)) {
      return 'Sales adjustments that have been invoiced or verified can\'t be edited. To make changes, you\'ll need to create a new sales adjustment.';
    }

    return false;
  };

  const isLoading = tripMatchIsLoading || salesAdjustmentIsLoading || salesRecordingsIsLoading;
  const isError = tripMatchError || salesAdjustmentError || salesRecordingsError;
  const formDisabledReason = getFormDisabledReason();

  const onSuccess = (createdUpdatedSalesRecordingId: string) => {
    mutateTripMatch();
    if (salesAdjustmentToEdit) {
      mutateSalesAdjustment();
    }
    // Fallback to sales recording page if the user cannot go back to the previous page
    if (location.key === 'default' && tripMatch) {
      navigate(`/trip-matches/${tripMatch.id}/sales/${createdUpdatedSalesRecordingId}`);
    } else {
      navigate(-1);
    }
  };

  const onCancel = () => {
    // Fallback to the home page if the user cannot go back to the previous page
    if (location.key === 'default' && tripMatch) {
      navigate('../');
    } else {
      navigate(-1);
    }
  };

  return (
    <SalesAdjustmentView
      formDisabledReason={formDisabledReason}
      isError={isError}
      isLoading={isLoading}
      onCancel={onCancel}
      onSuccess={onSuccess}
      salesAdjustmentToEdit={salesAdjustmentToEdit}
      salesRecordings={salesRecordings || []}
      tripMatch={tripMatch}
    />
  );
};

export default SalesAdjustmentContainer;

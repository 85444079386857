import React from 'react';
import { ApiError } from '@marageti/z4-sdk/lib/tracking';
import ChangePasswordForm from './change-password-form';

type SecurityFormViewProps = {
  passwordSubmissionError: ApiError | false,
  handleSubmitPassword: (data: any, onSuccess: any) => void,
};

const SecurityFormView = ({
  passwordSubmissionError,
  handleSubmitPassword,
}:SecurityFormViewProps) => (
  <ChangePasswordForm
    submissionError={passwordSubmissionError}
    onSubmit={handleSubmitPassword}
  />
);

export default SecurityFormView;

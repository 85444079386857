import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useApiClient,
  useFetch,
  BackButton,
} from '@marageti/z4-lib';
import { Container, Box } from '@mui/material';
import { Partner } from '@marageti/z4-sdk/lib/travel';
import { useSnackbar } from 'notistack';
import EditView from './edit-policies-and-terms-view';
import { useAuthStore } from '../../../../store';

const EditDetailFormContainer = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  const { loggedInAgent } = useAuthStore();

  const partnerId = loggedInAgent?.partnerId;

  const {
    data: partner, isLoading, error, mutate,
  } = useFetch<Partner>(apiClient.partnersClient.getPartner, [partnerId], !!partnerId);
  const [submissionError, setSubmissionError] = React.useState<boolean>(false);

  const goBack = () => {
    navigate('/settings/policies-and-terms');
  };

  const handleSubmit = async (data: any) => {
    if (partner && partnerId) {
      const updatedPartner = {
        ...partner,
        ...data,
      };
      try {
        await apiClient.partnersClient.putPartner(partnerId, updatedPartner);
        enqueueSnackbar('Partner terms updated successfully.', { variant: 'success' });
        mutate(updatedPartner, false);
        goBack();
      } catch (e) {
        setSubmissionError(true);
      }
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{ flex: 1 }}
    >
      <Box
        sx={{
          py: {
            desktop: 6,
            mobile: 3,
          },
          height: { mobile: 'calc(100vh - 77px)', desktop: '750px' },
        }}
      >
        <BackButton onClick={goBack} edge="start" sx={{ mb: 2, width: 'fit-content' }} />
        <EditView
          partner={partner || {} as Partner}
          isLoading={isLoading}
          partnerError={error}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={goBack}
        />
      </Box>
    </Container>
  );
};

export default EditDetailFormContainer;

import React from 'react';
import {
  Alert,
  AlertTitle,
  Stack, Typography,
} from '@mui/material';
import {
  PartnerCountry, SalesRecording, TripMatch,
} from '@marageti/z4-sdk/lib/travel';
import Form from './form';

type SalesAdjustmentFormViewProps = {
  generalSalesRecording?: SalesRecording,
  tripMatch: TripMatch,
  salesAdjustment?: SalesRecording | undefined,
  partnerCountries: PartnerCountry[] | undefined,
  totalsOfSalesRecordings: {
    price: {
      amount: number
    },
    commission: {
      amount: number
    }
  }
  handleSubmit: (data: any) => void
  submissionError: boolean
  onCancel?: () => void
};

const SalesAdjustmentFormView = ({
  generalSalesRecording = undefined,
  handleSubmit,
  onCancel = undefined,
  partnerCountries,
  salesAdjustment = undefined,
  submissionError,
  totalsOfSalesRecordings,
  tripMatch,
}: SalesAdjustmentFormViewProps) => {
  const formTitle = salesAdjustment ? 'Edit Sales Adjustment' : 'Add Sales Adjustment';

  return (
    <Stack
      spacing={2}
      width="100%"
    >
      <Typography variant="h2">{formTitle}</Typography>
      {!generalSalesRecording ? (
        <Alert severity="error">
          <AlertTitle>Cannot Display Sales Adjustment Form</AlertTitle>
          There is no general sales recording for this trip match.
        </Alert>
      ) : (
        <Form
          defaultValues={{
            countries: tripMatch.countries,
            departureDate: tripMatch.departureDate ? new Date(tripMatch.departureDate) : undefined,
            totalNights: tripMatch.totalNights,
            numberOfAdults: tripMatch.numberOfAdults,
            numberOfChildren: tripMatch.numberOfChildren,
            price: {
              currencyCode: generalSalesRecording.price.currencyCode,
              amount: salesAdjustment?.price.amount,
            },
            commission: {
              currencyCode: generalSalesRecording.commission.currencyCode,
              amount: salesAdjustment?.commission.amount,
            },
            priceComments: salesAdjustment?.priceComments,
            document: salesAdjustment?.document ? [{
              name: salesAdjustment?.document.sourceFileName,
              type: salesAdjustment?.document.mediaType,
              size: salesAdjustment?.document.fileSize,
            }] : undefined,
          }}
          primaryTraveler={tripMatch.travelers[0]}
          countries={partnerCountries}
          totalsOfSalesRecordings={totalsOfSalesRecordings}
          onSubmit={handleSubmit}
          submissionError={submissionError}
          onCancel={onCancel}
        />
      )}
    </Stack>
  );
};

export default SalesAdjustmentFormView;

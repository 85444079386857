import React from 'react';
import {
  Container, Box, Alert, AlertTitle, Typography, Link,
} from '@mui/material';
import {
  Review,
  ErrorBox,
  ErrorTitle,
  ErrorMessage,
  mapReviewToReviewProps,
  BackButton,
} from '@marageti/z4-lib';
import { ViewProps } from './types';
import ZSpinner from '../../components/z-lib/z-spinner';

const DetailView = ({ review, loading, error }: ViewProps) => (
  <Container maxWidth="md">
    <Box sx={{
      pt: { desktop: 5, mobile: 2 },
      pb: 5,
    }}
    >
      <BackButton
        aria-label="back to reviews"
        defaultPath="/reviews"
        sx={{ ml: -1, mb: 2 }}
      />
      {loading && <Box display="flex" justifyContent="center"><ZSpinner /></Box>}
      {error && (
        <ErrorBox>
          <ErrorTitle>
            There was an error getting review details
          </ErrorTitle>
          <ErrorMessage>
            {`Code: ${error.code}: ${error.message}`}
          </ErrorMessage>
        </ErrorBox>
      )}
      {review && (
        <Review
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...mapReviewToReviewProps(review, { internal: false })}
        />
      )}
      {!review?.agentReply.reply && (
        <Box sx={{ mt: 5 }}>
          <Alert severity="info">
            <AlertTitle>Reply to this Review</AlertTitle>
            <Typography>
              To reply to a review, please email the
              {' '}
              <Link href="mailto:review@zicasso.com" underline="always" color="inherit">
                Zicasso Care Team
              </Link>
              . We plan to implement the ability to reply directly in the future.
            </Typography>
          </Alert>
        </Box>
      )}
    </Box>
  </Container>
);

export default DetailView;

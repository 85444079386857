import React from 'react';
import { TripMatch } from '@marageti/z4-sdk/lib/travel';
import {
  useApiClient,
  useFetchImmutable,
} from '@marageti/z4-lib';
import { CountryCodeMap } from '@marageti/z4-sdk/lib/standards';
import SoldCardView from './sold-card-view';

type SoldCardContainerProps = {
  tripMatch: TripMatch;
};

const SoldCardContainer = ({
  tripMatch,
}: SoldCardContainerProps) => {
  const apiClient = useApiClient();

  const {
    data: countryCodes,
  } = useFetchImmutable<CountryCodeMap>(apiClient.standardsClient.getCountryCodes, [], true);

  return (
    <SoldCardView
      countryCodes={countryCodes}
      tripMatch={tripMatch}
    />
  );
};

export default SoldCardContainer;

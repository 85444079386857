import React from 'react';
import {
  Box,
  Typography,
  Stack,
} from '@mui/material';
import {
  Link,
} from '@marageti/z4-lib';
import ResetForm from '../../../components/auth/email-reset-link-form';
import { ReactComponent as ZicassoSVG } from '../../../assets/svg/zicasso.svg';
import { ViewProps } from './types';
import '../reset.scss';

const SendLinkView = ({
  isSubmitted,
  onSuccess,
}: ViewProps): React.ReactElement => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack
      spacing={3}
      sx={{
        width: { mobile: '342px', desktop: '333px' },
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stack flexDirection="column" spacing="10px" alignItems="center">
        <ZicassoSVG aria-label="Zicasso" />
        {isSubmitted ? (
          <Typography color="text.secondary" sx={{ marginTop: '10px', textAlign: 'center', maxWidth: '333px' }}>
            Thanks! We&apos;ll send you an email with instructions on how to reset your password.
          </Typography>
        ) : (
          <Typography color="text.secondary" sx={{ marginTop: '10px', textAlign: 'center', maxWidth: '281px' }}>
            Enter your email address and we&apos;ll send you a link to reset your password.
          </Typography>
        )}
      </Stack>
      {!isSubmitted && (
      <ResetForm onSuccess={onSuccess} type="reset" />
      )}
      <Link
        color="primary"
        sx={{ '&:hover': { color: 'secondary.main' } }}
        to="/login"
        underline="always"
        variant="subtitle1"
      >
        Go back to Sign In
      </Link>
    </Stack>
  </Box>
);

export default SendLinkView;

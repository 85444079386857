import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormGroup,
  FormControl,
  Checkbox,
  Avatar,
  Box,
  Typography,
  Tooltip,
  Stack,
  Divider,
} from '@mui/material';
import { Agent } from '@marageti/z4-sdk/lib/people';
import { ParticipantInfo } from '@marageti/z4-sdk/lib/travel';
import LoadingButton from '@mui/lab/LoadingButton';
import { getRoleDisplayName } from '../../../utils/helpers';

const EXCLUDED_PERSON_TYPES = ['traveler', 'teamMember'];

interface ParticipantRowProps {
  participant: ParticipantInfo | Agent;
  disabled?: boolean;
  checked: boolean;
  onToggle: (participant: ParticipantInfo | Agent) => void;
  assignedTo?: string;
  primaryTravelerId?: string;
}

interface EditParticipantsViewProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onToggleParticipant: (participant: ParticipantInfo | Agent) => void;
  participants: ParticipantInfo[];
  agents: Agent[];
  selectedParticipantIds: Set<string>;
  assignedTo: string | undefined;
  travelerId: string | undefined;
  isSubmitting?: boolean;
}

const getParticipantRole = (participant: ParticipantInfo | Agent, assignedTo: string | undefined, primaryTravelerId: string | undefined) => {
  if (primaryTravelerId === participant.id) return 'Primary Traveler';
  if (assignedTo === participant.id) return 'Assigned Specialist';
  if ('personType' in participant && participant.personType === 'traveler') return 'Co-Traveler';
  return getRoleDisplayName(participant.userInfo[0].roles) || '-';
};

const ParticipantRow = ({
  participant, disabled, checked, onToggle, assignedTo, primaryTravelerId,
}: ParticipantRowProps) => (
  <FormControl sx={{ width: '100%', cursor: 'pointer' }} onClick={disabled ? undefined : () => onToggle(participant)}>
    <Box display="flex" width="100%" gap={2} alignItems="center">
      <Avatar src={'avatar' in participant ? participant.avatar : undefined}>
        <Typography variant="captionBold">
          {participant.givenName[0]}
          {participant.familyName[0]}
        </Typography>
      </Avatar>
      <Stack width="100%">
        <Typography variant="bodyBold">
          {`${participant.givenName} ${participant.familyName}`}
        </Typography>
        <Typography variant="caption">
          {getParticipantRole(participant, assignedTo, primaryTravelerId)}
        </Typography>
      </Stack>
      <Tooltip
        title={disabled ? 'Cannot be removed.' : ''}
        placement="top"
        PopperProps={{
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, -25],
            },
          }],
        }}
      >
        <span>
          <Checkbox
            size="large"
            checked={checked}
            disabled={disabled}
          />
        </span>
      </Tooltip>
    </Box>
  </FormControl>
);

const EditParticipantsView = ({
  open,
  onClose,
  onConfirm,
  onToggleParticipant,
  participants,
  agents,
  selectedParticipantIds,
  assignedTo,
  travelerId,
  isSubmitting,
}: EditParticipantsViewProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        maxWidth: '400px',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
      },
    }}
  >
    <DialogTitle>Edit Participants</DialogTitle>
    <DialogContent sx={{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      pt: 2,
    }}
    >
      <DialogContentText>
        Manage participants in this conversation to receive message notifications and trip updates.
      </DialogContentText>
      <FormGroup sx={{
        mt: 4,
        gap: 2,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#FAFAFA',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#C0C0C0',
          borderRadius: '4px',
        },
      }}
      >
        {participants.map((participant) => (
          <ParticipantRow
            key={participant.id}
            participant={participant}
            disabled={EXCLUDED_PERSON_TYPES.includes(participant.personType) || assignedTo === participant.id}
            checked={selectedParticipantIds.has(participant.id)}
            onToggle={onToggleParticipant}
            assignedTo={assignedTo}
            primaryTravelerId={travelerId}
          />
        ))}
        {agents.length > 0 && <Divider sx={{ mr: 2 }} />}
        {agents.map((agent) => (
          <ParticipantRow
            key={agent.id}
            participant={agent}
            checked={selectedParticipantIds.has(agent.id)}
            onToggle={onToggleParticipant}
          />
        ))}
      </FormGroup>
    </DialogContent>
    <DialogActions sx={{ px: 4, py: 3 }}>
      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        onClick={onConfirm}
        disabled={isSubmitting}
      >
        Confirm
      </LoadingButton>
      <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default EditParticipantsView;

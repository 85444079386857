import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient, useFetchImmutable } from '@marageti/z4-lib';
import { TimeZoneMap } from '@marageti/z4-sdk/lib/standards';
import GettingStartedView from './getting-started-view';
import { useAuthStore } from '../../store';

const EditUserContainer = () => {
  const apiClient = useApiClient();
  const { loggedInAgent } = useAuthStore();
  const navigate = useNavigate();

  const timezones = useFetchImmutable<TimeZoneMap>(apiClient.standardsClient.getTimeZones, [], true);
  const timezonesMap = timezones.data ? new Map(Object.entries(timezones.data)) : new Map();
  const suggestedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/_/g, ' ');
  const suggestedTimezoneOption = timezonesMap.has(suggestedTimezone) ? {
    name: suggestedTimezone,
    rawOffsetInMinutes: timezonesMap.get(suggestedTimezone),
  }
    : {
      name: '',
      rawOffsetInMinutes: 0,
    };

  useEffect(() => {
    // If the user already has a valid timezone set, redirect them to the home page since they've already completed this step.
    const shouldRedirectToHome = loggedInAgent?.timeZone.name;
    if (shouldRedirectToHome) {
      navigate({
        pathname: '/',
      });
    }
  }, [loggedInAgent]);

  const redirectToHomeWithWelcomeMessage = () => {
    navigate({
      pathname: '/',
      search: '?welcome=true',
    });
  };

  return (
    <GettingStartedView
      agentId={loggedInAgent?.id}
      suggestedTimezoneOption={suggestedTimezoneOption}
      onSuccess={redirectToHomeWithWelcomeMessage}
    />
  );
};

export default EditUserContainer;
